import { LabPortalErrorPage } from '../../../LabPortalErrorPage';
import { LabPortalOrdersPage } from './LabPortalOrdersPage';
import type { TabOption } from './components/LabPortalOrderListTabs/LabPortalOrderListTabs';
import { LabPortalOrderUpdatesListView } from './components/LabPortalOrderListViews/updates/LabPortalOrderUpdatesListView';
import { LabPortalPullFromFloorUpdatesListView } from './components/LabPortalOrderListViews/updates/LabPortalPullFromFloorUpdatesListView';
import { LabPortalOrderTabCountsCtxProvider } from './components/providers/LabPortalOrderTabCountsProvider';
import { convertFilterFromHumanReadableQueryParams } from './utils/QueryParamConversionUtils';
import type { LabsGqlLabPortalUpdatesOrderListCountsFragment } from '@orthly/graphql-operations';
import { useLabPortalUpdatesOrderCountsQuery } from '@orthly/graphql-react';
import { LabsGqlLabPortalLabOrderSortKey } from '@orthly/graphql-schema';
import { useQueryParam } from '@orthly/ui';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

const TabOptions: TabOption[] = [
    {
        label: 'Pull From Floor',
        tabId: 'pull-from-floor',
        pathValue: '/updates/pull-from-floor',
        countsQueryKey: 'pullFromFloor',
    },
    { label: 'Order Updates', tabId: '', pathValue: '/updates', countsQueryKey: 'orderUpdates' },
];

export const LabPortalUpdatesPage: React.VFC = () => {
    const [filterString, _setFilterString] = useQueryParam('filters');

    const { data: countData, refetch } = useLabPortalUpdatesOrderCountsQuery({
        variables: {
            criteria: filterString ? convertFilterFromHumanReadableQueryParams(filterString) : [],
            sort: {},
            columns: TabOptions.map(opt => opt.countsQueryKey),
        },
    });

    const tabOptionsWithCounts = React.useMemo(
        () =>
            TabOptions.map(option => ({
                ...option,
                orderCount:
                    countData?.orders?.[
                        option.countsQueryKey as keyof Omit<
                            LabsGqlLabPortalUpdatesOrderListCountsFragment,
                            '__typename'
                        >
                    ],
            })),
        [countData],
    );

    return (
        <LabPortalOrderTabCountsCtxProvider refetch={refetch}>
            <LabPortalOrdersPage
                title={'Updates'}
                tabOptions={tabOptionsWithCounts}
                customSortOptions={[
                    { label: 'Ship Date', value: LabsGqlLabPortalLabOrderSortKey.ShipByDate },
                    { label: 'Time in List', value: LabsGqlLabPortalLabOrderSortKey.TimeInList },
                ]}
            >
                <Switch>
                    <Route exact path={`/updates/pull-from-floor`}>
                        <LabPortalPullFromFloorUpdatesListView />
                    </Route>
                    <Route exact path={'/updates'}>
                        <LabPortalOrderUpdatesListView />
                    </Route>
                    <Route>
                        <LabPortalErrorPage />
                    </Route>
                </Switch>
            </LabPortalOrdersPage>
        </LabPortalOrderTabCountsCtxProvider>
    );
};
