import { PanLabelSeries } from './PanLabelSeries.graphql';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const PanLabelRoute: React.VFC = () => {
    const { search } = useLocation();
    const queryParams = React.useMemo(() => new URLSearchParams(search), [search]);
    const orderIds = queryParams.get('orderId')?.split(',');
    // Mostly for testing purposes, suppress automatic printing when someone includes print=false as a URL parameter.
    const autoPrintEnabled = queryParams.get('print')?.toLowerCase() !== 'false';
    if (orderIds && orderIds.length > 0) {
        return <PanLabelSeries labOrderIds={orderIds} autoPrintEnabled={autoPrintEnabled} />;
    }
    return null;
};
