import { OrthlyApolloProvider } from '@orthly/graphql-react';
import { OrthlyEnvironment } from '@orthly/shared-types';
import { OrthlyBrowserConfig, OrthlyFrontendApps } from '@orthly/ui';
import React from 'react';

const config = new OrthlyBrowserConfig(OrthlyFrontendApps.labPortal);

export const LabPortalGqlProvider: React.FC = props => {
    return (
        <OrthlyApolloProvider
            clientName={process.env.REACT_APP_LOCAL_PROD ? `${config.appName}-LOCAL_PROD` : config.appName}
            version={config.version}
            env={process.env.REACT_APP_LOCAL_PROD ? OrthlyEnvironment.production : config.env}
            routerEndpoint={process.env.REACT_APP_GATEWAY_URL ?? '/graphql'}
            loadingFallback={null}
            disablePersistedCache={true}
        >
            {props.children}
        </OrthlyApolloProvider>
    );
};
