import { PracticeScreen } from '@orthly/dentin';
import {
    FlossPalette,
    Grid,
    Link,
    Paper,
    TablePrimitive as Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    makeStyles,
    Icon,
} from '@orthly/ui-primitives';
import type { ReactNode } from 'react';
import React from 'react';

const useStyles = makeStyles({
    root: {
        marginTop: 16,
        width: '100%',
    },
    nameCol: {
        display: 'flex',
        flexDirection: 'column',
    },
    nameColNums: {
        display: 'flex',
        flexDirection: 'row',
    },
    nameColOrderId: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: FlossPalette.STAR_GRASS,
        '&:hover': { cursor: 'pointer' },
    },
    bulkActionsLabel: {
        padding: '8px',
    },
    openInNewIcon: {
        maxHeight: '16px',
    },
    nameColOrderNum: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: FlossPalette.GRAY,
    },
    caraouselDotIcon: {
        maxHeight: '8px',
    },
});

export const LabPortalListViewNameColumn: React.VFC<{ orderId: string; orderNumber: string; patientName: string }> = ({
    orderId,
    orderNumber,
    patientName,
}) => {
    const classes = useStyles();

    return (
        <Grid className={classes.nameCol}>
            <b>{patientName}</b>
            <Grid className={classes.nameColNums}>
                <Link onClick={() => window.open(`/${PracticeScreen.lab}/${orderId}`, '_blank')}>
                    <Grid className={classes.nameColOrderId}>
                        <Grid>{orderId.slice(0, 5)}</Grid>
                        <Icon icon={'OpenInNew'} className={classes.openInNewIcon} />
                    </Grid>
                </Link>
                {orderNumber && (
                    <Grid className={classes.nameColOrderNum}>
                        <Icon icon={'FiberManualRecord'} className={classes.caraouselDotIcon} />
                        <Grid>{`#${orderNumber}`}</Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export const LabPortalListViewBulkActionsHeader: React.VFC<{ bulkActions: ReactNode }> = ({ bulkActions }) => {
    const classes = useStyles();
    return (
        <Grid>
            <Grid className={classes.bulkActionsLabel}>Actions</Grid>
            {bulkActions}
        </Grid>
    );
};

export interface ColumnDef {
    label: string | ReactNode;
    cell: (order: any) => ReactNode;
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

const LabPortalOrderListViewTableRow: React.VFC<{
    order: any;
    columns: ColumnDef[];
}> = ({ order, columns }) => {
    return (
        <TableRow hover tabIndex={-1} style={order.alert && { backgroundColor: FlossPalette.ATTENTION_BG }}>
            {columns.map((column, i) => (
                <TableCell key={i} align={column.align ?? 'left'}>
                    {column.cell(order)}
                </TableCell>
            ))}
        </TableRow>
    );
};

export const LabPortalOrderListView: React.VFC<{
    orders: any[];
    columns: ColumnDef[];
}> = ({ orders, columns }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.root} elevation={0}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {columns.map((column, i) => (
                            <TableCell key={i} align={column.align ?? 'left'} style={{ minWidth: 0 }}>
                                <b>{column.label}</b>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orders.map((order, i) => (
                        <LabPortalOrderListViewTableRow key={i} order={order} columns={columns} />
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
};
