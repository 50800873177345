import type { BillingDetailPanelTableData, BillingDetailTableData } from './types';
import { Format } from '@orthly/runtime-utils';
import { FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

export const HighlightChanged: React.FC<{ text: string; changed: boolean }> = props => {
    const { text, changed } = props;
    return (
        <>
            {text} {changed && <span style={{ color: FlossPalette.GRAY }}>(adjusted)</span>}
        </>
    );
};

export const TotalPrice: React.FC<{ data: BillingDetailPanelTableData }> = props => {
    const { order_price_cents, unit_price_cents, original_unit_price_cents } = props.data;
    const changed = unit_price_cents !== original_unit_price_cents;
    const totalPriceDollarsString = Format.currency(order_price_cents / 100, 'dollars');
    return <HighlightChanged text={totalPriceDollarsString} changed={changed} />;
};

export const OrderTotalPrice: React.FC<{ data: BillingDetailTableData }> = props => {
    const { order_price_cents, has_lab_adjustment } = props.data;
    const totalPriceDollarsString = Format.currency(order_price_cents / 100, 'dollars');
    return <HighlightChanged text={totalPriceDollarsString} changed={has_lab_adjustment} />;
};
