import { useManufacturerSelector } from '../../manufacturer/state/manufacturer.selectors';
import { useTimelineSteps } from '../components/LabsOrderTimeline';
import type {
    LabsGqlOrderTimelineItemFragment,
    LabsGqlOrder,
    LabsGqlChatMessageDtoFragment,
} from '@orthly/graphql-operations';
import { useFindLastChatForEntityQuery } from '@orthly/graphql-react';
import { LabsGqlOrderTimelineEventNameEnum } from '@orthly/graphql-schema';
import { UuidUtils } from '@orthly/runtime-utils';
import { useSession } from '@orthly/session-client';
import { LAB_SLIP_LAST_PRINTED_AT_PARAM_NAME } from '@orthly/veneer';
import constate from 'constate';
import _ from 'lodash';
import moment from 'moment';

type HighlightTimelineItemsResult = {
    timelineItems: LabsGqlOrderTimelineItemFragment[];
    timelineLoading: boolean;
    labSlipLastPrintedAt: Date | null;
};

interface OrderDetailTimelineItemsProviderProps {
    order?: Pick<LabsGqlOrder, 'manufacturer_id'>;
    orderId: string;
}

function useTimelineHighlighting({
    orderId,
    order,
}: OrderDetailTimelineItemsProviderProps): HighlightTimelineItemsResult {
    const session = useSession();
    const { items: timelineItems, loading: timelineLoading } = useTimelineSteps(orderId);
    const { data: chatMessageData } = useFindLastChatForEntityQuery({
        variables: { entity_id: orderId },
        skip: !UuidUtils.isUUID(orderId) || !session?.organization_id,
    });
    const labSlipAlertIsDismissed = useManufacturerSelector(s => s.dismissedLabSlipWarningOrderIds.includes(orderId));
    const labSlipLastPrintedAt = getLabSlipLastPrintTime({
        labSlipAlertIsDismissed,
        order,
        items: timelineItems,
        lastMessage: chatMessageData?.findLastChatForEntity ?? undefined,
    });

    return { labSlipLastPrintedAt, timelineLoading, timelineItems };
}

const [OrderDetailTimelineItemsProvider, useOrderDetailTimelineItems] = constate<
    OrderDetailTimelineItemsProviderProps,
    HighlightTimelineItemsResult,
    []
>(useTimelineHighlighting);
export { useOrderDetailTimelineItems, OrderDetailTimelineItemsProvider };

function getLastPrintableLabSlipViewedTimeFromTimeline(
    items: readonly LabsGqlOrderTimelineItemFragment[],
): number | undefined {
    const dateString = _.maxBy(
        items.filter(event => event.orderEvent === LabsGqlOrderTimelineEventNameEnum.PrintableLabSlipViewed),
        event => event.date,
    )?.date;

    if (!dateString) {
        return undefined;
    }

    return moment(dateString).unix();
}

interface LabSlipLastPrintTimeProps {
    labSlipAlertIsDismissed: boolean;
    items: readonly LabsGqlOrderTimelineItemFragment[];
    order?: Pick<LabsGqlOrder, 'manufacturer_id'>;
    lastMessage?: LabsGqlChatMessageDtoFragment;
}

function getLabSlipLastPrintTime({
    labSlipAlertIsDismissed,
    lastMessage,
    items,
    order,
}: LabSlipLastPrintTimeProps): Date | null {
    if (labSlipAlertIsDismissed) {
        return null;
    }

    const params = new URLSearchParams(window.location.search);
    const labSlipLastPrintedAtTimestampFromParams = parseInt(params.get(LAB_SLIP_LAST_PRINTED_AT_PARAM_NAME) ?? '');
    const labSlipLastPrintedAtTimestamp =
        Number.isNaN(labSlipLastPrintedAtTimestampFromParams) ||
        labSlipLastPrintedAtTimestampFromParams < 0 ||
        labSlipLastPrintedAtTimestampFromParams > new Date().valueOf()
            ? getLastPrintableLabSlipViewedTimeFromTimeline(items)
            : labSlipLastPrintedAtTimestampFromParams;

    if (labSlipLastPrintedAtTimestamp === undefined) {
        return null;
    }

    if (order === undefined) {
        return null;
    }

    const labSlipLastPrintedAt = new Date(labSlipLastPrintedAtTimestamp * 1000);

    const relevantEvents = items.some(event => moment(event.date).unix() > labSlipLastPrintedAtTimestamp);

    const lastMessageBeforeLastPrint =
        !lastMessage || moment(lastMessage.created_at).unix() < labSlipLastPrintedAtTimestamp;

    if (!relevantEvents && lastMessageBeforeLastPrint) {
        return null;
    }

    return labSlipLastPrintedAt;
}
