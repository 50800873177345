import { LabInboxBulkActionIconButton } from '../../../../components/bulk-actions/LabInboxBulkActionIconButton';
import type { LabPortalOrderActionProps, BatchResult } from './LabPortalOrderActions.types';
import { useBulkReturnReceivedMutation } from '@orthly/graphql-react';
import { Format } from '@orthly/runtime-utils';
import { useChangeSubmissionFn } from '@orthly/ui';
import { FlossPalette, CheckIcon } from '@orthly/ui-primitives';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';

function useBulkMarkReturnReceivedAction(refetchOrders: () => Promise<any>) {
    const { enqueueSnackbar } = useSnackbar();
    const [submitMtn] = useBulkReturnReceivedMutation({});
    const runUpload = React.useCallback(
        async (orderIds: string[]): Promise<BatchResult> => {
            const batches = _.chunk(orderIds, 10);
            const result: BatchResult = { errorIds: [], successIds: [], skippedIds: [] };
            await Promise.all(
                batches.map(async batchOrderIds => {
                    const { errors, data } = await submitMtn({ variables: { order_ids: batchOrderIds } });
                    if (errors) {
                        result.errorIds.push(...batchOrderIds);
                    } else if (data) {
                        result.errorIds.push(...data.markReturnReceivedBulk.error_ids);
                        result.successIds.push(...data.markReturnReceivedBulk.success_ids);
                    }
                }),
            );
            return result;
        },
        [submitMtn],
    );
    return useChangeSubmissionFn<BatchResult, [string[]]>(runUpload, {
        onSuccess: async result => {
            const errorCount = result.errorIds.length;
            const successCount = result.successIds.length;
            const totalCount = errorCount + successCount;
            if (errorCount > 0) {
                const countLabel = totalCount > 1 ? `${errorCount} of ${totalCount} orders` : `order`;
                enqueueSnackbar(`Unable to mark ${countLabel} as received, please retry or text Dandy`, {
                    variant: 'error',
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                });
            } else {
                enqueueSnackbar(
                    `Marked ${Format.pluralize('order', successCount)} as received and moved to New with Return!`,
                    {
                        variant: 'success',
                        anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    },
                );
            }
            await refetchOrders();
        },
    });
}

export const LabPortalMarkReturnReceivedAction: React.FC<LabPortalOrderActionProps> = props => {
    const { selectedOrders, refetch, disabled } = props;
    const { submit, submitting } = useBulkMarkReturnReceivedAction(refetch);
    const itemsLabel = props.onSingleRow ? 'order' : Format.pluralize('order', selectedOrders.length);
    return (
        <LabInboxBulkActionIconButton
            onClick={() => submit(selectedOrders.map(o => o.id))}
            loading={submitting}
            disabled={disabled}
            disableTooltip={disabled}
            selectionCount={selectedOrders.length}
            tooltipTitle={`Mark ${itemsLabel} as received and move to New with Return`}
        >
            <CheckIcon
                style={{ color: selectedOrders.length === 0 || submitting ? undefined : FlossPalette.STAR_GRASS }}
            />
        </LabInboxBulkActionIconButton>
    );
};
