import type { LabsGqlLabOrderForLabSlipFragment } from '@orthly/graphql-operations';
import { LabsGqlDenturesProductionType } from '@orthly/graphql-schema';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

/**
 * Helper to determine if we need to show the Print Wax Rim Instructions button in the batch shipping station
 */
export function useNeedsWaxRimInstructions() {
    const { value: waxRimPrintEnabled } = useFeatureFlag('printWaxRimInstructionsWithUnboxingSlip');
    const [needsWaxRimInstructions, setNeedsWaxRimInstructions] = React.useState(false);
    // This is a separate hook because we don't want to pre-load the order if we're not printing the packing slip,
    // so we wait for them to click print as the trigger to load the data
    const onLoadUnboxingSlip = React.useCallback(
        (order?: LabsGqlLabOrderForLabSlipFragment) => {
            // we dont parse the items here cause we can just check the GQL quickly to see if its a wax rim
            const hasWaxRim = (order?.items_v2 ?? []).some(
                i =>
                    i.__typename === 'DentureItem' &&
                    i.denture_production_type === LabsGqlDenturesProductionType.WaxRim,
            );
            if (hasWaxRim && waxRimPrintEnabled) {
                setNeedsWaxRimInstructions(true);
            }
        },
        [waxRimPrintEnabled],
    );
    return {
        needsWaxRimInstructions,
        onLoadUnboxingSlip,
        instructionUrl: 'https://cdn.meetdandy.com/denture-info/Wax-Rim-IFU.pdf',
    };
}
