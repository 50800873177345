import { LabInboxBulkActionIconButton } from '../../../../../components/bulk-actions/LabInboxBulkActionIconButton';
import { calculateShipDateDisplay } from '../../../utils/DisplayUtils';
import { useLabPortalOrderListViewContext } from '../../providers/LabPortalOrderListViewProvider';
import { useLabPortalOrderTabCountsContext } from '../../providers/LabPortalOrderTabCountsProvider';
import type { ColumnDef } from '../shared/LabPortalOrderListView';
import { LabPortalOrderListView, LabPortalListViewNameColumn } from '../shared/LabPortalOrderListView';
import { OrderItemsDisplay } from '../shared/ListViewDisplays';
import { StatusIconMap } from '../shared/OrderStatusTags';
import type { UpdateTagKey } from '../shared/UpdateTags';
import { UpdateTags } from '../shared/UpdateTags';
import {
    useLabPortalOrderUpdatesByListViewQuery,
    useAcknowledgeLabUpdatesForOrderMutation,
} from '@orthly/graphql-react';
import type {
    LabsGqlLabPortalPullFromFloorUpdateListItem,
    LabsGqlLabPortalUpdateInfo,
    LabsGqlLabOrderStatus,
} from '@orthly/graphql-schema';
import { LoadBlocker, IndicatorFlag } from '@orthly/ui';
import { FlossPalette, CheckIcon } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export const LabPortalOrderUpdatesListView: React.VFC = () => {
    const { filters, sort } = useLabPortalOrderListViewContext();
    const { refetch: refetchCounts } = useLabPortalOrderTabCountsContext();

    const [acknowledgeUpdates] = useAcknowledgeLabUpdatesForOrderMutation();
    const { data, loading, refetch } = useLabPortalOrderUpdatesByListViewQuery({
        fetchPolicy: 'no-cache',
        variables: { criteria: filters, labPortalSort: sort },
    });

    const columns: ColumnDef[] = React.useMemo(
        () => [
            {
                label: 'Name',
                cell: row => (
                    <LabPortalListViewNameColumn
                        orderId={row.orderId}
                        orderNumber={row.orderNumber}
                        patientName={row.patientName}
                    />
                ),
            },
            {
                label: 'Items',
                cell: (row: LabsGqlLabPortalPullFromFloorUpdateListItem) => (
                    <OrderItemsDisplay items={row.items ?? []} />
                ),
            },
            {
                label: 'Model Type',
                cell: row => row.modelType ?? '',
            },
            {
                label: 'Status',
                cell: row => (
                    <IndicatorFlag
                        color={FlossPalette.GRAY}
                        title={_.startCase(row.status)}
                        iconName={StatusIconMap[row.status as LabsGqlLabOrderStatus]}
                    />
                ),
            },
            {
                label: 'Update Type',
                cell: row =>
                    row.updates.map((u: LabsGqlLabPortalUpdateInfo) => UpdateTags[u.updateType as UpdateTagKey]),
            },
            {
                label: 'Time in List',
                cell: row => new Date(row.minUpdateDate).toLocaleString(),
            },
            {
                label: 'Last Update',
                cell: row => new Date(row.maxUpdateDate).toLocaleString(),
            },
            {
                label: 'Ship Date',
                cell: row => calculateShipDateDisplay(row.shipDate),
            },
            {
                label: 'Acknowledge',
                cell: row => (
                    <LabInboxBulkActionIconButton
                        onClick={async () => {
                            await acknowledgeUpdates({
                                variables: { order_id: row.orderId, max_update_date: row.maxUpdateDate },
                            });
                            await refetch();
                            await refetchCounts();
                        }}
                        loading={false}
                        disabled={false}
                        disableTooltip={false}
                        selectionCount={1}
                        tooltipTitle={`Acknowledge Update`}
                    >
                        <CheckIcon
                            style={{
                                color: FlossPalette.STAR_GRASS,
                            }}
                        />
                    </LabInboxBulkActionIconButton>
                ),
            },
        ],
        [acknowledgeUpdates, refetch, refetchCounts],
    );

    return (
        <LoadBlocker blocking={loading}>
            <LabPortalOrderListView orders={data?.updates ?? []} columns={columns} />
        </LoadBlocker>
    );
};
