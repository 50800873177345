import { useRemoveLabsFulfillmentShippingMutation } from '@orthly/graphql-react';
import { LabsGqlLabOrderStatus as OrderStatus } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { useChangeSubmissionFn } from '@orthly/ui';
import moment from 'moment';

export const VALID_ORDER_STATUSES = [OrderStatus.Delivered, OrderStatus.Shipped];

type IsEnabledArgs = {
    manufacturerId: string;
    orderStatus: OrderStatus;
    shipDate: Date | null;
    shippedByLab: boolean | null;
    organizationId?: string;
};

/** Check whether deleting a shipment is enabled. */
export function isEnabled(args: IsEnabledArgs): boolean {
    const { manufacturerId, orderStatus, shipDate, shippedByLab, organizationId } = args;

    if (!organizationId || organizationId !== manufacturerId) {
        return false;
    }
    if (!shippedByLab) {
        return false;
    }
    if (VALID_ORDER_STATUSES.includes(orderStatus) === false) {
        return false;
    }
    // If it was shipped > 14 days ago and it's been delivered, don't let labs delete it.
    if (orderStatus === OrderStatus.Delivered && shipDate && moment(shipDate).isBefore(moment().subtract(14, 'days'))) {
        return false;
    }

    return true;
}

type UseViewModelArgs = Omit<IsEnabledArgs, 'session'> & {
    salesOrderId: string;
};

export function useViewModel(args: UseViewModelArgs) {
    const session = useSession();
    const [mutation] = useRemoveLabsFulfillmentShippingMutation({
        variables: { data: { orderId: args.salesOrderId } },
    });
    const { submit, submitting } = useChangeSubmissionFn<any, []>(() => mutation(), {
        closeOnComplete: true,
        successMessage: () => ['Order shipment removed', {}],
    });

    const enabled = isEnabled({
        manufacturerId: args.manufacturerId,
        orderStatus: args.orderStatus,
        shipDate: args.shipDate,
        shippedByLab: args.shippedByLab,
        organizationId: session?.organization_id,
    });

    return { enabled, submit, submitting };
}
