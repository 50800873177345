import { PerhapsYouMeantToBeSomewhereElse } from './PerhapsYouMeantToBeSomewhereElse';
import { AnalyticsClient } from './analytics/analyticsClient';
import { LOGIN_ROUTE } from './routes';
import { LoggedOutRoot } from './screens/logged-out/LoggedOutRoot';
import { ManufacturerAppRoot } from './screens/manufacturer/ManufacturerAppRoot';
import { ConfigService } from './utils';
import { useManufacturerIdFromSession } from './utils/authorization';
import { HotKeyProvider } from '@orthly/dentin';
import { SessionGuard, SessionGuardUtils, useSession } from '@orthly/session-client';
import { AppContainer, LaunchDarklyProvider, useLaunchDarklyUserContext } from '@orthly/veneer';
import type { LocationDescriptorObject } from 'history';
import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

const AppLoggedIn: React.VFC = () => {
    const manufacturerId = useManufacturerIdFromSession();

    if (manufacturerId) {
        return <ManufacturerAppRoot manufacturerId={manufacturerId} />;
    }

    return <PerhapsYouMeantToBeSomewhereElse />;
};

export const App: React.VFC = () => {
    const ldContext = useLaunchDarklyUserContext();
    const location = useLocation();
    const [originalLocation] = React.useState<LocationDescriptorObject>({ ...location });

    const session = useSession();
    React.useEffect(() => {
        // We only identify here for labs because practices are identified when they select a user via
        // the Netflix-style login menu.
        // This method of identifying labs is identical to how we identify users in the ops portal.
        if (session?.organization_type === 'lab') {
            AnalyticsClient.identify(session.user_id, {
                firstName: session.user.first_name,
                lastName: session.user.last_name,
                email: session.user.email || undefined,
                roles: session.roles,
            });
        }
    }, [session]);

    return (
        <LaunchDarklyProvider config={ConfigService} context={ldContext}>
            <AppContainer>
                <HotKeyProvider>
                    <Switch>
                        <Route path={LOGIN_ROUTE}>
                            <LoggedOutRoot originalLocation={originalLocation} />
                        </Route>
                        <Route path={`/*`}>
                            <SessionGuard
                                test={SessionGuardUtils.sessionExists}
                                fallback={<Redirect to={LOGIN_ROUTE} />}
                            >
                                <AppLoggedIn />
                            </SessionGuard>
                        </Route>
                    </Switch>
                </HotKeyProvider>
            </AppContainer>
        </LaunchDarklyProvider>
    );
};
