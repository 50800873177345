import { LabPortalOrderDetails_Query } from './order-detail-v2/OrderDetailRoot.graphql';
import type { DocumentNode, WatchQueryFetchPolicy } from '@apollo/client';
import { useQuery } from '@apollo/client';
import type { LabPortalOrderDetails_FragmentFragment } from '@orthly/graphql-inline-react';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useOrder } from '@orthly/graphql-react';
import type { LabsGqlPracticeOrderStatus } from '@orthly/graphql-schema';
import React from 'react';
import { useHistory } from 'react-router-dom';

export type LabOrderRootStatus = LabsGqlPracticeOrderStatus;

export function useOpenOrderDetailPage() {
    const history = useHistory();

    return React.useCallback(
        (orderId: string, e?: React.MouseEvent) => {
            // button 1 is the middle mouse button.
            const openInNewWindow = e?.metaKey || e?.ctrlKey || e?.button === 1;
            const url = `/orders/${orderId}`;
            if (openInNewWindow) {
                window.open(url);
            } else {
                history.push(url);
            }
            return openInNewWindow;
        },
        [history],
    );
}

export type LabOrderForLabPortal = Omit<LabPortalOrderDetails_FragmentFragment, '__typename'> & LabsGqlOrder;

type UseLabPortalOrderOptions = {
    fetchPolicy?: WatchQueryFetchPolicy;
};

/**
 * Intended to combine data returned from 'useOrder' hook (single lab order query)
 * with data returned from new 'labPortalOrderDetails' query
 * The intention is for the use of 'useOrder' to go away entirely,
 * and all data required to load order details in the lab portal to go through the
 * 'labPortalOrderDetails' endpoint
 */
export function useLabPortalOrderDetails(orderId?: string | null, options?: UseLabPortalOrderOptions) {
    const { order, refetch: refetchOldOrderData, loading: loadingOldOrderData } = useOrder(orderId, options);

    const {
        data: labPortalData,
        refetch: refetchLabPortalData,
        loading: loadingLabPortalData,
    } = useQuery(LabPortalOrderDetails_Query as DocumentNode, {
        ...options,
        variables: { orderId },
    });

    const newOrder: LabOrderForLabPortal = React.useMemo(() => {
        if (!order || !labPortalData) {
            return order;
        }
        return {
            ...order,
            ...labPortalData.order,
        };
    }, [order, labPortalData]);

    const refetch = React.useCallback(
        async (variables: any) => {
            await Promise.all([refetchOldOrderData(variables), refetchLabPortalData(variables)]);
        },
        [refetchOldOrderData, refetchLabPortalData],
    );

    const loading = React.useMemo(() => {
        return loadingOldOrderData || loadingLabPortalData;
    }, [loadingOldOrderData, loadingLabPortalData]);

    return { order: newOrder, refetch, loading };
}
