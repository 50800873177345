import type { ActionCardVariant } from './ActionCard.types';
import { Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

interface ActionCardTitleProps {
    children: string | React.ReactNode;
}

export const ActionCardTitle: React.FC<ActionCardTitleProps> = props => {
    return (
        <Grid container>
            {typeof props.children !== 'string' ? (
                props.children
            ) : (
                <Text color={'BLACK'} variant={'body1'} medium role={'note'}>
                    {props.children}
                </Text>
            )}
        </Grid>
    );
};

interface ActionCardSubtitleProps {
    children: string | React.ReactNode;
    variant: ActionCardVariant;
}

export const ActionCardSubtitle: React.FC<ActionCardSubtitleProps> = props => {
    const { children, variant } = props;
    if (typeof children !== 'string') {
        return <>{children}</>;
    }
    return (
        <Grid container>
            <Text
                variant={'body2'}
                color={variant === 'black' ? 'WHITE' : 'DARK_GRAY'}
                style={variant === 'black' ? { fontWeight: 500 } : undefined}
            >
                {children}
            </Text>
        </Grid>
    );
};
