import { UpdateMetalPricingBanner } from '../components/UpdateMetalPricingBanner';
import {
    useGetLabMetalCostQuery,
    useGetMetalMaterialQuery,
    useUpsertMetalCostsBulkMutation,
} from '@orthly/graphql-react';
import type { LabsGqlLabMetalCostUpsertInput, LabsGqlLabMetalCostQueryDto } from '@orthly/graphql-schema';
import { MUITable } from '@orthly/mui-table';
import { SimpleTextField, CheckIcon, useChangeSubmissionFn, PencilOutlinedIcon } from '@orthly/ui';
import { Grid, IconButton } from '@orthly/ui-primitives';
import React from 'react';

type CostTableRowType = {
    id: string;
    metal_id: string;
    metal_name: string;
    current_price: number | null;
    upcoming_price: number | null;
    price_id: string | null;
};

interface EditMetalPriceProps {
    row: CostTableRowType;
    loading: boolean;
    refetch: (variables?: any) => Promise<any>;
}

const EditMetalPrice: React.FC<EditMetalPriceProps> = props => {
    const price = `${props.row.upcoming_price ?? ''}`;
    const [input, setInput] = React.useState(price); // user input
    const [saved, setSaved] = React.useState(price); // query result
    const [editing, setEditing] = React.useState(false); // to render SimpleTextField for input
    const [disableSaveButton, setDisableSaveButton] = React.useState(false);

    if (saved !== price) {
        setSaved(price);
        setInput(price);
    }

    // to submit price change
    const [submitMtn] = useUpsertMetalCostsBulkMutation();
    const mtnSubmitter = (metal_costs: LabsGqlLabMetalCostUpsertInput[]) => submitMtn({ variables: { metal_costs } });
    const { submit } = useChangeSubmissionFn<any, [Array<LabsGqlLabMetalCostUpsertInput>]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Metal price updated!', {}],
        onSuccess: async () => {
            await props.refetch();
        },
    });

    const InactiveCell = React.useCallback(() => {
        if (props.loading) {
            return null;
        }
        return (
            <Grid container direction={'row'} justifyContent={'space-around'} alignItems={'center'}>
                <Grid item xs={4}>
                    {saved}
                </Grid>
                <Grid item>
                    <IconButton onClick={() => setEditing(true)}>
                        <PencilOutlinedIcon />
                    </IconButton>
                </Grid>
            </Grid>
        );
    }, [saved, props.loading]);

    if (!editing) {
        return <InactiveCell />;
    }

    return (
        <Grid container direction={'row'} justifyContent={'space-around'} alignItems={'center'}>
            <Grid item xs={6}>
                <SimpleTextField
                    size={'small'}
                    label={'$ / pennyweight'}
                    value={input}
                    variant={'standard'}
                    required
                    onChange={value => {
                        if (isNaN(Number(value)) || value === '') {
                            setInput(''); // this will trigger the error prompt to show up
                            setDisableSaveButton(true);
                        } else {
                            setInput(value);
                            setDisableSaveButton(false);
                        }
                    }}
                />
            </Grid>
            <Grid item>
                <IconButton
                    onClick={async () => {
                        if (input !== saved) {
                            await submit([
                                {
                                    id: props.row.price_id,
                                    metal_id: props.row.metal_id,
                                    price_cents: Math.round((parseFloat(input) || 0) * 100),
                                },
                            ]);
                            setSaved(input);
                        }
                        setEditing(false);
                    }}
                    disabled={disableSaveButton}
                >
                    <CheckIcon style={{ color: 'secondary' }} />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export const ManufacturerMetalPricingRoot: React.FC = () => {
    // Get all configured metal prices for this lab
    const { data: costs_raw, loading: loadingCost, refetch: refetchCost } = useGetLabMetalCostQuery();
    const metal_costs = React.useMemo<Map<string, LabsGqlLabMetalCostQueryDto>>(() => {
        return new Map((costs_raw?.getLabMetalCost ?? []).map(c => [c.metal_name, c]));
    }, [costs_raw]);

    // Get all available materials and merge with configured prices
    const { data: metals_raw, loading: loadingMaterial } = useGetMetalMaterialQuery();
    const prices = React.useMemo<CostTableRowType[]>(() => {
        const parsePrice = (name: string, get_current: boolean) => {
            const price = get_current
                ? metal_costs.get(name)?.current?.price_cents ?? null
                : metal_costs.get(name)?.upcoming?.price_cents ?? null;
            return price ? price / 100 : null;
        };

        return (metals_raw?.getMetalMaterial ?? []).map<CostTableRowType>(m => {
            return {
                id: m.id,
                metal_name: m.name,
                metal_id: m.id,
                price_id: metal_costs.get(m.name)?.upcoming?.id ?? null,
                current_price: parsePrice(m.name, true),
                upcoming_price: parsePrice(m.name, false),
            };
        });
    }, [metals_raw, metal_costs]);

    return (
        <Grid container justifyContent={'flex-start'}>
            <UpdateMetalPricingBanner />
            <Grid item xs={1}>
                {' '}
            </Grid>
            <Grid item xs={10}>
                <MUITable<CostTableRowType>
                    title={'Metal Pricing'}
                    data={prices}
                    displayOptions={{
                        fixedSearch: true,
                        elevation: 0,
                        sort: true,
                    }}
                    columns={[
                        {
                            name: 'Metal',
                            field: 'metal_name',
                            render: row => row.metal_name,
                            defaultSort: 'asc',
                        },
                        {
                            name: 'Current Month Price',
                            field: 'current_price',
                            render: row => row.current_price,
                        },
                        {
                            name: 'Next Month Price',
                            field: 'upcoming_price',
                            render: r => (
                                <EditMetalPrice
                                    row={r}
                                    loading={loadingCost || loadingMaterial}
                                    refetch={refetchCost}
                                />
                            ),
                        },
                    ]}
                />
            </Grid>
        </Grid>
    );
};
