export type { IconName } from '@orthly/ui-primitives';
export { Icon } from '@orthly/ui-primitives';

export {
    SKUAlignerIcon,
    SKUBridgeIcon,
    SKUCrownIcon,
    SKUDentureIcon,
    SKUImplantBridgeIcon,
    SKUImplantIcon,
    SKUInlayIcon,
    SKUNightguardIcon,
    SKUOnlayIcon,
    SKUPartialIcon,
    SKUUnknownIcon,
    SKUVeneerIcon,
    SKUScanbodyIcon,
} from '@orthly/ui-primitives';

export type { SortIconProps } from '@orthly/ui-primitives';
export {
    AMEXIcon,
    AccountGearIcon,
    AchIcon,
    AddCrossSectionIcon,
    AddNoteIcon,
    AlignerCaseIcon,
    AlignerIcon,
    AlignerModelFaceFrontIcon,
    AlignerModelFaceLeftIcon,
    AlignerModelFaceRightIcon,
    ArchiveIcon,
    AsteriskIcon,
    AttachFileIcon,
    AttachIcon,
    AutoStoriesIcon,
    BackIcon,
    BadgeIcon,
    BottomBiteIcon,
    BoxIcon,
    BoxOfAlignersIcon,
    BrushAddIcon,
    BrushReduceStrengthIcon,
    BrushSmoothIcon,
    BrushTomIcon,
    BrushSubtractIcon,
    BrushDeformIcon,
    CalendarAppointmentsIcon,
    CalendarIcon,
    CalendarTimelineIcon,
    CallIcon,
    CameraIcon,
    CareGuideIcon,
    CartBackgroundSafeIcon,
    CartIcon,
    CelebrationDandy,
    ChatIcon,
    CheckIcon,
    ChevronDown,
    ChevronLeft,
    ChevronRight,
    ChevronUp,
    ChewiesIcon,
    ClockIcon,
    CloseCrossSectionIcon,
    CloudUploadIcon,
    CogIcon,
    CollisionsIcon,
    CollisionsUndercutIcon,
    CompassIcon,
    ContourIcon,
    CopyFileIcon,
    CopyLinkIcon,
    CreateIntegrationOrderIcon,
    CrownIcon,
    CubeIcon,
    CurtainsIcon,
    CustomizeDashboardIcon,
    DandyAvatarIcon,
    DandyDIcon,
    DandyRecommendedIcon,
    DeliveryMopedIcon,
    DentureWithAppliance,
    DentureWithoutAppliance,
    DesignArrowIcon,
    DesignCircleIcon,
    DesignIcon,
    DiamondIcon,
    DisableZoomCrossSectionIcon,
    DiscoverIcon,
    DoctorBadgeIcon,
    DoubleArrowRightIcon,
    DownloadIcon,
    DrawArrowIcon,
    DrawCircleIcon,
    DrawIcon,
    DrawRectangleIcon,
    EmojiIcon,
    ExclamationIcon,
    ExpandIcon,
    FabricationIcon,
    FilterIcon,
    ForwardIcon,
    FrontBiteIcon,
    FrontNoSmileIcon,
    FrontSmileIcon,
    GradientIcon,
    HeatMapIcon,
    HelpIcon,
    HomeIcon,
    JCBIcon,
    JewelIcon,
    LabChatIcon,
    LabIcon,
    LayersIcon,
    LeftBiteIcon,
    LockIcon,
    LookAtContoursIcon,
    LowerJawIcon,
    MagnifierZoomInIcon,
    MagnifierZoomOutIcon,
    MapMarkerIcon,
    MastercardIcon,
    MinimizeIcon,
    MobileChatIcon,
    ModelDoctorMarginIcon,
    ModelFaceBackIcon,
    ModelFaceBottomIcon,
    ModelFaceFrontIcon,
    ModelFaceLeftIcon,
    ModelFaceRightIcon,
    ModelFaceTopIcon,
    ModelMarginIcon,
    ModelTextureIcon,
    MoreIcon,
    OcclusionMapIcon,
    OrderPaperIcon,
    PalmIcon,
    PencilIcon,
    PencilOutlinedIcon,
    PencilRulerIcon,
    PersonAvatarIcon,
    PersonIcon,
    PhotoFrameIcon,
    PlusIcon,
    PopOutIcon,
    PracticeChatIcon,
    PracticeIcon,
    ProximalMapIcon,
    ProximalUndercutMapIcon,
    QCExtrasIcon,
    QCHeatmapIcon,
    QCThicknessIcon,
    ReadIcon,
    RedoIcon,
    RefabCircleIcon,
    ResizeHandleIcon,
    RestorativesIcon,
    RestorativesTransparentIcon,
    ReturnIcon,
    RightBiteIcon,
    RunChecksIcon,
    ScanIcon,
    ScannerNavIconOutlined,
    SculptIcon,
    SearchIcon,
    SendIcon,
    SendToFabricationFloorIcon,
    ShadeMatchIcon,
    ShowCrossSectionIcon,
    SideProfileIcon,
    SidebarChatIcon,
    SmallTrashCanIcon,
    SplitIcon,
    SquareIcon,
    StopIcon,
    TeethWithTeeth,
    TextIcon,
    ThicknessMapIcon,
    ThumbsDownIcon,
    ThumbsUpCheckIcon,
    ThumbsUpIcon,
    ThumbsUpOutlinedIcon,
    TissuePressureIcon,
    ToDoIcon,
    ToolIcon,
    ToolOutlinedIcon,
    ToothFilledIcon,
    ToothInFrameIcon,
    TargetToothIcon,
    ToothMarkingsIcon,
    ToothXrayIcon,
    TopBiteIcon,
    TrackIcon,
    TrashCanIcon,
    TrashIcon,
    TravelPouchIcon,
    TruckIcon,
    TruckIconOutlined,
    UndercutShadowIcon,
    UndoIcon,
    UnlockIcon,
    UpperJawIcon,
    VisaIcon,
    VisibilityIcon,
    WarningIcon,
    WebcamIcon,
    WhitenerIcon,
    XIcon,
    XYZArrowsIcon,
    mobileChatSvg,
    DesignRestorativeIcon,
    IncognitoIcon,
    LassoIcon,
    CursorIcon,
    PatchIcon,
    SortIcon,
    SelectFrontFacingIcon,
    ClearSelectionsIcon,
    BrushFlattenIcon,
    BrushResetIcon,
    IterativeAlignmentIcon,
    PointsAlignmentIcon,
    ICPAlignmentIcon,
    SplitScreenIcon,
    PoiIcon,
    PoiIconWide,
} from '@orthly/ui-primitives';
