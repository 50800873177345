import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import type { IconName } from '@orthly/ui-primitives';

export const StatusIconMap: { [K in LabsGqlLabOrderStatus]-?: IconName } = {
    [LabsGqlLabOrderStatus.New]: 'OrderPaperIcon',
    [LabsGqlLabOrderStatus.NeedsReview]: 'WarningIcon',
    [LabsGqlLabOrderStatus.OnHold]: 'PalmIcon',
    [LabsGqlLabOrderStatus.Fabrication]: 'ToolOutlinedIcon',
    [LabsGqlLabOrderStatus.Shipped]: 'TruckIconOutlined',
    [LabsGqlLabOrderStatus.Delivered]: 'CheckIcon',
    [LabsGqlLabOrderStatus.Cancelled]: 'CancelIcon',
    [LabsGqlLabOrderStatus.NeedsRefabrication]: 'RefabCircleIcon',
};
