import { AnalyticsClient } from '../../../../analytics/analyticsClient';
import { filterPennyweights } from './EditPennyweight.util';
import { useUpdateOrderItemPennyweightsV2Mutation } from '@orthly/graphql-react';
import { type LabsGqlUpdateOrderItemPennyweightsV2Command as UpdatePennyweightsCommand } from '@orthly/graphql-schema';
import type { IOrderItemV2DTO, ToothNumber } from '@orthly/items';
import { useChangeSubmissionFn } from '@orthly/ui';

/** Whether pennyweight editing is enabled. */
export function isEnabled(items: IOrderItemV2DTO[]): boolean {
    return items.length > 0;
}

type SubmitArgs = {
    itemId: string;
    pennyweights: Array<{ unn: ToothNumber; pennyweight: number }>;
    salesOrderId: string;
};

/** Edit a partial denture item. */
export function submit(args: SubmitArgs, submitChange: (data: UpdatePennyweightsCommand) => Promise<unknown>) {
    AnalyticsClient.track('Lab - Item Pennyweight Edited', {
        itemId: args.itemId,
        $groups: { order: args.salesOrderId },
    });
    void submitChange({
        itemId: args.itemId,
        orderId: args.salesOrderId,
        precious_metal_pennyweights: args.pennyweights,
    });
}

export function useViewModel(items: IOrderItemV2DTO[]) {
    // TODO: [DECP-331] Use an API endpoint that calls into
    //     LabSalesOrderItemUpdateService.updateSalesOrderItems.
    //     It doesn't exist yet.
    const [mutation] = useUpdateOrderItemPennyweightsV2Mutation();
    const submitter = (data: UpdatePennyweightsCommand) => mutation({ variables: { data } });
    const {
        open,
        setOpen,
        submit: submitChange,
        submitting,
    } = useChangeSubmissionFn(submitter, {
        closeOnComplete: true,
        successMessage: () => ['Item pennyweights updated!', {}],
    });

    const filteredItems = filterPennyweights(items);
    const enabled = isEnabled(filteredItems);

    const submit_ = (args: SubmitArgs) => {
        submit(args, submitChange);
    };

    return { enabled, filteredItems, open, setOpen, submit: submit_, submitting };
}
