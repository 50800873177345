import { QCErrorLogoAndMessage, QCWarnings } from './QcStationError';
import { useQcStationRemoteData } from './useQcStationRemoteData';
import { PracticeScreen } from '@orthly/dentin';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { OrderItemV2Utils } from '@orthly/items';
import { FlossPalette, Text } from '@orthly/ui-primitives';
import { ItemsSection, mergeOrderItems, useGroupPreferences } from '@orthly/veneer';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

const GENDER_INITIALS: Record<string, string> = { Male: `M`, Female: `F` };

function slaDate(labOrderFragment: LabsGqlLabOrderFragment): { ship_verb: string; ship_date: string } {
    function getShipDays(difference: number) {
        switch (difference) {
            case 0:
                return 'today';
            case 1:
                return '1 day';
            default:
                return `${Math.abs(difference)} days`;
        }
    }

    function getShipVerb(difference: number) {
        if (difference === 0) {
            // Ship today
            return 'Ship';
        }

        if (difference < 0) {
            return 'Late by';
        }

        return 'Ship in';
    }

    const ship_by = moment(labOrderFragment.manufacturer_sla.ship_by_date);
    const now = moment();

    const difference = now.diff(ship_by, 'day');

    const ship_date = getShipDays(difference);
    const ship_verb = getShipVerb(difference);

    return { ship_verb, ship_date };
}

const HeaderSection: React.VFC<{ order: LabsGqlLabOrderFragment }> = ({ order }) => {
    const { ship_verb, ship_date } = slaDate(order);
    return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: 16 }}>
            <Text variant={`h5`}>
                {order.patient.first_name} {order.patient.last_name}
                <span style={{ paddingLeft: 8, fontSize: 14, color: FlossPalette.DARK_GRAY }}>
                    {order.patient.birthday && moment().diff(moment(order.patient.birthday), 'years')}{' '}
                    {order.patient.gender && (GENDER_INITIALS[order.patient.gender] ?? '')}
                </span>
            </Text>
            <Link
                to={`/${PracticeScreen.lab}/${order.id}`}
                style={{ textDecoration: 'none', color: FlossPalette.STAR_GRASS }}
            >
                View order
            </Link>
            <Text variant={`body2`} color={`DARK_GRAY`} style={{ display: 'inline-block', marginTop: 4 }}>
                {ship_verb} <span style={{ color: FlossPalette.BLACK }}>{ship_date}</span>
            </Text>
        </div>
    );
};

const SectionHeader: React.VFC<{ title: string }> = ({ title }) => (
    <Text variant={'h6'} style={{ fontWeight: 500, color: FlossPalette.BLACK, marginTop: 16 }}>
        {title}
    </Text>
);

export const QcStationOrderDetails: React.VFC = () => {
    const { labOrderFragment: order } = useQcStationRemoteData();
    const items = OrderItemV2Utils.parseItems(order?.items_v2 ?? []);

    const { majorityPrefValues } = useGroupPreferences(items);
    const mergedItems = mergeOrderItems(items, { majorityPrefs: majorityPrefValues });

    if (!order) {
        return null;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <HeaderSection order={order} />

            {order.qc_error && <QCErrorLogoAndMessage error={order.qc_error} style={{ padding: '16px' }} />}
            <QCWarnings orderId={order.id} />
            <div style={{ height: 1, width: '100%', background: FlossPalette.DARK_TAN }} />

            <div style={{ display: 'flex', flexDirection: 'column', padding: `0px 16px`, overflow: 'scroll' }}>
                <SectionHeader title={'Items'} />
                <ItemsSection target={'html'} items={mergedItems} hideHeader={true} />

                <SectionHeader title={'Order notes'} />
                <Text variant={`body2`} style={{ whiteSpace: 'pre-line', marginTop: 8 }}>
                    {order.doctor_notes || `None`}
                </Text>
            </div>
        </div>
    );
};
