import { UNIT_TYPES, useViewModel } from './AddItemToSalesOrder.vm';
import { type ExtraCartItemV2UnitType } from '@orthly/items';
import { QuickForm, RootActionDialog } from '@orthly/ui';
import React from 'react';

type FormData = {
    unitType: ExtraCartItemV2UnitType;
};

type FormProps = {
    onSubmit: (data: FormData) => void;
    unitTypes: ExtraCartItemV2UnitType[];
};

const Form: React.VFC<FormProps> = ({ onSubmit, unitTypes }) => {
    const options = unitTypes.map(i => ({ label: i, value: i }));
    return (
        <QuickForm<FormData>
            initialValues={{}}
            onSubmit={onSubmit}
            submitButtonTitle={'Add Item'}
            fields={{ unitType: { type: 'select', options } }}
        />
    );
};

type ModalProps = FormProps & {
    key: string;
    loading: boolean;
    open: boolean;
    setOpen: (open: boolean) => void;
};

/** Modal for adding an item to a sales order. */
const Modal: React.VFC<ModalProps> = ({ key, loading, onSubmit, open, setOpen, unitTypes }) => {
    return (
        <RootActionDialog
            buttonProps={{ style: { height: '100%' } }}
            buttonText={'Add Item to Order'}
            key={key}
            loading={loading}
            open={open}
            setOpen={setOpen}
            style={{ padding: 0 }}
            title={'Add Item to Order'}
            content={<Form onSubmit={onSubmit} unitTypes={unitTypes} />}
        />
    );
};

type ModalWithVmProps = {
    salesOrderId: string;
};

/** Modal with view model for adding an item to a sales order. */
export const AddItemToSalesOrderModal: React.VFC<ModalWithVmProps> = ({ salesOrderId }) => {
    const { open, setOpen, submit, submitting } = useViewModel();
    return (
        <Modal
            key={salesOrderId}
            loading={submitting}
            open={open}
            setOpen={setOpen}
            unitTypes={UNIT_TYPES}
            onSubmit={data => {
                submit({ ...data, salesOrderId });
            }}
        />
    );
};

export const forStorybook = { Form, Modal };
