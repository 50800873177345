import React from 'react';

/**
 * Return functions to manage state around selecting orders via checkbox
 * @returns Selected orders and mutations to select/deselect
 */
export function useSelectOrders<T extends { id: string }>(orders: T[]) {
    const [selectedOrderIds, setSelectedOrderIds] = React.useState<string[]>([]);

    const selectedOrders = React.useMemo<T[]>(() => {
        // this makes it so all orders are passed to the top level actions if none selected
        if (selectedOrderIds.length === 0) {
            return orders;
        }
        return orders.filter(o => selectedOrderIds.includes(o.id));
    }, [orders, selectedOrderIds]);

    const onSelectOrder = (orderId: string) => () => {
        setSelectedOrderIds(curr => (curr.includes(orderId) ? curr.filter(c => c !== orderId) : [...curr, orderId]));
    };

    const onSelectAll = React.useCallback(() => {
        setSelectedOrderIds(curr => (curr.length === orders.length ? [] : orders.map(o => o.id)));
    }, [orders]);

    const resetSelected = React.useCallback(() => {
        setSelectedOrderIds([]);
    }, []);

    return { selectedOrderIds, selectedOrders, onSelectOrder, onSelectAll, resetSelected };
}
