import { PanLabelLowerSection } from './PanLabelLowerSection';
import { type PanLabelShadesProps, PanLabelShades } from './PanLabelShades';
import React from 'react';

export interface PanLabelCrownBridgeImplantSpecificationsProps {
    __typename: 'CrownBridgeImplantPanLabelInfo';
    puck?: { material: string; heightMillimeters: number };
    crownMaterial?: string;
    occlusalStaining?: string;
    shades?: { shadeMessage: string } | PanLabelShadesProps;
}

export const PanLabelCrownBridgeImplantSpecifications: React.VFC<PanLabelCrownBridgeImplantSpecificationsProps> = ({
    puck,
    crownMaterial,
    occlusalStaining,
    shades,
}: PanLabelCrownBridgeImplantSpecificationsProps) => {
    const { material, heightMillimeters } = puck ?? {};
    const hasSecondSpecificationLine = !!crownMaterial || !!occlusalStaining || typeof heightMillimeters === 'number';
    return (
        <PanLabelLowerSection variant={'specifications'}>
            {material && <div>{material}</div>}
            {hasSecondSpecificationLine && (
                <div className={'pan-label-breaks-ok'}>
                    {crownMaterial && <span className={'pan-label-no-breaks'}>{crownMaterial}</span>}{' '}
                    {typeof heightMillimeters === 'number' && (
                        <span className={'pan-label-no-breaks'}>{heightMillimeters}mm</span>
                    )}{' '}
                    {occlusalStaining && <span className={'pan-label-no-breaks'}>OS: {occlusalStaining}</span>}
                </div>
            )}
            {shades && ('shadeMessage' in shades ? <div>{shades.shadeMessage}</div> : <PanLabelShades {...shades} />)}
        </PanLabelLowerSection>
    );
};
