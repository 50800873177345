import { SidebarWidthMobile } from '../../util';
import type { RequireChildrenProps } from '../../util/floss.types';
import { stylesFactory } from '../../util/stylesFactory';
import { Grid, FlossPalette } from '@orthly/ui-primitives';
import cx from 'clsx';
import React from 'react';

export type SidebarContainerPosition = 'left' | 'right';

const SIDEBAR_WIDTH = 400;

const useStyles = stylesFactory<{ position: SidebarContainerPosition }>(theme => ({
    root: props => ({
        flexDirection: 'column',
        background: FlossPalette.TAN,
        [props.position === 'right' ? 'borderLeft' : 'borderRight']: `1px solid ${FlossPalette.DARK_TAN}`,
        padding: '0 24px',
        position: 'relative',
        width: SIDEBAR_WIDTH,
        zIndex: theme.zIndex.appBar - 1,
        [theme.breakpoints.down('md')]: {
            width: SidebarWidthMobile,
        },
    }),
}));

export interface SidebarContainerProps extends RequireChildrenProps {
    position: SidebarContainerPosition;
    className?: string;
    style?: React.CSSProperties;
}

export const SidebarContainer: React.FC<SidebarContainerProps> = props => {
    const classes = useStyles({ position: props.position });
    return (
        <Grid container className={cx(classes.root, props.className)} style={props.style}>
            {props.children}
        </Grid>
    );
};
