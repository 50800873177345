import { LabsUtilsBase } from '@orthly/dentin';
import type { LabsGqlLabPriceLedgerResultFragment as LabPriceLedger } from '@orthly/graphql-operations';
import type { LabsGqlLabOverrideOrderUnitPriceArgs } from '@orthly/graphql-schema';
import { LoadBlocker, RootActionDialog } from '@orthly/ui';
import { FlossPalette, Button, Collapse, Grid, Text } from '@orthly/ui-primitives';
import { SimpleDropzone } from '@orthly/veneer';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import Papa from 'papaparse';
import React from 'react';
import { z } from 'zod';

type ManufacturerBillingAdjustmentImportRawRow = Record<string, string | undefined>;

const labOverrideOrderUnitPriceArgsCsvRowSchema = z.object({
    order_id: z.string(),
    price_id: z.string(),
    fulfillment_number: z.string(),
    price_idx: z.string(),
    'Adjusted Unit Price': z.string(),
    'Adjustment Reason': z.string(),
});

type ManufacturerBillingAdjustmentImportRow = z.infer<typeof labOverrideOrderUnitPriceArgsCsvRowSchema>;

function isValidLabOverrideOrderUnitPriceArgsCsvRowSchema(
    row: ManufacturerBillingAdjustmentImportRawRow,
): row is ManufacturerBillingAdjustmentImportRow {
    const parseResult = labOverrideOrderUnitPriceArgsCsvRowSchema.safeParse(row);
    return parseResult.success;
}

interface UseCsvRowsArgs {
    checkColumns: (columns: string[]) => boolean;
    onValid: (rows: ManufacturerBillingAdjustmentImportRawRow[]) => void;
}

function useCsvRows(args: UseCsvRowsArgs) {
    const { checkColumns, onValid } = args;
    return (files: File[]) => {
        if (!files[0]) {
            return;
        }

        Papa.parse(files[0], {
            skipEmptyLines: true,
            complete: results => {
                const rawRows = results.data as string[][];

                if (_.isEmpty(rawRows)) {
                    window.alert("The uploaded file doesn't contain any rows.");
                    return;
                }

                const [titleRow, ...dataRows] = rawRows;
                if (!(titleRow && checkColumns(titleRow))) {
                    return;
                }

                const entries = dataRows.map(row => _.zipObject(titleRow, row));

                onValid(entries);
            },
        });
    };
}

function csvRowToOverride(row: ManufacturerBillingAdjustmentImportRawRow): LabsGqlLabOverrideOrderUnitPriceArgs | null {
    if (!isValidLabOverrideOrderUnitPriceArgsCsvRowSchema(row)) {
        return null;
    }
    const price_cents_override = LabsUtilsBase.dollarInputToAmountCents(row['Adjusted Unit Price']);
    if (typeof price_cents_override !== 'number' || isNaN(price_cents_override)) {
        return null;
    }
    return {
        order_id: row['order_id'],
        fulfillment_number: _.toInteger(row['fulfillment_number']),
        price_idx: _.toInteger(row['price_idx']),
        price_id: row['price_id'],
        price_cents_override: Math.round(price_cents_override),
        override_description: row['Adjustment Reason'],
    };
}

function stripNoOps(
    overrides: LabsGqlLabOverrideOrderUnitPriceArgs[],
    ledger: LabPriceLedger[],
): LabsGqlLabOverrideOrderUnitPriceArgs[] {
    const entriesByOrderId = _.groupBy(ledger, e => e.order_id);
    const findExisting = (order_id: string, fulfillment_number: number, price_idx: number) => {
        const entries = entriesByOrderId[order_id] ?? [];
        const fulfillmentEntry = entries.find(e => e.fulfillment_number === fulfillment_number);
        return fulfillmentEntry ? fulfillmentEntry.lab_prices[price_idx] ?? null : null;
    };
    return overrides.filter(override => {
        const existing = findExisting(override.order_id, override.fulfillment_number, override.price_idx);
        const isNoOp = existing && override.price_cents_override === existing.price_cents;
        return !isNoOp;
    });
}

interface ManufacturerBillingAdjustmentImportProps {
    ledger: LabPriceLedger[];
    loading: boolean;
    refetch: () => Promise<any>;
    open: boolean;
    setOpen: (open: boolean) => void;
    bulkAdjustmentFn: () => any;
}

const ManufacturerBillingAdjustmentImport: React.FC<ManufacturerBillingAdjustmentImportProps> = props => {
    const useBulkPriceAdjustment = props.bulkAdjustmentFn;
    const { submitMtn, loading, success, errors } = useBulkPriceAdjustment();
    const { enqueueSnackbar } = useSnackbar();

    const [inputRows, setInputRows] = React.useState<ManufacturerBillingAdjustmentImportRawRow[] | undefined>(
        undefined,
    );

    const onDropAccepted = useCsvRows({
        checkColumns: columns => {
            const requiredColumns = [
                'order_id',
                'price_id',
                'fulfillment_number',
                'price_idx',
                'Adjusted Unit Price',
                'Adjustment Reason',
            ];
            if (!requiredColumns.every(c => columns.includes(c))) {
                window.alert('Required columns missing');
                return false;
            }
            return true;
        },
        onValid: (rows: ManufacturerBillingAdjustmentImportRawRow[]) => {
            setInputRows(rows.filter(row => typeof row.order_id === 'string' && row.order_id !== ''));
        },
    });

    async function onSubmit() {
        if (!inputRows || loading) {
            return;
        }
        const overrides = stripNoOps(_.compact(inputRows.map(r => csvRowToOverride(r))), props.ledger);
        await submitMtn({ variables: { overrides: { overrides } } });
        await props.refetch();
        setInputRows(undefined);
    }

    React.useEffect(() => {
        if (!inputRows && success && errors.length === 0) {
            enqueueSnackbar('Adjustments submitted successfully!', { variant: 'success' });
        }
    }, [inputRows, success, errors, enqueueSnackbar]);

    return (
        <LoadBlocker blocking={loading || props.loading}>
            <Grid container style={{ padding: '10px 30px', display: inputRows ? 'none' : undefined }}>
                <Grid>
                    Instructions:
                    <ul>
                        <li>Use "download" to download a list of the current order prices</li>
                        <li>
                            Modify "Adjusted Unit Price" as necessary and add a description of at least 30 characters
                        </li>
                        <li>
                            Do <b>not</b> modify or remove any other columns
                        </li>
                        <li>Upload the result here</li>
                    </ul>
                </Grid>
                <SimpleDropzone
                    preUploadText={'Import Adjusted Unit Prices (click or drop file)'}
                    wrapperStyle={{ minHeight: 40, padding: 0 }}
                    options={{ onDropAccepted, multiple: false }}
                />
            </Grid>
            <Grid container alignItems={'center'} wrap={'nowrap'} style={{ padding: 20 }}>
                <Grid container>
                    <Collapse in={!!inputRows} style={{ width: '100% ' }}>
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                onSubmit().catch(console.error);
                            }}
                            startIcon={'CloudUploadIcon'}
                        >
                            Upload Import
                        </Button>
                    </Collapse>
                </Grid>
            </Grid>
            {!inputRows && success && errors.length > 0 && (
                <Grid container alignItems={'center'} style={{ padding: 20 }}>
                    <Text variant={'body1'} style={{ fontWeight: 500, width: '100%', color: FlossPalette.ATTENTION }}>
                        Errors
                    </Text>
                    <Text variant={'body1'} style={{ whiteSpace: 'pre-line' }}>
                        {errors.join('\n') || ''}
                    </Text>
                </Grid>
            )}
        </LoadBlocker>
    );
};

export const ManufacturerBillingAdjustmentImportDialog: React.FC<ManufacturerBillingAdjustmentImportProps> = props => {
    return (
        <RootActionDialog
            loading={props.loading}
            open={props.open}
            setOpen={props.setOpen}
            title={'Upload Adjusted Prices'}
            content={<ManufacturerBillingAdjustmentImport {...props} />}
            hideButton={true}
        />
    );
};
