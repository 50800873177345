import React from 'react';

const SCROLLTOP_TOLERANCE_DEFAULT = 24;
/**
 * scroll to bottom if before rerender it's at the former bottom
 * @see {@link https://web.dev/i18n/en/resize-observer/}
 */
export const useRescrollToBottomIfNeeded = (
    innerDivRef: React.RefObject<HTMLDivElement>,
    tolerance: number = SCROLLTOP_TOLERANCE_DEFAULT,
) => {
    React.useEffect(() => {
        const node = innerDivRef.current;
        const parent = node?.parentElement;
        if (node && parent) {
            let lastScrollHeight = 0;
            const observer = new ResizeObserver(() => {
                const scrollTopThreshold = lastScrollHeight - parent.clientHeight - tolerance;
                if (parent.scrollTop > scrollTopThreshold) {
                    parent.scrollBy({
                        top: Number.MAX_SAFE_INTEGER,
                        // if hight increased over one full screen then just jump
                        behavior: parent.scrollHeight - lastScrollHeight > node.clientHeight ? 'auto' : 'smooth',
                    });
                }
                lastScrollHeight = parent.scrollHeight;
            });
            observer.observe(node);
            return () => observer.disconnect();
        }
    }, [innerDivRef, tolerance]);
};
