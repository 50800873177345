import { useCreateFulfillmentMutation } from '@orthly/graphql-react';
import { apolloErrorMessage, useChangeSubmissionFn } from '@orthly/ui';

export function useViewModel(salesOrderId: string) {
    // TODO: [DECP-331] Use an API endpoint that calls into
    //     the manufacturing order service.
    const [mutation] = useCreateFulfillmentMutation({ variables: { data: { orderId: salesOrderId } } });
    const { submit, submitting } = useChangeSubmissionFn<any, []>(mutation, {
        closeOnComplete: true,
        errorMessage: e => {
            return [apolloErrorMessage(e), {}];
        },
        successMessage: () => ['Order accepted into fabrication.', {}],
    });

    const submit_ = () => {
        void submit();
    };

    return { submit: submit_, submitting };
}
