import { setisNativeApp } from '../analytics/analyticsClient';
import { PartnerUiActions } from './ui.actions';
import { PracticeScreen } from '@orthly/dentin';
import { QueryString } from '@orthly/ui';
import type { LocationChangePayload } from 'connected-react-router';
import { LOCATION_CHANGE } from 'connected-react-router';
import type { AnyAction, Reducer } from 'redux';
import type { Action } from 'redux-actions';
import { handleActions } from 'redux-actions';

const query = QueryString.parse(window.location.search);
const isNativeApp = query.launch === 'pwa';
setisNativeApp(isNativeApp);

export interface KustomerUser {
    jwt: string;
    email: string | null;
    phone: string | null;
}

export interface PracticeUIState {
    isNativeApp: boolean;
    hideTopBar: boolean;
    sidebarOpen: boolean;
    screen: PracticeScreen;
    unreadInboxItems: number;
    unreadChatCount: number;
    activeSpotlight?: 'search' | 'qrCode' | 'searchStackCode';
    kustomerOpen: boolean;
    kustomerUser?: KustomerUser;
    kustomerLoggedIn: boolean;
}

const initialState: PracticeUIState = {
    isNativeApp,
    hideTopBar: false,
    sidebarOpen: false,
    screen: PracticeScreen.lab,
    unreadInboxItems: 0,
    unreadChatCount: 0,
    kustomerOpen: false,
    kustomerUser: undefined,
    kustomerLoggedIn: false,
};

const actionHandler = handleActions<PracticeUIState, any>(
    {
        [LOCATION_CHANGE]: (state, action: Action<LocationChangePayload>) => {
            // We only look at the first part after the leading slash so we can always highlight the link in the navigation menu
            // for pages like product hubs `/lab_products/clear_aligners` or my profile pages like `/my_profile/settings`
            const currentScreen = Object.values(PracticeScreen).find(screenName => {
                return action.payload.location.pathname.split('/')[1] === screenName;
            });

            if (currentScreen && currentScreen !== state.screen) {
                return { ...state, screen: currentScreen };
            }

            return state;
        },
        ...PartnerUiActions.UPDATE_UI.reducer<PracticeUIState>((s, a) => ({ ...s, ...a.payload })),
        ...PartnerUiActions.SET_SPOTLIGHT_OPEN.reducer<PracticeUIState>((s, a) => ({
            ...s,
            activeSpotlight: a.payload ? 'search' : undefined,
        })),
        ...PartnerUiActions.SET_STACKCODE_SEARCH.reducer<PracticeUIState>((s, a) => ({
            ...s,
            activeSpotlight: a.payload ? 'searchStackCode' : undefined,
        })),
        ...PartnerUiActions.SET_QR_CODE_OPEN.reducer<PracticeUIState>((s, a) => ({
            ...s,
            activeSpotlight: a.payload ? 'qrCode' : undefined,
        })),
        ...PartnerUiActions.SET_IS_PWA_MODE.reducer<PracticeUIState>((s, a) => ({
            ...s,
            isNativeApp: a.payload,
        })),
        ...PartnerUiActions.KUSTOMER_LOGIN.reducer((s, _a) => ({ ...s, kustomerLoggedIn: true })),
        ...PartnerUiActions.KUSTOMER_LOGOUT.reducer((s, _a) => ({
            ...s,
            kustomerLoggedIn: false,
            kustomerOpen: false,
        })),
        ...PartnerUiActions.KUSTOMER_OPEN.reducer((s, _a) => ({ ...s, kustomerOpen: true })),
        ...PartnerUiActions.KUSTOMER_CLOSE.reducer((s, _a) => ({ ...s, kustomerOpen: false })),
        ...PartnerUiActions.KUSTOMER_SET_USER.reducer((s, a) => ({ ...s, kustomerUser: a.payload })),
    },
    initialState,
);
export const uiReducer = actionHandler as Reducer<PracticeUIState, AnyAction>;
