import type React from 'react';

export enum SORT_ORDER {
    asc = 'asc',
    desc = 'desc',
}

export interface EnhancedTableProps {
    handleSort: (event: React.MouseEvent<unknown>, id: string) => void;
    sortOrder: SORT_ORDER;
    orderBy: string;
    columns: { id: string; numeric?: boolean; label: string; sortable?: boolean; alignRight?: boolean }[];
}
