import { AnalyticsClient } from '../../../../analytics/analyticsClient';
import { LabOrderChat } from '../../../labs/components/LabOrderChat';
import { OrderDetailTimelineItemsProvider } from '../../../labs/order-detail-v2/OrderDetailTimelineItemsProvider';
import { QcPassFail } from './QcStationPassFail';
import { useQcStationAction } from './state/QcStation.actions';
import { useQcStationPropSelector, useQcStationSelector } from './state/QcStation.selectors';
import { QcStationTab, QcStationResult } from './state/QcStation.state';
import { useQcStationRemoteData } from './useQcStationRemoteData';
import type { LabsGqlQcIssueFragment, LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { useListChatMessagesQuery, useQualityControlIssuesForOrderQuery } from '@orthly/graphql-react';
import { UuidUtils } from '@orthly/runtime-utils';
import { TrashCanIcon, PlusIcon, SimpleSelect, ToolbarContainer, TabWithBadge, ThumbsUpOutlinedIcon } from '@orthly/ui';
import {
    Text,
    FlossPalette,
    stylesFactory,
    Grid,
    IconButton,
    createStyles,
    Tabs,
    Tab,
    Button,
} from '@orthly/ui-primitives';
import type { QCItemChecklistProps } from '@orthly/veneer';
import {
    useQCItemChecklistProps,
    QCItemChecklistView,
    useOrthlyDetectGPU,
    UnboxingSlip,
    usePrintableState,
} from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() =>
    createStyles({
        qcIssuesArea: {
            flexGrow: 1,
            flexBasis: 0,
            borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
            overflow: `auto`,
            alignContent: `flex-start`,
            padding: 18,
        },
        qcIssue: {
            padding: `8px 0`,
            '&:not(:last-child)': {
                borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
            },
        },
        qcIssueHeader: {
            justifyContent: `space-between`,
            alignItems: `center`,
        },
        qcIssueHeaderButtons: {
            marginRight: -12,
        },
    }),
);

interface QcIssueValueSelectProps {
    options: { value: string }[];
    value?: string;
    prompt: string;
    defaultOpenState: boolean;
    onChange: (_: string | undefined) => void;
    deleteValueProps: {
        disabled: boolean;
        onClick: () => void;
    };
}

const QcIssueValueSelect: React.VFC<QcIssueValueSelectProps> = ({
    options,
    prompt,
    defaultOpenState,
    value,
    onChange,
    deleteValueProps,
}) => {
    const [open, setOpen] = React.useState(defaultOpenState);

    return (
        <Grid container alignItems={'center'} style={{ paddingBottom: 8 }}>
            <SimpleSelect
                value={value}
                options={options}
                onChange={onChange}
                label={prompt}
                SelectProps={{
                    open,
                    onOpen: () => setOpen(true),
                    onClose: () => setOpen(false),
                    fullWidth: true,
                    style: { background: FlossPalette.WHITE },
                    variant: 'standard',
                }}
                InputLabelProps={{ style: { background: FlossPalette.WHITE } }}
                FormControlProps={{ style: { width: 'unset', flexGrow: 1 }, variant: 'standard' }}
            />
            <div style={{ marginRight: -12 }}>
                <IconButton disabled={deleteValueProps.disabled} onClick={deleteValueProps.onClick}>
                    <TrashCanIcon />
                </IconButton>
            </div>
        </Grid>
    );
};

const QcIssueItem: React.VFC<{ qcIssueFragment: LabsGqlQcIssueFragment }> = ({ qcIssueFragment }) => {
    const { id, name, prompt, options: rawOptions } = qcIssueFragment;
    const issue = useQcStationSelector(s => s.issues[id]);
    const classes = useStyles();
    const dispatchAdd = useQcStationAction('ADD_ISSUE');
    const dispatchClear = useQcStationAction('CLEAR_ISSUE');
    const dispatchSetOptions = useQcStationAction('SET_ISSUE_OPTIONS');
    const showNewOption = useQcStationAction('SHOW_ISSUE_NEW_OPTION');
    const hideNewOption = useQcStationAction('HIDE_ISSUE_NEW_OPTION');
    const add = React.useCallback(() => dispatchAdd({ id }), [dispatchAdd, id]);
    const clear = React.useCallback(() => dispatchClear({ id }), [dispatchClear, id]);
    const set = React.useCallback((options: string[]) => dispatchSetOptions({ id, options }), [dispatchSetOptions, id]);
    const options = React.useMemo(() => rawOptions.map(value => ({ value })), [rawOptions]);
    const defaultOptionValue = options[0]?.value;

    return (
        <Grid item container className={classes.qcIssue}>
            <Grid item container direction={`row`} className={classes.qcIssueHeader}>
                <Text variant={`body2`}>{name}</Text>
                {issue ? (
                    <div className={classes.qcIssueHeaderButtons}>
                        {defaultOptionValue && (
                            <IconButton
                                disabled={issue.options.length === 0 && issue.showNewOption}
                                onClick={() => showNewOption({ id })}
                            >
                                <PlusIcon />
                            </IconButton>
                        )}
                        <IconButton onClick={clear}>
                            <TrashCanIcon />
                        </IconButton>
                    </div>
                ) : (
                    <IconButton onClick={add}>
                        <PlusIcon />
                    </IconButton>
                )}
            </Grid>
            <Grid item container>
                {defaultOptionValue &&
                    issue &&
                    issue.options.length > 0 &&
                    issue.options.map((option, index) => (
                        <QcIssueValueSelect
                            key={index}
                            options={options}
                            value={option}
                            prompt={prompt}
                            defaultOpenState={false}
                            onChange={newValue =>
                                newValue &&
                                set(
                                    issue.options.map((oldValue, currentIdx) =>
                                        currentIdx === index ? newValue : oldValue,
                                    ),
                                )
                            }
                            deleteValueProps={{
                                disabled: false,
                                onClick: () => set(issue.options.filter((_, currentIndex) => currentIndex !== index)),
                            }}
                        />
                    ))}
                {defaultOptionValue && issue && issue.showNewOption && (
                    <QcIssueValueSelect
                        value={undefined}
                        prompt={prompt}
                        options={options}
                        defaultOpenState={true}
                        onChange={val => val && set([...issue.options, val])}
                        deleteValueProps={{ disabled: false, onClick: () => hideNewOption({ id }) }}
                    />
                )}
            </Grid>
        </Grid>
    );
};

const PackingSheetPrintButton: React.FC<{ open: () => void }> = ({ open }) => {
    return (
        <div
            style={{
                width: '100%',
                backgroundColor: FlossPalette.STAR_GRASS,
                padding: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                    style={{
                        borderRadius: '50%',
                        backgroundColor: FlossPalette.WHITE,
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '8px',
                    }}
                >
                    <ThumbsUpOutlinedIcon
                        viewBox={'0 0 16 16'}
                        style={{
                            color: FlossPalette.STAR_GRASS,
                            width: '24px',
                            height: '24px',
                        }}
                    />
                </div>
                <Text variant={'h6'} color={'WHITE'}>
                    Passed
                </Text>
            </div>
            <Button variant={'secondary'} endIcon={'PrintIcon'} onClick={open}>
                Print
            </Button>
        </div>
    );
};

interface QcAreaProps {
    order: LabsGqlLabOrderFragment;
    qcProps: QCItemChecklistProps;
}

const QcArea: React.VFC<QcAreaProps> = ({ order, qcProps }) => {
    const classes = useStyles();
    const [checkedItems, setCheckedItems] = React.useState<Record<string, boolean>>({});

    const { printableIsOpen, openPrintable, closePrintable } = usePrintableState();

    const { submitQcPassed, submitQcFailed } = useQcStationRemoteData();
    const pass = React.useCallback(
        () =>
            submitQcPassed({
                checkedItems,
            }),
        [checkedItems, submitQcPassed],
    );
    const fail = React.useCallback(
        () =>
            submitQcFailed({
                checkedItems,
            }),
        [submitQcFailed, checkedItems],
    );

    const { issues, qcStationResult } = useQcStationPropSelector(['issues', 'qcStationResult']);
    const issuesAreValid = Object.values(issues).every(i => i.options.length > 0);

    const { data } = useQualityControlIssuesForOrderQuery({ variables: { order_id: order.id } });
    const checklistComplete = React.useMemo(
        () => qcProps.items.reduce((checklistComplete, item) => checklistComplete && !!checkedItems[item.id], true),
        [qcProps.items, checkedItems],
    );
    const canSubmit = checklistComplete && issuesAreValid;

    const qcIssueFragments = data?.getQCIssuesForOrder ?? [];
    const qcFailReasons = qcIssueFragments
        .flatMap(({ sub_issues }) => sub_issues)
        .filter(({ is_fail_reason }) => is_fail_reason);
    /* eslint-disable no-nested-ternary */
    return (
        <>
            <Grid item container style={{ flexGrow: 1, flexBasis: 0 }} direction={`column`}>
                <QCItemChecklistView
                    {...qcProps}
                    disabled={false}
                    checkedItems={checkedItems}
                    onChange={(itemId, checked) => setCheckedItems({ ...checkedItems, [itemId]: checked })}
                    onDelete={() => qcProps.refetch()}
                />
            </Grid>
            <Grid item container className={classes.qcIssuesArea}>
                {qcIssueFragments.map(qcIssueFragment => (
                    <QcIssueItem key={qcIssueFragment.id} qcIssueFragment={qcIssueFragment} />
                ))}
            </Grid>
            {qcStationResult === QcStationResult.Passed ? (
                <PackingSheetPrintButton
                    open={() => {
                        openPrintable();
                        AnalyticsClient.track('Lab - Portal - Unboxing Slip Opened', {
                            $groups: { order: order.id },
                            displayLocation: 'qc_station',
                        });
                    }}
                />
            ) : (
                <QcPassFail canSubmit={canSubmit} qcFailReasons={qcFailReasons} pass={pass} fail={fail} order={order} />
            )}
            {printableIsOpen && <UnboxingSlip orderId={order.id} onPrintComplete={closePrintable} />}
        </>
    );
};

const QcSidebarSectionContent: React.VFC<{ order: LabsGqlLabOrderFragment }> = ({ order }) => {
    const { data: chatMessagesData, refetch: refetchChatMessages } = useListChatMessagesQuery({
        variables: { entity_ids: [order.id] },
        skip: !UuidUtils.isUUID(order.id),
    });
    const chatMessages = React.useMemo(() => chatMessagesData?.listChatMessages ?? [], [chatMessagesData]);
    const setQcTab = useQcStationAction('SET_QC_TAB');
    const { qcTab } = useQcStationPropSelector(['qcTab']);
    const qcProps = useQCItemChecklistProps(order.id);
    const qcItemCount = qcProps.items.filter(i => !i.complete).length;
    const chatCount = chatMessages.length ?? 0;

    return (
        <>
            <ToolbarContainer>
                <Tabs
                    indicatorColor={'secondary'}
                    textColor={'inherit'}
                    value={qcTab}
                    onChange={(_, qcTab) => setQcTab(qcTab)}
                >
                    <Tab value={QcStationTab.Qc} label={<TabWithBadge count={qcItemCount} label={`QC`} />} />
                    <Tab value={QcStationTab.Chat} label={<TabWithBadge count={chatCount} label={`Chat`} />} />
                </Tabs>
            </ToolbarContainer>

            <OrderDetailTimelineItemsProvider order={order} orderId={order.id}>
                {qcTab === QcStationTab.Qc ? (
                    <QcArea key={order.id} order={order} qcProps={qcProps} />
                ) : (
                    <LabOrderChat order={order} chatMessages={chatMessages} refetchChatMessages={refetchChatMessages} />
                )}
            </OrderDetailTimelineItemsProvider>
        </>
    );
};
export const QcSidebarSection: React.VFC = () => {
    const { labOrderFragment } = useQcStationRemoteData();
    const { isBadGpu, loading } = useOrthlyDetectGPU();

    if (!labOrderFragment) {
        return null;
    }

    if (loading || isBadGpu) {
        return null;
    }
    return <QcSidebarSectionContent order={labOrderFragment} />;
};
