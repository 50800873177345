import { Button, Text, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    button: {
        padding: '0 16px 0 0',
        justifyContent: 'flex-start',
        height: 'unset',
    },
}));

const ContentBlock: React.VFC<{
    headline: string;
    buttons: {
        text: string;
        onClick: () => void;
    }[];
}> = ({ headline, buttons }) => {
    const classes = useStyles();

    return (
        <>
            <Text variant={`body2`} color={'ATTENTION_FOREGROUND'} medium>
                {headline}
            </Text>
            <div>
                {buttons.map(({ onClick, text }) => (
                    <Button key={text} variant={'ghost'} onClick={onClick} className={classes.button}>
                        {text}
                    </Button>
                ))}
            </div>
        </>
    );
};

/**
 * @deprecated Use OrderLinkedOrdersBanners instead
 *
 * Delete when no more usages exist
 */
export const OrderDetailCancelAndResubmitBlock: React.VFC<{
    cancelled_order_id: string | null;
    replaced_by_ids: string[] | null;
    openOrder: (orderId: string, e?: React.MouseEvent) => void;
}> = ({ cancelled_order_id, replaced_by_ids, openOrder }) => {
    // It's possible for an order that was cancelled & resubmitted to have been replaced by multiple orders (split order)
    // We currently only care to show the first order of the split in this block, and if they want to view the other orders
    // they'll see it in the split order block on the new order as expected.
    const replacedByOrderId = replaced_by_ids?.[0];

    if (!replacedByOrderId && !cancelled_order_id) {
        return null;
    }

    const viewNewOrderButton = {
        text: 'View new order',
        onClick: () => replacedByOrderId && openOrder(replacedByOrderId),
    };

    const viewOriginalOrderButton = {
        text: 'View original order',
        onClick: () => cancelled_order_id && openOrder(cancelled_order_id),
    };

    if (replacedByOrderId && cancelled_order_id) {
        return (
            <ContentBlock
                headline={'This is a resubmitted order that was canceled and resubmitted again'}
                buttons={[viewNewOrderButton, viewOriginalOrderButton]}
            />
        );
    } else if (replacedByOrderId) {
        return <ContentBlock headline={'This order was canceled & resubmitted'} buttons={[viewNewOrderButton]} />;
    } else {
        return <ContentBlock headline={'This is a resubmitted order'} buttons={[viewOriginalOrderButton]} />;
    }
};
