import React from 'react';

interface LabPortalOrderTabCountsContextVal {
    refetch: () => Promise<any>;
}

const LabPortalOrderTabCountsProvider = React.createContext<LabPortalOrderTabCountsContextVal>({
    refetch: async () => {},
});

export const LabPortalOrderTabCountsCtxProvider: React.FC<{
    refetch?: () => Promise<any>;
}> = props => {
    return (
        <LabPortalOrderTabCountsProvider.Provider
            value={{
                refetch: props.refetch ?? (async () => {}),
            }}
        >
            {props.children}
        </LabPortalOrderTabCountsProvider.Provider>
    );
};

export function useLabPortalOrderTabCountsContext() {
    return React.useContext<LabPortalOrderTabCountsContextVal>(LabPortalOrderTabCountsProvider);
}
