import type { SortOption } from './LabPortalOrderListSortButton';
import { DefaultSortOptions } from './LabPortalOrderListSortButton';
import type { LabsGqlLabPortalListOrdersSort } from '@orthly/graphql-schema';
import { Button, Grid, Icon } from '@orthly/ui-primitives';
import React from 'react';

export const LabPortalOrderListViewSortTag: React.VFC<{
    sort: LabsGqlLabPortalListOrdersSort;
    setSortAscending: (sortAscending: boolean) => void;
    customSortOptions?: SortOption[];
}> = ({ sort, setSortAscending, customSortOptions = [] }) => {
    return (
        <Grid>
            <Button variant={'secondary'} onClick={() => setSortAscending(!sort.asc)}>
                <Icon icon={sort.asc ? 'KeyboardArrowUpIcon' : 'KeyboardArrowDownIcon'} />
                {[...DefaultSortOptions, ...customSortOptions].find(opt => opt.value === sort.key)?.label}
            </Button>
        </Grid>
    );
};
