import { PracticeUtils } from '../../utils/utils';
import { LoggedOutLayout } from './components/LoggedOutLayout';
import { useLoginStyles } from './components/login-styles';
import type { LabsGqlUserLoginOptionFragment } from '@orthly/graphql-operations';
import { useStaffMemberLoginProps, useSession } from '@orthly/session-client';
import { EmailPasswordLoginFields } from '@orthly/ui';
import {
    FlossPalette,
    Grid,
    Link,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Text,
    Typography,
    ArrowForwardIcon,
} from '@orthly/ui-primitives';
import { LOCAL_STORAGE_PRACTICE_ID_KEY } from '@orthly/veneer';
import cx from 'classnames';
import type { LocationDescriptorObject } from 'history';
import _ from 'lodash';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const persistOrganizationIdToLocalStorage = (organizationId: string) => {
    try {
        localStorage.setItem(LOCAL_STORAGE_PRACTICE_ID_KEY, organizationId);
    } catch (_e) {
        return;
    }
};

const SelectOrganization: React.FC<{ loginOptions: LabsGqlUserLoginOptionFragment[] }> = ({ loginOptions }) => {
    const classes = useLoginStyles();
    const { onSelectToken } = useStaffMemberLoginProps();
    return (
        <Grid container className={classes.columnRow}>
            <List dense style={{ width: '100%' }}>
                {loginOptions.map((option, idx) => (
                    <ListItem key={idx} button onClick={() => onSelectToken(option.access_token, option.id)}>
                        <ListItemText
                            primary={option.organization_name}
                            secondary={_.startCase(option.organization_type)}
                        />
                        <ListItemSecondaryAction>
                            <ArrowForwardIcon style={{ color: FlossPalette.STAR_GRASS }} />
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </Grid>
    );
};

const LoginForm: React.FC<{ email?: string }> = props => {
    const { loginError, loading, onSubmitLogin, loginOptions } = useStaffMemberLoginProps();
    const classes = useLoginStyles();
    const history = useHistory();
    return (
        <>
            <Text className={cx(classes.titleFont, classes.columnRow)} variant={'h5'}>
                {loginOptions ? (
                    <>Select organization</>
                ) : (
                    <>
                        Welcome back <br />
                        to Dandy
                    </>
                )}
            </Text>
            {loginOptions ? (
                <SelectOrganization loginOptions={loginOptions} />
            ) : (
                <EmailPasswordLoginFields
                    containerStyle={{ display: 'block', marginBottom: 32, padding: 0 }}
                    error={loginError}
                    loading={loading}
                    onSubmit={onSubmitLogin}
                    email={props.email}
                />
            )}
            <Grid container className={classes.columnRow}>
                <Link
                    color={'inherit'}
                    onClick={() => {
                        history.push('/login/reset');
                    }}
                    variant={'body2'}
                    className={classes.linkFont}
                >
                    Forgot password?
                </Link>
            </Grid>
        </>
    );
};

interface PracticeLoginPageProps {
    originalLocation: LocationDescriptorObject;
}

export const LoginRoot: React.FC<PracticeLoginPageProps> = props => {
    const { originalLocation } = props;
    const history = useHistory();
    const session = useSession();
    const location = useLocation();
    const email = PracticeUtils.getParsedQueryProperty(`email`, location.search);
    React.useEffect(() => {
        if (session?.organization_type) {
            persistOrganizationIdToLocalStorage(session?.organization_id);
            if (originalLocation.pathname?.includes('login')) {
                // we don't want to infinite loop redirecting back to the login screen once they are logged in, so push them to the home screen
                // this solves an issue where a logged-in user would load the app directly on /login and be stuck here
                return history.push('/');
            }
            history.push(originalLocation);
        }
    }, [history, originalLocation, session?.organization_id, session?.organization_type]);

    return (
        <LoggedOutLayout
            leftTitle={
                <>
                    Meet Dandy.
                    <br />A smarter way to grow your practice.
                </>
            }
            leftContent={
                <Typography color={'inherit'} variant={'body1'} style={{ fontSize: '1.1rem' }}>
                    Digital dentistry designed to work for you.
                </Typography>
            }
            rightColumnContent={<LoginForm email={email} />}
        />
    );
};
