/* eslint-disable no-nested-ternary */
import { stylesFactory } from '../../../util';
import { LoadBlocker } from '../../Loaders';
import type { ButtonVariant } from '../Button';
import { Button } from '../Button';
import type { IconName } from '@orthly/ui-primitives';
import { FlossPalette, Grid } from '@orthly/ui-primitives';
import cx from 'clsx';
import type { MouseEventHandler } from 'react';
import React from 'react';

const useStyles = stylesFactory<{ errorButton?: boolean }>(theme => ({
    primaryButton: props => ({
        backgroundColor: props.errorButton ? `${FlossPalette.ATTENTION} !important` : undefined,
        [theme.breakpoints.down('lg')]: { width: '100%' },
    }),
    secondaryButton: {
        width: '100%',
    },
    primaryButtonWrapper: {
        width: 'auto',
        position: 'relative',
        [theme.breakpoints.down('lg')]: { minWidth: '50%' },
    },
    secondaryButtonWrapper: { width: 'auto', position: 'relative' },
    actionsWrapper: {
        width: 'fit-content',
        height: 'fit-content',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexWrap: 'nowrap',
        [theme.breakpoints.down('lg')]: {
            paddingTop: 16,
            width: '100%',
            justifyContent: 'space-between',
        },
        [theme.breakpoints.down('md')]: {
            paddingTop: 10,
            width: 'auto',
            justifyContent: 'space-between',
            alignSelf: 'flex-end',
        },
    },
    separateRowActionsWrapper: {
        marginLeft: 68,
        [theme.breakpoints.down('lg')]: {
            paddingTop: '0 !important',
            width: '100%',
            justifyContent: 'flex-start !important',
        },
    },
    bannerButton: {
        minWidth: 'min-content',
    },
}));

export interface ActionCardButtonPropsBase {
    loading?: boolean;
    text: string;
    onClick: MouseEventHandler;
    disabled?: boolean;
    endIcon?: IconName;
    errorButton?: boolean;
    buttonVariant?: ButtonVariant;
}

export type OrderDetailAlertVariant = 'standard' | 'sidebar' | 'detail_block';

type ActionCardButtonProps = ActionCardButtonPropsBase & {
    disabled?: boolean;
    cardVariant: OrderDetailAlertVariant;
    buttonsBelowContent?: boolean;
};

export const ActionCardButtonPrimary: React.FC<ActionCardButtonProps> = props => {
    const { text, onClick, loading, disabled, endIcon, buttonVariant, errorButton, cardVariant, buttonsBelowContent } =
        props;
    const classes = useStyles({ errorButton });
    const isSidebarElement = cardVariant === 'sidebar';
    return (
        <LoadBlocker blocking={!!loading} ContainerProps={{ className: classes.primaryButtonWrapper }}>
            <Button
                style={{ width: !isSidebarElement ? '100%' : undefined }}
                disabled={disabled || loading}
                onClick={onClick}
                onAuxClick={onClick}
                className={cx(classes.primaryButton, buttonsBelowContent && classes.bannerButton)}
                variant={buttonVariant ?? 'primary'}
                endIcon={endIcon ? 'ChevronRight' : undefined}
            >
                {text}
            </Button>
        </LoadBlocker>
    );
};

export const ActionCardButtonSecondary: React.FC<ActionCardButtonProps> = props => {
    const { text, onClick, loading, disabled, endIcon, buttonVariant, buttonsBelowContent } = props;
    const classes = useStyles({});
    return (
        <LoadBlocker blocking={!!loading} ContainerProps={{ className: classes.secondaryButtonWrapper }}>
            <Button
                onClick={onClick}
                onAuxClick={onClick}
                className={cx(classes.secondaryButton, buttonsBelowContent && classes.bannerButton)}
                variant={buttonVariant ?? (buttonsBelowContent ? 'secondary-gray' : 'ghost')}
                disabled={disabled || loading}
                endIcon={endIcon ?? undefined}
                uppercase={false}
            >
                {text}
            </Button>
        </LoadBlocker>
    );
};

interface ActionCardButtonsProps {
    primary?: ActionCardButtonPropsBase | (React.ReactElement & Partial<ActionCardButtonPropsBase>);
    secondary?: ActionCardButtonPropsBase | (React.ReactElement & Partial<ActionCardButtonPropsBase>);
    buttonsBelowContent?: boolean;
}

export const ActionCardButtons: React.FC<ActionCardButtonsProps> = props => {
    const { primary, secondary, buttonsBelowContent } = props;
    const classes = useStyles({});
    if (!primary && !secondary) {
        return <Grid container item className={classes.actionsWrapper} />;
    }

    return (
        <Grid
            container
            item
            className={cx(classes.actionsWrapper, buttonsBelowContent && classes.separateRowActionsWrapper)}
            spacing={1}
        >
            {secondary && (
                <Grid item>
                    {React.isValidElement(secondary) ? (
                        secondary
                    ) : (
                        <ActionCardButtonSecondary
                            {...secondary}
                            cardVariant={'standard'}
                            buttonsBelowContent={buttonsBelowContent}
                        />
                    )}
                </Grid>
            )}
            <Grid item>
                {primary &&
                    (React.isValidElement(primary) ? (
                        primary
                    ) : (
                        <ActionCardButtonPrimary
                            {...primary}
                            cardVariant={'standard'}
                            buttonsBelowContent={buttonsBelowContent}
                        />
                    ))}
            </Grid>
        </Grid>
    );
};
