import type { Theme } from '@orthly/ui-primitives';
import { FlossPalette, createStyles, makeStyles } from '@orthly/ui-primitives';

export const useLoginStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            width: '100vw',
            height: '100vh',
            background: '#FDFCFA',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                overflowY: 'scroll',
            },
        },
        root: {
            height: '100%',
            background: FlossPalette.WHITE,
            maxWidth: '60vw',
            maxHeight: '80vh',
            overflowY: 'scroll',
            [theme.breakpoints.down('lg')]: {
                maxWidth: '80vw',
                maxHeight: '95vh',
                height: 'fit-content',
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: '95vw',
                maxHeight: '95vh',
            },
        },
        column: {
            padding: '7% 5%',
            boxShadow: theme.shadows[3],
            position: 'relative',
        },
        columnLeft: {
            background: FlossPalette.BLACK,
            color: FlossPalette.WHITE,
            '& $columnRow': {
                maxHeight: 'fit-content',
            },
            [theme.breakpoints.down('sm')]: {
                padding: '3% 5%',
            },
        },
        columnRight: {
            background: '#fff',
            [theme.breakpoints.down('sm')]: {
                height: 'fit-content',
            },
        },
        columnRow: {
            marginBottom: '32px',
            position: 'relative',
        },
        columnRowBottom: {
            height: '100%',
            alignItems: 'flex-end',
            display: 'flex',
            maxHeight: 'unset !important',
        },
        logoWrap: {
            [theme.breakpoints.down('sm')]: {
                marginBottom: 0,
            },
        },
        hideMobile: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        logo: {
            maxWidth: '40%',
            objectFit: 'contain',
        },
        titleFont: {
            lineHeight: 'normal',
            width: '100%',
            fontSize: '2.2em',
        },
        linkFont: {
            fontFamily: 'Inter Regular, Verdana, sans-serif',
            textDecoration: 'unset !important',
            borderBottom: '1px solid',
            height: 'fit-content',
            cursor: 'pointer',
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            width: '100%',
            height: '100%',
        },
    }),
);
