/**
 * Removes the need to use React suspense with a given hook.
 * @param {() => T} suspenseHook the hook that depends on suspense
 * @returns {{result?: T, loading: boolean, error?: Error}}
 */
export function removeSuspense<T>(suspenseHook: () => T): { result?: T; loading: boolean; error?: Error } {
    try {
        return { result: suspenseHook(), loading: false, error: undefined };
    } catch (error: any) {
        if (error instanceof Promise) {
            return { loading: true };
        }
        return { error, loading: false };
    }
}
