import { type LabsGqlMarkFulfillmentShippedMutationVariables } from '@orthly/graphql-operations';
import { useMarkFulfillmentShippedMutation, useOrderRefetch } from '@orthly/graphql-react';
import {
    LabsGqlMarkShippedDestinationType as DestinationType,
    LabsGqlShippingCarriersEnum as ShippingCarriers,
} from '@orthly/graphql-schema';
import { type RootActionDialogProps } from '@orthly/ui';
import { QuickForm, RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import moment from 'moment-timezone';
import React from 'react';
import { z } from 'zod';

type MutationArgs = LabsGqlMarkFulfillmentShippedMutationVariables['data'];

type Props = {
    CustomButton?: RootActionDialogProps['CustomButton'];
    salesOrder: {
        createdAt: Date;
        id: string;
    };
};

/** Modal for marking an order as shipped. */
export const MarkOrderShippedModal: React.VFC<Props> = ({ CustomButton, salesOrder }) => {
    const [mutation] = useMarkFulfillmentShippedMutation();
    const submitter = (data: MutationArgs) => mutation({ variables: { data } });
    const refetch = useOrderRefetch();
    const { open, setOpen, submit, submitting } = useChangeSubmissionFn<any, [MutationArgs]>(submitter, {
        closeOnComplete: true,
        onSuccess: () => refetch(salesOrder.id),
        successMessage: () => ['Order marked shipped', {}],
    });

    const buttonProps = CustomButton
        ? { CustomButton }
        : {
              buttonText: 'Mark Shipped',
              buttonProps: { fullWidth: false },
          };

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Mark Shipped'}
            content={
                <QuickForm<Omit<MutationArgs, 'orderId'>>
                    fields={{
                        carrier: {
                            type: 'select',
                            label: 'Shipping Carrier',
                            options: Object.entries(ShippingCarriers).map(([label, value]) => ({
                                label,
                                value,
                            })),
                        },
                        destination: {
                            type: 'radioGroup',
                            label: 'Shipping destination',
                            validation: z.nativeEnum(DestinationType),
                            options: [
                                { label: 'Ship to Practice', value: DestinationType.Practice },
                                { label: 'Ship to Dandy', value: DestinationType.Dandy },
                            ],
                        },
                        ship_date: {
                            type: 'date',
                            helperText: 'Date you shipped the order',
                            fieldProps: { minDate: salesOrder.createdAt },
                        },
                        tracking_number: { type: 'text' },
                    }}
                    initialValues={{
                        carrier: ShippingCarriers.Fedex,
                        destination: DestinationType.Practice,
                        ship_date: moment().toJSON(),
                    }}
                    onSubmit={async (result, actions) => {
                        await submit({
                            ...result,
                            orderId: salesOrder.id,
                            ship_date: moment(result.ship_date).toJSON(),
                        });
                        actions.setSubmitting(false);
                    }}
                />
            }
            {...buttonProps}
        />
    );
};
