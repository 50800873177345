/* eslint-disable no-nested-ternary */
import type { FlossInputConfig } from '../primitives/Input/FlossInput';
import { FlossInputThemeProvider } from '../primitives/Input/FlossInput';
import type {
    ChipProps,
    FormControlProps,
    FormHelperTextProps,
    InputLabelProps,
    MenuItemProps,
    SelectProps,
} from '@orthly/ui-primitives';
import {
    Checkbox,
    Chip,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Grid,
} from '@orthly/ui-primitives';
import React from 'react';

export interface SimpleSelectOption {
    value: string;
    // value will be shown in the select option if label is undefined
    label?: React.ReactNode | string | null;
    disabled?: boolean;
}

interface CommonProps<ValueType> extends FlossInputConfig {
    placeholder?: string;
    backgroundColor?: React.CSSProperties['backgroundColor'];
    options: SimpleSelectOption[];
    value?: ValueType;
    onChange: (value?: ValueType) => void;
    variant?: 'standard' | 'outlined' | 'filled';
    label: string;
    native?: boolean;
    disabled?: boolean;
    helperText?: React.ReactNode;
    errorText?: React.ReactNode;
    FormControlProps?: FormControlProps;
    SelectProps?: Omit<SelectProps, 'native'>;
    InputLabelProps?: InputLabelProps;
    MenuItemProps?: MenuItemProps;
    FormHelperTextProps?: FormHelperTextProps;
    disableAutoNativeOnMobile?: boolean;
    style?: React.CSSProperties;
}

export type SimpleSelectProps = CommonProps<string> & {
    RenderOption?: React.FC<{ option: SimpleSelectOption }>;
};

export const SimpleSelect: React.FC<SimpleSelectProps> = props => {
    const { label, options, value, onChange, placeholder, disabled, style, RenderOption } = props;
    const variant = props.variant || 'standard';
    return (
        <FlossInputThemeProvider flossInputConfig={props.flossInputConfig}>
            <FormControl
                disabled={disabled}
                error={!!props.errorText}
                {...props.FormControlProps}
                variant={variant}
                style={{ width: '100%', ...props.FormControlProps?.style }}
            >
                <InputLabel id={label} shrink={!!placeholder || !!value} {...props.InputLabelProps}>
                    {label}
                </InputLabel>
                <Select
                    fullWidth
                    style={{ width: '100%', backgroundColor: props.backgroundColor, ...style }}
                    variant={variant}
                    value={value || ''}
                    onChange={e => {
                        const newValue = e.target.value ? (e.target.value as string) : undefined;
                        if (newValue !== props.value) {
                            onChange(newValue);
                        }
                    }}
                    disabled={disabled}
                    inputProps={{ name: label, id: label }}
                    {...props.SelectProps}
                    labelId={label}
                    displayEmpty={!!placeholder}
                    disableUnderline
                >
                    {placeholder && (
                        <MenuItem value={''}>
                            <em>{placeholder}</em>
                        </MenuItem>
                    )}
                    {options.map(opt => (
                        <MenuItem
                            key={opt.value}
                            value={opt.value}
                            disabled={opt.disabled}
                            {...(props.MenuItemProps as any)}
                        >
                            {RenderOption ? <RenderOption option={opt} /> : opt.label ? opt.label : opt.value}
                        </MenuItem>
                    ))}
                </Select>
                {(props.helperText || props.errorText) && (
                    <FormHelperText {...props.FormHelperTextProps} error={!!props.errorText}>
                        {props.errorText || props.helperText}
                    </FormHelperText>
                )}
            </FormControl>
        </FlossInputThemeProvider>
    );
};

export type SimpleMultiSelectProps = CommonProps<string[]>;

export const SimpleMultiSelect: React.FC<SimpleMultiSelectProps> = props => {
    const value = props.value || [];
    const { label, disabled, style } = props;
    const variant = props.variant || 'standard';
    return (
        <FlossInputThemeProvider flossInputConfig={props.flossInputConfig}>
            <FormControl
                variant={variant}
                error={!!props.errorText}
                style={{ width: '100%' }}
                {...props.FormControlProps}
                disabled={disabled}
            >
                <InputLabel htmlFor={label} variant={variant} {...props.InputLabelProps}>
                    {label}
                </InputLabel>
                <Select
                    multiple
                    fullWidth
                    style={{ width: '100%', backgroundColor: props.backgroundColor, ...style }}
                    variant={variant}
                    value={props.value}
                    onChange={event => {
                        props.onChange(event.target.value as string[]);
                    }}
                    renderValue={selected => {
                        if ((selected as string[]).length === 0 && props.placeholder) {
                            return <em>{props.placeholder}</em>;
                        }
                        return (selected as string[])
                            .map(s => {
                                const option = props.options.find(o => o.value === s);
                                return option && option.label ? option.label : s;
                            })
                            .join(', ');
                    }}
                    {...props.SelectProps}
                    inputProps={{ name: label, id: label }}
                    disableUnderline
                >
                    {props.placeholder && (
                        <MenuItem value={''} disabled>
                            <em>{props.placeholder}</em>
                        </MenuItem>
                    )}
                    {props.options.map(opt => (
                        <MenuItem
                            key={opt.value}
                            value={opt.value}
                            {...(props.MenuItemProps as any)}
                            disabled={opt.disabled}
                        >
                            <Checkbox color={'secondary'} checked={value.includes(opt.value)} disabled={opt.disabled} />
                            <ListItemText
                                primary={opt.label || opt.value}
                                primaryTypographyProps={{ variant: 'body2' }}
                            />
                        </MenuItem>
                    ))}
                </Select>
                {(props.helperText || props.errorText) && (
                    <FormHelperText error={!!props.errorText}>{props.errorText || props.helperText}</FormHelperText>
                )}
            </FormControl>
        </FlossInputThemeProvider>
    );
};

export interface SimpleMultiSelectChipsProps extends SimpleMultiSelectProps {
    ChipProps?: Partial<Omit<ChipProps, 'onDelete'>>;
}

export const SimpleMultiSelectChips: React.FC<SimpleMultiSelectChipsProps> = props => {
    const { ChipProps, ...multiSelectProps } = props;
    const { onChange } = multiSelectProps;
    const [selectOpen, setSelectOpen] = React.useState<boolean>(false);
    const renderValue = React.useCallback(
        (val: unknown) => {
            const selected: string[] = Array.isArray(val) ? val : [];
            return (
                <Grid container>
                    {selected.map(selectedVal => {
                        const label =
                            multiSelectProps.options.find(opt => opt.value === selectedVal)?.label ?? selectedVal;
                        return (
                            <Chip
                                label={label}
                                style={{ margin: '1px 2px' }}
                                {...ChipProps}
                                key={selectedVal}
                                onDelete={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onChange(selected.filter(v => v !== selectedVal));
                                }}
                            />
                        );
                    })}
                </Grid>
            );
        },
        [ChipProps, multiSelectProps.options, onChange],
    );
    return (
        <SimpleMultiSelect
            {...multiSelectProps}
            SelectProps={{
                ...multiSelectProps.SelectProps,
                renderValue,
                open: selectOpen,
                onClose: () => setSelectOpen(false),
                onClick: () => !selectOpen && setSelectOpen(true),
                variant: 'standard',
            }}
        />
    );
};
