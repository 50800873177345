import type { LabOrderRootStatus } from '../../screens/labs/LabsUtils';
import type { OrdersSortField } from './orders.reducer';
import { createSyncAction } from '@orthly/redux-async-actions';

const PREFIX = 'orders';

export const OrdersActions = {
    CHANGE_STATUS: createSyncAction<LabOrderRootStatus, [LabOrderRootStatus]>(`${PREFIX}/CHANGE_STATUS`),

    SET_DOCTOR_FILTER: createSyncAction<string[], [string[]]>(`${PREFIX}/SET_DOCTOR_FILTER`),

    SET_PRODUCT_FILTER: createSyncAction<string[], [string[]]>(`${PREFIX}/SET_PRODUCT_FILTER`),

    SET_SORT_COL: createSyncAction<OrdersSortField, [OrdersSortField]>(`${PREFIX}/SET_SORT_COL`),

    TOGGLE_SORT_DIR: createSyncAction<undefined, []>(`${PREFIX}/TOGGLE_SORT_DIR`),
};
