import type { ManufacturerBillingCsvRow } from './types';
import type { LabsGqlLabPriceLedgerResultFragment as LabPriceLedger } from '@orthly/graphql-operations';
import dayjs from 'dayjs';
import _ from 'lodash';

function centsToDollarsString(cents: number): string {
    return (cents / 100).toFixed(2);
}

export function ledgerToCsvRows(ledger: LabPriceLedger[]): ManufacturerBillingCsvRow[] {
    return _.orderBy(
        ledger.flatMap(entry =>
            entry.lab_prices.map((price, index) => {
                const adjustedUnitPrice = centsToDollarsString(price.price_cents);
                const originalUnitPrice = centsToDollarsString(
                    price.price_override?.original_price_cents ?? price.price_cents,
                );
                const adjustedTotalPrice = centsToDollarsString(price.total_price_cents);
                const originalTotalPrice = centsToDollarsString(
                    price.price_override?.original_total_price_cents ?? price.total_price_cents,
                );
                return {
                    order_id: entry.order_id,
                    fulfillment_number: entry.fulfillment_number.toString(),
                    price_idx: index.toString(),
                    item_id: price.item_id ?? '',
                    price_id: price.price_id,
                    'Order Number': entry.order_number,
                    'Ship Date': dayjs(entry.ship_date).utc().format('YYYY-MM-DD'),
                    Patient: `${entry.patient_first_name} ${entry.patient_last_name}`,
                    Product: price.price_name,
                    'Adjusted Unit Price': adjustedUnitPrice,
                    'Original Unit Price': originalUnitPrice,
                    Quantity: price.lab_unit_count.toFixed(2),
                    'Adjusted Total': adjustedTotalPrice,
                    'Original Total': originalTotalPrice,
                    'Adjustment Reason': price.price_override?.description || '',
                    reviewed: entry.reviewed,
                };
            }),
        ),
        [
            row => row['Ship Date'],
            row => row['Order Number'],
            row => row['fulfillment_number'],
            row => row['price_idx'],
            row => row['Product'],
        ],
        ['desc', 'asc', 'asc', 'asc', 'asc'],
    );
}
