import { styled } from '@orthly/ui-primitives';
import React from 'react';

const Shades = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: 'repeat(2, 1lh)',
    columnGap: '4px',
    gridAutoFlow: 'column',
    justifySelf: 'left',
});

export interface PanLabelShadesProps {
    gingival?: string;
    base?: string;
    incisal?: string;
    stump?: string;
}

export const PanLabelShades: React.VFC<PanLabelShadesProps> = ({ gingival, base, incisal, stump }) => {
    return (
        <Shades>
            <div>Gingival</div>
            <div>{gingival ?? '-'}</div>
            <div>Base</div>
            <div>{base ?? '-'}</div>
            <div>Incisal</div>
            <div>{incisal ?? '-'}</div>
            <div>Stump</div>
            <div>{stump ?? '-'}</div>
        </Shades>
    );
};
