import { LabPortalErrorPage } from '../../../LabPortalErrorPage';
import { LabPortalOrdersPage } from './LabPortalOrdersPage';
import type { TabOption } from './components/LabPortalOrderListTabs/LabPortalOrderListTabs';
import { LabPortalDesignListView } from './components/LabPortalOrderListViews/design/LabPortalDesignListView';
import { LabPortalDesignReviseListView } from './components/LabPortalOrderListViews/design/LabPortalDesignReviseListView';
import { LabPortalDesignSendToFloorListView } from './components/LabPortalOrderListViews/design/LabPortalDesignSendToFloorListView';
import { LabPortalDesignWaitForDandyListView } from './components/LabPortalOrderListViews/design/LabPortalDesignWaitForDandyListView';
import { LabPortalOrderTabCountsCtxProvider } from './components/providers/LabPortalOrderTabCountsProvider';
import { convertFilterFromHumanReadableQueryParams } from './utils/QueryParamConversionUtils';
import type { LabsGqlLabPortalDesignOrderListCountsFragment } from '@orthly/graphql-operations';
import { useLabPortalDesignOrderCountsQuery } from '@orthly/graphql-react';
import { LabsGqlLabPortalLabOrderSortKey } from '@orthly/graphql-schema';
import { useQueryParam } from '@orthly/ui';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

const TabOptions: TabOption[] = [
    { label: 'Design', tabId: '', pathValue: '/design', countsQueryKey: 'design' },
    {
        label: 'Wait for Dandy',
        tabId: 'wait-for-dandy',
        pathValue: '/design/wait-for-dandy',
        countsQueryKey: 'designWaitForDandy',
    },
    {
        label: 'Revise',
        tabId: 'revise',
        pathValue: '/design/revise',
        countsQueryKey: 'designRevise',
    },
    {
        label: 'Send to Floor',
        tabId: 'send-to-floor',
        pathValue: '/design/send-to-floor',
        countsQueryKey: 'designSendToFloor',
    },
];

export const LabPortalDesignPage: React.VFC = () => {
    const [filterString, _setFilterString] = useQueryParam('filters');

    const { data: countData, refetch } = useLabPortalDesignOrderCountsQuery({
        variables: {
            criteria: filterString ? convertFilterFromHumanReadableQueryParams(filterString) : [],
            sort: {},
            columns: TabOptions.map(opt => opt.countsQueryKey),
        },
    });

    const tabOptionsWithCounts = React.useMemo(
        () =>
            TabOptions.map(option => ({
                ...option,
                orderCount:
                    countData?.orders?.[
                        option.countsQueryKey as keyof Omit<LabsGqlLabPortalDesignOrderListCountsFragment, '__typename'>
                    ],
            })),
        [countData],
    );

    return (
        <LabPortalOrderTabCountsCtxProvider refetch={refetch}>
            <LabPortalOrdersPage
                title={'Design'}
                tabOptions={tabOptionsWithCounts}
                customSortOptions={[
                    { label: 'Ship Date', value: LabsGqlLabPortalLabOrderSortKey.ShipByDate },
                    { label: 'Time in List', value: LabsGqlLabPortalLabOrderSortKey.TimeInList },
                ]}
            >
                <Switch>
                    <Route exact path={`/design`}>
                        <LabPortalDesignListView />
                    </Route>
                    <Route exact path={'/design/wait-for-dandy'}>
                        <LabPortalDesignWaitForDandyListView />
                    </Route>
                    <Route exact path={'/design/revise'}>
                        <LabPortalDesignReviseListView />
                    </Route>
                    <Route exact path={'/design/send-to-floor'}>
                        <LabPortalDesignSendToFloorListView />
                    </Route>
                    <Route>
                        <LabPortalErrorPage />
                    </Route>
                </Switch>
            </LabPortalOrdersPage>
        </LabPortalOrderTabCountsCtxProvider>
    );
};
