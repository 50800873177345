import { useQcStationAction } from './state/QcStation.actions';
import { useQcStationRemoteData } from './useQcStationRemoteData';
import { useGetQcWarningsForOrderQuery } from '@orthly/graphql-react';
import { LabsGqlQcErrorType } from '@orthly/graphql-schema';
import { StopIcon, WarningIcon } from '@orthly/ui';
import {
    Button,
    FlossPalette,
    stylesFactory,
    Text,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
} from '@orthly/ui-primitives';
import React from 'react';

export const getOrderErrorDescription = (error: LabsGqlQcErrorType | null): string | undefined => {
    switch (error) {
        case LabsGqlQcErrorType.OnHold:
        case LabsGqlQcErrorType.Unknown:
            // Unknown is an edge case - order might be delivered or cancelled (or in some other state). Just tell the QC station its on hold
            return 'on hold';
        case LabsGqlQcErrorType.InDesign:
            return 'still in design';
        case LabsGqlQcErrorType.InReview:
            return 'still in review';
        default:
            return undefined;
    }
};

const useWarningStyles = stylesFactory<{ large?: boolean }>(() => ({
    circle: ({ large }) => ({
        backgroundColor: FlossPalette.WHITE,
        borderRadius: '50%',
        display: 'flex',
        height: large ? '60px' : '32px',
        width: large ? '60px' : '32px',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
    }),
    icon: ({ large }) => ({
        color: FlossPalette.ATTENTION,
        height: large ? '40px' : '24px',
        width: large ? '40px' : '24px',
    }),
    text: ({ large }) => ({
        fontWeight: 'bold',
        paddingLeft: large ? '24px' : '16px',
        minWidth: 'max-content',
    }),
    dialog: {
        maxWidth: 'min-content',
    },
    dialogContent: {
        padding: 0,
        borderTop: 'none',
        backgroundColor: FlossPalette.ATTENTION,
        color: FlossPalette.WHITE,
    },
    dialogActions: {
        padding: '0px 32px 32px 16px',
        width: '100%',
        backgroundColor: FlossPalette.ATTENTION,
    },
}));

export const QCErrorLogoAndMessage: React.VFC<{
    error: LabsGqlQcErrorType;
    style?: React.CSSProperties;
    large?: boolean;
}> = ({ error, style, large }) => {
    const styles = useWarningStyles({ large: large ?? false });
    const prefix = large ? 'This order is' : 'Order';
    return (
        <Grid
            container
            alignItems={'center'}
            wrap={'nowrap'}
            style={{ ...style, backgroundColor: FlossPalette.ATTENTION }}
        >
            <div className={styles.circle}>
                <StopIcon className={styles.icon} />
            </div>
            <Text variant={large ? 'h4' : 'h6'} className={styles.text} color={`WHITE`}>
                {`${prefix} ${getOrderErrorDescription(error)}`}
            </Text>
        </Grid>
    );
};

export const QCStationErrorModal: React.VFC = () => {
    const { labOrderFragment: order } = useQcStationRemoteData();
    const resetOrderSearch = useQcStationAction('RESET_ORDER_SEARCH');
    const resetOrderId = React.useCallback(() => {
        resetOrderSearch();
    }, [resetOrderSearch]);
    const styles = useWarningStyles({ large: true });
    const [overrideError, setOverrideError] = React.useState(false);

    if (!order?.qc_error) {
        return null;
    }

    return (
        <Dialog
            open={!overrideError}
            maxWidth={'sm'}
            fullWidth
            PaperProps={{ className: styles.dialog }}
            style={{ zIndex: 99999 }}
        >
            <DialogContent className={styles.dialogContent}>
                <QCErrorLogoAndMessage error={order.qc_error} style={{ padding: '32px', paddingRight: '64px' }} large />
            </DialogContent>
            <DialogActions className={styles.dialogActions}>
                <Button variant={'secondary'} onClick={resetOrderId}>
                    Await Resolution
                </Button>
                <Button
                    variant={'secondary'}
                    onClick={() => setOverrideError(true)}
                    style={{ color: FlossPalette.ATTENTION }}
                >
                    Proceed Anyway
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const QCWarningMessage: React.VFC<{
    title: string;
    details: string[];
    style?: React.CSSProperties;
}> = ({ title, details, style }) => {
    const styles = useWarningStyles({});
    return (
        <Grid
            container
            alignItems={`center`}
            wrap={`nowrap`}
            style={{ ...style, padding: '16px', backgroundColor: FlossPalette.ATTENTION_BG }}
        >
            <Grid item className={styles.circle}>
                <WarningIcon className={styles.icon} />
            </Grid>
            <Grid item style={{ marginLeft: 16, flexGrow: 1 }}>
                <Text variant={`body1`} color={`ATTENTION`} style={{ fontWeight: `bold` }}>
                    {title}
                </Text>
                {details.map((detail, i) => (
                    <Text key={i} variant={`body2`} color={`ATTENTION`}>
                        {detail}
                    </Text>
                ))}
            </Grid>
        </Grid>
    );
};

export const QCWarnings: React.VFC<{ orderId?: string }> = ({ orderId }) => {
    const { data } = useGetQcWarningsForOrderQuery({ variables: { order_id: orderId ?? '' }, skip: !orderId });
    return (
        <>
            {data?.getQcWarningsForOrder.map((warning, i) => (
                <QCWarningMessage key={i} title={warning.title} details={warning.details} />
            ))}
        </>
    );
};
