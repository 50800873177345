import { stylesFactory } from '../../util';
import React from 'react';

interface ShapeProps {
    infinite: boolean;
    index: number;
    length: number;
}

function animationFunc(props: ShapeProps) {
    return {
        [`0%`]: {
            strokeOpacity: 1,
            strokeDashoffset: props.length,
        },
        [`${90 - props.index * 10}%`]: {
            fillOpacity: 1,
            strokeDashoffset: 0,
        },
    };
}

const useStyles = stylesFactory(() => ({
    '@keyframes animateDrawD1': animationFunc({ length: 131, index: 0, infinite: true }),
    pathD1: {
        animationName: '$animateDrawD1',
    },
    '@keyframes animateDrawA': animationFunc({ length: 126, index: 1, infinite: true }),
    pathA: {
        animationName: '$animateDrawA',
    },
    '@keyframes animateDrawN': animationFunc({ length: 152, index: 2, infinite: true }),
    pathN: {
        animationName: '$animateDrawN',
    },
    '@keyframes animateDrawD2': animationFunc({ length: 131, index: 3, infinite: true }),
    pathD2: {
        animationName: '$animateDrawD2',
    },
    '@keyframes animateDrawY': animationFunc({ length: 111, index: 4, infinite: true }),
    pathY: {
        animationName: '$animateDrawY',
    },
}));

const PathComp: React.FC<ShapeProps & React.SVGProps<SVGPathElement>> = props => {
    const { infinite, index, length, ...pathProps } = props;
    return (
        <path
            {...pathProps}
            style={{
                strokeDashoffset: 0,
                strokeDasharray: length,
                animationDuration: `2000ms`,
                animationTimingFunction: 'ease-in-out',
                animationDelay: `${props.index * 200}ms`,
                animationIterationCount: props.infinite ? 'infinite' : 'forwards',
                strokeWidth: '0.5px',
                fillOpacity: 0,
                strokeOpacity: 0,
            }}
        />
    );
};

export const LogoLoading: React.FC<{ style?: React.CSSProperties }> = props => {
    const classes = useStyles();
    return (
        <svg
            viewBox={'0 0 150 30.5'}
            width={'188'}
            height={'76'}
            style={{ ...props.style }}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            transform={'scale(1.25)'}
        >
            <PathComp
                index={0}
                fill={'#003A30'}
                stroke={'#003A30'}
                infinite={true}
                length={131}
                className={classes.pathD1}
                transform={'translate(3 4)'}
                d={
                    'M12.2204 1.21864V6.83126C11.3916 6.3114 10.2529 5.93017 8.73428 5.93017C3.79768 5.93017 0.0007267 9.6713 0.0007267 15.8038C0.0007267 20.446 2.55536 24.4991 7.52556 24.4991C9.56218 24.4991 11.3225 23.3216 12.2204 21.866V24.0139H16.9993V0.00094223H15.9828L12.2204 1.21864ZM12.2204 20.653C11.357 21.4145 10.4946 21.7611 9.11322 21.7611C6.17964 21.7611 4.97093 18.1933 4.97093 14.5205C4.97093 10.3635 6.59312 7.86911 8.76788 7.86911C11.1844 7.86911 12.2195 9.87831 12.2195 13.2729V20.6521L12.2204 20.653Z'
                }
            />
            <PathComp
                index={1}
                fill={'#003A30'}
                stroke={'#003A30'}
                infinite={true}
                length={126}
                className={classes.pathA}
                transform={'translate(22 10)'}
                d={
                    'M15.1617 6.68888C15.1617 2.66862 12.6406 0 7.97845 0C3.31627 0 0.277211 2.42602 0.277211 5.40656C0.277211 6.13436 0.484419 6.86217 0.933371 7.65929L5.043 6.30765C4.66312 5.64916 4.49045 4.99067 4.49045 4.36684C4.49045 2.91122 5.42289 1.73287 7.18416 1.73287C9.1181 1.73287 10.3613 3.67369 10.3613 6.68888V7.31272L8.49648 7.72861C4.21417 8.69901 0 9.91202 0 13.759C0 16.6356 2.00301 18.5764 4.8694 18.5764C7.42496 18.5764 9.0481 17.606 10.3604 15.4919V18.0753H15.1626V13.3487C15.1626 13.3234 15.1617 13.3 15.1617 13.2747V6.68982V6.68888ZM10.3613 13.759C9.70519 15.076 8.66915 15.8038 7.28776 15.8038C5.90637 15.8038 4.97393 14.6947 4.97393 13.2738C4.97393 11.2637 6.07905 10.0507 8.94543 9.32285L10.3613 8.97627V13.759Z'
                }
            />
            <PathComp
                index={2}
                fill={'#003A30'}
                stroke={'#003A30'}
                infinite={true}
                length={152}
                className={classes.pathN}
                transform={'translate(39 10)'}
                d={
                    'M15.7805 5.92361C15.7805 2.21714 13.8475 0 10.3604 0C8.25473 0 6.49439 1.07344 4.80219 3.01332V0H3.76709L0 1.21769V18.0827H4.77886L4.80219 5.02252C6.18265 3.63716 7.32229 3.18661 8.3238 3.18661C10.1187 3.18661 11.0156 4.36403 11.0156 6.40881V18.0827H15.8104L15.7796 5.92361H15.7805Z'
                }
            />
            <PathComp
                index={3}
                fill={'#003A30'}
                stroke={'#003A30'}
                infinite={true}
                length={131}
                className={classes.pathD2}
                transform={'translate(56 4)'}
                d={
                    'M12.2213 1.21864V6.83126C11.3925 6.3114 10.2538 5.93017 8.73427 5.93017C3.79768 5.93017 0.000724792 9.6713 0.000724792 15.8038C0.000724792 20.446 2.55536 24.4991 7.52556 24.4991C9.56217 24.4991 11.3225 23.3216 12.2204 21.866V24.0139H16.9993V0.00094223H15.9828L12.2204 1.21864H12.2213ZM12.2213 20.653C11.358 21.4145 10.4955 21.7611 9.11416 21.7611C6.18057 21.7611 4.97186 18.1933 4.97186 14.5205C4.97186 10.3635 6.59406 7.86911 8.76881 7.86911C11.1853 7.86911 12.2204 9.87831 12.2204 13.2729V20.6521L12.2213 20.653Z'
                }
            />
            <PathComp
                index={4}
                fill={'#003A30'}
                stroke={'#003A30'}
                infinite={true}
                length={111}
                className={classes.pathY}
                transform={'translate(75 10)'}
                d={
                    'M15.7758 0.00093653H14.7407L10.9979 1.21863L11.0128 12.4345C9.63238 13.8545 8.49273 14.3051 7.45763 14.3051C5.66276 14.3051 4.76486 13.1267 4.76486 11.0829L4.79939 0H3.76335L0 1.21769V11.5681C0 15.2746 1.93394 17.4917 5.42008 17.4917C7.5099 17.4917 9.25997 16.4323 10.9746 14.4859V19.3829C10.9746 22.3981 9.73132 24.3389 7.79738 24.3389C6.0361 24.3389 5.10367 23.1605 5.10367 21.7049C5.10367 21.0811 5.27634 20.4226 5.65622 19.7641L1.5466 18.4125C1.09764 19.2096 0.890433 19.9374 0.890433 20.6652C0.890433 23.6457 4.1367 26.0718 8.59167 26.0718C13.0467 26.0718 15.7749 23.4031 15.7749 19.3829L15.7758 0.00093653ZM11.0138 14.4447V14.5205L10.9914 14.469C10.9988 14.4606 11.0063 14.4531 11.0138 14.4447Z'
                }
            />
        </svg>
    );
};
