import { LogoLoading } from './LogoLoading';
import type { CircularProgressProps, GridProps, LinearProgressProps, Theme } from '@orthly/ui-primitives';
import { Text, createStyles, makeStyles, CircularProgress, LinearProgress, Grid } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
        },
        loaderContainer: {
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            zIndex: theme.zIndex.modal + 1000,
            top: 0,
            bottom: 0,
            cursor: 'wait',
            userSelect: 'none',
        },
        overlayContainer: {
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#fff',
            zIndex: theme.zIndex.modal + 999,
            opacity: 0.5,
            top: 0,
            bottom: 0,
            cursor: 'wait',
            userSelect: 'none',
        },
        circularProgress: {
            zIndex: theme.zIndex.modal + 1001,
        },
        linearProgress: {
            width: '100%',
            zIndex: theme.zIndex.modal + 1001,
        },
        '@keyframes fadeInAndOut': {
            '0%': { opacity: 1 },
            '50%': { opacity: 0.3 },
            '100%': { opacity: 1 },
        },
        linearLoader: {
            height: '4px',
            width: '100%',
            backgroundColor: 'rgb(167, 167, 167)',
            position: 'absolute',
            zIndex: theme.zIndex.modal + 1001,
            animationDuration: '1s',
            animationTimingFunction: 'linear',
            animationName: '$fadeInAndOut',
            animationIterationCount: 'infinite',
        },
    }),
);

/**
 * This is very similar to the linear LoadBlockerLoader - but it is nice to the CPU/GPU (fades are simpler to compute).
 */
export const SimpleLinearLoader: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => {
    const classes = useStyles();
    return <div role={'progressbar'} className={clsx(classes.linearLoader, className)} {...props} />;
};

export interface LoadBlockerProps {
    blocking: boolean;
    loader?: 'circular' | 'linear' | 'dandy' | React.ReactNode;
    loaderText?: string;
    overlayColor?: string;
    ContainerProps?: GridProps;
    LoaderContainerProps?: GridProps & { global?: boolean };
    OverlayProps?: GridProps;
    CircularProgressProps?: CircularProgressProps;
    LinearProgressProps?: LinearProgressProps;
}

type LoadBlockerLoaderProps = Omit<LoadBlockerProps, 'ContainerProps'>;

export const LoadBlockerLoader: React.FC<LoadBlockerLoaderProps> = ({
    blocking,
    loader,
    loaderText,
    LoaderContainerProps,
    LinearProgressProps,
    CircularProgressProps,
    OverlayProps,
    overlayColor,
}) => {
    const classes = useStyles();
    if (!blocking) {
        return null;
    }
    let loaderLocal = loader;

    if (!loader || loader === 'circular') {
        loaderLocal = (
            <CircularProgress
                size={30}
                color={'primary'}
                variant={'indeterminate'}
                className={classes.circularProgress}
                {...CircularProgressProps}
            />
        );
    } else if (loader === 'linear') {
        loaderLocal = (
            <LinearProgress
                className={classes.linearProgress}
                color={'primary'}
                variant={'query'}
                {...LinearProgressProps}
            />
        );
    } else if (loader === 'dandy') {
        loaderLocal = <LogoLoading />;
    }

    return (
        <>
            <Grid
                container
                data-testid={'dandy-load-blocker'}
                className={classes.loaderContainer}
                alignItems={loader === 'linear' ? 'flex-start' : 'center'}
                direction={'column'}
                {...LoaderContainerProps}
            >
                {loaderLocal}
                {loaderText && (
                    <Text variant={'body2'} style={{ marginTop: 12 }}>
                        {loaderText}
                    </Text>
                )}
            </Grid>
            <Grid
                container
                className={classes.overlayContainer}
                style={{ backgroundColor: overlayColor }}
                {...OverlayProps}
            />
        </>
    );
};

export const LoadBlocker: React.FC<LoadBlockerProps> = props => {
    const { children, ContainerProps, ...loaderProps } = props;
    const classes = useStyles();
    function eventHandler(e: React.KeyboardEvent<any> | React.MouseEvent<any>) {
        if (props.blocking) {
            e.preventDefault();
            e.stopPropagation();
        }
    }
    return (
        <Grid
            role={'none'}
            className={classes.root}
            container
            {...ContainerProps}
            onKeyDown={eventHandler}
            onKeyUp={eventHandler}
            onClick={eventHandler}
        >
            <LoadBlockerLoader {...loaderProps} />
            {props.children}
        </Grid>
    );
};
