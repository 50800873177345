import type { AnalyticsCallback } from '@orthly/analytics/dist/browser';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';

// Importing Redux State here causes a circular dependency, so we'll settle for this...
let isNativeApp = false;

export const setisNativeApp = (_isNativeApp: boolean) => {
    isNativeApp = _isNativeApp;
};

const SourceEnrichment: AnalyticsCallback['track'] = ctx => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIos = /iphone|ipod|ipad/.test(userAgent);
    if (isIos && isNativeApp) {
        ctx.updateEvent(`context.app.name`, 'Dandy iOS App');
    }
    if (!isIos && isNativeApp) {
        ctx.updateEvent(`context.app.name`, 'Dandy Android App');
    }
    ctx.updateEvent(`context.app.version`, process.env.REACT_APP_VERSION);
    return ctx;
};
export const AnalyticsClient = BrowserAnalyticsClientFactory.create(process.env.REACT_APP_SEGMENT_KEY || '', {
    initPosthog: false,
    log: true,
    plugins: [
        {
            name: 'Lab Portal Source Enrichment',
            type: 'enrichment',
            version: '1.0',
            isLoaded: () => true,
            load: () => Promise.resolve(),
            track: SourceEnrichment,
            page: SourceEnrichment,
            identify: SourceEnrichment,
        },
    ],
});
