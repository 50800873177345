import { PanLabelLowerSection } from './PanLabelLowerSection';
import React from 'react';

export interface PanLabelDentureSpecificationsProps {
    __typename: 'DenturesPanLabelInfo';
    arch?: string;
    tissueShade?: string;
    toothShade?: string;
    festooning?: string;
    stippling?: string;
}

export const PanLabelDentureSpecifications: React.VFC<PanLabelDentureSpecificationsProps> = ({
    arch,
    tissueShade,
    toothShade,
    festooning,
    stippling,
}) => {
    return (
        <PanLabelLowerSection variant={'specifications'}>
            {arch && <div>Arch: {arch}</div>}
            {tissueShade && <div>TS: {tissueShade}</div>}
            {toothShade && <div>Shade: {toothShade}</div>}
            {festooning && <div>Festooning: {festooning}</div>}
            {stippling && <div>Stippling: {stippling}</div>}
        </PanLabelLowerSection>
    );
};
