import type { LabsGqlOrderTimelineItemFragment } from '@orthly/graphql-operations';
import { useTimelineItemsQuery } from '@orthly/graphql-react';
import { UuidUtils } from '@orthly/runtime-utils';

type UseTimelineStepsRes = {
    refetch: ReturnType<typeof useTimelineItemsQuery>['refetch'];
    loading: boolean;
    items: LabsGqlOrderTimelineItemFragment[];
};

export function useTimelineSteps(orderId: string): UseTimelineStepsRes {
    const { data, loading, refetch } = useTimelineItemsQuery({
        variables: { entityId: orderId, entityType: 'order' },
        skip: !UuidUtils.isUUID(orderId),
    });
    const items =
        data?.timelineItems.filter(
            (i): i is LabsGqlOrderTimelineItemFragment => i.__typename === 'OrderTimelineItem',
        ) || ([] as LabsGqlOrderTimelineItemFragment[]);
    return { items, refetch, loading };
}
