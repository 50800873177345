import type {
    ButtonProps as ButtonPropsUiPrimitives,
    ButtonVariant as ButtonVariantUiPrimitives,
    IconToggleButtonProps as IconToggleButtonPropsUiPrimitives,
} from '@orthly/ui-primitives';
import {
    Button as ButtonUiPrimitives,
    IconToggleButton as IconButtonUiPrimitives,
    trackButtonClick as trackButtonClickUiPrimitives,
} from '@orthly/ui-primitives';

/**
 * @deprecated Import directly from ui-primitives instead. The API may change slightly.
 */
export const Button = ButtonUiPrimitives;
/**
 * @deprecated Import directly from ui-primitives instead. The API may change slightly.
 */
export type ButtonProps = ButtonPropsUiPrimitives;
/**
 * @deprecated Import directly from ui-primitives instead. The API may change slightly.
 */
export type ButtonVariant = ButtonVariantUiPrimitives;

/**
 * @deprecated Import directly from ui-primitives instead. The API may change slightly.
 */
export const IconToggleButton = IconButtonUiPrimitives;
/**
 * @deprecated Import directly from ui-primitives instead. The API may change slightly.
 */
export type IconToggleButtonProps = IconToggleButtonPropsUiPrimitives;

/**
 * @deprecated Import directly from ui-primitives instead. The API may change slightly.
 */
export const trackButtonClick = trackButtonClickUiPrimitives;
