import { usePanLabels } from '../../../../../../../utils/EnabledFeaturesForLab';
import { useManufacturerIdFromSession } from '../../../../../../../utils/authorization';
import { LabPortalAcceptOrdersAction } from '../../LabPortalOrderActions/LabPortalAcceptOrderAction';
import { LabPortalCompleteOrderIntakeAction } from '../../LabPortalOrderActions/LabPortalCompleteOrderIntakeAction.graphql';
import { LabPortalDownloadFilesAction } from '../../LabPortalOrderActions/LabPortalDownloadFilesAction';
import type { LabPortalPrintActionsDisplayProps } from '../../LabPortalOrderActions/LabPortalOrderActions.types';
import { LabPortalPrintLabSlipAction } from '../../LabPortalOrderActions/LabPortalPrintLabSlipAction';
import { LabPortalPrintPanLabelAction } from '../../LabPortalOrderActions/LabPortalPrintPanLabelAction';
import type {
    LabsGqlLabPortalNewOrderItemFragment,
    LabsGqlLabPortalNewOrderListItemFragment,
    LabsGqlLabPortalNestedIntakeOrderListItemFragment,
} from '@orthly/graphql-operations';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

export const OrderItemsDisplay: React.VFC<{ items: LabsGqlLabPortalNewOrderItemFragment[] }> = props => {
    const { items } = props;
    return (
        <Grid>
            {items.map((item, i) => (
                <Grid key={i}>
                    {item.unitType} - {item.material}
                </Grid>
            ))}
        </Grid>
    );
};

/**
 * Selects the appropriate print buttons to show based on manufacturer and feature flags
 * @param {React.PropsWithChildren<LabPortalPrintActionsDisplayProps>} props
 * @returns {JSX.Element}
 */
const LabPortalPrintActionsDisplay: React.FC<LabPortalPrintActionsDisplayProps> = props => {
    const { selectedOrders, refetch } = props;
    const onSingleRow = selectedOrders.length === 1;
    const disabled = selectedOrders.length === 0;

    const manufacturerId = useManufacturerIdFromSession() ?? '';

    //At time of writing, this will always be true
    const showLabSlips = true;
    const showPanLabels = usePanLabels(manufacturerId);

    return (
        <>
            {showLabSlips && (
                <LabPortalPrintLabSlipAction
                    onSingleRow={onSingleRow}
                    selectedOrders={selectedOrders}
                    refetch={refetch}
                    disabled={disabled}
                />
            )}
            {showPanLabels && (
                <LabPortalPrintPanLabelAction
                    onSingleRow={onSingleRow}
                    selectedOrders={selectedOrders}
                    refetch={refetch}
                    disabled={disabled}
                />
            )}
        </>
    );
};

export const NewOrderActionsDisplay: React.VFC<{
    orders: LabsGqlLabPortalNewOrderListItemFragment[];
    refetch: () => Promise<any>;
}> = props => {
    const { orders, refetch } = props;
    return (
        <Grid style={{ display: 'flex', flexDirection: 'row' }}>
            <LabPortalAcceptOrdersAction
                onSingleRow={orders.length === 1}
                selectedOrders={orders}
                refetch={refetch}
                disabled={orders.length === 0}
            />
        </Grid>
    );
};

export const NewAcceptedOrderActionsDisplay: React.VFC<{
    orders: LabsGqlLabPortalNewOrderListItemFragment[];
    refetch: () => Promise<any>;
}> = props => {
    const { orders, refetch } = props;
    return (
        <Grid style={{ display: 'flex', flexDirection: 'row' }}>
            <LabPortalDownloadFilesAction selectedOrders={orders} disabled={orders.length === 0} />
            <LabPortalPrintActionsDisplay selectedOrders={orders} refetch={refetch} />
        </Grid>
    );
};

export const IntakeOrderActionsDisplay: React.VFC<{
    orders: LabsGqlLabPortalNewOrderListItemFragment[] | LabsGqlLabPortalNestedIntakeOrderListItemFragment[];
    refetch: () => Promise<any>;
}> = props => {
    const { orders, refetch } = props;

    return (
        <Grid style={{ display: 'flex', flexDirection: 'row' }}>
            <LabPortalDownloadFilesAction selectedOrders={orders} disabled={orders.length === 0} />
            <LabPortalPrintActionsDisplay selectedOrders={orders} refetch={refetch} />
            <LabPortalCompleteOrderIntakeAction
                onSingleRow={orders.length === 1}
                selectedOrders={orders}
                refetch={refetch}
                disabled={orders.length === 0}
            />
        </Grid>
    );
};
