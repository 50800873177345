import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { removeSuspense, OrthlyBrowserConfig } from '@orthly/ui';
import { useDetectGPU } from '@react-three/drei';
import * as Sentry from '@sentry/react';
import type { TierResult } from 'detect-gpu';
import React from 'react';

// cache our GPU Tier detection for reporting
let globalGpuCachedTier: undefined | TierResult = undefined;

export function useOrthlyDetectGPU() {
    const { result: gpuTier, loading, error } = removeSuspense(useDetectGPU);
    const gpuTierIsBad =
        gpuTier?.type === 'WEBGL_UNSUPPORTED' ||
        gpuTier?.type === 'BLOCKLISTED' ||
        gpuTier?.gpu?.includes('swiftshader');
    const gpuIsBad = !OrthlyBrowserConfig.isDevelopment && gpuTierIsBad;

    React.useEffect(() => {
        if (gpuTier && gpuIsBad) {
            Sentry.captureException(new Error('Bad GPU Found'), {
                extra: { gpuTier },
            });
        }
    }, [gpuTier, gpuIsBad]);

    React.useEffect(() => {
        // if we have already cached, and the value changed (this effect tracks gpuInfo)
        BrowserAnalyticsClientFactory.Instance?.track('Global - GPU Tier Detected', {
            tier: gpuTier?.tier,
            type: gpuTier?.type,
            isChanged: !!globalGpuCachedTier,
        });
        globalGpuCachedTier = gpuTier;
    }, [gpuTier]);

    return { loading, error, isBadGpu: gpuIsBad, gpuInfo: gpuTier };
}
