import { useMergedMuiClasses } from '../../util';
import type { RequireChildrenProps, WithClassesProp } from '../../util/floss.types';
import { stylesFactory } from '../../util/stylesFactory';
import type { Theme } from '@orthly/ui-primitives';
import { useMediaQuery, Grid, FlossPalette } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

export type ToolbarContainerClassKey = 'root' | 'sticky' | 'disableMobileSticky';

// These reflect material-ui's defaults for toolbars
export const TOOLBAR_DESKTOP_HEIGHT = 64;
export const TOOLBAR_TABLET_DOWN_HEIGHT = 56;
export const DOUBLE_TOOLBAR_TABLET_HEIGHT = 112;

export function WindowHeightMinusToolbar(theme: Theme) {
    return {
        height: `calc(100vh - ${TOOLBAR_DESKTOP_HEIGHT}px)`,
        minHeight: `calc(100vh - ${TOOLBAR_DESKTOP_HEIGHT}px)`,
        [toolbarHeightBreakpoint(theme)]: {
            height: `calc(100vh - ${TOOLBAR_TABLET_DOWN_HEIGHT}px)`,
            minHeight: `calc(100vh - ${TOOLBAR_TABLET_DOWN_HEIGHT}px)`,
        },
    };
}

export function toolbarHeightBreakpoint(theme: Theme): string {
    return theme.breakpoints.down('lg');
}

const useStyles = stylesFactory<{}, ToolbarContainerClassKey>(theme => ({
    root: {
        height: TOOLBAR_DESKTOP_HEIGHT,
        minHeight: TOOLBAR_DESKTOP_HEIGHT,
        display: 'flex',
        borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
        padding: '12px 24px',
        alignItems: 'center',
        [toolbarHeightBreakpoint(theme)]: {
            height: TOOLBAR_TABLET_DOWN_HEIGHT,
            minHeight: TOOLBAR_TABLET_DOWN_HEIGHT,
        },
        '& > .MuiTabs-root:first-child': { marginLeft: -24 },
        '& > .MuiTabs-root': { height: 64, margin: `-12px 0` },
        '& > .MuiTabs-root:last-child': { margin: -24 },
    },
    sticky: {
        backgroundColor: FlossPalette.WHITE,
        position: 'sticky',
        top: 0,
        zIndex: 2,
    },
    disableMobileSticky: {
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            top: 'unset',
        },
    },
}));

export interface ToolbarContainerProps extends RequireChildrenProps, WithClassesProp<ToolbarContainerClassKey> {
    sticky?: boolean;
    disableMobileSticky?: boolean;
    className?: string;
    style?: React.CSSProperties;
    dataPendoId?: string;
}

export function useToolbarContainerHeight(): number {
    const isShrunk = useMediaQuery<Theme>(toolbarHeightBreakpoint);
    return isShrunk ? TOOLBAR_TABLET_DOWN_HEIGHT : TOOLBAR_DESKTOP_HEIGHT;
}

export const ToolbarContainer: React.FC<ToolbarContainerProps> = props => {
    const { sticky, disableMobileSticky, dataPendoId } = props;
    const classes = useMergedMuiClasses<ToolbarContainerClassKey>(useStyles(), props.classes);
    return (
        <Grid
            data-pendo-id={dataPendoId}
            style={props.style}
            container
            className={clsx(classes.root, props.className, {
                [classes.sticky]: sticky,
                [classes.disableMobileSticky]: disableMobileSticky,
            })}
        >
            {props.children}
        </Grid>
    );
};
