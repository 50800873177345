import '../../../assets/styles/pan-label.css';
import { PanLabelCenterSection } from './PanLabelCenterSection';
import {
    PanLabelCrownBridgeImplantSpecifications,
    type PanLabelCrownBridgeImplantSpecificationsProps,
} from './PanLabelCrownBridgeImplantSpecifications';
import {
    type PanLabelDentureSpecificationsProps,
    PanLabelDentureSpecifications,
} from './PanLabelDentureSpecifications';
import { PanLabelHeader } from './PanLabelHeader';
import { PanLabelLowerSection } from './PanLabelLowerSection';
import {
    type PanLabelNightGuardSpecificationsProps,
    PanLabelNightGuardSpecifications,
} from './PanLabelNightGuardSpecifications';
import { graphql } from '@orthly/graphql-inline-react';
import { styled } from '@orthly/ui-primitives';
import React from 'react';

export const PanLabelPage_Fragment = graphql(`
    fragment PanLabelPage_Fragment on PanLabelInfo {
        __typename
        labOrderId
        orderNumber
        labtracId
        batchNumber
        panCode
        shipByDate
        patientName
        labelTags
        items {
            name
            quantity
        }
        modelType
        ... on CrownBridgeImplantPanLabelInfo {
            puck {
                material
                heightMillimeters
            }
            crownMaterial
            occlusalStaining
            shades {
                ... on MultiShadeInfo {
                    shadeMessage
                }
                ... on ShadeInfo {
                    gingival
                    base
                    incisal
                    stump
                }
            }
        }
        ... on DenturesPanLabelInfo {
            arch
            tissueShade
            toothShade
            festooning
            stippling
        }
        ... on NightGuardPanLabelInfo {
            arch
            canineGuidance
            anteriorRamp
            occlusalSurfaceType
            idealHeight
        }
    }
`);

const PrintablePanLabelPage = styled('div')({
    // Including @page in any of these styles, or in a style property, throws an unhelpful error.  So, we define the
    // named page in pan-label.css (imported above) and reference it here.  This is what sets the printed-label page
    // size and margins.
    page: 'pan-label',
    color: 'black',
    pageBreakAfter: 'always',
    breakAfter: 'page',
    display: 'grid',
    // The page has defined margins, and we need to fit within them, which is why we subtract margins here instead
    // of using 'padding' or 'margin'.
    width: 'calc(3.9in - 0.25in)',
    height: 'calc(2.4in - 0.25in)',
    overflow: 'hidden',
    gridTemplateRows: '0.65in 0.24375in 1fr',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: '0.125in',
    gridTemplateAreas: `
    "header header"
    "name tags"
    "order specifications"
    `,
    fontSize: '0.125in',
});

/**
 * Mirrors {@link PanLabelPage_Fragment}, but really the backend version of it (the labs-server implementations of
 * PanLabelInfo).
 */
interface PanLabelPageBaseProps {
    labOrderId: string;
    orderNumber: string;
    labtracId?: string;
    batchNumber?: string;
    panCode: string;
    shipByDate: Date;
    patientName: string;
    labelTags: string[];
    items: { name: string; quantity: number }[];
    modelType?: string;
}

type PanLabelPageProps = PanLabelPageBaseProps &
    (
        | PanLabelCrownBridgeImplantSpecificationsProps
        | PanLabelDentureSpecificationsProps
        | PanLabelNightGuardSpecificationsProps
        // We are not implementing partial dentures right now, but the type exists in the backend GQL
        | { __typename: 'PartialDenturesPanLabelInfo' }
    );

/**
 * Renders a single pan label.
 * @param props The information to include on the label.
 */
export const PanLabelPage: React.VFC<PanLabelPageProps> = props => {
    const { items, modelType, patientName, labelTags } = props;
    return (
        <PrintablePanLabelPage>
            <PanLabelHeader {...props} />
            <PanLabelCenterSection variant={'name'}>{patientName}</PanLabelCenterSection>
            <PanLabelCenterSection variant={'tags'}>
                {labelTags.length > 0 ? labelTags.join(', ') : <span>&nbsp;</span>}
            </PanLabelCenterSection>
            <PanLabelLowerSection variant={'order'}>
                {items.map(({ name, quantity }) => (
                    <div key={name}>
                        {quantity}x {name}
                    </div>
                ))}
                {modelType && <div>Model: {modelType}</div>}
            </PanLabelLowerSection>
            {props.__typename === 'CrownBridgeImplantPanLabelInfo' && (
                <PanLabelCrownBridgeImplantSpecifications {...props} />
            )}
            {props.__typename === 'DenturesPanLabelInfo' && <PanLabelDentureSpecifications {...props} />}
            {props.__typename === 'NightGuardPanLabelInfo' && <PanLabelNightGuardSpecifications {...props} />}
        </PrintablePanLabelPage>
    );
};
