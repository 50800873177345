import { LoggedOutBottomButtons } from '../components/LoggedOutBottomButtons';
import { useLoginStyles } from '../components/login-styles';
import { useUpdateUserPasswordMutation } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import type { QuickFormCustomRenderProps, FieldsDefProp, FieldDefText } from '@orthly/ui';
import { LoadBlocker, QuickForm, useChangeSubmissionFn } from '@orthly/ui';
import { Grid, Text } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { z } from 'zod';

type NewPasswordFields = { confirm_password: string; password: string };

const FormLayout: React.FC<QuickFormCustomRenderProps<NewPasswordFields>> = props => {
    const history = useHistory();
    const classes = useLoginStyles();
    const password = props.formikProps.values.password || '';
    const passwordConfirmation = props.formikProps.values.confirm_password || '';
    const confirmationError = props.formikProps.errors.confirm_password;
    React.useEffect(() => {
        if (
            password?.length > 0 &&
            passwordConfirmation?.length > 0 &&
            password !== passwordConfirmation &&
            !confirmationError
        ) {
            props.formikProps.setFieldError('confirm_password', 'Must match password');
            return;
        }
        if (confirmationError && password === passwordConfirmation) {
            props.formikProps.setFieldError('confirm_password', '');
        }
    }, [confirmationError, password, passwordConfirmation, props.formikProps]);
    const passwordsDontMatch = password !== passwordConfirmation;
    return (
        <form
            onSubmit={event => {
                event.preventDefault();
                props.triggerSubmit();
            }}
            className={classes.form}
        >
            <LoadBlocker blocking={props.submitting}>{props.fieldList}</LoadBlocker>
            <LoggedOutBottomButtons
                right={{
                    disabled: passwordsDontMatch || props.submitting || !props.formikProps.isValid,
                    text: 'Submit',
                    type: 'submit',
                }}
                left={{
                    disabled: props.submitting,
                    text: 'Back To Login',
                    type: 'button',
                    onClick: () => history.push('/login'),
                }}
            />
        </form>
    );
};

function useSetNewPassword() {
    const history = useHistory();
    // the reset link uses an impersonation token to pre-auth the user
    const user_id = useSession()?.user_id;
    const [submitMtn] = useUpdateUserPasswordMutation();
    const onSubmit = React.useCallback(
        (password_raw: string) => {
            if (!user_id) {
                throw new Error('Reset link invalid');
            }
            return submitMtn({ variables: { data: { user_id, password_raw } } });
        },
        [user_id, submitMtn],
    );
    const backToLogin = React.useCallback(() => history.push('/login'), [history]);
    return useChangeSubmissionFn<any, [string]>(onSubmit, {
        successMessage: () => ['Successfully reset password!', {}],
        onSuccess: backToLogin,
        onError: backToLogin,
    });
}

const passwordFieldBase: FieldDefText = {
    label: 'New Password',
    fieldProps: {
        variant: 'standard',
        style: { marginBottom: 24, position: 'relative' },
        FormHelperTextProps: { style: { position: 'absolute' } },
        type: 'password',
        autoComplete: 'new-password',
    },
    type: 'text',
    validation: z.string({ invalid_type_error: 'Must be text' }).min(7),
};

const formFields: FieldsDefProp<NewPasswordFields> = {
    password: passwordFieldBase,
    confirm_password: { ...passwordFieldBase, label: 'Confirm New Password' },
};

export const SetNewPassword: React.VFC = () => {
    const classes = useLoginStyles();
    const { submit: setNewPassword, submitting } = useSetNewPassword();

    return (
        <>
            <Grid container className={classes.columnRow} style={{ flexBasis: 'unset' }}>
                <Text className={classes.titleFont} variant={'h5'}>
                    Create new
                    <br />
                    password
                </Text>
                <ul style={{ paddingInlineStart: '20px', margin: 0, paddingTop: 20 }}>
                    {[
                        'Must contain at least 7 characters',
                        'Does not match or significantly contain your username',
                    ].map((item, idx) => (
                        <li style={{ marginBottom: 5 }} key={`${item}-${idx}`}>
                            {item}
                        </li>
                    ))}
                </ul>
            </Grid>
            <QuickForm<NewPasswordFields>
                fields={formFields}
                disabled={submitting}
                onSubmit={fields => setNewPassword(fields.password)}
                initialValues={{ password: '', confirm_password: '' }}
                CustomLayout={FormLayout}
            />
        </>
    );
};
