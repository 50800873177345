import { Button } from '@orthly/ui-primitives';
import { makeLazyDialog, RootActionDialog } from '@orthly/ui/dist';
import React from 'react';

interface PrintDialogProps {
    title: string;
    onClick: () => void;
    autoClose?: boolean;
}
export const usePrintDialog = makeLazyDialog<PrintDialogProps>(props => {
    const { title, onClick, autoClose, open, setOpen } = props;

    return (
        <RootActionDialog
            title={title}
            open={open}
            setOpen={setOpen}
            loading={false}
            buttonText={``}
            CustomButton={() => null}
            content={
                <Button
                    variant={'primary'}
                    fullWidth={true}
                    onClick={() => {
                        if (autoClose) {
                            setOpen(false);
                        }
                        onClick();
                    }}
                    disabled={false}
                >
                    Print
                </Button>
            }
        />
    );
});
