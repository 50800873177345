import { usePracticeAppSelector } from '../../../redux';
import { usePartnerUiAction } from '../../../redux/ui.actions';
import { orderIdFromQRCodeUrl } from '../../../utils/utils';
import { useOpenOrderDetailPage } from '../../labs/LabsUtils';
import { RootActionDialog } from '@orthly/ui';
import { FlossPalette, useScreenIsMobileOrVerticalTablet, Grid } from '@orthly/ui-primitives';
import jsQR from 'jsqr';
import React from 'react';
import Webcam from 'react-webcam';

function orderIdFromWebcamImage(webcamRef: React.MutableRefObject<Webcam | null>): string | undefined {
    const webcam = webcamRef.current;
    const video = webcam?.video;
    const canvasElement = webcam?.getCanvas();
    const canvas = canvasElement?.getContext('2d');
    if (!video || video.readyState !== video.HAVE_ENOUGH_DATA || !canvasElement || !canvas) {
        return undefined;
    }
    const imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
    const code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: 'attemptBoth',
    });
    return code ? orderIdFromQRCodeUrl(code.data) : undefined;
}

interface QRCodeScannerContentProps {
    onOrderIdFound: (orderId: string) => void;
}

const QRCodeScannerContent: React.FC<QRCodeScannerContentProps> = props => {
    const { onOrderIdFound } = props;
    const webcamRef = React.useRef<Webcam | null>(null);
    const requestRef = React.useRef<number>();
    const [orderId, setOrderId] = React.useState<string | undefined>(undefined);
    React.useEffect(() => {
        // See this for detailed explanation: https://css-tricks.com/using-requestanimationframe-with-react-hooks/
        const checkCamForOrderId = (handle: number) => {
            const orderId = orderIdFromWebcamImage(webcamRef);
            if (orderId) {
                cancelAnimationFrame(handle);
                return setOrderId(orderId);
            }
            requestRef.current = requestAnimationFrame(checkCamForOrderId);
        };
        requestRef.current = requestAnimationFrame(checkCamForOrderId);
        return () => {
            typeof requestRef.current === 'number' && cancelAnimationFrame(requestRef.current);
        };
    }, []);
    // This has to be a separate useEffect because the one using requestAnimationFrame cannot have any dependencies
    React.useEffect(() => {
        if (orderId) {
            // doubly insure we've cancelled the animation frame loop
            typeof requestRef.current === 'number' && cancelAnimationFrame(requestRef.current);
            onOrderIdFound(orderId);
        }
    }, [onOrderIdFound, orderId]);

    return (
        <Grid container justifyContent={'center'} style={{ position: 'relative' }}>
            <Webcam
                audio={false}
                style={{ width: '100%' }}
                ref={webcamRef}
                screenshotFormat={'image/jpeg'}
                // always prefer environment because that is outward facing for mobile devices
                videoConstraints={{ facingMode: 'environment' }}
            />
            <Grid
                container
                style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <div style={{ width: 200, height: 200, border: `4px solid ${FlossPalette.STAR_GRASS}` }} />
            </Grid>
        </Grid>
    );
};

export const QRCodeScannerSpotlight: React.FC = () => {
    const setOpen = usePartnerUiAction('SET_QR_CODE_OPEN');
    const open = usePracticeAppSelector(s => s.ui.activeSpotlight === 'qrCode');
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const openOrder = useOpenOrderDetailPage();
    const onOrderIdFound = React.useCallback(
        (orderId: string) => {
            setOpen(false);
            openOrder(orderId);
        },
        [setOpen, openOrder],
    );
    return (
        <RootActionDialog
            showCloseButton
            dialogProps={{ fullScreen: isMobile, maxWidth: isMobile ? false : undefined }}
            contentProps={{ style: { padding: 0, border: 'none' } }}
            loading={false}
            open={open}
            setOpen={setOpen}
            title={'Scan Order QR Code'}
            content={open && <QRCodeScannerContent onOrderIdFound={onOrderIdFound} />}
            buttonProps={isMobile ? { fullWidth: true } : { style: { display: 'none' } }}
            buttonText={'Scan QR Code'}
        />
    );
};
