import type { LabOrderForLabPortal } from '../LabsUtils';
import { useOpenOrderDetailPage } from '../LabsUtils';
import { useDownloadOrderFiles } from '../components/useDownloadOrderFiles';
import { LabPortalOrderDetailSummary } from './components/LabPortalOrderDetailSummary';
import { OrderDetailsCbctScanManagement } from './components/OrderDetailCbctScanManagement';
import { OrderDetailTatProgress } from './components/OrderDetailTatProgress';
import { OrderDetailTracking } from './components/OrderDetailTracking.graphql';
import { OrderDetailsRefabs } from './components/OrderDetailsRefabs';
import type { LabsGqlLabOrderFragment, LabsGqlOrder } from '@orthly/graphql-operations';
import { useLabsAddresses } from '@orthly/graphql-react';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { LabOrderItemSKUType, OrderItemV2Utils } from '@orthly/items';
import { ActionCard, LoadBlocker } from '@orthly/ui';
import { stylesFactory, useScreenIsMobile, Grid, Text } from '@orthly/ui-primitives';
import {
    GuidedWaxupAlertBanner,
    OrderDesign,
    OrderDetailAligners,
    OrderDetailBlock,
    OrderDetailClinicalSupportNotesBlock,
    OrderDetailDentures,
    OrderDetailDesignWarning,
    OrderDetailDoctorPhotosBlock,
    OrderDetailMouth,
    OrderDetailPartials,
    OrderDetailPreferencesBlock,
    OrderDetailShadeMatchBlock,
    OrderItemsTable,
    OrderRefabAlertBanner,
    OrderMultiFulfillmentAlertBanner,
    OrderWaxup,
    ScanExportModelViewerBlock,
    useShouldShowGuidedWaxupFlow,
    OrderDetailOriginalPhotosBlock,
    useFeatureFlag,
    OrderDetailTryInFeedback,
    NotesSummaryBlock,
} from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    bodyRoot: {
        padding: '12px 28px',
        [theme.breakpoints.down('sm')]: { padding: 8 },
    },
}));

const OrderDetailNotes: React.FC<{ doctor_notes: string }> = ({ doctor_notes }) => {
    if (doctor_notes.trim().length === 0) {
        return null;
    }
    return (
        <OrderDetailBlock title={'Notes'} variant={'full'} fitHeightToContent rootStyle={{ flexBasis: 'auto' }}>
            <Text variant={'body1'}>{doctor_notes}</Text>
        </OrderDetailBlock>
    );
};

function useOrderAddressLabel(mailing_address_id: string, labelProperty: 'city' | 'street', partner_id?: string) {
    const { addresses } = useLabsAddresses({ variables: { partner_id: partner_id ?? '' }, skip: !partner_id });
    return React.useMemo<string>(() => {
        const address = addresses.find(a => a.id === mailing_address_id);
        if (!address) {
            return '';
        }
        const property = labelProperty === 'street' ? address.street_one : address.city;
        return _.startCase(property.toLowerCase());
    }, [addresses, labelProperty, mailing_address_id]);
}

function orderShouldDisplayDesignFileWarning(order: LabsGqlLabOrderFragment): boolean {
    switch (order.status) {
        case LabsGqlLabOrderStatus.Shipped:
        case LabsGqlLabOrderStatus.Delivered:
        case LabsGqlLabOrderStatus.Cancelled:
        case LabsGqlLabOrderStatus.NeedsRefabrication:
            return false;
        case LabsGqlLabOrderStatus.New:
        case LabsGqlLabOrderStatus.Fabrication:
        case LabsGqlLabOrderStatus.NeedsReview:
        case LabsGqlLabOrderStatus.OnHold:
            return true;
    }
}

interface OrderDetailSharedProps {
    order?: LabOrderForLabPortal;
    refetch: () => Promise<any>;
}

const OrderDetailMouthAndItems: React.FC<OrderDetailSharedProps> = props => {
    const { order, refetch } = props;

    return (
        <Grid container>
            <Grid container item xs={12} md={4} alignContent={'flex-start'} direction={'column'}>
                <OrderDetailMouth order={order} />
            </Grid>
            <Grid container item xs={12} md={8} alignContent={'flex-start'} direction={'column'} wrap={'nowrap'}>
                <OrderItemsTable items={OrderItemV2Utils.parseItems(order?.items_v2 ?? [])} variant={'full'} />
                <OrderDetailNotes doctor_notes={order?.doctor_notes ?? ''} />
                {order?.clinical_support_notes?.notes && (
                    <OrderDetailClinicalSupportNotesBlock order={order} disableEditing={true} refetchOrder={refetch} />
                )}
            </Grid>
        </Grid>
    );
};

const OrderDetailDesignViewerBlock: React.FC<OrderDetailSharedProps> = props => {
    const { order, refetch } = props;
    const isMobile = useScreenIsMobile();
    if (!order) {
        return null;
    }
    if (order.fulfillment_workflow.configuration.waxup_required === true) {
        return <OrderWaxup order={order} userRole={'manufacturer'} refetchOrder={refetch} clickToLoad={isMobile} />;
    }
    // If user is manufacturer, a design exists, and there is no waxup configured for this order, show design
    if (order.design_file_path) {
        return <OrderDesign order={order} userRole={'lab'} />;
    }
    return null;
};

const OrderShippedToDandyWarning: React.FC<{ order: LabsGqlOrder }> = ({ order }) => {
    if (order.shipped_to_dandy !== true) {
        return null;
    }
    return (
        <Grid container style={{ padding: '0 12px' }}>
            <ActionCard
                variant={'announcement'}
                title={'Order has been shipped to Dandy.'}
                subtitle={
                    "Though this order has already been shipped to Dandy, it will still be categorized under 'Fabrication'."
                }
            />
        </Grid>
    );
};

const OrderDetailBodyTopSectionManufacturer: React.FC<OrderDetailSharedProps> = props => {
    const { order } = props;
    const { designAvailable, loadingDesign, triggerDesignDownload } = useDownloadOrderFiles(order);
    if (!order) {
        return null;
    }
    return (
        <>
            <OrderShippedToDandyWarning order={order} />
            {orderShouldDisplayDesignFileWarning(order) && triggerDesignDownload && (
                <OrderDetailDesignWarning
                    order={order}
                    designAvailable={designAvailable}
                    loadingDesign={loadingDesign}
                    triggerDesignDownload={triggerDesignDownload}
                />
            )}
        </>
    );
};

const OrderDetailBodyTopSection: React.FC<OrderDetailSharedProps> = props => {
    const { order, refetch } = props;
    const [showTatProgress, setShowTatProgress] = React.useState(false);
    const { value: labPortalEnableTatProgress } = useFeatureFlag('labPortalEnableTatProgress');
    const addressLabel = useOrderAddressLabel(order?.mailing_address_id ?? ``, `street`, order?.partner_id);
    const openOrder = useOpenOrderDetailPage();

    const toggleTatProgress = React.useCallback(() => {
        setShowTatProgress(value => !value);
    }, [setShowTatProgress]);

    return (
        <Grid container>
            <OrderDetailBodyTopSectionManufacturer {...props} />
            <LabPortalOrderDetailSummary
                order={order}
                addressLabel={addressLabel}
                variant={`left`}
                openOrder={openOrder}
                canSeeCancelAndResubmitBlock={true}
            />
            {showTatProgress && labPortalEnableTatProgress && order ? (
                <OrderDetailTatProgress order={order} toggleTatProgress={toggleTatProgress} />
            ) : (
                <OrderDetailTracking
                    order={order}
                    refetch={refetch}
                    switchToTatProgressView={labPortalEnableTatProgress ? toggleTatProgress : null}
                />
            )}
            {order &&
                OrderItemV2Utils.parseItems(order.items_v2).some(
                    OrderItemV2Utils.itemIsType(LabOrderItemSKUType.SurgicalGuide),
                ) && <OrderDetailsCbctScanManagement order={order} refetch={refetch} />}
        </Grid>
    );
};

interface OrderDetailBodyProps extends OrderDetailSharedProps {
    loading: boolean;
}

export const OrderDetailBody: React.FC<OrderDetailBodyProps> = props => {
    const { order, refetch } = props;
    const isMobile = useScreenIsMobile();
    const classes = useStyles();
    const openOrder = useOpenOrderDetailPage();
    const enableNewGuidedWaxupFlow = useShouldShowGuidedWaxupFlow(order);
    const showGuidedWaxupBanner = order && enableNewGuidedWaxupFlow;
    const { value: showCaseNotesOnLabPortalOrderDetailsPage } = useFeatureFlag(
        'showCaseNotesOnLabPortalOrderDetailsPage',
    );

    return (
        <Grid container>
            {order && <OrderMultiFulfillmentAlertBanner order={order} openOrder={openOrder} />}
            {order && <OrderRefabAlertBanner order={order} openOrder={openOrder} />}
            {showGuidedWaxupBanner && <GuidedWaxupAlertBanner order={order} />}
            <Grid container className={classes.bodyRoot} alignItems={'flex-start'}>
                <LoadBlocker blocking={props.loading}>
                    <OrderDetailBodyTopSection refetch={refetch} order={order} />
                    {showCaseNotesOnLabPortalOrderDetailsPage && order && (
                        <NotesSummaryBlock
                            order={order}
                            refetchOrder={refetch}
                            readOnly={true}
                            showLabSlipAlerts={true}
                        />
                    )}
                    {order && <OrderDetailsRefabs order={order} />}
                    {order && (
                        <>
                            <OrderDetailDoctorPhotosBlock
                                order={order}
                                refetchOrder={refetch}
                                isPSR={false}
                                disablePhotoDownload={isMobile}
                            />
                            <OrderDetailOriginalPhotosBlock
                                order={order}
                                refetchOrder={refetch}
                                isPSR={false}
                                disablePhotoDownload={isMobile}
                            />
                        </>
                    )}
                    {order?.scan_export.image_urls && (
                        <OrderDetailShadeMatchBlock orderId={order.id} imageUrls={order?.scan_export.image_urls} />
                    )}
                    {order?.previous_fulfillment_order_id && (
                        <OrderDetailTryInFeedback
                            orderId={order.previous_fulfillment_order_id}
                            isPreviousOrder={true}
                        />
                    )}
                    {order?.dentures_fulfillment && <OrderDetailDentures order={order} />}
                    {order?.partials_fulfillment && <OrderDetailPartials order={order} />}
                    {order?.aligner_case ? (
                        <OrderDetailAligners
                            lab_order={order}
                            aligner_case={order.aligner_case}
                            refetch={refetch}
                            user_role={'manufacturer'}
                            disablePhotoDownload={isMobile}
                        />
                    ) : (
                        <OrderDetailMouthAndItems refetch={refetch} order={order} />
                    )}
                    {order && <ScanExportModelViewerBlock order={order} />}
                    {order && <OrderDetailDesignViewerBlock refetch={refetch} order={order} />}
                    {order && <OrderDetailPreferencesBlock order={order} />}
                </LoadBlocker>
            </Grid>
        </Grid>
    );
};
