import { LoggedOutDefaultLeft } from './LoggedOutDefaultLeft';
import { useLoginStyles } from './login-styles';
import { Grid, Link, Text, Typography } from '@orthly/ui-primitives';
import logo from '@orthly/ui/assets/logos/DandyLogoWhiteTrimmed.png';
import cx from 'classnames';
import React from 'react';

interface LoginLayoutLeftProps {
    title: React.ReactNode;
    content: React.ReactNode;
}

const LoginLayoutLeft: React.FC<LoginLayoutLeftProps> = props => {
    const classes = useLoginStyles();
    return (
        <>
            <Grid container className={cx(classes.columnRow, classes.logoWrap)} item xs={12} style={{ maxHeight: 30 }}>
                <img alt={'Logo'} src={logo} className={classes.logo} />
            </Grid>
            <Grid container className={cx(classes.columnRow, classes.hideMobile)} item xs={12}>
                <Text className={classes.titleFont} variant={'h5'}>
                    {props.title}
                </Text>
            </Grid>
            <Grid container className={cx(classes.columnRow, classes.hideMobile)} item xs={12}>
                {props.content}
            </Grid>
            <Grid container className={cx(classes.columnRow, classes.columnRowBottom, classes.hideMobile)}>
                <Link color={'inherit'} href={'https://meetdandy.com'} variant={'body2'} className={classes.linkFont}>
                    Back to meetdandy.com
                </Link>
            </Grid>
        </>
    );
};

interface LoginLayoutProps {
    leftTitle?: React.ReactNode;
    leftContent?: React.ReactNode;
    rightColumnContent: React.ReactNode;
}

export const LoggedOutLayout: React.FC<LoginLayoutProps> = props => {
    const classes = useLoginStyles();
    return (
        <Grid container justifyContent={'center'} className={classes.wrapper}>
            <Grid container className={classes.root}>
                <Grid container item xs={12} sm={5} className={cx(classes.column, classes.columnLeft)}>
                    <Grid container direction={'column'} wrap={'nowrap'}>
                        <LoginLayoutLeft
                            content={
                                props.leftContent || (
                                    <Typography color={'inherit'} variant={'body1'} style={{ fontSize: '1.1rem' }}>
                                        Digital dentistry designed to work for you.
                                    </Typography>
                                )
                            }
                            title={props.leftTitle || <LoggedOutDefaultLeft />}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={7} className={cx(classes.column, classes.columnRight)}>
                    <Grid container direction={'column'} wrap={'nowrap'}>
                        {props.rightColumnContent}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
