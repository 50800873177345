import { useQuery } from '@apollo/client';
import type { SingletonModelPayloadView, ModelPayloadView } from '@orthly/dentin';
import { ModelPayloadViewKind, QcModelViewer, DesignCaseParser, ModelViewer } from '@orthly/dentin';
import { OrderDesignPreviewDesign_FragmentFragmentDoc, graphql, getFragmentData } from '@orthly/graphql-inline-react';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import type { IOrderItemV2DTO } from '@orthly/items';
import { CartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import { FileNameUtils } from '@orthly/runtime-utils';
import { showMetadataTransformWarning } from '@orthly/shared-types';
import { ActionCard, ErrorPage, LoadBlocker } from '@orthly/ui';
import { FlossPalette, Text, Tab, Tabs, Container, Button, Collapse } from '@orthly/ui-primitives';
import {
    DesignViewerLiteWrapper,
    getGroupedDesignPreviews,
    useOrthlyDetectGPU,
    useOrderItemsViewModel,
    useAbutmentPartsMap,
    ListAbutmentPartsProvider,
    useProcessDesignData,
    useDesignModelPayloadsForScan,
    useFeatureFlag,
} from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

interface QcViewerTabsProps {
    tabNames: string[];
    tab: number;
    setTab: (t: number) => void;
}

const QcViewerTabs: React.VFC<QcViewerTabsProps> = ({ tabNames, tab, setTab }) => {
    return (
        <div
            style={{
                borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
                position: 'absolute',
                bottom: 0,
                width: '100%',
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <div style={{ display: 'inline-block' }}>
                <Tabs indicatorColor={'secondary'} textColor={'inherit'} value={tab} onChange={(_, tab) => setTab(tab)}>
                    {tabNames.map((tabName, idx) => (
                        <Tab value={idx} key={tabName} label={tabName} />
                    ))}
                </Tabs>
            </div>
            <Button
                style={{ minWidth: 'fit-content', margin: '10px' }}
                variant={'secondary'}
                onClick={() => setTab(tab + 1)}
                disabled={tab === tabNames.length - 1}
                endIcon={'ChevronRight'}
            >
                Next
            </Button>
        </div>
    );
};

type Restorative = { restorative: SingletonModelPayloadView; item: IOrderItemV2DTO };

const ItemsSummary: React.VFC<{ items: IOrderItemV2DTO[]; headingSuffix?: string }> = ({ items, headingSuffix }) => {
    const abutmentPartsMap = useAbutmentPartsMap();
    const itemsVM = useOrderItemsViewModel(items, abutmentPartsMap);
    const [detailsVisible, setDetailsVisible] = React.useState(true);
    return (
        <div style={{ position: 'fixed', top: 0, zIndex: 2, padding: 32 }}>
            {itemsVM.length > 1 && (
                <Button onClick={() => setDetailsVisible(!detailsVisible)} variant={'primary'} size={'small'}>
                    {detailsVisible ? 'Hide' : 'Show'} item details
                </Button>
            )}
            <Collapse in={detailsVisible || itemsVM.length === 1}>
                {itemsVM.map(item => (
                    <div key={item.name}>
                        <Text variant={'h6'} color={'BLACK'} style={{ display: 'inline-block', marginRight: '5px' }}>
                            {item.name}
                            {headingSuffix && (
                                <span style={{ color: FlossPalette.GRAY, marginLeft: '.5rem' }}>({headingSuffix})</span>
                            )}
                        </Text>
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'auto auto',
                                gap: '0 10px',
                            }}
                        >
                            {item.properties.map(property => (
                                <Text key={property.name} variant={'body2'}>
                                    <span style={{ color: FlossPalette.GRAY }}>{property.name}</span>: {property.value}
                                </Text>
                            ))}
                        </div>
                    </div>
                ))}
            </Collapse>
        </div>
    );
};

const QcNoItemsErrorDisplay: React.VFC = () => (
    <ErrorPage
        message={'No QC items found.'}
        text={'Sorry, this order does not have any QC restoratives. Please try viewing a different order.'}
    />
);

const BadGPUPerformance: React.VFC = () => (
    <ErrorPage
        message={'Minimum System Requirements Not Met'}
        text={'This browser or PC does not have adequate graphics performance.'}
    />
);

const LabPortalQcDesignModelViewerDesign_Query = graphql(`
    query LabPortalQcDesignModelViewerDesign_Query($orderId: String!) {
        getLatestDesignOrderDesignRevision(labOrderId: $orderId) {
            ...OrderDesignPreviewDesign_Fragment
        }
    }
`);

const QcDesignModelViewer: React.VFC<{ order: LabsGqlLabOrderFragment }> = ({ order }) => {
    const { data } = useQuery(LabPortalQcDesignModelViewerDesign_Query, {
        variables: {
            orderId: order.id,
        },
    });

    const designFragment = data?.getLatestDesignOrderDesignRevision;
    const design = getFragmentData(OrderDesignPreviewDesign_FragmentFragmentDoc, designFragment);

    const {
        modellingTreePayload,
        payloads: { result: designPayloads, loading: designPayloadsLoading },
        designMetadata,
    } = useProcessDesignData(design, order);

    const models = React.useMemo<ModelPayloadView[]>(
        () => getGroupedDesignPreviews(order, _.compact(designPayloads) ?? [], designMetadata, design ?? undefined),
        [designPayloads, order, design, designMetadata],
    );

    const items = React.useMemo<IOrderItemV2DTO[]>(() => OrderItemV2Utils.parseItems(order.items_v2), [order.items_v2]);

    // amortize the stripped file name calculation
    type SingletonModelPayloadViewWithFileName = SingletonModelPayloadView & { strippedFileName: string };
    const restoratives: SingletonModelPayloadViewWithFileName[] = DesignCaseParser.getRestoratives(
        order,
        _.compact(designPayloads) ?? [],
        design ?? undefined,
    ).map<SingletonModelPayloadViewWithFileName>(payload => ({
        payload,
        strippedFileName: FileNameUtils.fileNameWithoutExtension(payload.path),
        kind: ModelPayloadViewKind.SingletonPayloadView,
    }));

    const restorativesAndLineItems: Restorative[] = Object.entries(designMetadata.unnForFilePath)
        .filter(([_, unns]) => unns.length > 0)
        .map(([path, unns]) => {
            const strippedFileName = FileNameUtils.fileNameWithoutExtension(path);

            const restorative = restoratives.find(r => r.strippedFileName === strippedFileName);

            const item = items.find(i => _.intersection(CartItemV2Utils.getUniqueUNNs(i), unns).length > 0);

            if (!restorative || !item) {
                return undefined;
            }

            return { restorative: _.omit(restorative, 'strippedFileName'), item };
        }, [])
        .filter((r): r is Restorative => r !== undefined);

    const [tab, setTab] = React.useState(0);

    const activeRestoration = restorativesAndLineItems[tab];
    const showTransformWarning = showMetadataTransformWarning(designMetadata);
    const [warningDismissed, setWarningDismissed] = React.useState(false);
    const enableQcStationNewModelViewer = !!useFeatureFlag('enableQcStationNewModelViewer').value;

    if (!designPayloadsLoading && restoratives.length === 0) {
        return <QcNoItemsErrorDisplay />;
    }

    return (
        <LoadBlocker
            blocking={designPayloadsLoading || !restoratives.length}
            ContainerProps={{ style: { height: '100%' } }}
        >
            <ListAbutmentPartsProvider>
                {tab === restorativesAndLineItems.length ? (
                    <>
                        {models[0] &&
                            designFragment &&
                            (enableQcStationNewModelViewer ? (
                                <DesignViewerLiteWrapper
                                    order={order}
                                    designFragment={designFragment}
                                    style={{ height: '100%', width: '100%' }}
                                />
                            ) : (
                                <Container
                                    style={{
                                        maxWidth: '100vw',
                                        maxHeight: '100vw',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <ModelViewer
                                        orderMaterialsHaveLayers={true}
                                        full_screen={true}
                                        model_payload={models[0]}
                                        modelling_tree_buffer={modellingTreePayload}
                                        design_metadata={designMetadata}
                                        enable_textures={
                                            models[0] !== undefined &&
                                            !!design?.assets?.some(d => d.file_path.toLowerCase().endsWith('ply'))
                                        }
                                        style={{ height: '100%' }}
                                    />
                                </Container>
                            ))}
                    </>
                ) : (
                    <>
                        <ItemsSummary
                            items={activeRestoration ? [activeRestoration.item] : []}
                            headingSuffix={`${tab + 1} of ${restorativesAndLineItems.length}`}
                        />
                        {activeRestoration && <QcModelViewer model_payload={activeRestoration.restorative.payload} />}
                    </>
                )}
                {showTransformWarning && !warningDismissed && (
                    <ActionCard
                        style={{ margin: 10, marginBottom: 75 }}
                        variant={'alert'}
                        title={'We ran into potential issues displaying the 3D design'}
                        subtitle={'If the design looks misaligned or suspicious, QC the case in 3Shape Designer'}
                        secondaryAction={{ onClick: () => setWarningDismissed(true), text: 'Dismiss' }}
                    />
                )}
                <QcViewerTabs
                    tabNames={[
                        ...restorativesAndLineItems.map(
                            ({ item, restorative }) =>
                                // note: only using the unn's from the design file, not the item.
                                `${CartItemV2Utils.getDisplayName(item)} ${CartItemV2Utils.getUnnsDisplay(
                                    restorative.payload.unns ?? [],
                                )}`,
                        ),
                        'Wax-up',
                    ]}
                    tab={tab}
                    setTab={setTab}
                />
            </ListAbutmentPartsProvider>
        </LoadBlocker>
    );
};

const QcScanModelViewer: React.VFC<{ order: LabsGqlLabOrderFragment }> = ({ order }) => {
    const { result, loading, error } = useDesignModelPayloadsForScan(order.scan_export_id);
    const designPayloads = _.compact(result);

    const [tab, setTab] = React.useState<number>(0);
    if (error) {
        return <ErrorPage message={'Loading models failed'} text={'Please refresh the page to try again'} />;
    }
    if (designPayloads?.length === 0) {
        return <QcNoItemsErrorDisplay />;
    }
    const selectedModel = designPayloads?.[tab];
    return (
        <LoadBlocker blocking={loading}>
            {designPayloads && designPayloads.length ? (
                <ListAbutmentPartsProvider>
                    <ItemsSummary items={OrderItemV2Utils.parseItems(order.items_v2)} />
                    {selectedModel && <QcModelViewer model_payload={selectedModel} zoom={10} />}
                    <QcViewerTabs tabNames={designPayloads.map(p => p.name)} tab={tab} setTab={setTab} />
                </ListAbutmentPartsProvider>
            ) : (
                <QcNoItemsErrorDisplay />
            )}
        </LoadBlocker>
    );
};

export const Qc3DModelViewer: React.VFC<{ order: LabsGqlLabOrderFragment }> = ({ order }) => {
    const { isBadGpu, loading } = useOrthlyDetectGPU();
    if (loading) {
        return null;
    }
    if (isBadGpu) {
        return <BadGPUPerformance />;
    }
    if (!order.design_file_path) {
        return <QcScanModelViewer order={order} />;
    }
    return <QcDesignModelViewer order={order} />;
};
