import type { QcStationState } from '../screens/manufacturer/components/QcStation/state/QcStation.state';
import { qcReduxReducer } from '../screens/manufacturer/components/QcStation/state/QcStation.state';
import type { ManufacturerState } from '../screens/manufacturer/state/manufacturer.reducer';
import { manufacturerReducer } from '../screens/manufacturer/state/manufacturer.reducer';
import type { SelectStaffState } from '../screens/select-staff/state/select-staff.reducer';
import { staffMemberReducer } from '../screens/select-staff/state/select-staff.reducer';
import type { OrdersState } from './orders/orders.reducer';
import { ordersReducer } from './orders/orders.reducer';
import type { PracticeUIState } from './ui';
import { uiReducer } from './ui';
import type { StateWithAsync } from '@orthly/redux-async-actions';
import { createAsyncReducer } from '@orthly/redux-async-actions';
import type { RouterState } from 'connected-react-router';
import { connectRouter } from 'connected-react-router';
import type { History } from 'history';
import type { TypedUseSelectorHook } from 'react-redux';
import { useSelector } from 'react-redux';
import type { AnyAction } from 'redux';
import { combineReducers } from 'redux';

const reducer = (history: History) =>
    combineReducers<PartnerAppState>({
        async: createAsyncReducer(false),
        manufacturer: manufacturerReducer,
        orders: ordersReducer,
        router: connectRouter(history),
        ui: uiReducer,
        qcStation: qcReduxReducer,
        staffMember: staffMemberReducer,
    });

export const rootReducer = (history: History) => (state: PartnerAppState | undefined, action: AnyAction) => {
    return reducer(history)(state, action);
};

export interface PartnerAppState extends StateWithAsync {
    manufacturer: ManufacturerState;
    orders: OrdersState;
    router: RouterState;
    ui: PracticeUIState;
    qcStation: QcStationState;
    staffMember: SelectStaffState;
}

export const usePracticeAppSelector: TypedUseSelectorHook<PartnerAppState> = useSelector;
