import type { useAbutmentPartsMap } from '../context';
import { LabOrderItemSKUType, CartItemV2Utils } from '@orthly/items';
import type { ValidShadeType, IOrderItemV2DTO, IImplantToothGroup, IImplantBridgeItem } from '@orthly/items';
import _ from 'lodash';
import React from 'react';

type ViewModelProperty = { name: string; value: string };

export interface OrderItemViewModel {
    name: string;
    properties: ViewModelProperty[];
}

const shadeOrder: ValidShadeType[] = ['gingival', 'base', 'incisal', 'stump'];

function createShadeProperties(item: IOrderItemV2DTO): ViewModelProperty[] {
    if (!item.shades?.length) {
        return [];
    }

    return [
        {
            name: 'Shades',
            value: shadeOrder
                .flatMap<string>(shadeName => {
                    const shade = item.shades?.find(shade => shade.name === shadeName);
                    if (shadeName === 'stump' && !shade?.value) {
                        return [];
                    }

                    return [`${_.capitalize(shadeName)} ${shade?.value ?? 'N/A'}`];
                })
                .join(' • '),
        },
    ];
}

interface DedupedImplantDetails {
    unn: number;
    retention: string;
    manufacturer: string;
    system: string;
    connection_size: string;
    abutmentMaterial: string;
    abutmentSku?: string;
    abutmentManufacturer?: string;
    abutmentScrewSku?: string;
    analogSku?: string;
}

function getImplantDetails(
    implant: IImplantToothGroup,
    abutmentPartsMap: ReturnType<typeof useAbutmentPartsMap>,
): DedupedImplantDetails {
    const abutmentPartId = implant.implant_metadata?.part_id;
    const abutmentPart = abutmentPartId ? abutmentPartsMap.get(abutmentPartId) : undefined;
    return {
        unn: implant.unn,
        retention: _.startCase(implant.implant_metadata.relationship),
        manufacturer: implant.implant_metadata.manufacturer,
        system: implant.implant_metadata.system,
        connection_size: implant.implant_metadata.connection_size,
        abutmentMaterial: implant.abutment.material,
        abutmentSku: abutmentPart?.sku ?? undefined,
        abutmentManufacturer: abutmentPart?.manufacturer ?? undefined,
        abutmentScrewSku: abutmentPart?.screw_sku ?? undefined,
        analogSku: abutmentPart?.analog_sku ?? undefined,
    };
}

function implantDetailsToProperties(details: DedupedImplantDetails, nameSuffix?: string): ViewModelProperty[] {
    // appends the suffix to the property name, if exists
    const getName = (name: string) => (nameSuffix ? `${name} ${nameSuffix}` : name);
    return [
        { name: getName('Abutment'), value: details.abutmentMaterial },
        { name: getName('Retention'), value: details.retention },
        { name: getName('Implant Manufacturer'), value: details.manufacturer },
        { name: getName('System'), value: details.system },
        { name: getName('Connection'), value: details.connection_size },
        { name: getName('Abutment SKU'), value: details.abutmentSku },
        { name: getName('Abutment Manufacturer'), value: details.abutmentManufacturer },
        { name: getName('Screw SKU'), value: details.abutmentScrewSku ?? 'TBD' },
        { name: getName('Analog SKU'), value: details.analogSku },
    ].filter((i): i is ViewModelProperty => !!i.value);
}

function getImplantBridgeProperties(
    item: IImplantBridgeItem,
    abutmentPartsMap: ReturnType<typeof useAbutmentPartsMap>,
): ViewModelProperty[] {
    const details = item.implants.map(implant => getImplantDetails(implant, abutmentPartsMap));
    const grouped = _.groupBy(details, d => _.omit(d, ['unn']));
    const result = Object.values(grouped).flatMap(group => {
        const firstEntry = group[0];
        if (!firstEntry) {
            return null;
        }
        const unnString = CartItemV2Utils.getUnnsDisplay(group.map(g => g.unn));
        // no suffix if all implants have the same details, just add a property for the implant teeth
        if (group.length === item.implants.length) {
            return [
                // denote which teeth are implants if there are more than 2
                ...(group.length > 2 ? [{ name: 'Implants', value: `#${unnString}` }] : []),
                ...implantDetailsToProperties(firstEntry),
            ];
        }
        return implantDetailsToProperties(firstEntry, `(#${unnString})`);
    });
    return _.compact(result);
}

/**
 * Returns the key-value pairs for item properties.
 *
 * @param item The implant or implant-bridge item information
 * @param abutmentPartsMap The return value from {@link useAbutmentPartsMap}
 * @returns The key-value pairs for implant (bridge) item properties
 */
function getProperties(
    item: IOrderItemV2DTO,
    abutmentPartsMap: ReturnType<typeof useAbutmentPartsMap>,
): ViewModelProperty[] {
    const baseProperties: ViewModelProperty[] = [];

    if (CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.ImplantBridge)) {
        baseProperties.push(...getImplantBridgeProperties(item, abutmentPartsMap));
    }
    if (CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Implant)) {
        baseProperties.push(...implantDetailsToProperties(getImplantDetails(item.unit, abutmentPartsMap)));
    }
    if (CartItemV2Utils.isArchItem(item)) {
        baseProperties.push({ name: 'Arch', value: item.unit.arch });
    }

    return [
        { name: 'Material', value: CartItemV2Utils.getItemDisplayMaterial(item) },
        ...createShadeProperties(item),
        ...baseProperties,
        ...(item.item_notes ? [{ name: 'Notes', value: item.item_notes ?? '' }] : []),
    ].filter((i): i is ViewModelProperty => !!i.value);
}

/**
 * Given an order's line items, returns (memoized) information about the items, semi-convenient for display.
 *
 * @param {IOrderItemV2DTO[]} items The items in an order
 * @param abutmentPartsMap The return value from {@link useAbutmentPartsMap}
 * @returns Information about the order's items, semi-convenient for display
 */
export function useOrderItemsViewModel(
    items: IOrderItemV2DTO[],
    abutmentPartsMap: ReturnType<typeof useAbutmentPartsMap>,
): OrderItemViewModel[] {
    return React.useMemo(() => {
        return items.map<OrderItemViewModel>(item => {
            return {
                name: CartItemV2Utils.getFullDisplayName(item),
                properties: getProperties(item, abutmentPartsMap),
            };
        });
    }, [items, abutmentPartsMap]);
}
