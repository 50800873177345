import { Button } from '../primitives';
import {
    styled,
    ButtonGroup,
    MenuItem,
    MenuList,
    Popover,
    FlossPalette,
    useScreenIsMobile,
    Icon,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const StyledButtonGroup = styled(ButtonGroup)({
    '&:not(:last-child)': {
        borderRightColor: FlossPalette.STROKE_DARK,
    },
});

export interface SplitButtonProps {
    options: string[];
    onClickAction: (value?: string) => void;
    style?: React.CSSProperties;
}

export const SplitButton: React.VFC<SplitButtonProps> = props => {
    const { options, onClickAction } = props;
    const isMobile = useScreenIsMobile();
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const dropdownRef = React.useRef<HTMLButtonElement>(null);
    const [open, setOpen] = React.useState(false);

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleMenuItemClick = (_event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        handleToggle();
        setTimeout(() => {
            onClickAction(options[index]);
        }, 100);
    };

    return (
        <div style={props.style}>
            <StyledButtonGroup fullWidth={isMobile} ref={anchorRef}>
                <Button
                    data-test={'action-button'}
                    type={'submit'}
                    variant={'primary'}
                    onClick={() => onClickAction(_.first(options))}
                >
                    {_.first(options)}
                </Button>
                <Button
                    variant={'primary'}
                    size={'small'}
                    style={{ width: isMobile ? '20%' : undefined }}
                    onClick={e => {
                        e.currentTarget.blur();
                        handleToggle();
                    }}
                    ref={dropdownRef}
                    data-test={'dropdown-button'}
                >
                    <Icon icon={'ChevronDown'} />
                </Button>
            </StyledButtonGroup>
            <Popover
                open={open}
                anchorEl={anchorRef.current}
                onClose={handleToggle}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        width: anchorRef.current?.clientWidth,
                        border: `1px solid ${FlossPalette.PRIMARY_FOREGROUND}`,
                        boxShadow: `0px 0px 0px 4px ${FlossPalette.PRIMARY_BACKGROUND}`,
                        marginTop: 8,
                        borderRadius: '0 0 16px 16px',
                    },
                    elevation: 0,
                }}
            >
                <MenuList autoFocusItem data-test={'dropdown-menu'}>
                    {_.tail(options).map((option, index) => (
                        <MenuItem key={option} onClick={event => handleMenuItemClick(event, index + 1)}>
                            {option}
                        </MenuItem>
                    ))}
                </MenuList>
            </Popover>
        </div>
    );
};
