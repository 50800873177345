import type { LabOrderActionProps } from './index';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { getChatMessageVisibilityForWrite } from '@orthly/chat';
import type { LabsGqlCreateChatMessageMutationVariables } from '@orthly/graphql-operations';
import { useCreateChatMessageMutation } from '@orthly/graphql-react';
import type { LabsGqlStaffRoleWithAny } from '@orthly/graphql-schema';
import { LabsGqlChatEntityTypes, LabsGqlControlPanelActionType } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { getFullStoragePath, OrderingStorageConfigs } from '@orthly/shared-types';
import { OrthlyBrowserConfig, QuickForm, RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import {
    Button,
    FlossPalette,
    useScreenIsMobile,
    Collapse,
    Grid,
    IconButton,
    Tooltip,
    AttachFileIcon,
} from '@orthly/ui-primitives';
import { FileUploaderBulk } from '@orthly/veneer';
import React from 'react';

type Vars = Omit<
    LabsGqlCreateChatMessageMutationVariables['data'],
    'entity_type' | 'entity_id' | 'attachment_urls' | 'visible_to_org_ids' | 'visible_to_roles' | 'action_type'
>;

export const AddAttachmentAction = (
    props: LabOrderActionProps & { buttonVariant?: 'icon' | 'text'; iconStyle?: React.CSSProperties },
) => {
    const { order, buttonVariant } = props;
    const organizationType = useSession()?.organization_type ?? 'lab';
    const isMobile = useScreenIsMobile();
    const [attachmentUrls, setAttachmentUrls] = React.useState<string[]>([]);
    const [submitMtn] = useCreateChatMessageMutation();

    const mtnSubmitter = (data: Vars) =>
        submitMtn({
            variables: {
                data: {
                    ...data,
                    attachment_urls: attachmentUrls,
                    entity_type: LabsGqlChatEntityTypes.Order,
                    entity_id: order.id,
                    visible_to_roles: getChatMessageVisibilityForWrite<LabsGqlStaffRoleWithAny>(organizationType),
                    action_type: LabsGqlControlPanelActionType.AddFile,
                },
            },
        });
    const { open, setOpen, submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Attachment added!', {}],
        onSuccess: () => {
            BrowserAnalyticsClientFactory.Instance?.track(
                'Practice - Control Panel - Add Attachment Action Submitted',
                { $groups: { order: order.id } },
            );
            setAttachmentUrls([]);
            props.refetch();
        },
    });
    const openButton = React.useMemo(() => {
        return buttonVariant === 'icon' ? (
            <IconButton
                size={'small'}
                color={'inherit'}
                onClick={() => setOpen(true)}
                style={{ color: FlossPalette.STAR_GRASS, marginRight: 4, ...props.style }}
                data-test={'open-button'}
            >
                <Tooltip title={'Attach File'} arrow>
                    <AttachFileIcon style={{ color: FlossPalette.STAR_GRASS }} />
                </Tooltip>
            </IconButton>
        ) : (
            <Button
                variant={'ghost'}
                onClick={() => setOpen(true)}
                style={{ ...props.style }}
                data-test={'open-button'}
                analytics={{
                    AssetLocation: 'Practice - Control Panel - Action Taken',
                    AssetName: 'Attach File',
                }}
            >
                <AttachFileIcon color={'inherit'} style={props.iconStyle} />
                Attach file
            </Button>
        );
    }, [buttonVariant, setOpen, props.style, props.iconStyle]);
    const storagePathConfig = getFullStoragePath(OrthlyBrowserConfig.env, OrderingStorageConfigs.attachments, order.id);
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Add file'}
            subtitle={
                "These files will be shared with our order specialists - we'll reach out to you if we have any questions. If you would like to chat with an expert about this order immediately, please start a chat instead."
            }
            showCloseButton={true}
            content={
                <Grid container data-test={'add-attachment-dialog'}>
                    <FileUploaderBulk
                        autoSubmit
                        elevation={0}
                        storagePathConfig={storagePathConfig}
                        paperStyle={{ padding: '0 0 8px' }}
                        onComplete={results => {
                            results && setAttachmentUrls(results.map(r => r.uploadedPath));
                        }}
                        onReset={() => setAttachmentUrls([])}
                        prependTimestampToFilename={true}
                    />
                    <Collapse in={attachmentUrls.length > 0} style={{ width: '100%' }}>
                        <Grid container>
                            {attachmentUrls.length > 0 && (
                                <QuickForm<Vars>
                                    fields={{
                                        text: {
                                            type: 'text',
                                            label: 'Description',
                                            fieldProps: { multiline: true, minRows: 3, maxRows: 50 },
                                        },
                                    }}
                                    resetOnInitialValueChange={true}
                                    initialValues={{}}
                                    onSubmit={async result => {
                                        await submit({
                                            text: result.text,
                                        });
                                    }}
                                    submitButtonProps={{ style: { marginTop: isMobile ? 24 : 40 } }}
                                    submitButtonTitle={'Share note'}
                                />
                            )}
                        </Grid>
                    </Collapse>
                </Grid>
            }
            buttonText={'Add Attachment'}
            CustomButton={() => openButton}
        />
    );
};
