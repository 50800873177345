import type { LabsGqlOrderLabDelayPreviewFragment } from '@orthly/graphql-operations';
import { useOrderRefetch } from '@orthly/graphql-react';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import type { RootActionDialogProps } from '@orthly/ui';
import { AddOrderDelayAction } from '@orthly/veneer';
import React from 'react';

interface RequestManufacturerDelayProps {
    order: LabsGqlOrderLabDelayPreviewFragment;
    CustomButton?: RootActionDialogProps['CustomButton'];
}

export const RequestManufacturerDelay: React.FC<RequestManufacturerDelayProps> = props => {
    const { order } = props;
    const refetchOrder = useOrderRefetch();

    if (order.status !== LabsGqlLabOrderStatus.Fabrication) {
        return null;
    }

    return <AddOrderDelayAction order={order} refetchOrder={async () => refetchOrder(order.id)} />;
};
