import { useLoginStyles } from './login-styles';
import type { ButtonProps, GridJustification } from '@orthly/ui-primitives';
import { createStyles, Button, Grid, stylesFactory } from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';

const useStyles = stylesFactory(() =>
    createStyles({
        stickyBottomButtons: {
            position: 'sticky',
            background: '#fff',
            bottom: 12,
        },
    }),
);

type BottomButtonProps = Omit<ButtonProps, 'variant'> & {
    text: string;
    dontRender?: boolean;
};

interface BottomButtonsProps {
    left: BottomButtonProps;
    right: BottomButtonProps;
    justify?: GridJustification;
}

const bottomButtonStyle: React.CSSProperties = { width: '45%', height: 48 };

export const LoggedOutBottomButtons: React.FC<BottomButtonsProps> = props => {
    const classes = useLoginStyles();
    const btnClasses = useStyles();

    return (
        <Grid
            container
            className={cx(classes.columnRow, classes.columnRowBottom, btnClasses.stickyBottomButtons)}
            justifyContent={props.justify || 'space-between'}
        >
            <Button
                {...props.left}
                variant={'contained'}
                style={{
                    ...bottomButtonStyle,
                    display: props.left.dontRender ? 'none' : undefined,
                    ...props.left.style,
                }}
            >
                {props.left.text}
            </Button>
            <Button
                {...props.right}
                variant={'contained'}
                style={{
                    ...bottomButtonStyle,
                    display: props.right.dontRender ? 'none' : undefined,
                    ...props.right.style,
                }}
            >
                {props.right.text}
            </Button>
        </Grid>
    );
};
