import type {
    LabsGqlFilterCriteriaSubmissionInput,
    LabsGqlLabPortalListOrdersSort,
    LabsGqlLabPortalLabOrderSortKey,
} from '@orthly/graphql-schema';
import { LabsGqlFilterComparator } from '@orthly/graphql-schema';
import _ from 'lodash';

export const convertFiltersToHumanReadableQueryParams: (filters: LabsGqlFilterCriteriaSubmissionInput[]) => string = (
    filters: LabsGqlFilterCriteriaSubmissionInput[],
) => {
    return _.compact(
        filters.map(filter => {
            if (_.isEmpty(filter.comparison_value) && !filter.comparison_value) {
                return;
            }
            return `${filter.filter_id}-${filter.comparator}-${
                Array.isArray(filter.comparison_value) ? filter.comparison_value.join('|') : filter.comparison_value
            }`;
        }),
    ).join(',');
};

export const convertFilterFromHumanReadableQueryParams: (
    filterString: string,
) => LabsGqlFilterCriteriaSubmissionInput[] = (filterString: string) => {
    const filterStringComponents = filterString.split(',');
    return _.compact(
        filterStringComponents.map(filter => {
            let filterSplit: string[] = filter.split('-');
            // Expect 3 components to the filter
            if (filterSplit.length < 3) {
                return;
            }
            // Assume additional components are due to comparison value containing a '-'
            if (filterSplit.length > 3 && filterSplit[0] && filterSplit[1]) {
                const filterValue = filterSplit.slice(2).join('-');
                filterSplit = [filterSplit[0], filterSplit[1], filterValue];
            }
            // Determine whether to use a list or single value based on comparator
            const valueIsList = filterSplit[1] === LabsGqlFilterComparator.OneOf;
            let comparisonValue: string | string[] | undefined = filterSplit[2];
            if (comparisonValue && valueIsList) {
                comparisonValue = comparisonValue.includes('|') ? comparisonValue.split('|') : [comparisonValue];
            }
            return {
                filter_id: filterSplit[0],
                comparator: filterSplit[1] ?? '',
                comparison_value: comparisonValue,
            } as LabsGqlFilterCriteriaSubmissionInput;
        }),
    );
};

export const convertSortToHumanReadableQueryParams = (sort: LabsGqlLabPortalListOrdersSort) => {
    return `${sort.key}-${sort.asc ? 'asc' : 'desc'}`;
};

export const convertSortFromHumanReadableQueryParams: (sortString: string) => LabsGqlLabPortalListOrdersSort = (
    sortString: string,
) => {
    const sortStringSplit: string[] = sortString.split('-');
    if (sortStringSplit.length !== 2) {
        return {} as LabsGqlLabPortalListOrdersSort;
    }
    return { key: sortStringSplit[0] as LabsGqlLabPortalLabOrderSortKey, asc: sortStringSplit[1] === 'asc' };
};
