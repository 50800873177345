import { orderIdFromQRCodeUrl } from '../../../../../utils/utils';
// TODO ASAP: fix the circular dependencies. They are bad for performance and can cause bugs. EPDPLT-1362
// eslint-disable-next-line import/no-cycle
import { QCStationActions } from './QcStation.actions';
import _ from 'lodash';
import { handleActions } from 'redux-actions';

export enum QcStationResult {
    Passed = `Passed`,
    Failed = `Failed`,
}

export enum QcStationTab {
    Qc = `Qc`,
    Chat = `Chat`,
}

enum EmployeeSignInState {
    Unset = `Unset`,
    Skipped = `Skipped`,
    SignedIn = 'SignedIn',
}

export enum QcStationDialog {
    QcFail = `QcFail`,
    QcPass = `QcPass`,
    Shipping = `Shipping`,
}

export type QcStationState = {
    // this is the value used to query the backend for the order. its set only when the user submits the search by pressing enter
    activeOrderIdSearch?: string;
    checklistComplete: boolean;
    qcTab: QcStationTab;
    qcStationResult?: QcStationResult;
    qcDialog: QcStationDialog | null;
    failReason: string;
    issues: Record<string, { showNewOption: boolean; options: string[] }>;
    employeeSearch: string;
    integrationError?: string;
};

const ORDER_INITIAL_STATE: Omit<QcStationState, 'employeeSearch'> = {
    activeOrderIdSearch: undefined,
    checklistComplete: false,
    qcTab: QcStationTab.Qc,
    qcStationResult: undefined,
    qcDialog: null,
    failReason: ``,
    issues: {},
    integrationError: undefined,
};

const INITIAL_STATE: QcStationState = {
    ...ORDER_INITIAL_STATE,
    employeeSearch: ``,
};

export const qcReduxReducer = handleActions<QcStationState, any>(
    {
        ...QCStationActions.RESET_ORDER_SEARCH.reducer<QcStationState>(state => ({ ...state, ...ORDER_INITIAL_STATE })),
        ...QCStationActions.SUBMIT_ORDER_SEARCH.reducer<QcStationState>((state, { payload: search }) => {
            // if the search contains a UUID (usually from an order link) we assume that should be the active search, not the entire url
            const activeOrderIdSearch = orderIdFromQRCodeUrl(search) ?? search;
            // if they submit a search for the same orderId we don't do anything
            if (activeOrderIdSearch === state.activeOrderIdSearch) {
                return state;
            }
            return { ...state, ...ORDER_INITIAL_STATE, activeOrderIdSearch, qcStationResult: undefined };
        }),
        ...QCStationActions.SET_ORDER_PASSED.reducer<QcStationState>((state, { payload: { error } }) => {
            if (error) {
                return {
                    ...state,
                    qcDialog: QcStationDialog.QcPass,
                    integrationError: error,
                    qcStationResult: QcStationResult.Passed,
                };
            }
            return { ...state, qcStationResult: QcStationResult.Passed };
        }),
        ...QCStationActions.SET_ORDER_FAILED.reducer<QcStationState>(state => ({
            ...state,
            ...ORDER_INITIAL_STATE,
            delayDialogOpen: true,
            qcStationResult: QcStationResult.Failed,
        })),
        ...QCStationActions.DISMISS_INTEGRATION_ERROR.reducer<QcStationState>(state => ({
            ...state,
            integrationError: undefined,
        })),
        ...QCStationActions.TOGGLE_SHIPPING_OPEN.reducer<QcStationState>((state, { payload: shippingDialogOpen }) => ({
            ...state,
            qcDialog: shippingDialogOpen ? QcStationDialog.Shipping : null,
        })),
        ...QCStationActions.TOGGLE_FAIL_OPEN.reducer<QcStationState>((state, { payload: failOpen }) => ({
            ...state,
            qcDialog: failOpen ? QcStationDialog.QcFail : null,
            failReason: ``,
        })),
        ...QCStationActions.TOGGLE_PASS_OPEN.reducer<QcStationState>((state, { payload: passOpen }) => ({
            ...state,
            qcDialog: passOpen ? QcStationDialog.QcPass : null,
        })),
        ...QCStationActions.SET_FAIL_REASON.reducer<QcStationState>((state, { payload: failReason = '' }) => ({
            ...state,
            failReason,
        })),
        ...QCStationActions.SET_QC_TAB.reducer<QcStationState>((state, { payload: qcTab }) => ({ ...state, qcTab })),
        ...QCStationActions.ADD_ISSUE.reducer<QcStationState>((state, { payload: { id } }) => ({
            ...state,
            issues: { ...state.issues, [id]: { showNewOption: true, options: [] } },
        })),
        ...QCStationActions.CLEAR_ISSUE.reducer<QcStationState>((state, { payload: { id } }) => ({
            ...state,
            issues: _.omit(state.issues, id),
        })),
        ...QCStationActions.SET_ISSUE_OPTIONS.reducer<QcStationState>((state, { payload: { id, options } }) => {
            const issue = state.issues[id];
            if (options.length === 0 || !issue) {
                return { ...state, issues: _.omit(state.issues, id) };
            }
            return { ...state, issues: { ...state.issues, [id]: { ...issue, options, showNewOption: false } } };
        }),
        ...QCStationActions.SHOW_ISSUE_NEW_OPTION.reducer<QcStationState>((state, { payload: { id } }) => {
            const issue = state.issues[id];
            if (!issue) {
                return state;
            }
            return { ...state, issues: { ...state.issues, [id]: { ...issue, showNewOption: true } } };
        }),
        ...QCStationActions.HIDE_ISSUE_NEW_OPTION.reducer<QcStationState>((state, { payload: { id } }) => {
            const issue = state.issues[id];
            if (!issue) {
                return state;
            }
            return { ...state, issues: { ...state.issues, [id]: { ...issue, showNewOption: false } } };
        }),
        ...QCStationActions.SET_EMPLOYEE_SEARCH.reducer<QcStationState>((state, { payload: employeeSearch }) => {
            const employeeId = employeeSearch.match(/^([0-9]{3}|[0-9A-F]{10})$/)?.[0];
            if (!employeeId) {
                return { ...state, employeeSearch };
            }
            return { ...INITIAL_STATE, employeeId, employeeSearch, employeeSignInState: EmployeeSignInState.SignedIn };
        }),
        ...QCStationActions.SET_EMPLOYEE.reducer<QcStationState>((_state, { payload: employeeId }) => ({
            ...INITIAL_STATE,
            employeeId,
            employeeSignInState: EmployeeSignInState.SignedIn,
        })),
        ...QCStationActions.SKIP_EMPLOYEE.reducer<QcStationState>(_state => ({
            ...INITIAL_STATE,
            employeeSignInState: EmployeeSignInState.Skipped,
        })),
        ...QCStationActions.RESET_EMPLOYEE.reducer<QcStationState>(_state => INITIAL_STATE),
    },
    INITIAL_STATE,
);
