import { Button, FlossPalette, Grid, makeStyles } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles({
    selectedTab: {
        borderBottom: '2px solid green',
        display: 'inline-block',
    },
    tabContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    orderCount: {
        padding: '0 4px',
        marginLeft: '8px',
        backgroundColor: 'RGB(230,230,230)',
        color: FlossPalette.GRAY,
    },
});

export type TabOption = {
    label: string;
    tabId: string;
    pathValue: string;
    countsQueryKey: string;
    onClick?: () => void;
    orderCount?: number | null;
};

export const LabPortalOrderListTabs: React.VFC<{
    selectedTab: string | undefined;
    tabOptions: TabOption[];
}> = ({ selectedTab, tabOptions }) => {
    const classes = useStyles();
    return (
        <Grid className={classes.tabContainer}>
            {tabOptions.map(tabOption => (
                <Grid key={tabOption.tabId} className={selectedTab === tabOption.tabId ? classes.selectedTab : ''}>
                    <Button variant={'ghost'} onClick={tabOption.onClick}>
                        {tabOption.label}
                        <Grid className={classes.orderCount}>{tabOption.orderCount ?? 0}</Grid>
                    </Button>
                </Grid>
            ))}
        </Grid>
    );
};
