import type { PartnerAppState } from '../../../redux';
import type { ManufacturerState } from './manufacturer.reducer';
import type { TypedUseSelectorHook } from 'react-redux';
import { useSelector } from 'react-redux';

export const useManufacturerSelector: TypedUseSelectorHook<ManufacturerState> = <TSelected>(
    selector: (state: ManufacturerState) => TSelected,
    equalityFn?: (left: TSelected, right: TSelected) => boolean,
): TSelected => {
    return useSelector<PartnerAppState, TSelected>(s => selector(s.manufacturer), equalityFn);
};
