import { AnalyticsClient } from '../../../analytics/analyticsClient';
import { isOvernightServiceLevel, getShelfLabel, useIsNewPackagingEnabled } from '../batch-shipping.utils';
import { AddToBatchMutationProvider, useAddToBatchMutation } from '../hooks/useAddToBatchMutation.graphql';
import { useBatchShipDestinationAddress } from '../hooks/useBatchShipDestinationAddress.graphql';
import { useNeedsWaxRimInstructions } from '../hooks/useNeedsWaxRimInstructions';
import { AddOrderToBatchButton } from './AddOrderToBatchButton.graphql';
import { BatchShipDestinationSummary } from './BatchShipDestinationSummary.graphql';
import { BatchShipCandidateFragment_Doc } from './BatchShipOrderFragment.graphql';
import { BatchShipSummaryBox } from './BatchShipSummaryBox';
import { PrintBatchShippingLabelBtn } from './PrintBatchShippingLabelBtn.graphql';
import { PracticeScreen } from '@orthly/dentin';
import type {
    BatchShipCandidateSearchResultFragment,
    BatchShipCandidateOrderFragment,
} from '@orthly/graphql-inline-react';
import { getFragmentData } from '@orthly/graphql-inline-react';
import { Format } from '@orthly/runtime-utils';
import { DateUtils } from '@orthly/shared-types';
import { ActionCard } from '@orthly/ui';
import { FlossPalette, FlossPaletteUtils, Link, Grid, Text, Button, Icon } from '@orthly/ui-primitives';
import { UnboxingSlip, useFeatureFlag } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const SearchedOrderSummarySection: React.VFC<{
    searchedOrder: BatchShipCandidateOrderFragment;
    orderIdsInBatchWithSearchedOrder: string[];
    refetch: () => Promise<unknown>;
    searchLoading: boolean;
}> = ({ searchedOrder, orderIdsInBatchWithSearchedOrder, refetch, searchLoading }) => {
    // open state and which format
    const [unboxingSlipOpen, setUnboxingSlipOpen] = React.useState<'4x6' | 'letter' | null>(null);
    const newPackingEnabled = useIsNewPackagingEnabled(searchedOrder.practice_id);
    const isOvernight = isOvernightServiceLevel(searchedOrder.service_level ?? '');
    // if the add to batch mutation is loading, we disable the print label button
    const { loading: addingToBatch } = useAddToBatchMutation();
    const { needsWaxRimInstructions, onLoadUnboxingSlip, instructionUrl } = useNeedsWaxRimInstructions();

    // Printing is disabled if we are re-fetching the search or adding to batch, because that can impact the order IDs
    // that we need to record print for
    const printDisabled = addingToBatch || !!unboxingSlipOpen || searchLoading;
    return (
        <BatchShipSummaryBox title={`Search Result${orderIdsInBatchWithSearchedOrder.length > 1 ? ' (in batch)' : ''}`}>
            <Grid container justifyContent={'space-between'}>
                <Text variant={'h6'} style={{ margin: '4px 0' }}>
                    <span style={{ color: FlossPalette.GREEN }}>#{searchedOrder.order_number}</span> - &nbsp;
                    {searchedOrder.patient_name}
                    <Link
                        onClick={() => window.open(`/${PracticeScreen.lab}/${searchedOrder.lab_order_id}`, '_blank')}
                        style={{ marginLeft: 4, cursor: 'pointer' }}
                    >
                        <Icon icon={'OpenInNew'} style={{ color: FlossPalette.STAR_GRASS, maxHeight: '16px' }} />
                    </Link>
                </Text>
                <Text variant={'h6'} style={{ color: isOvernight ? FlossPalette.REVIEW_RED : FlossPalette.GRAY }}>
                    {_.startCase(searchedOrder.service_level).toUpperCase()}
                </Text>
            </Grid>
            <Grid container style={{ paddingTop: 12 }}>
                {newPackingEnabled && (
                    <Button
                        variant={'alert-secondary'}
                        style={{ marginRight: 8 }}
                        onClick={() => setUnboxingSlipOpen('4x6')}
                    >
                        New Packaging Slip
                    </Button>
                )}
                <Button variant={'secondary'} style={{ marginRight: 8 }} onClick={() => setUnboxingSlipOpen('letter')}>
                    Unboxing slip
                </Button>
                {needsWaxRimInstructions && (
                    <Button
                        style={{ marginRight: 8 }}
                        variant={'secondary'}
                        sx={{ color: `${FlossPalette.HIGHLIGHT_BLUE} !important` }}
                        onClick={() => window.open(instructionUrl, '_blank')}
                    >
                        Wax Rim Instructions
                    </Button>
                )}
                {searchedOrder.label_url && (
                    <PrintBatchShippingLabelBtn
                        refetch={refetch}
                        orderIdsInBatch={orderIdsInBatchWithSearchedOrder}
                        labelUrl={searchedOrder.label_url}
                        batchTrackingNumber={searchedOrder.tracking_number ?? 'unknown'}
                        disabled={printDisabled}
                    />
                )}
            </Grid>
            {unboxingSlipOpen && (
                <UnboxingSlip
                    layout={unboxingSlipOpen}
                    orderId={searchedOrder.lab_order_id}
                    onPrintComplete={() => {
                        setUnboxingSlipOpen(null);
                        AnalyticsClient.track('Lab - Portal - Unboxing Slip Opened', {
                            $groups: { order: searchedOrder.lab_order_id },
                            displayLocation: 'batch_shipping_page',
                        });
                    }}
                    onLoadComplete={onLoadUnboxingSlip}
                />
            )}
        </BatchShipSummaryBox>
    );
};

interface ShipmentBatch {
    trackingNumber: string;
    containsSearchedOrder: boolean;
    orders: BatchShipCandidateOrderFragment[];
    labelUrl: string | null;
    packingCompletedDate: Date | null;
}

interface ShippingBatchSummaryProps {
    batch: ShipmentBatch;
    searchedOrderId: string;
}

const ShippingBatchSummary: React.VFC<ShippingBatchSummaryProps> = ({ searchedOrderId, batch }) => {
    const { trackingNumber, containsSearchedOrder, orders, packingCompletedDate } = batch;
    const firstOrder = orders[0];
    if (!firstOrder) {
        return null;
    }
    const panNumbers = _.compact(orders.map(o => o.stack_code));
    return (
        <Grid container key={trackingNumber} style={{ marginBottom: 8 }}>
            <BatchShipSummaryBox
                title={
                    <Grid container justifyContent={'space-between'}>
                        <Text variant={'h6'}>
                            <span style={{ color: FlossPalette.GRAY }}>
                                {_.upperCase(_.startCase(firstOrder.service_level))} - &nbsp;
                            </span>
                            {panNumbers.length > 0
                                ? `Pans: ${panNumbers.join(', ')}`
                                : `${Format.pluralize('Order', orders.length)}`}
                        </Text>
                        <AddOrderToBatchButton
                            packingCompletedDate={packingCompletedDate}
                            containsSearchedOrder={containsSearchedOrder}
                            ordersInBatch={orders}
                            searchedOrderId={searchedOrderId}
                        />
                    </Grid>
                }
            >
                <Grid container style={{ padding: '8px 0' }}>
                    {orders.map(order => (
                        <Grid
                            key={order.lab_order_id}
                            container
                            style={{
                                backgroundColor:
                                    order.lab_order_id === searchedOrderId
                                        ? FlossPaletteUtils.toRgba('STAR_GRASS', 0.1)
                                        : undefined,
                                padding: 4,
                                borderBottom: `1px solid #eee`,
                                borderTop: `1px solid #eee`,
                                fontSize: 16,
                            }}
                            justifyContent={'space-between'}
                        >
                            <Grid container style={{ width: '70%' }}>
                                <Text variant={'body1'} style={{ width: '100%', fontWeight: 'bold' }}>
                                    #{order.order_number}
                                    {order.stack_code && (
                                        <span style={{ color: FlossPalette.STAR_GRASS }}>- {order.stack_code}</span>
                                    )}
                                </Text>
                                <Link
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        window.open(`/${PracticeScreen.lab}/${order.lab_order_id}`, '_blank')
                                    }
                                >
                                    <Text variant={'body2'} color={'GRAY'}>
                                        {order.patient_name}
                                        <Icon icon={'OpenInNew'} style={{ maxHeight: 16 }} />
                                    </Text>
                                </Link>
                            </Grid>
                            <div
                                style={{
                                    display: 'flex',
                                    width: '30%',
                                    flexWrap: 'wrap',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                }}
                            >
                                <Icon
                                    icon={order.scanned_in_shipping_at ? 'CheckCircle' : 'XIcon'}
                                    fontSize={'small'}
                                    style={{ marginRight: 4 }}
                                    color={order.scanned_in_shipping_at ? 'primary' : 'error'}
                                />{' '}
                                <Text variant={'body2'} color={order.scanned_in_shipping_at ? 'BLACK' : 'REVIEW_RED'}>
                                    {order.scanned_in_shipping_at ? (
                                        <>
                                            Scanned:&nbsp;
                                            <b>{DateUtils.relativeFormat(new Date(order.scanned_in_shipping_at))}</b>
                                        </>
                                    ) : (
                                        'Not Scanned Yet'
                                    )}
                                </Text>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </BatchShipSummaryBox>
        </Grid>
    );
};

// groups orders by tracking number into "batches" (orders all being shipped with the same label)
function getBatchesFromQuery(query: BatchShipCandidateSearchResultFragment): {
    batches: ShipmentBatch[];
    orderAlreadyInBatch: boolean;
    orderIdsInBatchWithSearchedOrder: string[];
    unpackedOrderCount: number;
    allOrdersReadyForBatching: boolean;
} {
    const searchedOrder = getFragmentData(BatchShipCandidateFragment_Doc, query.search_result);
    const shippedOrders = query.shipped_today.map(order => getFragmentData(BatchShipCandidateFragment_Doc, order));
    const byTrackingNumber = _.groupBy(shippedOrders, o => o.tracking_number ?? 'NOT FOUND');
    const rows = Object.entries(byTrackingNumber).map<ShipmentBatch>(([trackingNumber, orders]) => {
        const packingCompletedDates = orders.map(o =>
            o.packing_completed_at ? new Date(o.packing_completed_at) : null,
        );
        return {
            trackingNumber,
            packingCompletedDate: _.max(_.compact(packingCompletedDates)) ?? null,
            containsSearchedOrder: searchedOrder.tracking_number === trackingNumber,
            orders: [...(searchedOrder.tracking_number === trackingNumber ? [searchedOrder] : []), ...orders],
            labelUrl: orders[0]?.label_url ?? null,
        };
    });
    const batches = _.sortBy(rows, r => {
        if (r.containsSearchedOrder) {
            // bring the searched order to the top
            return -1000;
        }
        // sort desc by count of orders with that tracking number. if packing was completed, it goes to the bottom
        return r.packingCompletedDate ? 0 : -r.orders.length;
    });
    const batchWithOrder = batches.find(b => b.containsSearchedOrder);
    const orderIdsInBatchWithSearchedOrder = batchWithOrder?.orders.map(o => o.lab_order_id) ?? [
        searchedOrder.lab_order_id,
    ];
    const orderAlreadyInBatch = !!batchWithOrder;
    const unpackedOrderCount = shippedOrders.filter(o => !o.packing_completed_at).length;

    const unscannedOrderCount = shippedOrders.filter(o => !o.scanned_in_shipping_at).length;
    const allOrdersReadyForBatching = unscannedOrderCount === 0 && query.expected_to_ship_today_count === 0;
    return {
        batches,
        orderAlreadyInBatch,
        orderIdsInBatchWithSearchedOrder,
        unpackedOrderCount,
        allOrdersReadyForBatching,
    };
}

function getHexEmbedLink(orderNumber: string): string {
    return `https://app.hex.tech/99fa47a2-e436-4bc7-9258-7cfb103b9003/app/b626832c-d55e-45a1-9b70-06e97f2d1752/latest?embedded=true&embeddedStaticCellId=8fe9a843-f4ba-4f40-a73d-de819ad66105&_scan_input=%22${orderNumber}%22`;
}

const BatchInstructionsAndHex: React.VFC<{
    orderNumber: string;
    allOrdersReadyForBatching: boolean;
    shelfLabel: string;
}> = props => {
    const { orderNumber, shelfLabel, allOrdersReadyForBatching } = props;
    const [displayEmbed, setDisplayEmbed] = React.useState<boolean>(allOrdersReadyForBatching);
    return (
        <Grid container style={{ paddingBottom: 8 }}>
            <ActionCard
                variant={allOrdersReadyForBatching ? 'info' : 'alert'}
                title={`${
                    allOrdersReadyForBatching ? 'Ready to batch and pack!' : 'Eligible for Batching'
                } - ${shelfLabel}`}
                subtitle={
                    allOrdersReadyForBatching
                        ? 'Click "Show Pans" to find packing list'
                        : `Store on ${shelfLabel} consolidation shelf if order just arrived from QC`
                }
                style={{ minHeight: 'unset', padding: 8, margin: '0 0 8px' }}
                primaryAction={{
                    onClick: () => setDisplayEmbed(c => !c),
                    buttonVariant: 'nav',
                    text: displayEmbed ? 'Hide Pans' : 'Show Pans',
                }}
            />
            {displayEmbed && (
                <Grid container>
                    <iframe src={getHexEmbedLink(orderNumber)} style={{ width: '100%', height: 300, border: 'none' }} />
                </Grid>
            )}
        </Grid>
    );
};

interface BatchShipFoundOrderSectionProps {
    query: BatchShipCandidateSearchResultFragment;
    refetchQuery: () => Promise<unknown>;
    searchLoading: boolean;
}

/**
 * Renders the section to optionally add the searched order into an existing batch.
 */
export const BatchShipFoundOrderSection: React.VFC<BatchShipFoundOrderSectionProps> = ({
    query,
    refetchQuery,
    searchLoading,
}) => {
    const searchedOrder = getFragmentData(BatchShipCandidateFragment_Doc, query.search_result);
    const {
        batches,
        orderAlreadyInBatch,
        orderIdsInBatchWithSearchedOrder,
        unpackedOrderCount,
        allOrdersReadyForBatching,
    } = React.useMemo(() => getBatchesFromQuery(query), [query]);
    const addressQuery = useBatchShipDestinationAddress(searchedOrder.mailing_address_id);
    // we add 1 to the count because the searched order is not counted in unpackedOrderCount or expected_to_ship_today
    const totalBatchCandidates = query.expected_to_ship_today_count + unpackedOrderCount + 1;
    const { value: disabledPracticeIds = [] } = useFeatureFlag('batchShippingDisabledPracticeIds');
    // some practices have opted out of batching, so they are disabled no matter how many orders they have
    const practiceBatchesEnabled = !disabledPracticeIds.includes(searchedOrder.practice_id);
    // short term rate-limiting fix for lehi capacity constraints in pilot. if an order is already in a batch, that wins
    const eligibleForBatching = orderAlreadyInBatch || (practiceBatchesEnabled && totalBatchCandidates >= 2);
    const shelfNumber = addressQuery.mailingAddress?.shelfNumber ?? null;
    const shelfLabel = getShelfLabel(searchedOrder.service_level ?? '', shelfNumber);

    return (
        <AddToBatchMutationProvider searchedOrderId={searchedOrder.lab_order_id} refetchSearchQuery={refetchQuery}>
            <Grid container>
                {eligibleForBatching && (
                    <BatchInstructionsAndHex
                        orderNumber={searchedOrder.order_number}
                        allOrdersReadyForBatching={allOrdersReadyForBatching}
                        shelfLabel={shelfLabel}
                    />
                )}
                <Grid container spacing={3}>
                    <Grid item xs={7}>
                        <SearchedOrderSummarySection
                            searchLoading={searchLoading}
                            refetch={refetchQuery}
                            searchedOrder={searchedOrder}
                            orderIdsInBatchWithSearchedOrder={orderIdsInBatchWithSearchedOrder}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <BatchShipDestinationSummary
                            loading={addressQuery.loading}
                            mailingAddress={addressQuery.mailingAddress}
                            remainingToShipToday={query.expected_to_ship_today_count}
                        />
                    </Grid>
                </Grid>
                {eligibleForBatching ? (
                    <Grid container>
                        <Text variant={'h5'} style={{ padding: '18px 0', width: '100%' }}>
                            Shipments today:&nbsp;
                            {shelfNumber && <span style={{ color: FlossPalette.GREEN }}>Shelf #{shelfNumber}</span>}
                        </Text>
                        {batches.map(batch => (
                            <ShippingBatchSummary
                                key={batch.trackingNumber}
                                batch={batch}
                                searchedOrderId={searchedOrder.lab_order_id}
                            />
                        ))}
                    </Grid>
                ) : (
                    <Grid container>
                        <Text variant={'h5'} style={{ padding: '18px 0', width: '100%' }}>
                            Order not eligible for batching (
                            {!practiceBatchesEnabled ? 'Practice has opted out' : 'Not enough candidates'})
                        </Text>
                    </Grid>
                )}
            </Grid>
        </AddToBatchMutationProvider>
    );
};
