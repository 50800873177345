import { LoginRoutes } from './LoginPaths';
import { LoginRoot } from './LoginRoot';
import { LoggedOutLayout } from './components/LoggedOutLayout';
import { SendResetEmail } from './password-reset/SendResetEmail';
import { SetNewPassword } from './password-reset/SetNewPassword';
import type { LocationDescriptorObject } from 'history';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

interface PracticeLoginRootProps {
    originalLocation: LocationDescriptorObject;
}

export const LoggedOutRoot: React.FC<PracticeLoginRootProps> = props => {
    return (
        <Switch>
            <Route path={'/login/reset'}>
                <LoggedOutLayout rightColumnContent={<SendResetEmail />} />
            </Route>
            <Route path={'/login/new-password'}>
                <LoggedOutLayout rightColumnContent={<SetNewPassword />} />
            </Route>
            <Route path={Object.values(LoginRoutes)}>
                <LoginRoot originalLocation={props.originalLocation} />
            </Route>
        </Switch>
    );
};
