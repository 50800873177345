import { useOrderDownloadConfig, getScanFilesNew } from '../../../../../../utils/OrderFileDownloadUtils';
import { LabInboxBulkActionIconButton } from '../../../../components/bulk-actions/LabInboxBulkActionIconButton';
import type { LabPortalDownloadableOrder } from './LabPortalOrderActions.types';
import { DownloadIcon } from '@orthly/ui';
import { Menu, MenuItem } from '@orthly/ui-primitives';
import {
    BulkDownloadDialog,
    DesignOrderLatestRawRevisionsDownload,
    DesignOrderRevisionManufacturerFilesDownload,
    useFeatureFlag,
} from '@orthly/veneer';
import React from 'react';

interface LabPortalOrderDownloadActionProps {
    selectedOrders: LabPortalDownloadableOrder[];
    disabled?: boolean;
}
export const LabPortalDownloadFilesAction: React.FC<LabPortalOrderDownloadActionProps> = props => {
    const { selectedOrders, disabled } = props;
    const [open, setOpen] = React.useState(false);
    const downloadButtonRef = React.useRef();
    const config = useOrderDownloadConfig();
    const allOrdersMissingScanFiles = React.useMemo(
        () => selectedOrders.every(order => !order.scanExportSanitizedUrl),
        [selectedOrders],
    );
    const allOrdersMissingDesignFiles = !selectedOrders.some(order => order.designFilePath);

    const { value: allowDownloadLabManufacturingDesignFiles } = useFeatureFlag('downloadLabManufacturingDesignFiles');

    const [scanFileDownloadOpen, setScanFileDownloadOpen] = React.useState(false);

    return (
        <>
            <LabInboxBulkActionIconButton
                buttonProps={{ buttonRef: downloadButtonRef }}
                onClick={() => {
                    setOpen(true);
                }}
                loading={false}
                disabled={disabled || false}
                disableTooltip={true}
                selectionCount={selectedOrders.length}
                tooltipTitle={''}
            >
                <DownloadIcon />
            </LabInboxBulkActionIconButton>

            <BulkDownloadDialog
                open={scanFileDownloadOpen}
                setOpen={setScanFileDownloadOpen}
                files={selectedOrders
                    .flatMap(order => getScanFilesNew(order))
                    .map(record => ({
                        source: 'firebase',
                        path: record.recordPath,
                        name: record.fileName,
                    }))}
            />

            <Menu
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                anchorEl={downloadButtonRef.current}
                open={open}
                onClose={() => setOpen(false)}
            >
                <MenuItem
                    disabled={allOrdersMissingScanFiles}
                    onClick={() => {
                        setScanFileDownloadOpen(true);
                        setOpen(false);
                    }}
                >
                    Download scans
                </MenuItem>

                <DesignOrderLatestRawRevisionsDownload
                    orderIds={selectedOrders.map(order => order.id)}
                    Button={({ onClick }) => (
                        <MenuItem disabled={allOrdersMissingDesignFiles} onClick={onClick}>
                            Download design ZIP
                        </MenuItem>
                    )}
                />

                <DesignOrderRevisionManufacturerFilesDownload
                    orderIds={selectedOrders.map(order => order.id)}
                    rotated={config.autoRotate}
                    Button={({ onClick }) => (
                        <MenuItem
                            onClick={() => {
                                onClick();
                            }}
                            disabled={allOrdersMissingDesignFiles || !allowDownloadLabManufacturingDesignFiles}
                        >
                            Download manufacturer STLs
                        </MenuItem>
                    )}
                />
            </Menu>
        </>
    );
};
