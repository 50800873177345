import { OrderDetailBlock } from './OrderDetailBlock';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import { LoadBlocker } from '@orthly/ui';
import { Button, FlossPalette, stylesFactory, Grid, Text, AttachFileIcon } from '@orthly/ui-primitives';
import React from 'react';

const useClasses = stylesFactory(() => ({
    warningRoot: { backgroundColor: FlossPalette.ATTENTION_BG },
}));

interface OrderDetailDesignWarningProps {
    order: LabsGqlOrder;
    designAvailable: boolean;
    loadingDesign: boolean;
    triggerDesignDownload: () => Promise<any>;
}

export const OrderDetailDesignWarning: React.FC<OrderDetailDesignWarningProps> = props => {
    const { order, designAvailable, loadingDesign, triggerDesignDownload } = props;
    const classes = useClasses();
    const [designWarningDismissed, setDesignWarningDismissed] = React.useState(false);
    const currentlyInDesign = ['ExternalDesign', 'InternalDesign'].includes(
        order.fulfillment_workflow.active_task?.type ?? '',
    );

    const isWaxupRevision =
        order.fulfillment_workflow.configuration.waxup_required &&
        order.fulfillment_workflow.active_task?.__typename === 'ExternalDesignWorkflowTask' &&
        order.fulfillment_workflow.active_task?.configuration.is_revision;

    if ((currentlyInDesign && !isWaxupRevision) || !designAvailable || designWarningDismissed) {
        return null;
    }

    const isPendingApproval = (
        [LabsGqlWorkflowTaskType.WaxupReview, LabsGqlWorkflowTaskType.ExternalDesignVerification] as (
            | LabsGqlWorkflowTaskType
            | undefined
        )[]
    ).includes(order.fulfillment_workflow.active_task?.type);

    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
    // eslint-disable-next-line no-nested-ternary
    const designFileDescriptor = isPendingApproval
        ? 'pending approval'
        : isWaxupRevision
          ? 'with requested changes'
          : 'attached';

    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
    // eslint-disable-next-line no-nested-ternary
    const designFileInstruction = isPendingApproval
        ? 'await approval before downloading'
        : isWaxupRevision
          ? 'make revisions and resubmit for approval'
          : 'download before sending this order to Fabrication';

    return (
        <LoadBlocker blocking={loadingDesign}>
            <OrderDetailBlock
                title={
                    <Grid container>
                        <AttachFileIcon />
                        {`Design files ${designFileDescriptor}`}
                    </Grid>
                }
                buttonsBelowOnMobile={true}
                variant={'full'}
                classes={{ innerRoot: classes.warningRoot }}
                actions={
                    <Grid>
                        {!isPendingApproval && !isWaxupRevision && (
                            <Button
                                variant={'ghost'}
                                startIcon={'DownloadIcon'}
                                onClick={() => {
                                    void triggerDesignDownload?.().then(() => setDesignWarningDismissed(true));
                                }}
                            >
                                Download
                            </Button>
                        )}
                        <Button
                            variant={'ghost'}
                            startIcon={'HighlightOffIcon'}
                            onClick={() => setDesignWarningDismissed(true)}
                        >
                            Dismiss
                        </Button>
                    </Grid>
                }
            >
                <Text>
                    This order has design files {designFileDescriptor}. Please {designFileInstruction}.
                </Text>
            </OrderDetailBlock>
        </LoadBlocker>
    );
};
