import type { IconName } from '@orthly/ui-primitives';
import { Icon, Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

export const IndicatorFlag: React.FC<{
    iconName: IconName;
    title: string;
    color: string;
    style?: React.CSSProperties;
}> = ({ iconName, title, color, style }) => (
    <Grid container alignItems={`center`} style={{ width: `auto`, ...style }}>
        <Icon icon={iconName} style={{ color, marginRight: 4, width: 16, height: 16 }} />
        <Text variant={'body2'} style={{ color, fontSize: 12, fontWeight: 600 }}>
            {title}
        </Text>
    </Grid>
);
