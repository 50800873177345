import type { KustomerUser, PracticeUIState } from './ui';
import { createSyncAction, generateUseActionHook } from '@orthly/redux-async-actions';

const PREFIX = 'ui';

export const PartnerUiActions = {
    SET_IS_PWA_MODE: createSyncAction<boolean, [boolean]>(`${PREFIX}/SET_IS_PWA_MODE`),
    UPDATE_UI: createSyncAction<Partial<PracticeUIState>, [Partial<PracticeUIState>]>(`${PREFIX}/UPDATE_UI`),
    SET_SPOTLIGHT_OPEN: createSyncAction<boolean, [boolean]>(`${PREFIX}/SET_SPOTLIGHT_OPEN`),
    SET_STACKCODE_SEARCH: createSyncAction<boolean, [boolean]>(`${PREFIX}/SET_STACKCODE_SEARCH`),
    SET_QR_CODE_OPEN: createSyncAction<boolean, [boolean]>(`${PREFIX}/SET_QR_CODE_OPEN`),
    /** @deprecated use unified chat setWindow(MiniWindowVariant.Maximized, 'move') */
    // the empty payload creator here ensures events curried into params by accident aren't logged to redux devtools (crashes it)
    KUSTOMER_OPEN: createSyncAction<undefined, []>(`${PREFIX}/KUSTOMER_OPEN`, () => undefined),
    /** @deprecated use unified chat setWindow(MiniWindowVariant.Closed) */
    KUSTOMER_CLOSE: createSyncAction<undefined, []>(`${PREFIX}/KUSTOMER_CLOSE`),
    /** @deprecated use unified chat useGetChatAvailablityQuery */
    KUSTOMER_LOGIN: createSyncAction<undefined, []>(`${PREFIX}/KUSTOMER_LOGIN`),
    /** @deprecated use unified chat useGetChatAvailablityQuery */
    KUSTOMER_LOGOUT: createSyncAction<undefined, []>(`${PREFIX}/KUSTOMER_LOGOUT`),
    /**
     * @deprecated
     * As of 2022-06 this action is almost no-op.
     *
     * - In src/utils/KustomerWrapper.tsx it logs in with `this.rds.retainer.getOrgPrimaryAdmin(partnerId).email`
     * - In src/screens/checkout-v2/components/PatientDoctorInfo/CheckoutSelectDoctor.tsx it logs in with `partner.primary_contact?.email`
     *
     * Therefore unified chat does not support changing user sessions in this way.
     */
    KUSTOMER_SET_USER: createSyncAction<KustomerUser, [KustomerUser]>(`${PREFIX}/KUSTOMER_SET_USER`),
};

export const usePartnerUiAction = generateUseActionHook<typeof PartnerUiActions>(PartnerUiActions);
