import type { MutableRefObject } from 'react';
import React from 'react';

export const useKeyboardCapture = (ref: MutableRefObject<HTMLInputElement | null>) => {
    const listener = React.useCallback(() => {
        if (![`input`, `textarea`].includes(window.document.activeElement?.tagName.toLowerCase() ?? ``)) {
            ref.current?.select();
        }
    }, [ref]);
    React.useEffect(() => {
        window.addEventListener(`keydown`, listener);
        return () => window.removeEventListener(`keydown`, listener);
    }, [listener]);
};
