// TODO ASAP: fix the circular dependencies. They are bad for performance and can cause bugs. EPDPLT-1362
// eslint-disable-next-line import/no-cycle
import type { QcStationTab } from './QcStation.state';
import { createSyncAction, generateUseActionHook } from '@orthly/redux-async-actions';

const PREFIX = 'qc-station';
type IssueActionPayload = { id: string };
type IssueOptionsPayload = { id: string; options: string[] };

export const QCStationActions = {
    // Makes the current search (or the ID parsed from it) the active value we will use for the backend order lookup
    SUBMIT_ORDER_SEARCH: createSyncAction<string>(`${PREFIX}/SUBMIT_ORDER_SEARCH`),
    RESET_ORDER_SEARCH: createSyncAction<undefined>(`${PREFIX}/RESET_ORDER_SEARCH`),
    SET_ORDER_PASSED: createSyncAction<{ error?: string }>(`${PREFIX}/SET_ORDER_PASSED`),
    SET_ORDER_FAILED: createSyncAction<undefined>(`${PREFIX}/SET_ORDER_FAILED`),
    DISMISS_INTEGRATION_ERROR: createSyncAction<undefined>(`${PREFIX}/DISMISS_INTEGRATION_ERROR`),
    TOGGLE_SHIPPING_OPEN: createSyncAction<boolean>(`${PREFIX}/TOGGLE_SHIPPING_OPEN`),
    TOGGLE_FAIL_OPEN: createSyncAction<boolean>(`${PREFIX}/TOGGLE_FAIL_OPEN`),
    TOGGLE_PASS_OPEN: createSyncAction<boolean>(`${PREFIX}/TOGGLE_PASS_OPEN`),
    SET_FAIL_REASON: createSyncAction<string | undefined, [string | undefined]>(`${PREFIX}/SET_FAIL_REASON`),
    SET_QC_TAB: createSyncAction<QcStationTab>(`${PREFIX}/SET_QC_TAB`),
    ADD_ISSUE: createSyncAction<IssueActionPayload>(`${PREFIX}/ADD_ISSUE`),
    CLEAR_ISSUE: createSyncAction<IssueActionPayload>(`${PREFIX}/CLEAR_ISSUE`),
    SET_ISSUE_OPTIONS: createSyncAction<IssueOptionsPayload>(`${PREFIX}/SET_ISSUE_OPTIONS`),
    SHOW_ISSUE_NEW_OPTION: createSyncAction<IssueActionPayload>(`${PREFIX}/SHOW_ISSUE_NEW_OPTION`),
    HIDE_ISSUE_NEW_OPTION: createSyncAction<IssueActionPayload>(`${PREFIX}/HIDE_ISSUE_NEW_OPTION`),
    SET_EMPLOYEE_SEARCH: createSyncAction<string>(`${PREFIX}/SET_EMPLOYEE_SEARCH`),
    SET_EMPLOYEE: createSyncAction<string>(`${PREFIX}/SET_EMPLOYEE`),
    SKIP_EMPLOYEE: createSyncAction<undefined>(`${PREFIX}/SKIP_EMPLOYEE`),
    RESET_EMPLOYEE: createSyncAction<undefined>(`${PREFIX}/RESET_EMPLOYEE`),
};

export const useQcStationAction = generateUseActionHook<typeof QCStationActions>(QCStationActions);
