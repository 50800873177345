import { useGetOrderProceduresV2Query } from '@orthly/graphql-react';
import { LabtracDetailsSidebar } from '@orthly/veneer';
import React from 'react';

export const LabtracDetails: React.FC<{ orderId: string }> = ({ orderId }) => {
    const { data: procedureData, loading: proceduresLoading } = useGetOrderProceduresV2Query({
        variables: { order_id: orderId, manufacturer_id: null },
    });
    return (
        <LabtracDetailsSidebar
            dataLoading={proceduresLoading}
            labtracId={procedureData?.getOrderProceduresV2?.labtracId}
            procedureData={procedureData?.getOrderProceduresV2?.procedures}
        />
    );
};
