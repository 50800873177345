import { selectFirstTruthyKey } from '@orthly/runtime-utils';
import { stylesFactory, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    toolbarTitle: {
        lineHeight: '1',
    },
}));

type TaskListHeaderVariant = 'Normal' | 'HighlightText' | 'HighlightBackground';

interface TaskListHeaderTitleProps {
    title: string;
    variant?: TaskListHeaderVariant;
}

// used by BillingColumn.tsx
export const TaskListHeaderTitle: React.FC<TaskListHeaderTitleProps> = props => {
    const classes = useStyles();
    const { title, variant } = props;
    return (
        <Text
            variant={'h6'}
            color={
                selectFirstTruthyKey({
                    ATTENTION: variant === 'HighlightText',
                    WHITE: variant === 'HighlightBackground',
                }) || 'BLACK'
            }
            className={classes.toolbarTitle}
        >
            {title}
        </Text>
    );
};
