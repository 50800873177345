import { LEHI_MANUFACTURER_ID } from '@orthly/shared-types';

const PAN_LABEL_SUPPORTED_LAB_IDS: string[] = [LEHI_MANUFACTURER_ID];

/**
 * Whether to show elements related to PanLabels
 * @param manufacturerId The user's manufacturer ID.  `undefined` is permitted because this is a hook that must
 * execute unconditionally, but `undefined` will always return `false`.
 * @returns Whether to show buttons to access pan labels
 */
export function usePanLabels(manufacturerId: string | undefined): boolean {
    return manufacturerId ? PAN_LABEL_SUPPORTED_LAB_IDS.includes(manufacturerId) : false;
}

export function useShowUnboxingSlipOnOrderDetailsPage(manufacturerId: string): boolean {
    return manufacturerId !== LEHI_MANUFACTURER_ID;
}
