import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';

interface DtFormatParams {
    timezone?: string | null;
    dtFormat?: string;
    tFormat?: string;
}

export function dtFormatWithEastern(
    when: string | Date | Moment,
    { timezone, dtFormat = 'MM/DD/YY h:mma', tFormat = 'h:mma' }: DtFormatParams,
): string {
    const ny = 'America/New_York';
    const tz = timezone || ny;
    const localMoment = moment(when).tz(tz);
    const localStr = `${localMoment.format(dtFormat)} ${localMoment.zoneAbbr()}`;
    if (tz === ny) {
        return localStr;
    }
    const easternMoment = moment(when).tz(ny);
    return `${localStr} (${easternMoment.format(tFormat)} ${easternMoment.zoneAbbr()})`;
}
