import type { FormControlLabelProps, SwitchProps } from '@orthly/ui-primitives';
import { FormControlLabel, Switch } from '@orthly/ui-primitives';
import React from 'react';

interface SimpleToggleProps {
    label: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
    FormControlLabelProps?: Partial<FormControlLabelProps>;
    SwitchProps?: Partial<SwitchProps>;
    // If true, the input element will blur itself after it is clicked, in order to give up the focus.
    blur?: boolean;
}

export const SimpleToggle: React.FC<SimpleToggleProps> = props => {
    const ref = React.useRef<HTMLInputElement>(null);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.checked);
        if (props.blur) {
            ref.current?.blur();
        }
    };

    return (
        <FormControlLabel
            {...props.FormControlLabelProps}
            control={
                <Switch
                    {...props.SwitchProps}
                    color={'secondary'}
                    checked={props.checked}
                    onChange={onChange}
                    inputRef={ref}
                />
            }
            label={props.label}
        />
    );
};
