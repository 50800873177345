import { LoadBlocker } from '@orthly/ui';
import { Grid, IconButton, Tooltip } from '@orthly/ui-primitives';
import React from 'react';

interface LabInboxBulkActionIconButtonProps {
    loading: boolean;
    selectionCount: number;
    tooltipTitle: string;
    onClick: () => any;
    children: React.ReactNode;
    disabled?: boolean;
    disableTooltip?: boolean;
    buttonProps?: {
        buttonRef: React.MutableRefObject<undefined>;
    };
}

export const LabInboxBulkActionIconButton: React.FC<LabInboxBulkActionIconButtonProps> = props => {
    const { tooltipTitle, selectionCount, loading, onClick, buttonProps } = props;
    return (
        <LoadBlocker blocking={loading} ContainerProps={{ style: { width: 'auto', marginLeft: 5 } }}>
            <Tooltip
                title={selectionCount === 0 ? `Select orders to enable bulk action` : tooltipTitle}
                placement={'right'}
                arrow
                disableHoverListener={props.disableTooltip}
            >
                <Grid container style={{ width: 'auto' }}>
                    <IconButton
                        {...buttonProps}
                        style={{ color: props.disabled ? 'reset' : 'inherit' }}
                        disabled={selectionCount === 0 || props.disabled}
                        size={'small'}
                        onClick={e => {
                            e.stopPropagation();
                            onClick();
                        }}
                    >
                        {props.children}
                    </IconButton>
                </Grid>
            </Tooltip>
        </LoadBlocker>
    );
};
