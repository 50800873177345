import { LabPortalMarkReturnReceivedAction } from '../../LabPortalOrderActions/LabPortalMarkReturnReceivedAction';
import { useLabPortalOrderListViewContext } from '../../providers/LabPortalOrderListViewProvider';
import { useLabPortalOrderTabCountsContext } from '../../providers/LabPortalOrderTabCountsProvider';
import type { IndicatorFlagKey } from '../shared/IndicatorFlags';
import { IndicatorFlags } from '../shared/IndicatorFlags';
import type { ColumnDef } from '../shared/LabPortalOrderListView';
import { LabPortalOrderListView, LabPortalListViewNameColumn } from '../shared/LabPortalOrderListView';
import { useLabPortalReturnsPendingOrdersByListViewQuery } from '@orthly/graphql-react';
import { LoadBlocker } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

export const LabPortalReturnsPendingListView: React.VFC = () => {
    const { filters, sort } = useLabPortalOrderListViewContext();

    const { refetch: refetchCounts } = useLabPortalOrderTabCountsContext();
    const { data, loading, refetch } = useLabPortalReturnsPendingOrdersByListViewQuery({
        fetchPolicy: 'no-cache',
        variables: { criteria: filters, labPortalSort: sort },
    });

    const columns: ColumnDef[] = React.useMemo(
        () => [
            {
                label: 'Name',
                cell: order => (
                    <LabPortalListViewNameColumn
                        orderId={order.id}
                        orderNumber={order.orderNumber}
                        patientName={order.patientName}
                    />
                ),
            },
            { label: 'Order Product Line', cell: order => order.orderProductLine },
            {
                label: 'Flags',
                cell: order =>
                    order.listFlags?.map((flag: IndicatorFlagKey) => <Grid key={flag}>{IndicatorFlags[flag]}</Grid>),
            },
            {
                label: 'Actions',
                cell: order => (
                    <LabPortalMarkReturnReceivedAction
                        onSingleRow={true}
                        selectedOrders={[order]}
                        refetch={async () => {
                            await refetch();
                            await refetchCounts();
                        }}
                        disabled={false}
                    />
                ),
            },
        ],
        [refetch, refetchCounts],
    );

    return (
        <LoadBlocker blocking={loading}>
            <LabPortalOrderListView orders={data?.orders ?? []} columns={columns} />
        </LoadBlocker>
    );
};
