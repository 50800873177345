import { useViewModel } from './EditPennyweight.vm';
import type { IOrderItemSingleToothUnit, IOrderItemV2DTO, ToothNumber } from '@orthly/items';
import { PennyweightUtils, OrderItemV2Utils, CartItemV2Utils } from '@orthly/items';
import { SimpleSelect, RootActionDialog, SimpleInput } from '@orthly/ui';
import { Text, Button, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

type InputProps = {
    onChange: (value: string | undefined) => void;
    value?: string;
};

/** Input for editing a pennyweight. */
const Input: React.VFC<InputProps> = ({ onChange, value: initialValue }) => {
    const [value, setValue] = React.useState<string | undefined>(initialValue);

    const isValid = (v?: string) => {
        const num = _.toNumber(v);
        return !_.isNaN(num) && num > 0;
    };

    return (
        <>
            <SimpleInput
                label={'Pennyweight'}
                value={value}
                onChange={v => {
                    setValue(v);
                    const valid = isValid(v);
                    onChange(valid ? v : undefined);
                }}
            />
            {!isValid(value) && (
                <Text variant={'body2'} color={'REVIEW_RED'}>
                    Please enter a positive number
                </Text>
            )}
        </>
    );
};

type EditUnitGridProps = {
    onChange: (value: string | undefined) => void;
    unit: IOrderItemSingleToothUnit<'Crown' | 'CrownPontic'>;
};

/** Grid for editing a single unit's pennyweight. */
const EditUnitGrid: React.VFC<EditUnitGridProps> = ({ onChange, unit }) => {
    const defaultValue = PennyweightUtils.defaultPreciousMetalPennyweight(unit);
    const pennyweight = unit.precious_metal_pennyweight ?? defaultValue ?? undefined;
    const value = _.isFinite(pennyweight) ? `${pennyweight}` : undefined;

    return (
        <Grid container>
            <Grid item xs={4}>
                {unit.unn}
            </Grid>
            <Grid item xs={4}>
                {unit.material}
            </Grid>
            <Grid item xs={4}>
                <Input onChange={onChange} value={value} />
            </Grid>
        </Grid>
    );
};

type EditUnitsFormData = Array<{ unn: ToothNumber; pennyweight: number }>;

type EditUnitsFormProps = {
    onSubmit: (data: EditUnitsFormData) => void;
    units: IOrderItemSingleToothUnit<'Crown' | 'CrownPontic'>[];
};

/** Form for editing the pennyweights of a single item. */
const EditUnitsForm: React.VFC<EditUnitsFormProps> = ({ onSubmit, units }) => {
    const [data, setData] = React.useState<Map<ToothNumber, string | undefined>>(new Map());

    const disabled = [...data.values()].some(i => i === undefined);

    const handleOnChange = (unn: ToothNumber, value: string | undefined) => {
        data.set(unn, value);
        setData(new Map(data));
    };

    return (
        <Grid
            alignItems={'center'}
            container
            direction={'row'}
            item
            spacing={1}
            style={{ width: '100%', margin: '1rem 0' }}
        >
            <Grid container>
                <Grid item xs={4}>
                    <Text variant={'h6'}>Tooth Number</Text>
                </Grid>
                <Grid item xs={4}>
                    <Text variant={'h6'}>Material</Text>
                </Grid>
                <Grid item xs={4}>
                    <Text variant={'h6'}>Pennyweight</Text>
                </Grid>
            </Grid>
            {units.map(unit => (
                <EditUnitGrid key={unit.unn} onChange={v => handleOnChange(unit.unn, v)} unit={unit} />
            ))}
            <Grid container>
                <Button
                    variant={'primary'}
                    disabled={disabled}
                    onClick={() => {
                        const data_ = Array.from(data.entries()).map(([unn, value]) => {
                            return { unn, pennyweight: _.toNumber(value) };
                        });
                        onSubmit(data_);
                    }}
                >
                    Submit
                </Button>
            </Grid>
        </Grid>
    );
};

type SelectFormData = {
    itemId: string;
    pennyweights: EditUnitsFormData;
};

type SelectFormProps = {
    items: IOrderItemV2DTO[];
    onSubmit: (data: SelectFormData) => void;
};

/** Form for selecting an item to edit. */
const SelectForm: React.VFC<SelectFormProps> = ({ items, onSubmit }) => {
    const [item, setItem] = React.useState<IOrderItemV2DTO | null>(null);

    const units = item
        ? OrderItemV2Utils.getSingleToothUnits(item).filter(PennyweightUtils.isRelevantSingleToothUnit)
        : [];

    return (
        <>
            <SimpleSelect
                label={'Item'}
                value={item?.id}
                onChange={id => setItem(items.find(item => item.id === id) ?? null)}
                options={items.map(i => ({
                    label: CartItemV2Utils.getFullDisplayName(i),
                    value: i.id,
                }))}
            />
            {item && units.length > 0 && (
                <EditUnitsForm
                    key={item.id}
                    onSubmit={data => onSubmit({ itemId: item.id, pennyweights: data })}
                    units={units}
                />
            )}
        </>
    );
};

type ModalProps = SelectFormProps & {
    key: string;
    loading: boolean;
    open: boolean;
    setOpen: (open: boolean) => void;
};

/** Modal for editing pennyweights. */
const Modal: React.VFC<ModalProps> = ({ items, key, loading, onSubmit, open, setOpen }) => {
    return (
        <RootActionDialog
            buttonProps={{ style: { height: '100%' } }}
            buttonText={'Edit Pennyweights'}
            key={key}
            loading={loading}
            open={open}
            setOpen={setOpen}
            style={{ padding: 0 }}
            title={'Edit Precious Metal Pennyweights'}
            content={<SelectForm items={items} onSubmit={onSubmit} />}
        />
    );
};

type ModalWithVmProps = {
    items: IOrderItemV2DTO[];
    salesOrderId: string;
};

/** Modal with view model for editing pennyweights. */
export const EditPennyweightModal: React.VFC<ModalWithVmProps> = ({ items, salesOrderId }) => {
    const { enabled, filteredItems, open, setOpen, submit, submitting } = useViewModel(items);
    return enabled ? (
        <Modal
            items={filteredItems}
            key={salesOrderId}
            loading={submitting}
            open={open}
            setOpen={setOpen}
            onSubmit={data => {
                submit({ ...data, salesOrderId });
            }}
        />
    ) : null;
};

export const forStorybook = { Input, EditUnitGrid, EditUnitsForm, SelectForm, Modal };
