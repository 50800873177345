import { LoggedOutLayout } from './screens/logged-out/components/LoggedOutLayout';
import { useApolloClient } from '@apollo/client';
import { clearApolloCache, useGetOwnImpersonationTokenQuery } from '@orthly/graphql-react';
import { RetainerTokenStorage } from '@orthly/retainer-common';
import { stylesFactory, Grid, Typography } from '@orthly/ui-primitives';
import { PracticeOutageBanner, useFeatureFlag } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    headerWrapper: {
        position: 'fixed',
        zIndex: theme.zIndex.appBar,
    },
}));

export const PerhapsYouMeantToBeSomewhereElse: React.VFC = () => {
    const classes = useStyles();
    const { data: impersonateTokenData } = useGetOwnImpersonationTokenQuery({
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    const client = useApolloClient();
    const onLogoutClick = React.useCallback(() => {
        clearApolloCache(client).catch(console.error);
        RetainerTokenStorage.removeToken();
    }, [client]);
    const { value: maintenanceBannerPractice } = useFeatureFlag('maintenanceBannerPractice');

    return (
        <Grid container>
            <Grid container className={classes.headerWrapper}>
                <PracticeOutageBanner banner={maintenanceBannerPractice?.[0]} />
                <LoggedOutLayout
                    leftTitle={
                        <>
                            Meet Dandy.
                            <br />A smarter way to grow your practice.
                        </>
                    }
                    leftContent={
                        <Typography color={'inherit'} variant={'body1'} style={{ fontSize: '1.1rem' }}>
                            Digital dentistry designed to work for you.
                        </Typography>
                    }
                    rightColumnContent={
                        <div>
                            <h2>
                                Your login does not appear to be for a lab. Perhaps you meant to end up somewhere else?
                            </h2>
                            <ul>
                                <li>
                                    <a href={impersonateTokenData?.getOwnImpersonationToken.practiceUrl}>
                                        Go to the practice portal
                                    </a>
                                </li>
                                <li>
                                    <a href={'javascript::void()'} onClick={onLogoutClick}>
                                        Login as a different user
                                    </a>
                                </li>
                            </ul>
                        </div>
                    }
                />
            </Grid>
        </Grid>
    );
};
