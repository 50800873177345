import { useAddOrderItemsV2Mutation } from '@orthly/graphql-react';
import {
    type LabsGqlAddOrderItemsV2Command as AddOrderItemsCommand,
    OrderItemV2InputUtils,
} from '@orthly/graphql-schema';
import {
    CartItemV2UpdateUtils,
    CartItemV2Utils,
    type ExtraCartItemV2UnitType,
    ExtraCartItemV2Utils,
    LabOrderItemSKUType as SKUType,
    ManufacturerAddToOrderExtraOptions,
    OrderItemModelType as ModelType,
} from '@orthly/items';
import { useChangeSubmissionFn } from '@orthly/ui';

export const UNIT_TYPES = ManufacturerAddToOrderExtraOptions.map(o => o.unit_type);

export type SubmitArgs = {
    salesOrderId: string;
    unitType: ExtraCartItemV2UnitType;
};

/** Add an item to a sales order. */
export function submit(args: SubmitArgs, submitChange: (data: AddOrderItemsCommand) => Promise<unknown>) {
    const baseItem = ExtraCartItemV2Utils.itemFromUnitType(args.unitType);
    // If we're adding a model, use the dual full arch type.
    const item = CartItemV2Utils.itemIsType(baseItem, SKUType.Model)
        ? CartItemV2UpdateUtils.updateItem(baseItem, {
              name: 'model_type',
              payload: ModelType.DualFullArch,
          })
        : baseItem;
    const itemsBySku = OrderItemV2InputUtils.getOrderItemV2InputBySKU([item]);
    if (itemsBySku) {
        void submitChange({ items_v2_by_sku: itemsBySku, orderId: args.salesOrderId });
    }
}

export function useViewModel() {
    // TODO: [DECP-331] Use an API endpoint that calls into
    //     LabSalesOrderItemUpdateService.addSalesOrderItems.
    //     It doesn't exist yet.
    const [mutation] = useAddOrderItemsV2Mutation();
    const submitter = (data: AddOrderItemsCommand) => mutation({ variables: { data } });
    const {
        open,
        setOpen,
        submit: submitChange,
        submitting,
    } = useChangeSubmissionFn(submitter, {
        closeOnComplete: true,
        errorMessage: () => ['Error adding item to order.', {}],
        successMessage: () => ['Item added to order!', {}],
    });

    const submit_ = (args: SubmitArgs) => {
        submit(args, submitChange);
    };

    return { open, setOpen, submit: submit_, submitting };
}
