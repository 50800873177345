import type { CreateStaffStep } from './select-staff.progression';
import type { SelectStaffState, StaffProfileInfo } from './select-staff.reducer';
import { createSyncAction } from '@orthly/redux-async-actions';

const PREFIX = 'select-profile';

type ADD_STAFF_CREATION_FIELD_PAYLOAD = [
    Partial<SelectStaffState['profileCreationState']>,
    { autoNextStep?: boolean }?,
];
export const SelectStaffActions = {
    SELECT_PRACTICE_EMPLOYEE: createSyncAction<StaffProfileInfo, [StaffProfileInfo]>(
        `${PREFIX}/SELECT_PRACTICE_EMPLOYEE`,
        ({ __typename, id, name, roles, contact_email, staff_member_id }) => ({
            __typename,
            id,
            name,
            roles,
            contact_email,
            staff_member_id,
        }),
    ),
    SKIP_SELECT_STAFF: createSyncAction<undefined>(`${PREFIX}/SKIP_SELECT_STAFF`),
    CONTINUE_SELECT_STAFF: createSyncAction<undefined>(`${PREFIX}/CONTINUE_SELECT_STAFF`),
    LOGOUT_STAFF: createSyncAction<undefined>(`${PREFIX}/LOGOUT_STAFF`),
    SET_SELECT_STAFF_STEP: createSyncAction<CreateStaffStep>(`${PREFIX}/SET_SELECT_STAFF_STEP`),
    NEXT_SELECT_STAFF_STEP: createSyncAction<undefined>(`${PREFIX}/NEXT_SELECT_STAFF_STEP`),
    PREV_SELECT_STAFF_STEP: createSyncAction<undefined>(`${PREFIX}/PREV_SELECT_STAFF_STEP`),
    START_STAFF_CREATION: createSyncAction<undefined>(`${PREFIX}/START_STAFF_CREATION`),
    ADD_STAFF_CREATION_FIELD: createSyncAction<ADD_STAFF_CREATION_FIELD_PAYLOAD, ADD_STAFF_CREATION_FIELD_PAYLOAD>(
        `${PREFIX}/ADD_STAFF_CREATION_FIELD`,
        (draft, options) => [draft, options],
    ),
    CREATE_NEW_STAFF_WITHOUT_LOGOUT: createSyncAction<undefined>(`${PREFIX}/CREATE_NEW_STAFF_WITHOUT_LOGOUT`),
};
