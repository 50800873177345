import ModelViewerBackground from '../../../assets/images/ModelViewerBackground.svg';
import { DesignViewerLite } from '../DesignViewer/WaxupDesignViewerLite';
import { OrderAnalyticsContext } from '@orthly/analytics/dist/browser';
import type {
    DesignCaseParserOrder,
    MainViewCameraControlsRef,
    MainViewModelRef,
    ModelAppearance,
    TakeSnapshotRef,
} from '@orthly/dentin';
import {
    DesignComparisonMenu,
    MODEL_VIEWER_INITIAL_APPEARANCE,
    ModelAppearanceFilters,
    ModelVisibilityToggler,
    useExternalAppearanceFilters,
    useNewModelViewerStyles,
} from '@orthly/dentin';
import type { FragmentType, OrderDesignPreviewDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import { IconToggleButton, ModelTextureIcon } from '@orthly/ui';
import { useScreenIsMobileOrVerticalTablet, Popover, VisibilityIcon, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    modelViewerContainer: {
        display: 'flex',
        background: `url(${ModelViewerBackground}), linear-gradient(#fbfbfb, #ffffff)`,
        overflow: 'hidden',
        position: 'relative',
        minHeight: 300,
    },
}));

export type DesignViewerLiteWrapperProps = {
    order: DesignCaseParserOrder & { id: string };
    refetchDesign?: () => Promise<unknown> | void;
    designFragment?: FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc>;
    previousDesignFragment?: FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc>;
    takeSnapshotRef?: TakeSnapshotRef;
    style?: React.CSSProperties;
};

export const DesignViewerLiteWrapper: React.VFC<DesignViewerLiteWrapperProps> = ({
    order,
    refetchDesign,
    designFragment,
    previousDesignFragment,
    takeSnapshotRef,
    style,
}) => {
    const classes = useStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();

    const [appearance, setAppearance] = React.useState<ModelAppearance>(MODEL_VIEWER_INITIAL_APPEARANCE);
    const modelRef: MainViewModelRef = React.useRef(undefined);
    const controlRef: MainViewCameraControlsRef = React.useRef(null);

    const externalAppearanceApp = useExternalAppearanceFilters(setAppearance);

    // This may be a little weird but needed a way to pass a button
    // into the qc toolbar children to match the figma and this does
    // give some flexibilty to extend more tools into ModelViewer from
    // outside
    const mvStyles = useNewModelViewerStyles();
    const [visibilityPopoverAnchor, setVisibilityPopoverAnchor] = React.useState<HTMLElement | null>(null);

    const [desiredPreviousDesignFiles, setDesiredPreviousDesignFiles] = React.useState<string[]>([]);

    const hasPastRestoratives = !!appearance.pastRestoratives.CAD.length;

    const [maxMarginDistance, setMaxMarginDistance] = React.useState<number | undefined>(undefined);

    return (
        <div className={classes.modelViewerContainer} style={style}>
            <OrderAnalyticsContext.Provider value={{ orderId: order.id }}>
                <DesignViewerLite
                    selectedDesignFragment={designFragment}
                    order={order}
                    refetch={refetchDesign}
                    fullScreen={true}
                    isMobile={isMobile}
                    designQcConfig={{
                        appearance,
                        setAppearance,
                        modelRef,
                        controlRef,
                        takeSnapshotRef,
                    }}
                    externalTools={
                        <IconToggleButton
                            tooltip={'Toggle Color'}
                            onClick={externalAppearanceApp.toggleColor}
                            active={externalAppearanceApp.appearanceFilterValues.showColor}
                            className={mvStyles.qcToolbarButton}
                            key={'0_ColorButton'}
                        >
                            <ModelTextureIcon />
                        </IconToggleButton>
                    }
                    externalToolsLower={
                        isMobile && (
                            <IconToggleButton
                                tooltip={'Toggle layer visibility'}
                                active={false}
                                className={mvStyles.qcToolbarButton}
                                onClick={e => {
                                    setVisibilityPopoverAnchor(e.currentTarget);
                                }}
                            >
                                <VisibilityIcon />
                            </IconToggleButton>
                        )
                    }
                    previousDesignFragment={previousDesignFragment}
                    desiredPreviousDesignFiles={desiredPreviousDesignFiles}
                    setDesiredPreviousDesignFiles={setDesiredPreviousDesignFiles}
                    setMaxMarginDistance={setMaxMarginDistance}
                />
                {!isMobile && <ModelAppearanceFilters appearance={appearance} setAppearance={setAppearance} />}
                {hasPastRestoratives && (
                    <div style={{ position: 'absolute', right: 20, top: 20 }}>
                        <DesignComparisonMenu
                            appearance={appearance}
                            setAppearance={setAppearance}
                            maxMarginDistanceMm={maxMarginDistance}
                            displayLocation={'waxup_viewer'}
                            setDesiredPreviousDesignFiles={setDesiredPreviousDesignFiles}
                        />
                    </div>
                )}
                <Popover
                    open={!!visibilityPopoverAnchor}
                    anchorEl={visibilityPopoverAnchor}
                    onClose={() => {
                        setVisibilityPopoverAnchor(null);
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <ModelVisibilityToggler appearance={appearance} setAppearance={setAppearance} />
                </Popover>
            </OrderAnalyticsContext.Provider>
        </div>
    );
};
