import { useSelectOrders } from '../../../utils/CheckboxSelectUtils';
import { calculateShipDateDisplay } from '../../../utils/DisplayUtils';
import { LabPortalPrintLabSlipAction } from '../../LabPortalOrderActions/LabPortalPrintLabSlipAction';
import { LabPortalSendToFloorAction } from '../../LabPortalOrderActions/LabPortalSendToFloorAction';
import { useLabPortalOrderListViewContext } from '../../providers/LabPortalOrderListViewProvider';
import { useLabPortalOrderTabCountsContext } from '../../providers/LabPortalOrderTabCountsProvider';
import type { IndicatorFlagKey } from '../shared/IndicatorFlags';
import { IndicatorFlags } from '../shared/IndicatorFlags';
import type { ColumnDef } from '../shared/LabPortalOrderListView';
import {
    LabPortalOrderListView,
    LabPortalListViewNameColumn,
    LabPortalListViewBulkActionsHeader,
} from '../shared/LabPortalOrderListView';
import { useLabPortalDesignSendToFloorOrdersByListViewQuery } from '@orthly/graphql-react';
import { LoadBlocker } from '@orthly/ui';
import { Grid, CheckboxPrimitive as Checkbox } from '@orthly/ui-primitives';
import React from 'react';

export const LabPortalDesignSendToFloorListView: React.VFC = () => {
    const { filters, sort } = useLabPortalOrderListViewContext();

    const { refetch: refetchCounts } = useLabPortalOrderTabCountsContext();
    const { data, loading, refetch } = useLabPortalDesignSendToFloorOrdersByListViewQuery({
        fetchPolicy: 'no-cache',
        variables: { criteria: filters, labPortalSort: sort },
    });

    const { selectedOrderIds, selectedOrders, onSelectOrder, onSelectAll, resetSelected } = useSelectOrders(
        data?.orders ?? [],
    );

    const columns: ColumnDef[] = React.useMemo(
        () => [
            {
                label: (
                    <Checkbox
                        color={'secondary'}
                        checked={
                            data?.orders?.length !== undefined &&
                            data?.orders?.length > 0 &&
                            selectedOrderIds.length === data?.orders?.length
                        }
                        onChange={onSelectAll}
                    />
                ),
                cell: order => (
                    <Checkbox
                        color={'secondary'}
                        checked={selectedOrderIds.includes(order.id)}
                        onChange={onSelectOrder(order.id)}
                    />
                ),
            },
            {
                label: 'Name',
                cell: order => (
                    <LabPortalListViewNameColumn
                        orderId={order.id}
                        orderNumber={order.orderNumber}
                        patientName={order.patientName}
                    />
                ),
            },
            { label: 'Order Product Line', cell: order => order.orderProductLine },
            {
                label: 'Flags',
                cell: order =>
                    order.listFlags?.map((flag: IndicatorFlagKey) => <Grid key={flag}>{IndicatorFlags[flag]}</Grid>),
            },
            {
                label: 'Ship Date',
                cell: order => calculateShipDateDisplay(order.shipDate),
            },
            {
                label: (
                    <LabPortalListViewBulkActionsHeader
                        bulkActions={
                            <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                                <LabPortalPrintLabSlipAction
                                    onSingleRow={selectedOrders.length === 1}
                                    selectedOrders={selectedOrders}
                                    refetch={async () => {
                                        resetSelected();
                                        await refetch();
                                        await refetchCounts();
                                    }}
                                    disabled={selectedOrders.length === 0}
                                />
                                <LabPortalSendToFloorAction
                                    onSingleRow={selectedOrders.length === 1}
                                    selectedOrders={selectedOrders}
                                    refetch={async () => {
                                        resetSelected();
                                        await refetch();
                                        await refetchCounts();
                                    }}
                                    disabled={selectedOrders.length === 0}
                                />
                            </Grid>
                        }
                    />
                ),
                cell: order => (
                    <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                        <LabPortalPrintLabSlipAction
                            onSingleRow={true}
                            refetch={async () => {
                                resetSelected();
                                await refetch();
                                await refetchCounts();
                            }}
                            selectedOrders={[order]}
                            disabled={false}
                        />
                        <LabPortalSendToFloorAction
                            onSingleRow={true}
                            selectedOrders={[order]}
                            refetch={async () => {
                                resetSelected();
                                await refetch();
                                await refetchCounts();
                            }}
                            disabled={false}
                        />
                    </Grid>
                ),
            },
        ],
        [
            data?.orders?.length,
            onSelectAll,
            onSelectOrder,
            refetch,
            refetchCounts,
            resetSelected,
            selectedOrderIds,
            selectedOrders,
        ],
    );

    return (
        <LoadBlocker blocking={loading}>
            <LabPortalOrderListView orders={data?.orders ?? []} columns={columns} />
        </LoadBlocker>
    );
};
