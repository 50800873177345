import { AnalyticsClient } from '../../../analytics/analyticsClient';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { apolloErrorMessage } from '@orthly/ui';
import constate from 'constate';
import { useSnackbar } from 'notistack';
import React from 'react';

const BatchOrderShipmentsMutation = graphql(`
    mutation BatchOrderShipments($orderIds: [String!]!) {
        batchOrderShipments(orderIds: $orderIds) {
            label_url
            tracking_number
            service_level
            updated_order_count
        }
    }
`);

interface UseAddToBatchMutationArgs {
    // Id of the order loaded from search
    searchedOrderId: string;
    // refetch for the search query
    refetchSearchQuery: () => Promise<unknown>;
}

interface UseAddToBatchBatchMutationValue {
    // whether or not the mutation is executing
    loading: boolean;
    // the order ids currently being added to the batch
    currentlyLoadingIds: string[];
    // callback to trigger the mutation
    createBatch: (orderIds: string[]) => void;
}

function useAddToBatchMutationImpl({ searchedOrderId, refetchSearchQuery }: UseAddToBatchMutationArgs) {
    const { enqueueSnackbar } = useSnackbar();
    const [currentlyLoadingIds, setCurrentlyLoadingIds] = React.useState<string[]>([]);
    const [createBatchMtn, { loading }] = useMutation(BatchOrderShipmentsMutation, {
        fetchPolicy: 'no-cache',
        onError: error => {
            enqueueSnackbar(apolloErrorMessage(error), { variant: 'error' });
        },
        onCompleted: ({ batchOrderShipments: result }) => {
            const message = `Order added to batch, ${result.updated_order_count} order's label updated`;
            enqueueSnackbar(message, { variant: 'success' });
            AnalyticsClient.track('Lab - Portal - Order Added to Batch', {
                $groups: { order: searchedOrderId },
                trackingNumber: result.tracking_number,
            });
            void refetchSearchQuery();
        },
    });
    const createBatch = (orderIds: string[]) => {
        if (loading) {
            enqueueSnackbar('Developer error: cannot add to batch while other loading');
        }
        setCurrentlyLoadingIds(orderIds);
        void createBatchMtn({ variables: { orderIds } }).finally(() => {
            setCurrentlyLoadingIds([]);
        });
    };

    return { loading, createBatch, currentlyLoadingIds };
}

export const [AddToBatchMutationProvider, useAddToBatchMutation] = constate<
    UseAddToBatchMutationArgs,
    UseAddToBatchBatchMutationValue,
    []
>(useAddToBatchMutationImpl);
