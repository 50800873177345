import { SimpleDialog, WarningIcon } from '@orthly/ui';
import { Button, FlossPalette, stylesFactory, Text, Grid } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';

const UPDATE_PRICING_BANNER_HEIGHT = 40;

const useStyles = stylesFactory(() => ({
    banner: {
        backgroundColor: FlossPalette.WARNING_BACKGROUND,
        height: UPDATE_PRICING_BANNER_HEIGHT,
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconStyles: {
        width: 24,
        height: 24,
        marginRight: 8,
    },
    button: {
        marginLeft: 16,
        height: 32,
    },
    bannerText: {
        maxWidth: 596,
        overflow: 'hidden',
        textTruncate: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}));

export const UpdateMetalPricingBanner: React.VFC = () => {
    // show this banner if there are `metalPricingNotificationDays` days until the next month begins.
    const { value: metalPricingNotificationDays } = useFeatureFlag('metalPricingNotificationDays');
    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = React.useState(false);

    if (!metalPricingNotificationDays) {
        return null;
    }

    // number of days left in the current month
    const daysLeft = moment().endOf('month').diff(moment(), 'days');
    const nextMonth = moment().add(1, 'month').format('MMMM');

    if (daysLeft > metalPricingNotificationDays) {
        return null;
    }

    const toggleOpen = () => setOpen(!open);
    const navigateToPage = () => {
        history.push('/metal-pricing');
        setOpen(false);
    };

    return (
        <>
            <Grid className={classes.banner} container>
                <WarningIcon className={classes.iconStyles} />
                <Text medium variant={'body2'} className={classes.bannerText}>
                    You have {daysLeft} days to lock in next month's metal pricing.
                </Text>
                <Button variant={'secondary'} className={classes.button} onClick={toggleOpen}>
                    Learn More
                </Button>
            </Grid>
            <SimpleDialog
                open={open}
                onClose={toggleOpen}
                title={'Update Metal Pricing'}
                actions={
                    <div>
                        <Button variant={'primary'} onClick={navigateToPage} style={{ marginRight: 8 }}>
                            Update
                        </Button>
                        <Button variant={'secondary'} onClick={toggleOpen} style={{ marginRight: 8 }}>
                            Close
                        </Button>
                    </div>
                }
            >
                You have {daysLeft} days to finalize next month's metal pricing, which goes into effect {nextMonth} 1st.
                If you have no changes to next month's prices, you can ignore this message.
            </SimpleDialog>
        </>
    );
};
