import type { RequireChildrenProps } from '../../util/floss.types';
import { stylesFactory } from '../../util/stylesFactory';
import { Grid } from '@orthly/ui-primitives';
import cx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        maxHeight: '100vh',
        flexWrap: 'nowrap',
        position: 'relative',
        width: '100%',
        justifyContent: 'space-between',
    },
}));

interface PageContainerProps extends RequireChildrenProps {
    className?: string;
}

/**
 * Page container, wrapper around sidebars and content
 */
export const PageContainer: React.FC<PageContainerProps> = props => {
    const classes = useStyles();
    return (
        <Grid container className={cx(classes.root, props.className)}>
            {props.children}
        </Grid>
    );
};
