import { useRefArray } from '../../../hooks';
import { stylesFactory } from '../../../util';
import type { ActionCardVariant } from './ActionCard.types';
import type { ActionCardButtonPropsBase } from './ActionCardButtons';
import { ActionCardButtons } from './ActionCardButtons';
import { ActionCardIcon } from './ActionCardIcon';
import { ActionCardSubtitle, ActionCardTitle } from './ActionCardTitle';
import type { SvgIconProps } from '@orthly/ui-primitives';
import { FlossPalette, FlossPaletteUtils, Icon, Grid } from '@orthly/ui-primitives';
import cx from 'classnames';
import _ from 'lodash';
import React from 'react';

const defaultMarginBottom = 16;

const useStyles = stylesFactory(theme => ({
    root: {
        borderRadius: 16,
        backgroundColor: FlossPalette.TAN,
        alignItems: 'center',
        minHeight: 96,
        padding: 24,
        marginBottom: defaultMarginBottom,
        flexWrap: 'nowrap',
        [theme.breakpoints.down('lg')]: {
            flexWrap: 'wrap',
            paddingBottom: 12,
        },
        whiteSpace: 'pre-wrap',
        border: `1px solid ${FlossPaletteUtils.toRgba('BLACK', 0.08)}`,
    },
    childRoot: {
        [theme.breakpoints.down('md')]: {
            flexDirection: 'row',
            paddingTop: 0,
            paddingBottom: 24,
        },
    },
    parentRoot: {
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse',
            paddingTop: 0,
        },
    },
    innerContainer: {
        flexWrap: 'nowrap',
        [theme.breakpoints.down('md')]: { flexWrap: 'wrap' },
    },
    text: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    rootAlert: {
        backgroundColor: FlossPalette.ATTENTION_BG,
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    },
    blackAlert: { backgroundColor: FlossPalette.BLACK, minHeight: 80, border: 'none' },
    greenAlert: { backgroundColor: FlossPalette.PRIMARY_BACKGROUND, border: 'none' },
    parentAlert: {
        cursor: 'pointer',
    },
    separateRowActionsAlert: {
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
        border: 'none',
        flexWrap: 'wrap',
        gap: 16,
        paddingBottom: 12,
    },
}));

export interface ActionCardProps {
    title: string | React.ReactNode;
    subtitle: string | React.ReactNode;
    primaryAction?: ActionCardButtonPropsBase | (React.ReactElement & Partial<ActionCardButtonPropsBase>);
    secondaryAction?: ActionCardButtonPropsBase | (React.ReactElement & Partial<ActionCardButtonPropsBase>);
    otherActions?: React.ReactNode;
    variant: ActionCardVariant;
    IconComponent?: React.ComponentType<SvgIconProps>;
    style?: React.CSSProperties;
    onClick?: React.MouseEventHandler;
    avatarStyle?: React.CSSProperties;
    parentCard?: boolean;
    buttonsBelowContent?: boolean;
}

export const ActionCard = React.forwardRef<HTMLDivElement, ActionCardProps>((props, ref) => {
    const classes = useStyles(props);
    const {
        variant,
        title,
        subtitle,
        secondaryAction,
        primaryAction,
        otherActions,
        style,
        avatarStyle,
        parentCard,
        IconComponent,
        onClick,
        buttonsBelowContent,
    } = props;
    return (
        <Grid
            container
            className={cx(
                classes.root,
                parentCard ? classes.parentRoot : classes.childRoot,
                variant === 'alert' && classes.rootAlert,
                variant === 'black' && classes.blackAlert,
                variant === 'info' && classes.greenAlert,
                buttonsBelowContent && classes.separateRowActionsAlert,
            )}
            alignItems={'center'}
            style={style}
            ref={ref}
            onClick={e => onClick?.(e)}
        >
            <Grid container item alignItems={'center'} className={classes.innerContainer}>
                <Grid item style={{ width: 'auto' }}>
                    <ActionCardIcon
                        parentCard={parentCard}
                        variant={variant}
                        IconComponent={IconComponent}
                        style={avatarStyle}
                    />
                </Grid>
                <Grid className={classes.text}>
                    <ActionCardTitle>{title}</ActionCardTitle>
                    <ActionCardSubtitle variant={variant}>{subtitle}</ActionCardSubtitle>
                </Grid>
            </Grid>
            {(primaryAction || secondaryAction) && (
                <ActionCardButtons
                    primary={primaryAction}
                    secondary={secondaryAction}
                    buttonsBelowContent={buttonsBelowContent}
                />
            )}
            {otherActions}
        </Grid>
    );
});

interface MultiActionCardProps {
    parentActionCard: ActionCardProps;
    childActionCards: ActionCardProps[];
}

export const MultiActionCard: React.FC<MultiActionCardProps> = props => {
    const { parentActionCard, childActionCards } = props;
    const maxMinimizedCards = 2;
    const [open, setOpen] = React.useState(false);
    const [childActionRefs, setChildActionRefs] = useRefArray<HTMLDivElement>();
    const [childActionHeights, setChildActionHeights] = React.useState<number[]>([]);

    const calculateChildActionHeights = React.useCallback(() => {
        if (_.compact(childActionRefs).length !== childActionCards.length) {
            return;
        }
        window.requestAnimationFrame(() => {
            setChildActionHeights(childActionRefs.map(child => (child ? child.offsetHeight : 0)));
        });
    }, [childActionRefs, childActionCards]);

    const toggleOpen = () => {
        setOpen(!open);
        // sometimes the heights change based off whether or not they're open
        // since the width also changes, possibly forcing layout shift
        // in this case the animation is a bit weird but its much better than
        // ending with a poor layout
        setTimeout(calculateChildActionHeights, 300);
    };

    React.useEffect(() => {
        calculateChildActionHeights();
    }, [calculateChildActionHeights]);

    React.useEffect(() => {
        const cb = () => {
            calculateChildActionHeights();
            // sometimes resize triggers rerenders on other components
            // so we set an arbitrary timeout and recalculate again
            setTimeout(calculateChildActionHeights, 300);
        };
        window.addEventListener('resize', cb);
        return () => {
            window.removeEventListener('resize', cb);
        };
    }, [calculateChildActionHeights]);

    if (childActionCards.length === 0) {
        return null;
    }

    if (childActionCards.length === 1 && childActionCards[0]) {
        return <ActionCard {...childActionCards[0]} />;
    }

    return (
        <Grid container style={{ overflow: 'hidden' }}>
            <ActionCard
                {...parentActionCard}
                parentCard
                primaryAction={
                    parentActionCard.primaryAction ?? open ? (
                        <Icon icon={'ArrowUpIcon'} fontSize={'large'} />
                    ) : (
                        <Icon icon={'ArrowDownIcon'} fontSize={'large'} />
                    )
                }
                style={{ ...parentActionCard.style, cursor: 'pointer', zIndex: childActionCards.length + 1 }}
                onClick={e => {
                    toggleOpen();
                    parentActionCard.onClick?.(e);
                }}
            />
            <Grid
                style={{
                    width: '100%',
                    transitionDuration: '0.3s',
                    // childActionHeights only includes the size of the element, not the surrounding margin
                    // when open, we need to allow enough height to show the cards and the margin between them
                    // when closed we need enough height to show all of the partial cards
                    height: open
                        ? _.sum(childActionHeights) + defaultMarginBottom * childActionHeights.length
                        : Math.min(maxMinimizedCards, childActionHeights.length) * defaultMarginBottom,
                }}
            >
                {childActionCards.map((child, idx) => {
                    const num = idx + 1;
                    // when closed, we want each child to be less wide than the parent,
                    // and also the child above it until we hit the max
                    // in which case everything after the max assumes the same position
                    const xPadding = Math.min(maxMinimizedCards, num) * 20;
                    // when closed, we need to move the card up to be right under the parent
                    // so we calculate the height of all the cards that come before it
                    // if it's after the max we move it up some more
                    // so that it assumes the same position
                    const topOffset =
                        _.sum(childActionHeights.slice(0, num)) +
                        Math.max(0, num - maxMinimizedCards) * defaultMarginBottom;

                    return (
                        <ActionCard
                            {...child}
                            key={idx}
                            ref={ref => setChildActionRefs(idx, ref)}
                            style={{
                                ...child.style,
                                transitionDuration: '0.3s',
                                width: open ? '100%' : `calc(100% - ${xPadding * 2}px)`,
                                position: 'relative',
                                top: open ? 0 : -topOffset,
                                left: open ? 0 : xPadding,
                                zIndex: childActionCards.length - num,
                            }}
                        />
                    );
                })}
            </Grid>
        </Grid>
    );
};
