import { styled } from '@orthly/ui-primitives';
import React from 'react';
import QRCode from 'react-qr-code';

const QRCodeWrapper = styled('div')({
    position: 'relative',
    height: '100%',
    // If width is not slightly greater than height, the right edge of the QR code is cut off when the QR code
    // is at the right margin of the label.
    aspectRatio: '1.02',
    '&.pan-label-labtrac-qr-code': {
        gridArea: 'labtrac',
    },
    '&.pan-label-lab-portal-qr-code': {
        gridArea: 'labportal',
        justifySelf: 'right',
    },
});

interface PanLabelQRCodeProps {
    value: string;
    variant: 'labtrac' | 'lab-portal';
}

export const PanLabelQRCode: React.VFC<PanLabelQRCodeProps> = ({ value, variant }) => {
    return (
        <QRCodeWrapper className={`pan-label-${variant}-qr-code`}>
            <QRCode
                value={value}
                style={{ width: 'auto', maxHeight: '100%', height: '100%' }}
                viewBox={'0 0 256 256'}
            />
        </QRCodeWrapper>
    );
};
