import { LabInboxBulkActionIconButton } from '../../../../components/bulk-actions/LabInboxBulkActionIconButton';
import type { LabPortalOrderActionProps, BatchResult } from './LabPortalOrderActions.types';
import { useCreateManualLabOrderIntegrationAttemptMutation } from '@orthly/graphql-react';
import { Format } from '@orthly/runtime-utils';
import { useChangeSubmissionFn, makeLazyDialog, RootActionDialog } from '@orthly/ui';
import { FlossPalette, Button, CheckIcon } from '@orthly/ui-primitives';
import { useSnackbar } from 'notistack';
import React from 'react';

function useBulkMarkOrderManuallyEnteredAction(refetchOrders: () => Promise<any>) {
    const { enqueueSnackbar } = useSnackbar();
    const [submitMtn] = useCreateManualLabOrderIntegrationAttemptMutation();
    const runUpload = React.useCallback(
        async (orderIds: string[]): Promise<BatchResult> => {
            const result: BatchResult = { errorIds: [], successIds: [], skippedIds: [] };
            const { data, errors } = await submitMtn({ variables: { order_ids: orderIds } });
            const successfulOrderIds = data?.createManualLabOrderIntegrationAttempt
                ?.filter(resp => resp.success === true)
                .map(resp => resp.orderId);
            const skippedOrderIds = data?.createManualLabOrderIntegrationAttempt
                ?.filter(resp => resp.success === false)
                .map(resp => resp.orderId);
            if (errors) {
                result.errorIds.push(...orderIds);
            } else {
                result.successIds.push(...(successfulOrderIds ?? orderIds));
                result.skippedIds.push(
                    ...(skippedOrderIds ? orderIds.filter(id => !successfulOrderIds?.includes(id)) : []),
                );
            }
            return result;
        },
        [submitMtn],
    );
    return useChangeSubmissionFn<BatchResult, [string[]]>(runUpload, {
        onSuccess: async result => {
            const errorCount = result.errorIds.length;
            const successCount = result.successIds.length;
            const skippedCount = result.skippedIds.length;
            const totalCount = errorCount + successCount + skippedCount;
            if (errorCount > 0) {
                const countLabel = totalCount > 1 ? `${errorCount} of ${totalCount} orders` : `order`;
                enqueueSnackbar(`Failed to mark ${countLabel} manually entered, please retry`, {
                    variant: 'error',
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                });
            } else {
                const skippedMessage =
                    skippedCount > 0 ? ` Skipped ${Format.pluralize('invalid order', skippedCount)}` : '';
                enqueueSnackbar(
                    `Marked ${Format.pluralize('order', successCount)} as manually entered!${skippedMessage}`,
                    {
                        variant: 'success',
                        anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    },
                );
            }
            await refetchOrders();
        },
    });
}

const useBulkMarkOrderEnteredDialog = makeLazyDialog<{ submit: () => Promise<void>; orderLabel: string }>(props => {
    const { submit, open, setOpen, orderLabel } = props;

    return (
        <RootActionDialog
            title={`Are you sure you want to mark ${orderLabel} as manually entered?`}
            open={open}
            setOpen={setOpen}
            loading={false}
            buttonText={``}
            content={
                <Button
                    variant={'primary'}
                    fullWidth={true}
                    onClick={async () => {
                        await submit();
                        setOpen(false);
                    }}
                >
                    Accept Orders
                </Button>
            }
        />
    );
});

export const LabPortalMarkManuallyEnteredAction: React.VFC<LabPortalOrderActionProps> = props => {
    const { selectedOrders, refetch, disabled } = props;
    const { submit, submitting } = useBulkMarkOrderManuallyEnteredAction(refetch);

    const submitOrders = React.useCallback(async () => {
        await submit(selectedOrders.map(o => o.id));
    }, [selectedOrders, submit]);

    const itemsLabel = props.onSingleRow ? '1 order' : Format.pluralize('order', selectedOrders.length);

    const [setBulkMarkOrderEnteredDialog, bulkMarkOrderEnteredDialog] = useBulkMarkOrderEnteredDialog({
        submit: submitOrders,
        orderLabel: itemsLabel,
    });
    return (
        <>
            <LabInboxBulkActionIconButton
                onClick={() => setBulkMarkOrderEnteredDialog(true)}
                loading={submitting}
                disabled={disabled ?? false}
                disableTooltip={disabled ?? false}
                selectionCount={selectedOrders.length}
                tooltipTitle={`Mark ${itemsLabel} as manually entered into lab tracking system`}
            >
                <CheckIcon
                    style={{ color: selectedOrders.length === 0 || submitting ? undefined : FlossPalette.STAR_GRASS }}
                />
            </LabInboxBulkActionIconButton>
            {bulkMarkOrderEnteredDialog}
        </>
    );
};
