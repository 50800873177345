import React from 'react';

// a simple utility for when you need a dynamic number of refs
// allows you to maintain an array of refs and set a value at a given index
export function useRefArray<T>(
    initialValues: (T | null)[] = [],
): [(T | null)[], (index: number, newRef: T | null) => void] {
    const ref = React.useRef<(T | null)[]>(initialValues);

    const setRef = (index: number, newRef: T | null) => {
        if (newRef !== ref.current[index]) {
            ref.current[index] = newRef;
        }
    };

    return [ref.current, setRef];
}
