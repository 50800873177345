import { LabsGqlLabPortalLabOrderSortKey } from '@orthly/graphql-schema';
import { SimpleSelect } from '@orthly/ui';
import { Button, Grid, Popover } from '@orthly/ui-primitives';
import React from 'react';

export type SortOption = { label: string; value: LabsGqlLabPortalLabOrderSortKey };

export const DefaultSortOptions: SortOption[] = [
    { label: 'Order Placed On', value: LabsGqlLabPortalLabOrderSortKey.CreatedAt },
    { label: 'Last Order Update', value: LabsGqlLabPortalLabOrderSortKey.UpdatedAt },
];

export const LabPortalOrderListViewSortButton: React.VFC<{
    sortKey: LabsGqlLabPortalLabOrderSortKey | undefined;
    setSortKey: (newSortField: string | undefined) => void;
    customSortOptions?: SortOption[];
}> = ({ sortKey, setSortKey, customSortOptions }) => {
    const [sortOpen, setSortOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement | null>(null);
    return (
        <Grid>
            <Button ref={anchorRef} variant={'primary'} onClick={() => setSortOpen(sortOpen => !sortOpen)}>
                Sort
            </Button>
            <Popover
                anchorEl={anchorRef.current}
                open={sortOpen}
                onClose={() => setSortOpen(false)}
                PaperProps={{
                    style: { width: '400px' },
                }}
            >
                <SimpleSelect
                    options={[...DefaultSortOptions, ...(customSortOptions ?? [])]}
                    onChange={selected => setSortKey(selected)}
                    value={sortKey}
                    label={'Sort by'}
                    SelectProps={{ open: sortOpen, onClose: () => setSortOpen(false), variant: 'standard' }}
                />
            </Popover>
        </Grid>
    );
};
