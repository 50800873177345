import { SORT_ORDER } from './Table.types';
// eslint-disable-next-line no-restricted-imports
import { useState, useMemo, useEffect } from 'react';

export type ColumnLabel = { label: string; numeric?: boolean; alignRight?: boolean; id: string; sortable?: boolean };

export type UpdateSort = {
    sortOrder: SORT_ORDER;
    orderByParam: string;
    pageSize: number;
    pageNumber: number;
    columnMap: Record<string, ColumnLabel>;
};

export const getColumnMap = (columns: ColumnLabel[]) => {
    return columns.reduce(
        (acc, column) => {
            acc[column.id] = column;
            return acc;
        },
        {} as Record<string, ColumnLabel>,
    );
};

/**
 * Sorts and paginates the unsortedRows
 */
export function sortAndPaginate<Type>(
    rowData: Type[],
    { sortOrder, orderByParam, pageSize, pageNumber, columnMap }: UpdateSort,
): Type[] {
    const sortBy = orderByParam;
    const column = columnMap[sortBy] as ColumnLabel;

    const sortedData = [...rowData].sort((a, b) => {
        const aVal = a[sortBy as keyof typeof a];
        const bVal = b[sortBy as keyof typeof b];
        if (aVal === undefined || bVal === undefined) {
            return 0;
        }
        if (column.numeric) {
            return sortOrder === 'asc' ? (aVal as number) - (bVal as number) : (bVal as number) - (aVal as number);
        }
        return sortOrder === 'asc'
            ? (aVal as string).localeCompare(bVal as string)
            : (bVal as string).localeCompare(aVal as string);
    });
    return sortedData.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize) as Type[];
}

/**
 * Contains all the logic for sorting and pagination
 */
export function useSortedRows<Type>({
    unsortedRows,
    columns,
    defaultSortColumn,
    defaultSortDirection,
    defaultPageSize = 5,
}: {
    unsortedRows: Type[];
    columns: ColumnLabel[];
    defaultSortColumn?: string;
    defaultSortDirection?: SORT_ORDER;
    defaultPageSize?: number;
}) {
    const defaultSortBy = defaultSortColumn || columns[0]?.id || '';
    const columnMap = useMemo(() => getColumnMap(columns), [columns]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize);
    const [sortOrder, setSortOrder] = useState(defaultSortDirection || SORT_ORDER.asc);
    const [orderBy, setOrderBy] = useState(defaultSortBy);
    const [sortedRows, setSortedRows] = useState<Type[]>(() =>
        sortAndPaginate(unsortedRows, {
            sortOrder: sortOrder,
            orderByParam: orderBy,
            pageSize: rowsPerPage,
            pageNumber: page,
            columnMap,
        }),
    );

    useEffect(() => {
        setSortedRows(
            sortAndPaginate(unsortedRows, {
                sortOrder: sortOrder,
                orderByParam: orderBy,
                pageSize: rowsPerPage,
                pageNumber: page,
                columnMap,
            }),
        );
        // We only want this to change when the row length changes.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unsortedRows.length]);

    const handleChangePage = (_event: unknown, newPage: number) => {
        const newRows = sortAndPaginate(unsortedRows, {
            sortOrder: sortOrder,
            orderByParam: orderBy,
            pageSize: rowsPerPage,
            pageNumber: newPage,
            columnMap,
        });
        setPage(newPage);
        setSortedRows(newRows);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        const newRows = sortAndPaginate(unsortedRows, {
            sortOrder: sortOrder,
            orderByParam: orderBy,
            pageSize: newRowsPerPage,
            pageNumber: 0,
            columnMap,
        });
        setRowsPerPage(newRowsPerPage);
        setPage(0);
        setSortedRows(newRows);
    };
    const handleSort = (_event: React.MouseEvent<unknown>, property: string) => {
        const isAsc = orderBy === property && sortOrder === SORT_ORDER.asc;
        const newSortOrder = isAsc ? SORT_ORDER.desc : SORT_ORDER.asc;
        const newRows = sortAndPaginate(unsortedRows, {
            sortOrder: newSortOrder,
            orderByParam: property,
            pageSize: rowsPerPage,
            pageNumber: page,
            columnMap,
        });
        setSortOrder(newSortOrder);
        setOrderBy(property);
        setSortedRows(newRows);
    };
    return {
        sortableHeadersProps: {
            handleSort,
            sortOrder,
            orderBy,
            columns,
        },
        paginationProps: {
            rowsPerPageOptions: [5, 10, 25],
            count: unsortedRows.length,
            rowsPerPage,
            page,
            onPageChange: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
        },
        handleChangePage,
        handleChangeRowsPerPage,
        handleSort,
        sortOrder,
        orderBy,
        rowsPerPage,
        page,
        sortedRows,
    };
}
