import { useManufacturerOptions } from '../../state/useManufacturerOptions';
import { useLabPortalOverviewQuery } from '@orthly/graphql-react';
import { LoadBlocker } from '@orthly/ui';
import type { GridSize } from '@orthly/ui-primitives';
import { FlossPalette, stylesFactory, Grid, Text } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = stylesFactory(() => ({
    overviewSection: {
        margin: '32px 0 0',
    },
    countBox: {
        height: '128px',
        padding: '24px',
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        borderRadius: '16px',
        display: 'flex',
        color: FlossPalette.GRAY,
        justifyContent: 'space-between',
        textDecoration: 'none',
        '&:hover': {
            backgroundColor: FlossPalette.TAN,
        },
    },
    pageHeader: {
        borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        height: '64px',
        lineHeight: '64px',
        fontSize: '40px',
        verticalAlign: 'middle',
        paddingLeft: '20px',
        paddingTop: '12px',
    },
}));

const OverviewCell: React.VFC<{
    link?: string;
    label: string;
    count: number;
    backgroundColor?: string;
}> = props => {
    const { link, label, count, backgroundColor } = props;
    const classes = useStyles();
    const history = useHistory();
    const hasLink = link !== undefined;
    return (
        <Grid
            container
            direction={'column'}
            className={classes.countBox}
            style={{ backgroundColor: backgroundColor, cursor: link !== undefined ? 'pointer' : 'default' }}
            onClick={link !== undefined ? () => history.push(link) : () => {}}
        >
            <Text variant={'body2'}>{label}</Text>
            <Text variant={'h6'}>
                <span style={{ color: hasLink ? 'inherit' : FlossPalette.GRAY }}>{count}</span>
                <span style={{ float: 'right', display: hasLink ? 'default' : 'none' }}>›</span>
            </Text>
        </Grid>
    );
};

// eslint-disable-next-line max-lines-per-function
export const LabPortalOverviewPage: React.VFC<{ enabledPages: Set<string> }> = props => {
    const { enabledPages } = props;
    const classes = useStyles();
    const options = useManufacturerOptions();
    const { loading, data } = useLabPortalOverviewQuery({
        variables: {
            pages: [...enabledPages],
        },
    });
    const overviewDisplayConfig = React.useMemo(() => {
        const requestDateString = data?.labPortalOverview.requestDate ?? new Date().toUTCString();
        const requestDate = new Date(requestDateString).toLocaleDateString('en-us', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
        return {
            showDesignLinks: options.lab_does_design && enabledPages.has('Design_V2'),
            showUpdateLinks: enabledPages.has('Updates_V2'),
            showNewLinks: enabledPages.has('New_V2'),
            showIntakeLinks: enabledPages.has('Intake'),
            showShippingLinks: enabledPages.has('Expedite_V2'),
            showReturnsLinks: enabledPages.has('Returns_V2'),
            showOnHoldLinks: enabledPages.has('OnHold_V2'),
            showDesign: options.lab_does_design && (enabledPages.has('Design_V2') || enabledPages.has('Design')),
            showNeedsManualEntry: options.has_write_integration,
            newWidth: (enabledPages.has('Intake') ? 3 : 6) as GridSize,
            intakeWidth: (enabledPages.has('New_V2') ? 3 : 6) as GridSize,
            fabricationWidth: (options.has_write_integration ? 2 : 3) as GridSize,
            showReturns: enabledPages.has('Returns_V2') || enabledPages.has('Returns'),
            manufacturerName: data?.labPortalOverview.manufacturerName ?? '',
            requestDate: requestDate,
        };
    }, [enabledPages, options, data]);
    return (
        <>
            <Grid container className={classes.pageHeader}>
                <Text variant={'h4'}>Overview</Text>
            </Grid>
            <LoadBlocker blocking={loading}>
                <Grid container style={{ padding: '20px 20px 50px' }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Text variant={'h4'}>{overviewDisplayConfig.manufacturerName}</Text>
                        </Grid>
                        <Grid item xs={12}>
                            {overviewDisplayConfig.requestDate} • Welcome back!
                        </Grid>
                    </Grid>

                    <Grid container className={classes.overviewSection} direction={'row'}>
                        <Grid item xs={12}>
                            <Text variant={'h5'} style={{ marginBottom: '10px' }}>
                                Orders (#)
                            </Text>
                        </Grid>
                        <Grid container direction={'row'} spacing={3}>
                            {overviewDisplayConfig.showNewLinks && (
                                <Grid item xs={overviewDisplayConfig.newWidth}>
                                    <OverviewCell
                                        label={'New Orders'}
                                        count={data?.labPortalOverview.ordersNewOrders ?? 0}
                                        link={overviewDisplayConfig.showNewLinks ? '/new/with-design' : undefined}
                                        backgroundColor={FlossPalette.PRIMARY_BACKGROUND}
                                    />
                                </Grid>
                            )}
                            {overviewDisplayConfig.showIntakeLinks && (
                                <Grid item xs={overviewDisplayConfig.intakeWidth}>
                                    <OverviewCell
                                        label={'Orders Needing Intake'}
                                        count={data?.labPortalOverview.ordersIntakeOrders ?? 0}
                                        link={overviewDisplayConfig.showIntakeLinks ? '/intake/with-design' : undefined}
                                        backgroundColor={FlossPalette.PRIMARY_BACKGROUND}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={overviewDisplayConfig.fabricationWidth}>
                                <OverviewCell
                                    label={'In fabrication'}
                                    count={data?.labPortalOverview.ordersInFabrication ?? 0}
                                />
                            </Grid>
                            {overviewDisplayConfig.showNeedsManualEntry && (
                                <Grid item xs={2}>
                                    <OverviewCell
                                        label={'Needs Manual Entry'}
                                        count={data?.labPortalOverview.ordersNeedsManualEntry ?? 0}
                                        link={
                                            overviewDisplayConfig.showNewLinks ? '/new/needs-manual-entry' : undefined
                                        }
                                    />
                                </Grid>
                            )}
                            <Grid item xs={overviewDisplayConfig.fabricationWidth}>
                                <OverviewCell
                                    label={'On Hold'}
                                    count={data?.labPortalOverview.ordersOnHold ?? 0}
                                    link={overviewDisplayConfig.showOnHoldLinks ? '/on-hold' : undefined}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {overviewDisplayConfig.showDesign && (
                        <Grid container className={classes.overviewSection} direction={'row'}>
                            <Grid item xs={12}>
                                <Text variant={'h5'} style={{ marginBottom: '10px' }}>
                                    Design (#)
                                </Text>
                            </Grid>
                            <Grid container direction={'row'} spacing={3}>
                                <Grid item xs={3}>
                                    <OverviewCell
                                        label={'In Design'}
                                        count={data?.labPortalOverview.designInDesign ?? 0}
                                        link={overviewDisplayConfig.showDesignLinks ? '/design' : undefined}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <OverviewCell
                                        label={'Wait for Dandy'}
                                        count={data?.labPortalOverview.designWaitForDandy ?? 0}
                                        link={
                                            overviewDisplayConfig.showDesignLinks ? '/design/wait-for-dandy' : undefined
                                        }
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <OverviewCell
                                        label={'Revise'}
                                        count={data?.labPortalOverview.designRevise ?? 0}
                                        link={overviewDisplayConfig.showDesignLinks ? '/design/revise' : undefined}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <OverviewCell
                                        label={'Send to floor'}
                                        count={data?.labPortalOverview.designSendToFloor ?? 0}
                                        link={
                                            overviewDisplayConfig.showDesignLinks ? '/design/send-to-floor' : undefined
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    <Grid container className={classes.overviewSection} direction={'row'}>
                        <Grid item xs={12}>
                            <Text variant={'h5'} style={{ marginBottom: '10px' }}>
                                Shipping (#)
                            </Text>
                        </Grid>
                        <Grid container direction={'row'} spacing={3}>
                            <Grid item xs={4}>
                                <OverviewCell
                                    label={'Orders shipping today'}
                                    count={data?.labPortalOverview.shippingToday ?? 0}
                                    link={overviewDisplayConfig.showShippingLinks ? '/ship-today' : undefined}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <OverviewCell
                                    label={'Late orders'}
                                    count={data?.labPortalOverview.shippingLate ?? 0}
                                    link={overviewDisplayConfig.showShippingLinks ? '/ship-today/late' : undefined}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <OverviewCell
                                    label={'Shipped week to date'}
                                    count={data?.labPortalOverview.shippingWeekToDate ?? 0}
                                    link={overviewDisplayConfig.showShippingLinks ? '/ship-today/overnight' : undefined}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {overviewDisplayConfig.showReturns && (
                        <Grid container className={classes.overviewSection} direction={'row'}>
                            <Grid item xs={12}>
                                <Text variant={'h5'} style={{ marginBottom: '10px' }}>
                                    Returns (#)
                                </Text>
                            </Grid>
                            <Grid container direction={'row'} spacing={3}>
                                <Grid item xs={3}>
                                    <OverviewCell
                                        label={'Returns'}
                                        count={data?.labPortalOverview.returns ?? 0}
                                        link={overviewDisplayConfig.showReturnsLinks ? '/returns/pending' : undefined}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    <Grid container className={classes.overviewSection} direction={'row'}>
                        <Grid item xs={12}>
                            <Text variant={'h5'} style={{ marginBottom: '10px' }}>
                                Updates (#)
                            </Text>
                        </Grid>
                        <Grid container direction={'row'} spacing={3}>
                            <Grid item xs={3}>
                                <OverviewCell
                                    label={'Pull from floor'}
                                    count={data?.labPortalOverview.updatesPullFromFloor ?? 0}
                                    link={
                                        overviewDisplayConfig.showUpdateLinks ? '/updates/pull-from-floor' : undefined
                                    }
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <OverviewCell
                                    label={'Updates'}
                                    count={data?.labPortalOverview.updatesOther ?? 0}
                                    link={overviewDisplayConfig.showUpdateLinks ? '/updates' : undefined}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </LoadBlocker>
        </>
    );
};
