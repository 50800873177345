import logger from '@redux-beacon/logger';
import Segment, { identifyUser, trackEvent } from '@redux-beacon/segment';
import * as Sentry from '@sentry/react';
import type { ActionMeta } from 'redux-actions';
import type { EventDefinition, EventsMap, EventsMapper } from 'redux-beacon';
import { createMiddleware } from 'redux-beacon';

interface Traits {
    address?: {
        city?: string;
        country?: string;
        postalCode?: string;
        state?: string;
        street?: string;
    };
    age?: number;
    avatar?: string;
    birthday?: string;
    company?: {
        name?: string;
        id?: string | number;
        industry?: string;
        employee_count?: number;
        plan?: string;
    };
    createdAt?: string;
    description?: string;
    email?: string;
    firstName?: string;
    gender?: string;
    id?: string;
    lastName?: string;
    name?: string;
    phone?: string;
    title?: string;
    username?: string;
    website?: string;
}

interface BeaconSegmentIdentify {
    userId?: string;
    traits?: Traits;
    options?: any;
}

interface BeaconSegmentTrack {
    name: string;
    properties?: any;
    options?: any;
}

export function orthlyTrackEvent<A = any, M = void, State = {}>(
    eventDef: EventDefinition<BeaconSegmentTrack, ActionMeta<A, M>, State>,
) {
    return trackEvent(eventDef as EventDefinition<BeaconSegmentTrack>);
}

export function orthlyIdentify<A = any, M = void, State = {}>(
    eventDef: EventDefinition<BeaconSegmentIdentify, ActionMeta<A, M>, State>,
) {
    return identifyUser(eventDef as EventDefinition<BeaconSegmentIdentify>);
}

const segment = Segment();

interface AnalyticsMiddlewareOpts {
    disableApm?: boolean;
    // actions to exclude from analytics tracking, defaults to ['_ENDED', '_STARTED', 'Contentful', 'persist']
    actionNameBlacklist: string[];
}

const eventsMapper =
    (eventsMap: EventsMap): EventsMapper =>
    action => {
        if (
            typeof action.type === 'string' &&
            action.type.includes('FAILED') &&
            action.payload &&
            action.payload.error
        ) {
            Sentry.captureException(action.payload.error);
        }
        return eventsMap[action.type] ?? [];
    };

export const createAnalyticsMiddleware = (eventsMap: EventsMap, _opts: AnalyticsMiddlewareOpts) =>
    createMiddleware(eventsMapper(eventsMap), segment, process.env.NODE_ENV === 'production' ? undefined : { logger });
