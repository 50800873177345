import React from 'react';

type ClassNameMap<ClassKey extends string = string> = Record<ClassKey, string>;

/**
 * Not exported from MUI annoyingly
 * @see https://github.com/mui-org/material-ui/blob/master/packages/material-ui-styles/src/mergeClasses/mergeClasses.js
 */
export function mergeMuiClasses<ClassKey extends string = string>(
    baseClasses: ClassNameMap<ClassKey>,
    newClasses?: Partial<ClassNameMap<ClassKey>>,
): ClassNameMap<ClassKey> {
    if (!newClasses) {
        return baseClasses;
    }
    const nextClasses = { ...baseClasses };
    Object.keys(newClasses).forEach(key => {
        const classKey = key as ClassKey;
        if (newClasses[classKey]) {
            nextClasses[classKey] = `${baseClasses[classKey]} ${newClasses[classKey]}`;
        }
    });
    return nextClasses;
}

export function useMergedMuiClasses<ClassKey extends string = string>(
    baseClasses: ClassNameMap<ClassKey>,
    newClasses?: Partial<ClassNameMap<ClassKey>>,
): ClassNameMap<ClassKey> {
    return React.useMemo(() => mergeMuiClasses(baseClasses, newClasses), [baseClasses, newClasses]);
}
