import type { BillingMonthDetailRootProps, CanceledOrdersDetailTableData } from './types';
import { useCreateLabCaseCompletionMutation, useGetCanceledLabOrdersQuery } from '@orthly/graphql-react';
import type { LabsGqlCreateLabCaseCompletionCommand } from '@orthly/graphql-schema';
import { MUITable } from '@orthly/mui-table';
import { DefaultValidationMap, LoadBlocker, QuickForm, RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import { Button, Grid, IconButton, Tooltip, EditIcon } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import React from 'react';

interface AddLabCaseCompletionActionProps {
    labCaseId: string;
    onComplete: () => Promise<any>;
}

/**
 * Returns the dialog for adding percentage completed.
 */
const AddLabCaseCompletionAction: React.FC<AddLabCaseCompletionActionProps> = props => {
    const { labCaseId, onComplete } = props;
    const [submitMtn] = useCreateLabCaseCompletionMutation();
    const mtnSubmitter = (data: LabsGqlCreateLabCaseCompletionCommand) => submitMtn({ variables: { data } });
    const { submit, submitting, open, setOpen } = useChangeSubmissionFn<any, [LabsGqlCreateLabCaseCompletionCommand]>(
        mtnSubmitter,
        {
            closeOnComplete: true,
            successMessage: () => ['Updated!', {}],
            onSuccess: async () => {
                await onComplete();
            },
        },
    );

    return (
        <RootActionDialog
            showCloseButton
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'What percentage did you complete for this order?'}
            content={
                <Grid container style={{ padding: 10 }}>
                    <QuickForm<{ percentage_completed: number }>
                        fields={{
                            percentage_completed: {
                                label: 'Percentage Completed',
                                type: 'number',
                                validation: DefaultValidationMap.number.min(0).max(100),
                            },
                        }}
                        initialValues={{}}
                        onSubmit={async result => {
                            await submit({
                                lab_case_id: labCaseId,
                                percentage_completed: result.percentage_completed,
                            });
                        }}
                    />
                    <Grid item xs={12} container justifyContent={'flex-end'}>
                        <Button variant={'contained'} onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            }
            style={{ padding: 0, flexBasis: 0 }}
            buttonText={'Update'}
            buttonProps={{ fullWidth: false }}
            CustomButton={({ onClick }) => (
                <IconButton onClick={onClick}>
                    <Tooltip title={'Enter lab case completion'}>
                        <EditIcon />
                    </Tooltip>
                </IconButton>
            )}
        />
    );
};

export const CancelledLabOrders: React.FC<BillingMonthDetailRootProps> = props => {
    const { period_begin, period_end } = props;
    const date_range =
        period_begin && period_end
            ? {
                  begin: period_begin.toISOString(),
                  end: period_end.toISOString(),
              }
            : null;
    const {
        data,
        loading: ledgerSummaryLoading,
        refetch,
    } = useGetCanceledLabOrdersQuery({
        variables: {
            date_range,
        },
    });

    const title = 'Canceled';
    const records = data?.getCanceledLabOrders || [];

    return (
        <LoadBlocker blocking={ledgerSummaryLoading}>
            <MUITable<CanceledOrdersDetailTableData>
                title={title}
                displayOptions={{ download: false, sort: true, search: true, filterValues: true, elevation: 0 }}
                columns={[
                    { name: 'id', hidden: true, render: 'id' },
                    { name: 'order_id', hidden: true, render: 'order_id' },
                    {
                        name: 'Date Canceled',
                        render: row => dayjs(row.canceled_at).format('MM/DD/YYYY'),
                        defaultSort: 'desc',
                    },
                    {
                        name: 'Order',
                        render: 'order_number',
                    },
                    {
                        name: 'Patient Name',
                        render: 'patient_name',
                    },
                    {
                        name: 'Actions',
                        sort: false,
                        render: row => <AddLabCaseCompletionAction labCaseId={row.id} onComplete={refetch} />,
                    },
                ]}
                data={records
                    .map(e => {
                        return {
                            id: e.id,
                            order_id: e.order_id,
                            order_number: e.order_number,
                            fulfillment_number: e.fulfillment_number,
                            canceled_at: dayjs(e.canceled_at).toDate(),
                            patient_name: `${e.patient_first_name} ${e.patient_last_name}`,
                            percentage_completed: e.percentage_completed,
                        };
                    })
                    .filter(record => record.percentage_completed === null)}
                actions={{
                    global: [{ position: 'toolbar', onClick: refetch, icon: 'refresh', tooltip: 'Refresh' }],
                }}
            />
        </LoadBlocker>
    );
};
