import { styled } from '@orthly/ui-primitives';
import React from 'react';

const LowerSection = styled('div')({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1.5lh repeat(auto-fit, minmax(1lh, max-content))',
    overflowY: 'hidden',
    overflowX: 'visible',
    textWrap: 'nowrap',
    '&.pan-label-order-section': {
        gridArea: 'order',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'clip',
    },
    '&.pan-label-specifications-section': {
        gridArea: 'specifications',
        justifySelf: 'right',
        textAlign: 'end',
    },
    '& .pan-label-breaks-ok': {
        textWrap: 'wrap',
    },
    '& .pan-label-no-breaks': {
        textWrap: 'nowrap',
    },
});

interface PanLabelLowerSectionProps {
    children: React.ReactNode;
    variant: 'order' | 'specifications';
}

export const PanLabelLowerSection: React.VFC<PanLabelLowerSectionProps> = ({ children, variant }) => {
    return (
        <LowerSection className={`pan-label-${variant}-section`}>
            <div>{variant === 'order' ? 'Order' : 'Specifications'}</div>
            {children}
        </LowerSection>
    );
};
