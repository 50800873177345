import { LabInboxBulkActionIconButton } from '../../../../components/bulk-actions/LabInboxBulkActionIconButton';
import type { LabPortalOrderActionProps } from './LabPortalOrderActions.types';
import { Icon } from '@orthly/ui-primitives';
import { UploadDesignFileAction } from '@orthly/veneer';
import React from 'react';

export const LabPortalUploadDesignAction: React.FC<LabPortalOrderActionProps> = props => {
    const { selectedOrders, refetch, disabled } = props;

    if (!selectedOrders[0]) {
        return null;
    }

    return (
        <UploadDesignFileAction
            CustomButton={props => (
                <LabInboxBulkActionIconButton
                    onClick={props.onClick}
                    loading={false}
                    disabled={disabled}
                    disableTooltip={disabled}
                    selectionCount={1}
                    tooltipTitle={`Upload Design`}
                >
                    <Icon icon={'CloudUploadIcon'} />
                </LabInboxBulkActionIconButton>
            )}
            refetchOrder={async () => {
                await refetch();
            }}
            // TODO: use actual design_file_path from the backend
            order={{ id: selectedOrders[0].id ?? null, design_file_path: null }}
            onClose={() => {}}
        />
    );
};
