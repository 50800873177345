import { AnalyticsClient } from '../../analytics/analyticsClient';
import { BatchShipFoundOrderSection } from './components/BatchShipFoundOrderSection.graphql';
import { BatchShipCandidateFragment_Doc } from './components/BatchShipOrderFragment.graphql';
import { BatchShipmentsSearchBox } from './components/BatchShipSearchBox';
import { useMutation } from '@apollo/client';
import {
    graphql,
    getFragmentData,
    type RecordScanAndGetBatchShipCandidatesMutation,
} from '@orthly/graphql-inline-react';
import { LoadBlocker, apolloErrorMessage } from '@orthly/ui';
import { FlossPalette, stylesFactory, Collapse, Grid, Text } from '@orthly/ui-primitives';
import { useSnackbar } from 'notistack';
import React from 'react';

const BatchShipSearchResultFragment_Doc = graphql(`
    fragment BatchShipCandidateSearchResult on BatchShipCandidatesSearchResult {
        expected_to_ship_today_count
        search_result {
            ...BatchShipCandidateOrder
        }
        shipped_today {
            ...BatchShipCandidateOrder
        }
    }
`);

const RecordScanAndGetCandidates_Doc = graphql(`
    mutation RecordScanAndGetBatchShipCandidates($search: String!) {
        recordShippingDeptScanAndFetchBatchCandidates(search: $search) {
            ...BatchShipCandidateSearchResult
        }
    }
`);

const useStyles = stylesFactory(() => ({
    pageHeader: {
        borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        height: '64px',
        lineHeight: '64px',
        fontSize: '40px',
        verticalAlign: 'middle',
        paddingLeft: '20px',
        paddingTop: '12px',
    },
}));

// Todo: Remove once we've populated the data for shipping scans in our own DB (~ 4/17/2024)
function trackOrderSearchSucceeded(data?: RecordScanAndGetBatchShipCandidatesMutation): void {
    if (!data) {
        return;
    }
    const result = getFragmentData(
        BatchShipSearchResultFragment_Doc,
        data.recordShippingDeptScanAndFetchBatchCandidates,
    );
    const searchedOrder = getFragmentData(BatchShipCandidateFragment_Doc, result.search_result);
    if (searchedOrder) {
        AnalyticsClient.track('Labs - Portal - Order Shipping Search Succeeded', {
            order_id: searchedOrder.lab_order_id,
            $groups: { order: searchedOrder.lab_order_id },
        });
    }
}

/**
 * Root component for the batch shipments pilot at Lehi. It allows fulfillment staff to search for orders and batch them
 * together into the same package / shipping label with other orders that shipped to the practice and address today.
 */
export const BatchShipmentsRoot: React.VFC = () => {
    const [orderSearch, _setOrderSearch] = React.useState<string>();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [triggerMtn, { data: mtnResult, loading }] = useMutation(RecordScanAndGetCandidates_Doc, {
        variables: { search: orderSearch ?? '' },
        fetchPolicy: 'no-cache',
        onError: error => {
            enqueueSnackbar(apolloErrorMessage(error), { variant: 'error' });
            _setOrderSearch(undefined);
        },
        onCompleted: data => trackOrderSearchSucceeded(data),
    });
    const setOrderSearch = React.useCallback(
        (search: string | undefined) => {
            _setOrderSearch(search);
            if (search && search !== orderSearch) {
                void triggerMtn({ variables: { search } });
            }
        },
        [orderSearch, triggerMtn],
    );
    const refetch = React.useCallback(async (): Promise<void> => {
        await triggerMtn();
    }, [triggerMtn]);
    const fragmentData = getFragmentData(
        BatchShipSearchResultFragment_Doc,
        mtnResult?.recordShippingDeptScanAndFetchBatchCandidates,
    );

    return (
        <Grid container direction={'column'}>
            <Grid container className={classes.pageHeader}>
                <Text variant={'h4'}>Batch shipments</Text>
            </Grid>
            <LoadBlocker blocking={loading}>
                <Grid container style={{ padding: '20px 20px 50px' }}>
                    <BatchShipmentsSearchBox orderSearch={orderSearch} setOrderSearch={setOrderSearch} />
                    <Collapse in={!!fragmentData} style={{ width: '100%' }}>
                        {fragmentData && (
                            <BatchShipFoundOrderSection
                                query={fragmentData}
                                refetchQuery={refetch}
                                searchLoading={loading}
                            />
                        )}
                    </Collapse>
                </Grid>
            </LoadBlocker>
        </Grid>
    );
};
