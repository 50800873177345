import { displayClaspType, getCoverageType } from './EditPartialDenture.util';
import { useViewModel } from './EditPartialDenture.vm';
import {
    CartItemV2Utils,
    type IOrderItemPartialDentureClasp as PartialDentureClasp,
    type IOrderItemV2DTO,
    type IPartialDentureItem,
    OrderItemPartialDentureClaspType as ClaspType,
    OrderItemPartialDentureCoverageType as CoverageType,
} from '@orthly/items';
import { QuickForm, RootActionDialog, SimpleSelect } from '@orthly/ui';
import React from 'react';
import { z } from 'zod';

type EditFormData = {
    clasps: PartialDentureClasp[] | null;
    coverageType: CoverageType | null;
};

type EditFormProps = {
    item: IPartialDentureItem;
    onSubmit: (data: EditFormData) => void;
};

/** Form for editing a partial denture. */
const EditForm: React.VFC<EditFormProps> = ({ item, onSubmit }) => (
    <QuickForm<EditFormData>
        key={item.id}
        submitButtonProps={{ children: 'Update Partial Denture' }}
        initialValues={{
            // TODO: Pass these values into the component as properties, instead
            //     of calling util functions directly from the component.
            coverageType: getCoverageType(item),
            clasps: item.clasps,
        }}
        onSubmit={onSubmit}
        fields={{
            coverageType: {
                type: 'select',
                options: Object.values(CoverageType),
                optional: true,
            },
            clasps: {
                type: 'array',
                label: 'Clasps',
                optional: true,
                of: {
                    type: 'nested',
                    fields: {
                        type: {
                            type: 'select',
                            label: 'Clasp Type',
                            optional: false,
                            options: Object.values(ClaspType).map(value => ({
                                value,
                                label: displayClaspType(value),
                            })),
                        },
                        count: {
                            type: 'number',
                            label: 'Clasp Count',
                            optional: false,
                            validation: z.number().min(1),
                        },
                    },
                },
            },
        }}
    />
);

type SelectFormData = EditFormData & {
    itemId: string;
};

type SelectFormProps = {
    items: IPartialDentureItem[];
    onSubmit: (data: SelectFormData) => void;
};

/** Form for selecting a partial denture to edit. */
const SelectForm: React.VFC<SelectFormProps> = ({ items, onSubmit }) => {
    const [item, setItem] = React.useState<IPartialDentureItem | null>(null);
    return (
        <>
            <SimpleSelect
                label={'Partial Denture Item'}
                value={item?.id}
                onChange={id => setItem(items.find(item => item.id === id) ?? null)}
                options={items.map(i => ({
                    label: CartItemV2Utils.getFullDisplayName(i),
                    value: i.id,
                }))}
            />
            {item && <EditForm item={item} onSubmit={data => onSubmit({ ...data, itemId: item.id })} />}
        </>
    );
};

type ModalProps = SelectFormProps & {
    key: string;
    loading: boolean;
    open: boolean;
    setOpen: (open: boolean) => void;
};

/** Modal for editing partial dentures. */
const Modal: React.VFC<ModalProps> = ({ key, items, loading, onSubmit, open, setOpen }) => {
    return (
        <RootActionDialog
            buttonProps={{ style: { height: '100%' } }}
            buttonText={'Edit Partial Denture Details'}
            key={key}
            loading={loading}
            open={open}
            setOpen={setOpen}
            style={{ padding: 0 }}
            title={'Edit Partial Denture Details'}
            content={<SelectForm items={items} onSubmit={onSubmit} />}
        />
    );
};

type ModalWithVmProps = {
    items: IOrderItemV2DTO[];
    salesOrderId: string;
};

/** Modal with view model for editing partial dentures. */
export const EditPartialDentureModal: React.VFC<ModalWithVmProps> = ({ items, salesOrderId }) => {
    const { enabled, filteredItems, open, setOpen, submit, submitting } = useViewModel(items);
    return enabled ? (
        <Modal
            items={filteredItems}
            key={salesOrderId}
            loading={submitting}
            open={open}
            setOpen={setOpen}
            onSubmit={data => {
                submit({ ...data, salesOrderId });
            }}
        />
    ) : null;
};

export const forStorybook = { EditForm, SelectForm, Modal };
