import { IndicatorFlag } from '@orthly/ui';
import { FlossPalette } from '@orthly/ui-primitives';

export type IndicatorFlagKey = keyof typeof IndicatorFlags;

export const IndicatorFlags = {
    refab: (
        <IndicatorFlag
            color={FlossPalette.BURGUNDY}
            title={'Refabrication'}
            iconName={'RefreshIcon'}
            style={{ paddingRight: 4 }}
        />
    ),
    premium: (
        <IndicatorFlag
            iconName={'JewelIcon'}
            title={'Premium'}
            color={FlossPalette.BLACK}
            style={{ paddingRight: 4 }}
        />
    ),
    'delay-accounted': (
        <IndicatorFlag color={FlossPalette.BURGUNDY} title={'Delayed'} iconName={'Error'} style={{ paddingRight: 4 }} />
    ),
    'delay-unaccounted': (
        <IndicatorFlag
            color={FlossPalette.GRAY}
            title={'Unaccounted Delayed'}
            iconName={'Error'}
            style={{ paddingRight: 4 }}
        />
    ),
    'delay-pending': (
        <IndicatorFlag
            color={FlossPalette.GRAY}
            title={'Pending Delay'}
            iconName={'WarningIcon'}
            style={{ paddingRight: 4 }}
        />
    ),
    'waxup-requested': (
        <IndicatorFlag
            color={FlossPalette.BURGUNDY}
            title={'Waxup requested'}
            iconName={'LabIcon'}
            style={{ paddingRight: 4 }}
        />
    ),
    'waxup-approval': (
        <IndicatorFlag
            color={FlossPalette.GRAY}
            title={'Waxup approval'}
            iconName={'WarningIcon'}
            style={{ paddingRight: 4 }}
        />
    ),
    'waxup-revision': (
        <IndicatorFlag
            color={FlossPalette.GRAY}
            title={'Waxup revision'}
            iconName={'WarningIcon'}
            style={{ paddingRight: 4 }}
        />
    ),
    'rush-request': (
        <IndicatorFlag
            color={FlossPalette.GRAY}
            title={'Rush request'}
            iconName={'ToDoIcon'}
            style={{ paddingRight: 4 }}
        />
    ),
    cancelled: (
        <IndicatorFlag
            color={FlossPalette.GRAY}
            title={'Canceled'}
            iconName={'CancelIcon'}
            style={{ paddingRight: 4 }}
        />
    ),
    'portal-design': (
        <IndicatorFlag
            color={FlossPalette.ATTENTION}
            title={'Portal Design'}
            iconName={'CastConnected'}
            style={{ paddingRight: 4 }}
        />
    ),
    'dentures-fixup': (
        <IndicatorFlag
            color={FlossPalette.STAR_GRASS}
            title={'Fixup'}
            iconName={'RefreshIcon'}
            style={{ paddingRight: 4 }}
        />
    ),
    'inbound-return': <IndicatorFlag color={FlossPalette.GRAY} title={'Inbound return'} iconName={'ReturnIcon'} />,
    'lab-design': <IndicatorFlag color={FlossPalette.GRAY} title={'Lab Design'} iconName={'CubeIcon'} />,
    'partial-with-return': (
        <IndicatorFlag color={FlossPalette.GRAY} title={'Partial with return'} iconName={'ReturnIcon'} />
    ),
};
