import { stylesFactory } from '../../../util';
import type { ActionCardVariantProp, ActionCardVariant } from './ActionCard.types';
import type { IconName, SvgIconProps } from '@orthly/ui-primitives';
import { Icon, Avatar, FlossPalette, ChatIcon, WarningIcon, DandyDIcon, InfoIcon } from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';

interface ActionCardIconDisplayInfo {
    backgroundColor: React.CSSProperties['backgroundColor'];
    color: React.CSSProperties['color'];
    mobileColor: React.CSSProperties['color'];
    IconComponent: React.ComponentType<SvgIconProps>;
}

const DEFAULT_DISPLAY_INFO: ActionCardIconDisplayInfo = {
    backgroundColor: '#fff',
    color: FlossPalette.BLACK,
    mobileColor: FlossPalette.BLACK,
    IconComponent: ChatIcon,
};

const VARIANT_TO_DISPLAY_INFO: Record<ActionCardVariant, ActionCardIconDisplayInfo> = {
    message: DEFAULT_DISPLAY_INFO,
    black: DEFAULT_DISPLAY_INFO,
    alert: {
        backgroundColor: FlossPalette.ATTENTION,
        color: '#fff',
        mobileColor: FlossPalette.ATTENTION,
        IconComponent: WarningIcon,
    },
    info: {
        backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
        color: '#fff',
        mobileColor: FlossPalette.PRIMARY_BACKGROUND,
        IconComponent: InfoIcon,
    },
    announcement: {
        ...DEFAULT_DISPLAY_INFO,
        IconComponent: DandyDIcon,
    },
};

const useStyles = stylesFactory<ActionCardVariantProp>(theme => ({
    iconAvatar: props => ({
        backgroundColor: VARIANT_TO_DISPLAY_INFO[props.variant].backgroundColor,
        color: VARIANT_TO_DISPLAY_INFO[props.variant].color,
        marginRight: 24,
        width: 48,
        height: 48,
        '& img,& svg': { width: 32, height: 32 },
        [theme.breakpoints.down('md')]: {
            width: 38,
            height: 38,
            '& img,& svg': { width: 24, height: 24 },
            background: 'none',
            color: VARIANT_TO_DISPLAY_INFO[props.variant].mobileColor,
        },
    }),
    childRoot: {
        [theme.breakpoints.down('md')]: {
            margin: '16px 0',
        },
    },
    parentRoot: {
        [theme.breakpoints.down('md')]: {
            margin: 'calc(-80% + 16px) 0 16px 0',
        },
    },
}));

interface ActionCardIconProps {
    variant: ActionCardVariant;
    IconComponent?: React.ComponentType<SvgIconProps>;
    iconName?: IconName;
    style?: React.CSSProperties;
    parentCard?: boolean;
    className?: string;
}

export const ActionCardIcon: React.FC<ActionCardIconProps> = props => {
    const { variant, IconComponent, parentCard, iconName } = props;
    const classes = useStyles({ variant });
    const IconType = IconComponent ?? VARIANT_TO_DISPLAY_INFO[variant].IconComponent;
    return (
        <Avatar
            className={cx(classes.iconAvatar, parentCard ? classes.parentRoot : classes.childRoot, props.className)}
            style={props.style}
        >
            {iconName ? <Icon icon={iconName} /> : <IconType color={'inherit'} />}
        </Avatar>
    );
};
