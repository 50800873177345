import { LabsGqlLabOrderStatus as OrderStatus } from '@orthly/graphql-schema';
import {
    type IOrderItemV2DTO,
    LabOrderItemSKUType as SKUType,
    OrderItemV2Utils,
    PennyweightUtils,
} from '@orthly/items';

type EnabledActions = {
    addItemToSalesOrder: boolean;
    editPartialDenture: boolean;
    editPennyweight: boolean;
    markInFabrication: boolean;
};

/** Get the enabled order actions. */
export function getEnabledActions(items: IOrderItemV2DTO[], orderStatus: OrderStatus): EnabledActions {
    const isNew = orderStatus === OrderStatus.New;
    const canBeShipped = [OrderStatus.New, OrderStatus.OnHold, OrderStatus.Fabrication].includes(orderStatus);

    const hasPennyweights = items.some(item => PennyweightUtils.isRelevantItem(item));
    const hasPartialDenture = items.some(OrderItemV2Utils.itemIsType(SKUType.Partial));

    return {
        addItemToSalesOrder: canBeShipped,
        editPartialDenture: canBeShipped && hasPartialDenture,
        editPennyweight: canBeShipped && hasPennyweights,
        markInFabrication: isNew,
    };
}

export function useViewModel(items: IOrderItemV2DTO[], orderStatus: OrderStatus) {
    const enabledActions = getEnabledActions(items, orderStatus);
    return { enabledActions };
}
