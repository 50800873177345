import { PrintableSlip } from '../PrintableSlip';
import { PanLabelPage_Fragment, PanLabelPage } from './PanLabelPage.graphql';
import { useQuery, useMutation } from '@apollo/client';
import { getFragmentData, graphql, type PanLabelPage_FragmentFragment } from '@orthly/graphql-inline-react';
import { dayjsExt as dayjs } from '@orthly/runtime-utils';
import { useRootActionCommand } from '@orthly/ui';
import React from 'react';

interface PanLabelSeriesParams {
    autoPrintEnabled: boolean;
    /**
     * The lab order IDs.  The sort order within this array is significant; labels for the first lab order in the array
     * will print before labels for the second lab order, etc.  Each order can, and often will, have multiple labels.
     * The order of labels for one particular order will match the order that the backend provides them.
     */
    labOrderIds: string[];
    onPrintComplete?: () => void;
}

export const PanLabelSeries_Query = graphql(`
    query PanLabelSeries_Query($ids: [String!]!) {
        panLabelsByLabOrderIds(ids: $ids) {
            ...PanLabelPage_Fragment
        }
    }
`);

const BulkRecordLabSlipsPrintedMutation = graphql(`
    mutation BulkRecordLabSlipsPrinted_Mutation($labOrderIds: [String!]!) {
        recordLabSlipsViewedByLabOrderIds(labOrderIds: $labOrderIds)
    }
`);

type NoNulls<T extends {}> = {
    [K in keyof T]: Exclude<T[K], null>;
};

/**
 * None of the properties from the backend are nullable, but rather optional.  So, if nulls appear because of
 * GraphQL, discard those properties.  This is a shallow removal for now.
 * @param obj The object from which to remove nulls.
 * @returns A new object without the null-valued properties.
 */
function removeNulls<T extends {}>(obj: T): NoNulls<T> {
    return Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== null)) as NoNulls<T>;
}

type ConvertedShipByDate<T extends { shipByDate: string }> = Omit<T, 'shipByDate'> & { shipByDate: Date };

/**
 * The only Date property from the backend is shipByDate, and GraphQL deserializes it as a string.  Make it a Date
 * again.
 * @param obj The object with a shipByDate property to convert.
 * @returns A new object with shipByDate as a Date.
 */
function convertShipByDate<T extends { shipByDate: string }>(obj: T): ConvertedShipByDate<T> {
    return {
        ...obj,
        shipByDate: dayjs(obj.shipByDate).toDate(),
    };
}

export const PanLabelSeries: React.VFC<PanLabelSeriesParams> = ({ autoPrintEnabled, labOrderIds, onPrintComplete }) => {
    const { data } = useQuery(PanLabelSeries_Query, { variables: { ids: labOrderIds } });

    // Ideally, we would:
    // 1. Distinguish pan-label views from lab-slip views, even if they have the same or similar downstream effects; and
    // 2. Handle doing so from the backend query rather than making another GraphQL call.
    // We don't get to do either right now.  Avoiding PrintableLabSlipViewedEvent entirely would be the architecturally
    // best option, but we have too much reliance on that event for e.g. chat-rail timeline and TAT state management.
    // Triggering PrintableLabSlipViewedEvent from the backend in the above is hard because we'd have to refactor a
    // lot of code to gain access to it.  Also, an added property in PrintableLabSlipViewedEvent, distinguishing
    // pan-label views from lab-slip views, would be surprisingly hard to consume in at least one spot (chat-rail
    // timeline).  Plus, there are hard-to-break dependencies on ManufacturerOrderHistoryDTO, which we really shouldn't
    // use for pan labels because the relevant timestamp is in a different table.  In short, this is the best bad
    // option left.
    const mutation = useMutation(BulkRecordLabSlipsPrintedMutation);
    const { submit } = useRootActionCommand(mutation, {});

    if (data) {
        // Ensure that we sort labels first by the sort order in labOrderIds, then by the order that the backend
        // returns the labels.  It's possible that this work is better left to the backend; we can refine later if
        // it makes sense.  JS Map retains the order of initial key insertion.
        const sortedLabOrderIdMap = new Map<string, PanLabelPage_FragmentFragment[]>();
        for (const id of labOrderIds) {
            sortedLabOrderIdMap.set(id, []);
        }
        for (const result of data.panLabelsByLabOrderIds) {
            const resultFragment = getFragmentData(PanLabelPage_Fragment, result);
            sortedLabOrderIdMap.get(resultFragment.labOrderId)?.push(resultFragment);
        }
        // Only record views for orders that have labels.
        const orderIdsWithLabels = [...sortedLabOrderIdMap.entries()]
            .filter(([, value]) => value.length > 0)
            .map(([key]) => key);
        // We risk firing the mutation multiple times if we try to use React's dependency and rendering system, even in
        // production.  Doing so would lead to apparent duplicate chat-rail-timeline entries, among other possible
        // woes (i.e., the mutation isn't idempotent).  Just fire the mutation when printing is complete, from the
        // browser's perspective.  That includes clicking "Cancel" on the print dialog.
        const printCompleteCallback = () => {
            if (orderIdsWithLabels.length > 0) {
                void submit({ labOrderIds: orderIdsWithLabels });
            }
            if (onPrintComplete) {
                onPrintComplete();
            }
        };

        return (
            <PrintableSlip openPrintWindow={autoPrintEnabled} onPrintComplete={printCompleteCallback}>
                {[...sortedLabOrderIdMap.values()].flat().map(labelInfo => (
                    <PanLabelPage key={labelInfo.panCode} {...removeNulls(convertShipByDate(labelInfo))} />
                ))}
            </PrintableSlip>
        );
    }

    // If loading multiple orders proves too slow, we may need a loading state, but I'm omitting that flourish here.
    return null;
};
