import { LabPortalErrorPage } from '../../../LabPortalErrorPage';
import { LabPortalOrdersPage } from './LabPortalOrdersPage';
import type { TabOption } from './components/LabPortalOrderListTabs/LabPortalOrderListTabs';
import { LabPortalReturnsPendingListView } from './components/LabPortalOrderListViews/returns/LabPortalReturnsPendingListView';
import { LabPortalOrderTabCountsCtxProvider } from './components/providers/LabPortalOrderTabCountsProvider';
import { convertFilterFromHumanReadableQueryParams } from './utils/QueryParamConversionUtils';
import type { LabsGqlLabPortalReturnsOrderListCountsFragment } from '@orthly/graphql-operations';
import { useLabPortalReturnsOrderCountsQuery } from '@orthly/graphql-react';
import { useQueryParam } from '@orthly/ui';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

const TabOptions: TabOption[] = [
    {
        label: 'Pending Returns',
        tabId: 'pending',
        pathValue: '/returns/pending',
        countsQueryKey: 'returnsPending',
    },
];

export const LabPortalReturnsPage: React.VFC = () => {
    const [filterString, _setFilterString] = useQueryParam('filters');

    const { data: countData, refetch } = useLabPortalReturnsOrderCountsQuery({
        variables: {
            criteria: filterString ? convertFilterFromHumanReadableQueryParams(filterString) : [],
            sort: {},
            columns: TabOptions.map(opt => opt.countsQueryKey),
        },
    });

    const tabOptionsWithCounts = React.useMemo(
        () =>
            TabOptions.map(option => ({
                ...option,
                orderCount:
                    countData?.orders?.[
                        option.countsQueryKey as keyof Omit<
                            LabsGqlLabPortalReturnsOrderListCountsFragment,
                            '__typename'
                        >
                    ],
            })),
        [countData],
    );

    return (
        <LabPortalOrderTabCountsCtxProvider refetch={refetch}>
            <LabPortalOrdersPage title={'Returns'} tabOptions={tabOptionsWithCounts}>
                <Switch>
                    <Route exact path={'/returns/pending'}>
                        <LabPortalReturnsPendingListView />
                    </Route>
                    <Route>
                        <LabPortalErrorPage />
                    </Route>
                </Switch>
            </LabPortalOrdersPage>
        </LabPortalOrderTabCountsCtxProvider>
    );
};
