import { LoadBlocker } from '@orthly/ui';
import { stylesFactory, Switch } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    switchRoot: {
        margin: 0,
        height: 16,
        width: 36,
        borderRadius: 12,
    },
    switchThumb: {
        borderRadius: 12,
        height: 12,
        width: 12,
    },
    switchBase: {
        width: 36,
        transform: 'translateX(-10px)',
        '&.Mui-checked': {
            transform: 'translateX(10px)',
        },
    },
    loadBlocker: { position: 'relative', alignItems: 'center', height: 16, width: 36 },
}));

export const LedgerEntryReviewToggle: React.FC<{
    checked: boolean;
    submit: () => void;
    submitting: boolean;
}> = props => {
    const classes = useStyles();
    const { checked, submit, submitting } = props;
    return (
        <LoadBlocker
            blocking={submitting}
            overlayColor={'transparent'}
            ContainerProps={{ className: classes.loadBlocker }}
            CircularProgressProps={{ style: { height: 16, width: 16 } }}
        >
            <Switch
                size={'medium'}
                color={'secondary'}
                checked={checked}
                onChange={() => submit()}
                classes={{ root: classes.switchRoot, thumb: classes.switchThumb, switchBase: classes.switchBase }}
            />
        </LoadBlocker>
    );
};
