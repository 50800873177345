import { orderIdFromQRCodeUrl } from '../../../utils/utils';
import { QRCodeOrderSearch } from '../../manufacturer/components/QcQrInput/QRCodeOrderSearch';
import { Grid, IconButton, Tooltip, CloseIcon, FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

interface BatchShipmentsSearchBoxProps {
    orderSearch: string | undefined;
    setOrderSearch: (search: string | undefined) => void;
}

export const BatchShipmentsSearchBox: React.VFC<BatchShipmentsSearchBoxProps> = ({ setOrderSearch, orderSearch }) => {
    const onSearchChange = React.useCallback(
        (search: string) => {
            // if the search contains a UUID (usually from an order link) we assume that should be the active search, not the entire url
            const activeOrderIdSearch = orderIdFromQRCodeUrl(search) ?? search;
            // if they submit a search for the same orderId we don't do anything
            if (activeOrderIdSearch === orderSearch) {
                return;
            }
            setOrderSearch(activeOrderIdSearch);
        },
        [setOrderSearch, orderSearch],
    );
    return (
        <Grid
            container
            style={{ paddingBottom: 8 }}
            justifyContent={'space-between'}
            wrap={'nowrap'}
            alignItems={'center'}
        >
            <Grid container style={{ paddingRight: 16 }}>
                <QRCodeOrderSearch setActiveSearch={onSearchChange} />
            </Grid>
            <IconButton
                onClick={() => setOrderSearch(undefined)}
                disabled={!orderSearch}
                style={{
                    color: !!orderSearch ? FlossPalette.ATTENTION : FlossPalette.LIGHT_GRAY,
                    height: 'fit-content',
                }}
            >
                <Tooltip title={'Clear search'} disableHoverListener={!orderSearch}>
                    <CloseIcon />
                </Tooltip>
            </IconButton>
        </Grid>
    );
};
