import { usePracticeAppSelector } from '../redux';
import { usePartnerUiAction } from '../redux/ui.actions';
import { useOpenOrderDetailPage } from '../screens/labs/LabsUtils';
import { OrdersSpotlightSearch, HotkeysManager } from '@orthly/veneer';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

export const PartnerOrdersSpotlightSearch: React.FC = () => {
    const open = usePracticeAppSelector(s => s.ui.activeSpotlight === 'search');
    const setOpen = usePartnerUiAction('SET_SPOTLIGHT_OPEN');

    const toggleShowingSearch = React.useCallback(() => {
        setOpen(!open);
    }, [open, setOpen]);
    useHotkeys(HotkeysManager.keyCombo('search'), toggleShowingSearch, [toggleShowingSearch]);

    const openOrder = useOpenOrderDetailPage();
    return <OrdersSpotlightSearch open={open} setOpen={setOpen} openOrder={openOrder} />;
};
