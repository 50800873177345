import { LoggedOutBottomButtons } from '../components/LoggedOutBottomButtons';
import { useLoginStyles } from '../components/login-styles';
import { useSendResetPasswordEmailMutation } from '@orthly/graphql-react';
import { ValidationUtils } from '@orthly/runtime-utils';
import { LoadBlocker, SimpleInput, useChangeSubmissionFn } from '@orthly/ui';
import { Grid, Text } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const SendResetEmail: React.VFC = () => {
    const classes = useLoginStyles();
    const history = useHistory();
    const [email, setEmail] = React.useState<string>('');
    const [showError, setShowError] = React.useState<boolean>(false);
    const [submitMtn] = useSendResetPasswordEmailMutation();
    const {
        submit,
        submitting: loading,
        called: sendAttempted,
    } = useChangeSubmissionFn<any, []>(() => submitMtn({ variables: { email } }), {
        closeOnComplete: true,
        successMessage: () => ['Email sent!', {}],
    });
    const onClickBack = React.useCallback(() => {
        history.push('/login');
    }, [history]);
    const sendDisabled = React.useMemo(() => loading || !ValidationUtils.isEmail(email), [email, loading]);
    const emailInvalid = showError && !!email && !ValidationUtils.isEmail(email);

    return (
        <form
            onSubmit={event => {
                event.preventDefault();
                !sendDisabled && void submit();
            }}
            className={classes.form}
        >
            <Grid container className={classes.columnRow}>
                <Text className={classes.titleFont} variant={'h5'}>
                    {sendAttempted ? (
                        <>
                            An email has <br />
                            been sent
                        </>
                    ) : (
                        <>
                            Recover your
                            <br />
                            password
                        </>
                    )}
                </Text>
                <Text variant={'body1'} style={{ fontSize: '1.2rem', marginTop: 10 }}>
                    {sendAttempted
                        ? 'Please click the link when you receive it'
                        : 'No worries, it happens to the best of us.'}
                </Text>
            </Grid>
            {!sendAttempted && (
                <LoadBlocker blocking={loading} ContainerProps={{ style: { marginBottom: 45, display: 'block' } }}>
                    <SimpleInput
                        fullWidth
                        onChange={value => setEmail(value || '')}
                        value={email}
                        label={'Email'}
                        variant={'standard'}
                        TextFieldProps={{
                            margin: 'none',
                            type: 'email',
                            autoComplete: 'email',
                            autoFocus: true,
                            onBlur: () => {
                                setShowError(true);
                            },
                            error: emailInvalid,
                            helperText: emailInvalid ? 'Invalid Email' : undefined,
                            FormHelperTextProps: { style: { position: 'absolute', bottom: -16 } },
                            InputProps: {
                                autoComplete: 'email',
                                autoFocus: true,
                                inputMode: 'email',
                                type: 'email',
                            },
                        }}
                    />
                </LoadBlocker>
            )}
            <LoggedOutBottomButtons
                justify={sendAttempted ? 'flex-end' : undefined}
                left={{ onClick: onClickBack, text: 'Back To Login', disabled: loading, type: 'button' }}
                right={{
                    text: 'Submit',
                    type: 'submit',
                    disabled: sendDisabled,
                    dontRender: sendAttempted,
                }}
            />
        </form>
    );
};
