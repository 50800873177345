import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { Icon, FlossPalette } from '@orthly/ui';
import { Grid, Text, styled } from '@orthly/ui-primitives';
import { SimpleDropzone } from '@orthly/veneer';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import Papa from 'papaparse';
import React from 'react';

const ActionsContainer = styled('div')({
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
});

const UploadTextContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
});

const StyledIcon = styled(Icon)({
    color: FlossPalette.PRIMARY_FOREGROUND,
    marginLeft: '8px',
});

enum CsvColumns {
    nestId = 'Id (Nests)',
    partId = 'Part References',
}
const MarkMfgUnitsAutoNestingComplete_Mutation = graphql(`
    mutation MarkMfgUnitsAutoNestingComplete($mfg_unit_ids: [String!]!, $nest_id: String!) {
        markMfgUnitsAutoNestingCompleteWithDetailedResponse(mfg_unit_ids: $mfg_unit_ids, nest_id: $nest_id) {
            completed_mfg_unit_ids
            skipped_mfg_unit_ids
        }
    }
`);

interface NestingCsvRow {
    nest_id: string;
    mfg_unit_id: string;
}

interface UseCsvRowsArgs {
    checkColumns: (columns: string[]) => boolean;
    onValid: (rows: Record<string, string>[]) => void;
}

function useCsvRows(args: UseCsvRowsArgs) {
    const { checkColumns, onValid } = args;
    return (files: File[]) => {
        if (!files[0]) {
            return;
        }

        Papa.parse(files[0], {
            skipEmptyLines: true,
            complete: results => {
                const rawRows = results.data as string[][];

                if (_.isEmpty(rawRows)) {
                    window.alert("The uploaded file doesn't contain any rows.");
                    return;
                }

                const [titleRow, ...dataRows] = rawRows;
                if (!(titleRow && checkColumns(titleRow))) {
                    return;
                }

                const entries = dataRows.map(row => _.zipObject(titleRow, row));

                onValid(entries);
            },
        });
    };
}

export const LabPortalNestingOrdersPage: React.VFC = () => {
    const [markMfgUnitsAutoNestingCompleteMtn] = useMutation(MarkMfgUnitsAutoNestingComplete_Mutation);
    const { enqueueSnackbar } = useSnackbar();
    const [skippedMfgUnitIds, setSkippedMfgUnitIds] = React.useState<string[]>([]);

    const submitMtn = async (inputRows: NestingCsvRow[]) => {
        try {
            const { data } = await markMfgUnitsAutoNestingCompleteMtn({
                variables: {
                    nest_id: inputRows[0]?.nest_id as string,
                    mfg_unit_ids: inputRows.map(row => row.mfg_unit_id),
                },
            });
            enqueueSnackbar('Oqton CSV submitted successfully!', { variant: 'success' });
            const skippedIds = data?.markMfgUnitsAutoNestingCompleteWithDetailedResponse?.skipped_mfg_unit_ids;
            if (skippedIds && skippedIds.length > 0) {
                setSkippedMfgUnitIds(skippedIds);
            }
        } catch (e) {
            console.error(e);
            enqueueSnackbar('Oqton CSV failed!', { variant: 'error' });
        }
    };

    const onDropAccepted = useCsvRows({
        checkColumns: columns => {
            const requiredColumns = Object.values(CsvColumns);
            const missingColumns = requiredColumns.filter(c => !columns.includes(c));
            if (missingColumns.length > 0) {
                window.alert(`Required columns missing: ${missingColumns.join(', ')}`);
                return false;
            }
            return true;
        },
        onValid: (rows: Record<string, string>[]) => {
            const requiredValues = Object.values(CsvColumns);
            const hasNullValues = requiredValues.some(value => rows.some(row => !row[value]));
            if (hasNullValues) {
                window.alert('The uploaded file is missing nest or part IDs.');
                return;
            }

            const inputRows: NestingCsvRow[] = rows.map(row => ({
                nest_id: row[CsvColumns.nestId] as string,
                mfg_unit_id: row[CsvColumns.partId] as string,
            }));
            void submitMtn(inputRows);
        },
    });

    return (
        <Grid container style={{ width: '100%', alignContent: 'center', justifyContent: 'center' }}>
            <ActionsContainer>
                <SimpleDropzone
                    dropzoneContent={
                        <UploadTextContainer>
                            <Text variant={'body1'} medium color={'PRIMARY_FOREGROUND'}>
                                Upload Oqton CSV
                            </Text>
                            <StyledIcon icon={'CloudUploadIcon'} />
                        </UploadTextContainer>
                    }
                    wrapperStyle={{
                        backgroundColor: 'transparent',
                        width: '800px',
                        cursor: 'pointer',
                    }}
                    options={{
                        onDropAccepted,
                        multiple: false,
                        disabled: false,
                    }}
                />
            </ActionsContainer>
            {skippedMfgUnitIds.length > 0 && (
                <Grid
                    container
                    style={{ width: '50%', marginTop: '48px', backgroundColor: FlossPalette.ATTENTION_BACKGROUND }}
                >
                    <Grid style={{ color: FlossPalette.ATTENTION, fontWeight: 'bold', marginBottom: '24px' }}>
                        The following units have been updated and will go through the legacy nesting flow. Please mark
                        these units as cancelled on the nesting report. You can find the corresponding unit IDs below in
                        the "Part References" column on the nest report
                    </Grid>
                    <Grid>
                        {skippedMfgUnitIds.map(id => (
                            <Grid key={id}>{id}</Grid>
                        ))}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};
