import { getLegacyIdObject } from '../../../utils/ChatVisibility';
import { AddAttachmentAction } from '../actions/AddAttachmentAction';
import { useOrderDetailTimelineItems } from '../order-detail-v2/OrderDetailTimelineItemsProvider';
import { getChatMessageVisibilityForWrite, getChatVisibilityOrgTypes } from '@orthly/chat';
import type {
    LabsGqlChatMessageDtoFragment,
    LabsGqlCreateChatMessageMutationVariables,
    LabsGqlDeleteChatMessageMutationVariables,
    LabsGqlOrder,
} from '@orthly/graphql-operations';
import { useCreateChatMessageMutation, useDeleteChatMessageMutation, useOrderRefetch } from '@orthly/graphql-react';
import { LabsGqlChatEntityTypes, LabsGqlOrganizationType } from '@orthly/graphql-schema';
import { OrderItemV2Utils } from '@orthly/items';
import { useSession } from '@orthly/session-client';
import { apolloErrorMessage, useChangeSubmissionFn } from '@orthly/ui';
import { FlossPalette, stylesFactory, useScreenIsMobile } from '@orthly/ui-primitives';
import type { DandyChatClassKey, DandyChatMessage, TimelineEventProps } from '@orthly/veneer';
import { DandyChat, TimelineIcon } from '@orthly/veneer';
import React from 'react';

const useDandyChatStyles = stylesFactory<{ isManufacturer: boolean }, DandyChatClassKey>(theme => ({
    chatMessageContainer: {},
    timelineItemWrapper: {},
    inputRoot: {},
    inputContainer: props => ({
        [theme.breakpoints.down('lg')]: {
            position: 'fixed',
            bottom: props.isManufacturer ? 72 : 0,
            right: 0,
            left: 0,
            width: 'auto',
        },
        [theme.breakpoints.down('sm')]: { left: 0 },
    }),
    rootWrapper: { background: FlossPalette.TAN },
    scrollWrapper: _props => ({
        [theme.breakpoints.down('lg')]: {
            paddingLeft: 0,
            paddingBottom: 128,
        },
    }),
    lightbox: {},
    chatInput: {},
}));

type CreateChatMessageVars = Omit<
    LabsGqlCreateChatMessageMutationVariables['data'],
    'entity_id' | 'entity_type' | 'visible_to_org_ids' | 'visible_to_roles'
>;
type DeleteChatMessageVars = LabsGqlDeleteChatMessageMutationVariables['data'];

function useDeleteMessage(refetchOrder: () => Promise<any>) {
    const [deleteMtn] = useDeleteChatMessageMutation();
    const { submit } = useChangeSubmissionFn<any, [DeleteChatMessageVars]>(
        (data: DeleteChatMessageVars) => deleteMtn({ variables: { data } }),
        {
            onSuccess: async () => {
                await refetchOrder();
            },
        },
    );
    return submit;
}

interface LabOrderChatProps {
    order: LabsGqlOrder;
    chatMessages: LabsGqlChatMessageDtoFragment[];
    refetchChatMessages: () => Promise<any>;
    inputPlaceholder?: string;
}

export const LabOrderChat: React.FC<LabOrderChatProps> = props => {
    const { order, chatMessages, refetchChatMessages, inputPlaceholder } = props;
    const { labSlipLastPrintedAt, timelineItems: items, timelineLoading } = useOrderDetailTimelineItems();
    const chatClasses = useDandyChatStyles({ isManufacturer: true });
    const isMobile = useScreenIsMobile();
    const refetchOrder = useOrderRefetch();
    const session = useSession();
    const staffId = session?.id ?? '';
    const [submitMtn] = useCreateChatMessageMutation();
    const deleteMtnSubmitter = useDeleteMessage(async () => refetchChatMessages());
    const anchorOrigin = isMobile ? { horizontal: 'center' as 'center', vertical: 'top' as 'top' } : undefined;
    const { submit, submitting } = useChangeSubmissionFn<any, [CreateChatMessageVars]>(
        (data: CreateChatMessageVars) =>
            submitMtn({
                variables: {
                    data: {
                        ...data,
                        entity_type: LabsGqlChatEntityTypes.Order,
                        entity_id: order.id,
                        visible_to_roles: getChatMessageVisibilityForWrite(session?.organization_type ?? 'lab'),
                    },
                },
            }),
        {
            closeOnComplete: true,
            errorMessage: e => [apolloErrorMessage(e), { anchorOrigin }],
        },
    );
    const events = React.useMemo(() => {
        return items.map<TimelineEventProps>(event => ({
            date: new Date(event.date),
            title: event.title,
            subtitle: event.subtitle.length === 0 ? undefined : event.subtitle.join('\n'),
            action: event.title_link
                ? { text: event.title_link.text, type: 'link', href: event.title_link.href }
                : undefined,
            Icon: props => {
                return <TimelineIcon type={event.orderEvent} IconProps={props} />;
            },
            attachments: event.attachments,
        }));
    }, [items]);
    const messages = React.useMemo(() => {
        const getUsername = (message: LabsGqlChatMessageDtoFragment) =>
            // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
            // eslint-disable-next-line no-nested-ternary
            message.created_by_staff_id === staffId
                ? 'You'
                : // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                  // eslint-disable-next-line no-nested-ternary
                  message.sender.organization_type === LabsGqlOrganizationType.Lab
                  ? 'Lab'
                  : message.sender.organization_type === LabsGqlOrganizationType.Practice
                    ? 'Dentist'
                    : `${message.sender.user.first_name} from Dandy`;
        const sessionOrgType = session?.organization_type;
        return chatMessages
            .filter(
                message =>
                    message.created_by_staff_id === staffId ||
                    (sessionOrgType &&
                        getChatVisibilityOrgTypes(
                            message,
                            getLegacyIdObject(sessionOrgType, session?.organization_id),
                        ).has(sessionOrgType)),
            )
            .map<DandyChatMessage>(message => {
                const hideDeleteOption = message.deleted_at || message.created_by_staff_id !== staffId;
                const onDeleteChat = hideDeleteOption
                    ? undefined
                    : async () => {
                          await deleteMtnSubmitter({ chat_id: message.id });
                          void refetchChatMessages();
                      };

                return {
                    onDeleteChat,
                    staffId: message.created_by_staff_id,
                    date: new Date(message.created_at),
                    text: message.text,
                    attachments: message.attachments,
                    senderRole: message.sender.organization_type,
                    username: getUsername(message),
                };
            });
    }, [
        chatMessages,
        staffId,
        deleteMtnSubmitter,
        refetchChatMessages,
        session?.organization_id,
        session?.organization_type,
    ]);
    return (
        <DandyChat
            orderId={order.id}
            currentStaffId={staffId}
            onAddChat={async (text: string) => {
                await submit({ text, attachment_urls: [] });
                void refetchChatMessages();
            }}
            messages={messages}
            submitting={submitting || timelineLoading}
            events={events}
            classes={chatClasses}
            inputStartAdornment={
                <AddAttachmentAction
                    items={OrderItemV2Utils.parseItems(order.items_v2)}
                    onError={() => {}}
                    onSuccess={() => {
                        refetchOrder(order.id);
                        void refetchChatMessages();
                    }}
                    order={order}
                    salesOrderId={order.id}
                    refetch={() => {
                        refetchOrder(order.id);
                        void refetchChatMessages();
                    }}
                    buttonVariant={'icon'}
                />
            }
            highlight_since={labSlipLastPrintedAt}
            practice_id={order.partner_id}
            lab_id={order.manufacturer_id}
            inputPlaceholder={inputPlaceholder}
        />
    );
};
