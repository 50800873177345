import type { FlossInputConfig } from '../primitives/Input/FlossInput';
import { FlossInputThemeProvider } from '../primitives/Input/FlossInput';
import type { TextFieldProps } from '@orthly/ui-primitives';
import { TextField } from '@orthly/ui-primitives';
import type { FocusEventHandler } from 'react';
import React from 'react';
import ReactInputMask from 'react-input-mask';

export interface SimpleInputProps extends FlossInputConfig {
    /**
     * Called when the user changes the value of the input.
     * @param {string} value
     */
    onChange: (value?: string) => void;
    /**
     * The value of the input.
     */
    value: string | undefined;
    /**
     * The label of the input.
     */
    label: string;
    /**
     * The placeholder of the input.
     */
    placeholder?: string;
    /**
     * Boolean indicating whether the input is full width.
     */
    fullWidth?: boolean;
    /**
     * Variant type of the input (default: 'standard')
     */
    variant?: 'filled' | 'outlined' | 'standard';
    /**
     * Props to pass to the underlying <TextField/> component.
     */
    TextFieldProps?: Partial<TextFieldProps & { 'data-test'?: string }>;

    disabled?: boolean;

    required?: boolean;
}

/**
 * A text input component that uses the FlossInputThemeProvider to apply style changes
 * @param props
 * @returns {JSX.Element}
 * @constructorpwd
 */
export const SimpleInput: React.FC<SimpleInputProps> = props => {
    const { onChange, TextFieldProps, value, variant, required, flossInputConfig, ...others } = props;
    return (
        <FlossInputThemeProvider flossInputConfig={flossInputConfig}>
            <TextField
                {...props.TextFieldProps}
                {...others}
                value={value || ''}
                variant={(variant as any) || 'standard'}
                required={required}
                onChange={event => {
                    const value = event.target.value;
                    onChange(value && value.length > 0 ? value : undefined);
                }}
                InputProps={{ disableUnderline: true, ...props.TextFieldProps?.InputProps }}
            />
        </FlossInputThemeProvider>
    );
};

/**
 * The same as SimpleInput, but maintains text value state itself. This allows
 * parents to write changes into a mutable Ref rather than into state in order
 * to avoid unnecessary re-renders.
 */
export const StatefulSimpleInput: React.VFC<SimpleInputProps> = props => {
    const [value, setValue] = React.useState('');
    React.useEffect(() => {
        setValue(props.value || '');
    }, [props.value]);

    const newProps: SimpleInputProps = {
        ...props,
        value,
        onChange: e => {
            setValue(e || '');
            props.onChange(e);
        },
    };
    return <SimpleInput {...newProps} />;
};

export interface SimpleMaskedInputProps extends SimpleInputProps {
    /**
     * The mask to apply to the input. https://github.com/sanniassin/react-input-mask#mask
     */
    mask: string | (string | RegExp)[];
}

export const SimpleMaskedInput: React.FC<SimpleMaskedInputProps> = props => {
    const { onChange, TextFieldProps, value, variant, mask, ...others } = props;
    return (
        <ReactInputMask
            mask={mask}
            onChange={event => {
                const value = event.target.value;
                onChange(value && value.length > 0 ? value : undefined);
            }}
            value={value || ''}
        >
            {(inputProps: any) => {
                return (
                    <TextField
                        {...props.TextFieldProps}
                        {...others}
                        variant={(variant as any) || 'standard'}
                        {...inputProps}
                    />
                );
            }}
        </ReactInputMask>
    );
};

export interface SimplePhoneInputProps extends SimpleInputProps {
    onBlur?: FocusEventHandler<HTMLInputElement>;
}

function usaPhoneDigits(normalizedPhoneNumber: string) {
    // not using cleanPhoneNumber because I don't trust it for intermediate values
    return (
        normalizedPhoneNumber
            // strip leading country code
            .replace(/^\+1/, '')
            // remove all non-digits like spaces, dashes, and parens
            .replace(/\D/g, '')
    );
}

function normalizeUsaPhoneDigits(formattedPhoneNumber: string): string {
    // remove all non-digits like spaces, dashes, and parens
    return formattedPhoneNumber ? `+1${formattedPhoneNumber.replace(/\D/g, '')}` : '';
}

export const SimplePhoneInput: React.FC<SimplePhoneInputProps> = props => {
    const { onChange, TextFieldProps, value, variant, onBlur, disabled, ...others } = props;

    return (
        <ReactInputMask
            mask={'(999) 999-9999'}
            onChange={evt => onChange(normalizeUsaPhoneDigits(evt.target.value))}
            onBlur={onBlur}
            disabled={disabled}
            value={(value && usaPhoneDigits(value)) || ''}
        >
            {(inputProps: any) => {
                return (
                    <TextField
                        variant={(variant as any) || 'standard'}
                        {...props.TextFieldProps}
                        {...others}
                        {...inputProps}
                        InputProps={{
                            disableUnderline: true,
                            ...TextFieldProps?.InputProps,
                        }}
                    />
                );
            }}
        </ReactInputMask>
    );
};

export type FieldChangeType = 'blur' | 'focus';

export interface SimpleTextFieldProps {
    /** The id of the input, used to associate with a label. */
    id?: string;
    /** The value of the input. */
    value: string | undefined;
    /** The onChange handler. */
    onChange: (value: string, type?: FieldChangeType) => void;
    /** The label to with the input. */
    label?: string;
    /** event to trigger on blur or focus */
    onBlurOrFocus?: (type: FieldChangeType, value?: string) => void;
    /** Is the input required? */
    required?: boolean;
    /** The variant of the input. */
    variant?: TextFieldProps['variant'];
    /** The size of the input. */
    size?: TextFieldProps['size'];
    /** The helper text to display. */
    helperText?: string | null;
    /** Props to pass to the underlying TextField. */
    TextFieldProps?: Partial<
        Omit<TextFieldProps, 'value' | 'label' | 'required' | 'size' | 'onChange' | 'onBlur' | 'onFocus'>
    >;
    /** Is the input disabled? */
    disabled?: boolean;
    /** Should the error message be hidden? */
    hideError?: true; // true | undefined, force-hides error message if true
    /** This allows `styled(SimpleTextField)` to work with this component */
    className?: string;
}

/**
 * A text field that only triggers onChange events on blur or focus.
 */
export const SimpleTextField: React.FC<SimpleTextFieldProps> = props => {
    const onBlurOrFocus = (type: FieldChangeType) => (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = (e.target?.value || '').trim();
        props.onBlurOrFocus && props.onBlurOrFocus(type, newValue);
        if (newValue !== props.value) {
            props.onChange(newValue, type);
        }
    };
    const onErrorStateChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = (e.target?.value || '').trim();
        if (!!newValue !== !!props.value) {
            props.onChange(newValue);
        }
    };
    const showError = !props.hideError && !props.value && props.required;

    return (
        <TextField
            fullWidth
            id={props.id}
            className={props.TextFieldProps?.className || props.className}
            required={props.required}
            label={props.label}
            variant={props.variant ?? 'standard'}
            defaultValue={props.value}
            error={showError}
            helperText={showError ? `Please enter ${props.label}` : props.helperText}
            size={props.size}
            disabled={props.disabled ?? false}
            {...props.TextFieldProps}
            FormHelperTextProps={{
                ...props.TextFieldProps?.FormHelperTextProps,
                style: { textTransform: 'none', ...props.TextFieldProps?.FormHelperTextProps?.style },
            }}
            style={{ fontWeight: 500, ...props.TextFieldProps?.style }}
            onBlur={onBlurOrFocus('blur')}
            onFocus={onBlurOrFocus('focus')}
            onChange={onErrorStateChange}
        />
    );
};
