import { inputBaseClasses } from '@mui/material';
import { createTheme, ThemeProvider, DANDY_THEME, FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

export interface ThemeOverrideConfig {
    backgroundColor: 'white' | 'gray';
    bordered?: boolean;
}

const flossInputThemeOverride = (config: ThemeOverrideConfig) =>
    createTheme(DANDY_THEME, {
        components: {
            MuiInput: {
                defaultProps: {
                    disableUnderline: true,
                },
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        marginTop: 11,
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        // marginTop: 11,
                    },
                },
            },
            MuiInputLabel: {
                defaultProps: {
                    shrink: true,
                },
                styleOverrides: {
                    root: {
                        color: FlossPalette.BLACK,
                        marginTop: -16,
                        fontSize: 14,
                        fontWeight: 500,
                        fontFamily: 'Inter Regular, Verdana, sans-serif',
                    },
                    shrink: {
                        transform: 'none',
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        backgroundColor: config.backgroundColor === 'white' ? FlossPalette.WHITE : FlossPalette.TAN,
                        color: FlossPalette.BLACK,
                        fontSize: 14,
                        borderRadius: 8,
                        border: `1px solid ${config.bordered ? '#E4E4E4' : 'rgba(0,0,0,0)'}`,
                        transition: 'border 25ms linear, backgroundColor 75ms linear',
                        '&.Mui-focused, &:hover': {
                            '&::after': {
                                display: 'none',
                            },
                        },
                        '&:hover': {
                            transition: 'border 50ms ease-out, box-shadow 50ms ease-out',
                            border: '1px solid #ECEAE6',
                        },
                        '&.Mui-focused': {
                            transition: 'border 50ms ease-out, box-shadow 50ms ease-out',
                            boxShadow: `none`,
                            border: '1px solid #158915',
                        },
                        [`&.${inputBaseClasses.multiline}`]: {
                            padding: '16px 0 8px',
                        },
                        '&.Mui-error': {
                            color: FlossPalette.ATTENTION,
                            backgroundColor: FlossPalette.ATTENTION_BG,
                        },
                        '&.Mui-error:hover, &.Mui-error.Mui-focused': {
                            border: `1px solid ${FlossPalette.ATTENTION}`,
                        },
                        '&.Mui-error.Mui-focused': {
                            backgroundColor: FlossPalette.WHITE,
                            boxShadow: '0px 0px 0px 4px #FFECEA',
                        },
                        '&.Mui-disabled': { color: FlossPalette.GRAY, backgroundColor: FlossPalette.DARK_TAN },
                        [`&.${inputBaseClasses.adornedStart}`]: {
                            '& .MuiIconButton-root, & svg': {
                                color: FlossPalette.GRAY,
                                marginLeft: 16,
                            },
                        },
                        [`&.${inputBaseClasses.adornedEnd}`]: {
                            '& .MuiIconButton-root, & svg': {
                                color: FlossPalette.GRAY,
                            },
                        },
                        '&.Mui-error *::placeholder': {
                            opacity: 1,
                            color: FlossPalette.ATTENTION,
                        },
                    },
                    input: {
                        padding: '16px !important',
                        height: 24,
                        lineHeight: '24px',
                        margin: '0 !important',
                    },
                },
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        marginTop: 0,
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    inputRoot: {
                        padding: 0,
                    },
                },
            },
        },
    });

export interface FlossInputConfig {
    /**
     * Override the default theme for the input.
     */
    flossInputConfig?: ThemeOverrideConfig;
}

export const FlossInputThemeProvider: React.FC<FlossInputConfig> = ({ children, flossInputConfig }) => {
    if (!flossInputConfig) {
        return <>{children}</>;
    }

    return <ThemeProvider theme={flossInputThemeOverride(flossInputConfig)}>{children}</ThemeProvider>;
};
