import { styled } from '@orthly/ui-primitives';

// TODO: Remove the 'as HTMLDivElement' casting when EPDPLT-3985 is resolved.
export const Stack = styled('div')({
    display: 'flex',
    flexWrap: 'nowrap',
});

export const StackX = styled('div')({
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
});

export const StackY = styled('div')({
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
});
