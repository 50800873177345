import { TaskListHeaderTitle } from './ManufacturerTaskListColumn';
import type { BillingColumnProps } from './types';
import { useStyles } from './types';
import { objectEntries, Format } from '@orthly/runtime-utils';
import { ToolbarContainer, LoadBlockerLoader } from '@orthly/ui';
import { Text, Grid, List, ListItem } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import React from 'react';

export const BillingColumn: React.FC<BillingColumnProps> = props => {
    const classes = useStyles();
    const { ledgers, loading, onClick } = props;

    return (
        <Grid className={classes.virtualColumnRoot}>
            <ToolbarContainer className={classes.toolbarContainer} sticky>
                <TaskListHeaderTitle title={'Billing'} />
                <LoadBlockerLoader
                    blocking={loading}
                    loader={'linear'}
                    overlayColor={'transparent'}
                    LoaderContainerProps={{ style: { position: 'absolute', top: 'unset', bottom: 0, left: 0 } }}
                />
            </ToolbarContainer>
            <List>
                {objectEntries(ledgers).map(([month, summary]) => {
                    const { orders_count } = summary;
                    return (
                        <ListItem key={month} button onClick={() => onClick(month)}>
                            <Grid container>
                                <Grid container item>
                                    <Text variant={'body1'}>{dayjs(month).utc().format('MMMM YYYY')}</Text>
                                </Grid>
                                <Grid container item>
                                    <Text variant={'body2'}>{Format.pluralize('order', orders_count)}</Text>
                                </Grid>
                            </Grid>
                        </ListItem>
                    );
                })}
            </List>
        </Grid>
    );
};
