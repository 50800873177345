/**
 * QueryString is a very thin wrapper around URLSearchParams that provides an API similar to query-string or qs.
 *
 * The thin-ness is by design. For anything fancy, consider doing it yourself before complicating these utils.
 **/
export class QueryString {
    static parse(str: string) {
        return Object.fromEntries(new URLSearchParams(str));
    }

    static stringify(params: Record<string, any>) {
        return new URLSearchParams(params).toString();
    }
}
