import type { PartnerAppState } from '../redux';
import { AnalyticsClient } from './analyticsClient';
import { createReduxActionListenerMiddleware } from '@orthly/ui';
import type { LocationChangeAction } from 'connected-react-router';
import { LOCATION_CHANGE } from 'connected-react-router';

export const labPortalAnalyticsMiddleware = createReduxActionListenerMiddleware<PartnerAppState>([
    {
        config: {
            actions: [LOCATION_CHANGE],
        },
        handler: (_newState, action, _oldState) => {
            AnalyticsClient.page(`LabPortal`, `Generic Page`, {
                path: (action as LocationChangeAction).payload.location.pathname,
            });
        },
    },
]);
