import { labPortalAnalyticsMiddleware } from './analytics/lab-portal-analytics.middleware';
import { rootReducer } from './redux';
import * as Sentry from '@sentry/react';
import { routerMiddleware } from 'connected-react-router';
import type { History } from 'history';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

const initRedux = (history: History) => {
    const initialState = {};
    const isDev = process.env.NODE_ENV === 'development' || process.env.REACT_APP_STAGING === 'true';
    const composeEnhancers =
        (isDev &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                trace: false,
                traceLimit: 5,
            })) ||
        compose;
    const middleware = [routerMiddleware(history), thunk];
    if (!!window.analytics) {
        middleware.unshift(labPortalAnalyticsMiddleware);
    }

    const store = createStore(
        rootReducer(history),
        initialState,
        composeEnhancers(applyMiddleware(...middleware), Sentry.createReduxEnhancer()),
    );

    return store;
};

// we export singletons so that we use the same store across the entire app
export const reduxHistory = createBrowserHistory();
export const store = initRedux(reduxHistory);
