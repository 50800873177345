import { Grid, FlossPalette, Text } from '@orthly/ui-primitives';
import React from 'react';

// Utility component to wrap a summary section on the Batch Shipping page
export const BatchShipSummaryBox: React.FC<{ title: string | React.ReactNode }> = props => {
    return (
        <Grid container style={{ padding: 16, border: `1px solid ${FlossPalette.STROKE_LIGHT}`, borderRadius: 16 }}>
            <Grid container>
                {typeof props.title === 'string' ? <Text variant={'h5'}>{props.title}</Text> : props.title}
            </Grid>
            <Grid container>{props.children}</Grid>
        </Grid>
    );
};
