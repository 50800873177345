import { useSelectOrders } from '../../../utils/CheckboxSelectUtils';
import { calculateShipDateDisplay, calculateTimeInListDisplay } from '../../../utils/DisplayUtils';
import { useLabPortalOrderListViewContext } from '../../providers/LabPortalOrderListViewProvider';
import { useLabPortalOrderTabCountsContext } from '../../providers/LabPortalOrderTabCountsProvider';
import type { IndicatorFlagKey } from '../shared/IndicatorFlags';
import { IndicatorFlags } from '../shared/IndicatorFlags';
import type { ColumnDef } from '../shared/LabPortalOrderListView';
import {
    LabPortalOrderListView,
    LabPortalListViewNameColumn,
    LabPortalListViewBulkActionsHeader,
} from '../shared/LabPortalOrderListView';
import { NewOrderActionsDisplay, OrderItemsDisplay } from '../shared/ListViewDisplays';
import type { LabsGqlLabPortalNewOrderListItemFragment } from '@orthly/graphql-operations';
import { useLabPortalNewOrdersWithDesignByListViewQuery } from '@orthly/graphql-react';
import { LoadBlocker } from '@orthly/ui';
import { Grid, CheckboxPrimitive as Checkbox } from '@orthly/ui-primitives';
import React from 'react';

export const LabPortalNewWithDesignListView: React.VFC = () => {
    const { filters, sort } = useLabPortalOrderListViewContext();
    const { refetch: refetchCounts } = useLabPortalOrderTabCountsContext();

    const { data, loading, refetch } = useLabPortalNewOrdersWithDesignByListViewQuery({
        fetchPolicy: 'no-cache',
        variables: { criteria: filters, labPortalSort: sort },
    });

    const { selectedOrderIds, selectedOrders, onSelectOrder, onSelectAll, resetSelected } = useSelectOrders(
        data?.orders ?? [],
    );

    const columns: ColumnDef[] = React.useMemo(
        () => [
            {
                label: (
                    <Checkbox
                        color={'secondary'}
                        checked={
                            data?.orders?.length !== undefined &&
                            data?.orders?.length > 0 &&
                            selectedOrderIds.length === data?.orders?.length
                        }
                        onChange={onSelectAll}
                    />
                ),
                cell: order => (
                    <Checkbox
                        color={'secondary'}
                        checked={selectedOrderIds.includes(order.id)}
                        onChange={onSelectOrder(order.id)}
                    />
                ),
            },
            {
                label: 'Name',
                cell: order => (
                    <LabPortalListViewNameColumn
                        orderId={order.id}
                        orderNumber={order.orderNumber}
                        patientName={order.patientName}
                    />
                ),
            },
            {
                label: 'Items',
                cell: (order: LabsGqlLabPortalNewOrderListItemFragment) => <OrderItemsDisplay items={order.items} />,
            },
            {
                label: 'Model Type',
                cell: order => order.modelType ?? '',
            },
            {
                label: 'Flags',
                cell: order =>
                    order.listFlags?.map((flag: IndicatorFlagKey) => <Grid key={flag}>{IndicatorFlags[flag]}</Grid>),
            },
            {
                label: 'In List Date',
                cell: order => calculateTimeInListDisplay(order.inListDate),
            },
            {
                label: 'Ship Date',
                cell: order => calculateShipDateDisplay(order.shipDate),
            },
            {
                label: (
                    <LabPortalListViewBulkActionsHeader
                        bulkActions={
                            <NewOrderActionsDisplay
                                orders={selectedOrders}
                                refetch={async () => {
                                    resetSelected();
                                    await refetch();
                                    await refetchCounts();
                                }}
                            />
                        }
                    />
                ),
                cell: order => (
                    <NewOrderActionsDisplay
                        orders={[order]}
                        refetch={async () => {
                            resetSelected();
                            await refetch();
                            await refetchCounts();
                        }}
                    />
                ),
            },
        ],
        [
            data?.orders?.length,
            onSelectAll,
            onSelectOrder,
            refetch,
            refetchCounts,
            resetSelected,
            selectedOrderIds,
            selectedOrders,
        ],
    );

    return (
        <LoadBlocker blocking={loading}>
            <LabPortalOrderListView orders={data?.orders ?? []} columns={columns} />
        </LoadBlocker>
    );
};
