import { ManufacturerBillingContent } from '../components/ManufacturerBillingContent';
import { UpdateMetalPricingBanner } from '../components/UpdateMetalPricingBanner';
import { Grid } from '@orthly/ui-primitives';

export const ManufacturerBillingRoot: React.VFC = () => {
    return (
        <Grid container>
            <UpdateMetalPricingBanner />
            <ManufacturerBillingContent />
        </Grid>
    );
};
