import type { LabsGqlFilterCriteriaSubmissionInput } from '@orthly/graphql-schema';
import { LabsGqlFilterComparator } from '@orthly/graphql-schema';
import type { MaterialUiPickersDate } from '@orthly/ui';
import { SimpleDatePicker, FilterIcon } from '@orthly/ui';
import { Button, Grid, Popover } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

/**
 * Defines the component for filtering a list view by ship date
 * Used by the LabPortalOrdersPage if `ship_by_date` is passed in as a custom filter
 */
export const LabPortalOrderListViewShipDateFilterTag: React.VFC<{
    currentFilterValue: any;
    addFilter: (filter: LabsGqlFilterCriteriaSubmissionInput) => void;
}> = ({ currentFilterValue, addFilter }) => {
    const [filterTagOpen, setFilterTagOpen] = React.useState(false);
    const [filterValue, setFilterValue] = React.useState<MaterialUiPickersDate>(currentFilterValue);

    const anchorRef = React.useRef<HTMLButtonElement | null>(null);
    return (
        <Grid>
            <Button
                ref={anchorRef}
                variant={'secondary'}
                onClick={() => setFilterTagOpen(filterTagOpen => !filterTagOpen)}
            >
                <FilterIcon /> Ship by Date
                {filterValue ? `: ${moment(filterValue).format('MMM D')}` : ''}
            </Button>
            <Popover
                anchorEl={anchorRef.current}
                open={filterTagOpen}
                PaperProps={{
                    style: { width: '200px' },
                }}
                onClose={() => setFilterTagOpen(false)}
            >
                <SimpleDatePicker
                    onChange={date => {
                        setFilterValue(date);
                        setFilterTagOpen(false);
                        addFilter({
                            filter_id: 'ship_by_date',
                            comparator: LabsGqlFilterComparator.Lte,
                            comparison_value: moment(date).format('YYYY/MM/DD'),
                        });
                    }}
                    value={(filterValue ?? '') as MaterialUiPickersDate}
                    label={''}
                />
            </Popover>
        </Grid>
    );
};
