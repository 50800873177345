import React from 'react';

/**
 * Keep track of the last non-null value. Useful for rendering stale
 * network data while the request is being revalidated.
 *
 * @param {T | undefined | null} value the given value or null
 * @returns {T | null} the last non-null value given
 */
export function useStaleValue<T>(value: T | undefined | null): T | null {
    const ref = React.useRef<T | null>(value ?? null);
    ref.current = value ?? ref.current ?? null;
    return ref.current;
}
