import { CreateShippingLabelButton } from './CreateShippingLabelModal';
import { MarkOrderShippedModal } from './MarkOrderShippedModal';
import { useSingleMailingAddressQuery, useCurrentManufacturerProfileQuery } from '@orthly/graphql-react';
import { type LabsGqlPortalShipShippingSpeed as ShippingSpeed } from '@orthly/graphql-schema';
import { type RootActionDialogProps } from '@orthly/ui';
import { RootActionDialog } from '@orthly/ui';
import { Text, Grid } from '@orthly/ui-primitives';
import { useSnackbar } from 'notistack';
import React from 'react';

type CreateShipmentModalProps = {
    mailingAddressId: string;
    salesOrder: {
        createdAt: Date;
        id: string;
    };
    shippingSpeed: ShippingSpeed;
    CustomButton?: RootActionDialogProps['CustomButton'];
};

/** Modal for creating a shipment. */
const CreateShipmentModal: React.VFC<CreateShipmentModalProps> = ({
    CustomButton,
    mailingAddressId,
    salesOrder,
    shippingSpeed,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { data: mailingAddress } = useSingleMailingAddressQuery({
        variables: {
            id: mailingAddressId,
        },
        onError: () => enqueueSnackbar('Failed to fetch order.', { variant: 'error' }),
    });

    const [open, setOpen] = React.useState(false);
    const buttonProps: Partial<RootActionDialogProps> = CustomButton
        ? {
              CustomButton,
          }
        : {
              buttonText: 'Ship Order',
              buttonProps: {
                  startIcon: 'TruckIconOutlined',
              },
          };

    if (!mailingAddress) {
        return null;
    }

    return (
        <RootActionDialog
            loading={false}
            open={open}
            setOpen={setOpen}
            title={'Ship this order'}
            content={
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Text variant={'h6'}>Mark as shipped</Text>
                        <Text variant={'body1'}>
                            Provide your own shipping information for this order, and mark it as shipped.
                        </Text>
                        <Grid container spacing={2} style={{ paddingTop: 16, margin: 0 }}>
                            <MarkOrderShippedModal salesOrder={salesOrder} />
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Text variant={'h6'}>Create shipping label</Text>
                        <Text variant={'body1'}>
                            Create a new shipping label for this order, and mark it as shipped.
                        </Text>
                        <Grid container spacing={2} style={{ paddingTop: 16, margin: 0 }}>
                            <CreateShippingLabelButton
                                mailingAddress={mailingAddress.singleAddress}
                                salesOrderId={salesOrder.id}
                                shippingSpeed={shippingSpeed}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            }
            {...buttonProps}
        />
    );
};

type ShipOrderModalProps = {
    mailingAddressId: string;
    salesOrder: {
        createdAt: Date;
        id: string;
    };
    shippingSpeed: ShippingSpeed;
    CustomButton?: RootActionDialogProps['CustomButton'];
};
/** Modal for creating a shipment or marking an order as shipped. */
export const ShipOrderModal: React.VFC<ShipOrderModalProps> = ({
    mailingAddressId,
    salesOrder,
    shippingSpeed,
    CustomButton,
}) => {
    const { data: manufacturerProfileData } = useCurrentManufacturerProfileQuery({ fetchPolicy: 'cache-first' });
    const enablePortalShip = !!manufacturerProfileData?.profile.feature_states.portalShip;
    if (!enablePortalShip) {
        return <MarkOrderShippedModal salesOrder={salesOrder} CustomButton={CustomButton} />;
    }
    return (
        <CreateShipmentModal
            mailingAddressId={mailingAddressId}
            salesOrder={salesOrder}
            shippingSpeed={shippingSpeed}
            CustomButton={CustomButton}
        />
    );
};
