/* eslint-disable max-lines */
import { LoadBlocker } from '../../Loaders';
import type { QuickFormCustomSubmitProps } from '../../QuickForm/QuickForm';
import type { ButtonProps } from '../Button';
import { Button } from '../Button';
import type {
    PaperProps,
    SvgIconProps,
    DialogProps,
    DialogContentProps,
    DialogTitleProps,
    IconButtonProps,
    TextProps,
} from '@orthly/ui-primitives';
import {
    stylesFactory,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    FlossPalette,
    Grid,
    Text,
    useScreenIsMobile,
    Icon,
} from '@orthly/ui-primitives';
import type { CSSProperties } from 'react';
import React from 'react';

const useStyles = stylesFactory(() =>
    createStyles({
        title: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            position: 'sticky',
            top: 0,
            borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
            zIndex: 3,
            backgroundColor: '#fff',
        },
        buttonLabel: {
            whiteSpace: 'pre-line',
        },
        closeButton: {
            position: 'absolute',
            top: 8,
            right: 8,
        },
    }),
);

export interface RootActionDialogProps {
    /**
     * Show a loading spinner over the open dialog
     **/
    loading: boolean;
    /**
     * Whether the dialog is open or not
     */
    open: boolean;
    /**
     * Function to set the open state of the dialog
     * @param {boolean} open
     */
    setOpen: (open: boolean) => void;
    /**
     * Title of the dialog
     */
    title: string | React.ReactNode;
    /**
     * Subtitle of the dialog
     */
    subtitle?: string | React.ReactNode;
    /**
     * Content of the dialog
     */
    content: React.ReactNode;
    /**
     * Actions to be displayed at the bottom of the dialog
     */
    actions?: React.ReactNode;
    /**
     * Action to be displayed in the title bar of the dialog
     */
    titleAction?: React.ReactNode;
    /**
     * Text to be displayed on the button that opens the dialog
     */
    buttonText?: string;
    /**
     * Color of the button that opens the dialog
     */
    buttonColor?: string;
    /**
     * Color of the text on the button that opens the dialog
     */
    buttonTextColor?: string;
    /**
     * Props to be passed to the button that opens the dialog
     */
    buttonProps?: Partial<ButtonProps>;
    /**
     * Props to be passed to the Material UI <Dialog>
     */
    dialogProps?: Partial<DialogProps>;
    /**
     * Props to be passed to the Material UI <DialogContent>
     */
    contentProps?: DialogContentProps & { 'data-test'?: string };
    /**
     * Display the close button in the title bar of the dialog
     */
    showCloseButton?: boolean;
    /**
     * Provide a custom button to open the dialog
     */
    CustomButton?: React.FC<ButtonProps>;
    /**
     * Callback to be called when the dialog is closed
     */
    onClose?: () => void;
    /**
     * Custom styles to be applied to (???)
     */
    style?: CSSProperties;
    /**
     * Props to be passed to the Material UI <DialogTitle>
     */
    titleProps?: Partial<DialogTitleProps & { 'data-test': string }>;
    /**
     * Props to be passed to the Material UI <Typography> that displays the title
     */
    titleTextProps?: Partial<TextProps & { 'data-test': string }>;
    /**
     * Props to be passed to the Material UI <Typography> that displays the subtitle
     */
    subtitleTextProps?: Partial<TextProps & { 'data-test': string }>;
    /**
     * Props to be passed to the Material UI <IconButton> that displays the close button
     */
    closeIconButtonProps?: Partial<IconButtonProps & { 'data-test': string }>;
    /**
     * Props to be passed to the Material UI <CloseIcon> that displays the close button
     */
    closeIconProps?: Partial<SvgIconProps>;
    /**
     * Show/hide the button that opens the dialog
     */
    hideButton?: boolean;
    /**
     * Props to be passed to the Material UI <Paper>
     */
    PaperProps?: PaperProps;
    /**
     * Boolean to determine if the dialog should be full screen
     */
    fullScreen?: boolean;
    /**
     * The material-ui 'maxWidth' prop for the <Dialog>
     */
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    /**
     * Boolean to determine if the dialog should be full height
     */
    fullHeight?: boolean;
    /**
     * class name to be applied to the root <Dialog> element
     */
    className?: string;
    /**
     * Disable backdrop click
     */
    disableBackdropClick?: boolean;
}

/**
 * A Modal that can be opened from a button or a custom button
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const RootActionDialog: React.VFC<RootActionDialogProps> = props => {
    const classes = useStyles();
    const disableBackdropClick = props.disableBackdropClick;

    const isMobile = useScreenIsMobile();
    const { open, setOpen, CustomButton, hideButton, PaperProps } = props;

    const doSetOpen = (isOpen: boolean) => {
        setOpen(isOpen);
        if (!isOpen && open) {
            // closing, call the callback
            props.onClose?.();
        }
    };

    // @ts-ignore - "label" exists in v4 and is merged into "root" in v5
    const labelClasses = props.buttonProps?.classes?.label
        ? // @ts-ignore - "label" exists in v4 and is merged into "root" in v5.
          `${props.buttonProps?.classes?.label} ${classes.buttonLabel}`
        : classes.buttonLabel;

    const buttonProps: ButtonProps = {
        fullWidth: true,
        variant: 'ghost',
        ...props.buttonProps,
        onClick: () => setOpen(true),
        classes: {
            ...props.buttonProps?.classes,
            root: labelClasses,
        },
    };

    if (props.buttonColor) {
        buttonProps.style = {
            ...buttonProps.style,
            backgroundColor: props.buttonColor,
        };
    }

    if (props.buttonTextColor) {
        buttonProps.style = {
            ...buttonProps.style,
            color: props.buttonTextColor,
        };
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={(_event, reason) => {
                    if (disableBackdropClick) {
                        if (reason !== 'backdropClick') {
                            !props.loading && doSetOpen(false);
                        }
                    } else {
                        !props.loading && doSetOpen(false);
                    }
                }}
                maxWidth={props.maxWidth || 'sm'}
                className={props.className}
                fullWidth
                fullScreen={props.fullScreen}
                PaperProps={{ ...PaperProps, style: { minWidth: 350, ...PaperProps?.style, borderRadius: 8 } }}
                {...props.dialogProps}
            >
                <DialogTitle
                    data-test={'root-action-dialog-title-wrapper'}
                    {...props.titleProps}
                    className={classes.title}
                >
                    <Grid style={{ padding: isMobile ? '0 0 8px 0' : '16px 16px 8px 16px', width: '100%' }}>
                        <Text
                            data-test={'root-action-dialog-title'}
                            {...props.titleTextProps}
                            variant={isMobile ? 'h5' : 'h4'}
                            style={{ paddingBottom: props.subtitle ? 0 : 16 }}
                            component={'span'}
                        >
                            {props.title}
                        </Text>
                        {!!props.subtitle && (
                            <Text
                                variant={'body2'}
                                style={{
                                    paddingTop: 8,
                                    paddingBottom: isMobile ? 8 : 16,
                                    color: FlossPalette.GRAY,
                                    lineHeight: '24px',
                                }}
                                data-test={'root-action-dialog-subtitle'}
                                {...props.subtitleTextProps}
                            >
                                {props.subtitle}
                            </Text>
                        )}
                    </Grid>
                    {props.titleAction}
                    {props.showCloseButton && (
                        <IconButton
                            onClick={() => {
                                !props.loading && doSetOpen(false);
                            }}
                            disabled={props.loading}
                            size={'small'}
                            data-test={'root-action-dialog-close-button'}
                            className={classes.closeButton}
                            {...props.closeIconButtonProps}
                        >
                            <Icon icon={'CloseIcon'} color={'primary'} {...props.closeIconProps} />
                        </IconButton>
                    )}
                </DialogTitle>
                <LoadBlocker
                    blocking={props.loading}
                    ContainerProps={props.fullHeight ? { style: { height: '100%' } } : undefined}
                >
                    <DialogContent
                        style={{ padding: isMobile ? '0 24px 24px 24px' : '0 40px 40px 40px', borderTop: 'none' }}
                        data-test={'root-action-dialog-content-wrapper'}
                        {...props.contentProps}
                    >
                        {props.content}
                    </DialogContent>
                    {props.actions && (
                        <DialogActions
                            style={{
                                paddingTop: 10,
                                width: '100%',
                                position: 'sticky',
                                bottom: 0,
                                borderTop: `1px solid ${FlossPalette.STROKE_LIGHT}`,
                                backgroundColor: FlossPalette.WHITE,
                            }}
                            data-test={'root-action-dialog-actions-wrapper'}
                        >
                            {props.actions}
                        </DialogActions>
                    )}
                </LoadBlocker>
            </Dialog>
            {!hideButton &&
                (CustomButton ? (
                    <CustomButton {...buttonProps} />
                ) : (
                    <Button {...buttonProps} data-test={'root-action-dialog-button'}>
                        {props.buttonText || 'Open'}
                    </Button>
                ))}
        </>
    );
};

interface DialogImplicitProps {
    open: boolean;
    setOpen: (opening: boolean) => void;
}

export function makeLazyDialog<T>(
    ModalComponent: React.FC<T & DialogImplicitProps>,
): (props: T) => [(opening: boolean) => void, React.ReactNode] {
    return (props: T) => {
        const [open, setOpen] = React.useState(false);
        const elem = open ? <ModalComponent open={open} setOpen={setOpen} {...props} /> : null;
        return [setOpen, elem];
    };
}

export const QuickFormDialogSubmit =
    (title: string): React.FC<QuickFormCustomSubmitProps> =>
    ({ triggerSubmit, disabled }) => (
        <DialogActions style={{ marginTop: 16, padding: '8px 0' }}>
            <Button variant={'primary'} disabled={disabled} onClick={() => triggerSubmit()}>
                {title}
            </Button>
        </DialogActions>
    );

interface SimpleActionButtonProps extends ButtonProps {
    buttonText: string;
    buttonColor?: string;
    buttonTextColor?: string;
}

export const SimpleActionButton: React.FC<SimpleActionButtonProps> = props => {
    const { buttonText, buttonColor, buttonTextColor, ...defaultProps } = props;
    const btnProps: ButtonProps = {
        fullWidth: true,
        ...defaultProps,
    };
    if (props.buttonColor) {
        btnProps.style = {
            ...btnProps.style,
            backgroundColor: props.buttonColor,
        };
    }
    if (props.buttonTextColor) {
        btnProps.style = {
            ...btnProps.style,
            color: props.buttonTextColor,
        };
    }
    return (
        <Grid item xs={12} style={{ padding: '10px 0' }}>
            {props.children}
            <Button {...btnProps}>{props.buttonText}</Button>
        </Grid>
    );
};
