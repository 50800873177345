import { usePracticeAppSelector } from '../redux';
import { HORIZONTAL_NAV_HEIGHT } from '@orthly/dentin';
import { useScreenIsMd, useScreenIsMobile } from '@orthly/ui-primitives';
import { PRACTICE_OUTAGE_BANNER_HEIGHT } from '@orthly/veneer';

const LAB_TAB_BAR_HEIGHT = 80;

type ContentHeightParams = {
    maintenanceBannerPractice?: boolean;
};

const useShowMobileLayout = (): boolean => {
    const screenIsMedium = useScreenIsMd();
    const isNativeApp = usePracticeAppSelector(s => s.ui.isNativeApp);
    return isNativeApp || screenIsMedium;
};

export const useCalculateContentHeight = (params: ContentHeightParams): string => {
    const { maintenanceBannerPractice } = params;
    const screenIsMedium = useScreenIsMd();
    const isMobile = useScreenIsMobile();
    const isMobileLayout = useShowMobileLayout();

    let offsetHeight = 0;

    if (!isMobile) {
        offsetHeight += HORIZONTAL_NAV_HEIGHT;
    }

    if (isMobileLayout) {
        offsetHeight += LAB_TAB_BAR_HEIGHT;
    }

    if (maintenanceBannerPractice && !screenIsMedium) {
        offsetHeight += PRACTICE_OUTAGE_BANNER_HEIGHT;
    }

    return `calc(100vh - ${offsetHeight}px)`;
};
