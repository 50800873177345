import { LabInboxBulkActionIconButton } from '../../../../components/bulk-actions/LabInboxBulkActionIconButton';
import type { LabPortalSkipAutoNestingActionProps } from './LabPortalOrderActions.types';
import type { DocumentNode } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { Format } from '@orthly/runtime-utils';
import { useChangeSubmissionFn, makeLazyDialog, RootActionDialog } from '@orthly/ui';
import { FlossPalette, Button, XIcon } from '@orthly/ui-primitives';
import { useSnackbar } from 'notistack';
import React from 'react';

const SkipMillingAutoNestingForOrderMutation = graphql(`
    mutation SkipMillingAutoNestingForOrder($manufacturing_order_id: String!, $reason: String!) {
        skipMillingAutoNestingForOrder(manufacturing_order_id: $manufacturing_order_id, reason: $reason)
    }
`);

type BulkSkipMillingAutoNestingResult = {
    errorCount: number;
    successCount: number;
};

function useBulkSkipAutoNestingAction(refetchOrders: () => Promise<any>) {
    const { enqueueSnackbar } = useSnackbar();

    const [submitMtn] = useMutation(SkipMillingAutoNestingForOrderMutation as DocumentNode);

    const runUpload = React.useCallback(
        async (manufacturingOrderIds: string[]): Promise<BulkSkipMillingAutoNestingResult> => {
            const result: BulkSkipMillingAutoNestingResult = { errorCount: 0, successCount: 0 };

            for (const manufacturingOrderId of manufacturingOrderIds) {
                const { errors } = await submitMtn({
                    variables: { manufacturing_order_id: manufacturingOrderId, reason: 'Manual Cancellation' },
                });

                if (errors) {
                    result.errorCount += 1;
                } else {
                    result.successCount += 1;
                }
            }

            return result;
        },
        [submitMtn],
    );
    return useChangeSubmissionFn<BulkSkipMillingAutoNestingResult, [string[]]>(runUpload, {
        onSuccess: async result => {
            const errorCount = result.errorCount;
            const successCount = result.successCount;
            const totalCount = errorCount + successCount;
            if (errorCount > 0) {
                const countLabel = totalCount > 1 ? `${errorCount} of ${totalCount} orders` : `order`;
                enqueueSnackbar(`Failed to skip auto-nesting for ${countLabel}, please retry`, {
                    variant: 'error',
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                });
            } else {
                enqueueSnackbar(`Skipped auto-nesting for ${Format.pluralize('order', successCount)}!`, {
                    variant: 'success',
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                });
            }
            await refetchOrders();
        },
    });
}

const useBulkSkipAutoNestingDialog = makeLazyDialog<{ submit: () => Promise<void>; orderLabel: string }>(props => {
    const { submit, open, setOpen, orderLabel } = props;

    return (
        <RootActionDialog
            title={`Are you sure you want to skip auto-nesting for ${orderLabel}?`}
            open={open}
            setOpen={setOpen}
            loading={false}
            buttonText={``}
            content={
                <Button
                    variant={'primary'}
                    fullWidth={true}
                    onClick={async () => {
                        await submit();
                        setOpen(false);
                    }}
                >
                    Skip Auto-Nesting
                </Button>
            }
        />
    );
});

export const LabPortalSkipAutoNestingAction: React.VFC<LabPortalSkipAutoNestingActionProps> = props => {
    const { selectedOrders, refetch, disabled } = props;
    const { submit, submitting } = useBulkSkipAutoNestingAction(refetch);

    const submitOrders = React.useCallback(async () => {
        await submit(selectedOrders.map(o => o.manufacturingOrderId));
    }, [selectedOrders, submit]);

    const itemsLabel = props.onSingleRow ? '1 order' : Format.pluralize('order', selectedOrders.length);

    const [setBulkSkipDialogOpen, bulkSkipAutoNestingDialog] = useBulkSkipAutoNestingDialog({
        submit: submitOrders,
        orderLabel: itemsLabel,
    });

    return (
        <>
            <LabInboxBulkActionIconButton
                onClick={() => setBulkSkipDialogOpen(true)}
                loading={submitting}
                disabled={disabled ?? false}
                disableTooltip={disabled ?? false}
                selectionCount={selectedOrders.length}
                tooltipTitle={`Skip auto-nesting for ${itemsLabel}`}
            >
                <XIcon
                    style={{ color: selectedOrders.length === 0 || submitting ? undefined : FlossPalette.STAR_GRASS }}
                />
            </LabInboxBulkActionIconButton>
            {bulkSkipAutoNestingDialog}
        </>
    );
};
