import { AnalyticsClient } from '../../../analytics/analyticsClient';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { LoadBlocker, apolloErrorMessage } from '@orthly/ui';
import { Button, Icon, Tooltip } from '@orthly/ui-primitives';
import { useSnackbar } from 'notistack';
import React from 'react';

const RecordPackingCompleteMutation_Doc = graphql(`
    mutation RecordPackingComplete($labOrderIds: [String!]!) {
        recordPackingCompletedForManufacturingOrders(labOrderIds: $labOrderIds)
    }
`);

function recordShippingLabelPrintForBatch(orderIdsInBatch: string[], batchTrackingNumber: string, success: boolean) {
    for (const orderId of orderIdsInBatch) {
        AnalyticsClient.track('Lab - Portal - Shipping Label Printed', {
            $groups: { order: orderId },
            displayLocation: 'batch_shipping_page',
            success,
            batchTrackingNumber,
        });
    }
}

interface PrintShippingLabelButtonProps {
    labelUrl: string;
    orderIdsInBatch: string[];
    disabled: boolean;
    refetch: () => Promise<unknown>;
    batchTrackingNumber: string;
}

/**
 * Batch shipping print label component. This button records that the order(s) are packed when pressed, make sure that
 * is the intended behavior you want if using elsewhere
 */
export const PrintBatchShippingLabelBtn: React.VFC<PrintShippingLabelButtonProps> = props => {
    const { orderIdsInBatch, labelUrl, disabled, refetch, batchTrackingNumber } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [triggerMtn, { loading }] = useMutation(RecordPackingCompleteMutation_Doc, {
        variables: { labOrderIds: orderIdsInBatch },
        onCompleted: () => {
            recordShippingLabelPrintForBatch(orderIdsInBatch, batchTrackingNumber, true);
            void refetch();
        },
        onError: error => {
            recordShippingLabelPrintForBatch(orderIdsInBatch, batchTrackingNumber, false);
            enqueueSnackbar(apolloErrorMessage(error), { variant: 'error' });
        },
    });
    const onClick = React.useCallback(() => {
        void triggerMtn();
        window.open(labelUrl, '_blank', 'noreferrer noopener');
    }, [labelUrl, triggerMtn]);

    return (
        <LoadBlocker blocking={loading} ContainerProps={{ style: { width: 'auto' } }}>
            <Button variant={'secondary'} onClick={onClick} disabled={disabled}>
                Print Label + Pack
                <Tooltip
                    title={
                        <span style={{ fontSize: '12pt', lineHeight: 1.2 }}>
                            Marks the shipment as "waiting for pickup".{' '}
                            <strong>Other orders cannot be batched with it afterwards.</strong>
                            <br />
                            <br />
                            Only click this button when you are ready to close the package and put it in the pickup
                            area.
                        </span>
                    }
                >
                    <div style={{ display: 'flex' }}>
                        <Icon icon={'InfoOutlinedIcon'} style={{ marginLeft: 3, maxHeight: 16 }} />
                    </div>
                </Tooltip>
            </Button>
        </LoadBlocker>
    );
};
