import { createStyles, stylesFactory } from '@orthly/ui-primitives';
import { MagicTouchQREntry } from '@orthly/veneer';
import QrEncode from 'qrcode';
import React from 'react';
import { useAsync } from 'react-async-hook';

const useStyles = stylesFactory(() =>
    createStyles({
        table: {
            'border-collapse': 'collapse',
            width: '100%',
            '& tr': {
                border: '1px solid #dddddd',
                'text-align': 'left',
                padding: '8px',
            },
            '& tr:nth-child(even)': {
                'background-color': '#dddddd',
            },
        },
    }),
);

const exampleData: MagicTouchQREntry.QRFieldEntryData = {
    patientFirst: 'John',
    patientLast: 'Smith',
    orderNumber: '12-112-273',
    shade: 'A1',
    shipDate: '01021970',
    dueDate: '01021989',
    doctorName: 'Dr. Strangelove',
};

const useEncodedQR = (data: string) => {
    const dataUrl = useAsync<string>(async (x: string) => QrEncode.toDataURL(x), [data]);
    const size = { width: 250, height: 250 };
    return dataUrl.result ? (
        <img src={dataUrl.result ?? ''} alt={'QR Code'} {...size} />
    ) : (
        <div style={{ display: 'inline-block', ...size }} />
    );
};

export const MagicTouchFieldEntryConfigDebugger: React.FC = () => {
    const styles = useStyles();
    const [fieldData, setFieldData] = React.useState<MagicTouchQREntry.QRFieldEntryData>(exampleData);
    const [fieldConfig, setFieldConfig] = React.useState<MagicTouchQREntry.QRFieldPlacementConfig>(
        MagicTouchQREntry.DEFAULT_MT_QR_ENTRY_FIELD_PLACEMENTS,
    );
    const qrData = MagicTouchQREntry.createEntryQRCodeString(fieldData, fieldConfig);

    return (
        <div style={{ padding: '20px' }}>
            <h1>Magic Touch Field Entry Config Generator</h1>
            <p>
                Instructions: Use the toggles to generate QR entry codes until it fit's your setup. Once you've found
                one that works for you, send the config to the Dandy Team
            </p>

            <h2>Field Entry Configuration</h2>
            <table className={styles.table}>
                <tr>
                    <th>Field Name</th>
                    <th>Example Value</th>
                    <th>Enabled?</th>
                    <th>Field Depth</th>
                </tr>
                {MagicTouchQREntry.QR_FIELDS.map((fieldName, i) => (
                    <tr key={i}>
                        <td>{fieldName}</td>
                        <td>
                            <input
                                value={fieldData[fieldName]}
                                name={fieldName}
                                onChange={e => {
                                    setFieldData({ ...fieldData, [fieldName]: e.currentTarget.value });
                                }}
                            />
                        </td>
                        <td>
                            <input
                                type={'checkbox'}
                                checked={fieldConfig[fieldName].length !== 0}
                                onChange={e => {
                                    const defaultValue =
                                        MagicTouchQREntry.DEFAULT_MT_QR_ENTRY_FIELD_PLACEMENTS[fieldName];
                                    setFieldConfig({
                                        ...fieldConfig,
                                        [fieldName]: e.target.checked ? [defaultValue[0] ?? 15] : [],
                                    });
                                }}
                            />
                        </td>
                        <td>
                            <input
                                type={'number'}
                                disabled={!fieldConfig[fieldName]?.length}
                                min={'0'}
                                max={'100'}
                                value={fieldConfig[fieldName][0] ?? ''}
                                onChange={e => {
                                    setFieldConfig({ ...fieldConfig, [fieldName]: [parseInt(e.currentTarget.value)] });
                                }}
                            />
                        </td>
                    </tr>
                ))}
            </table>

            <h2>Generated QR code for the Above Config</h2>
            {useEncodedQR(qrData)}

            <h2>When it Works How You Want, Send us This Data!</h2>
            <pre style={{ backgroundColor: 'gray', padding: '25px', color: 'white' }}>
                {JSON.stringify(fieldConfig, null, 2)}
            </pre>

            <details>
                <summary>Debug</summary>

                <h3>QR Data</h3>
                <pre>{JSON.stringify(qrData)}</pre>
                <h3>Example Data</h3>
                <pre>{JSON.stringify(fieldData)}</pre>
            </details>
        </div>
    );
};
