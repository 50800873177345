import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { Button, Tooltip, Text } from '@orthly/ui-primitives';
import { OrderDetailBlock, TatProgressTrackingBlock } from '@orthly/veneer';
import React from 'react';

interface OrderDetailTatProgressProps {
    order: LabsGqlOrder;
    toggleTatProgress: () => void;
}

export const OrderDetailTatProgress: React.FC<OrderDetailTatProgressProps> = ({ order, toggleTatProgress }) => {
    return (
        <OrderDetailBlock
            variant={'right'}
            title={'TAT Progress'}
            actions={
                <Tooltip title={<Text variant={'body1'}>Switch to Tracking view</Text>}>
                    <Button variant={'contained'} onClick={toggleTatProgress} startIcon={'SyncAlt'}>
                        <span>Tracking</span>
                    </Button>
                </Tooltip>
            }
        >
            <TatProgressTrackingBlock order={order} />
        </OrderDetailBlock>
    );
};
