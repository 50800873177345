import { CreateShippingLabelModal } from '../../../labs/actions/practitioners/ShipmentDialog/CreateShippingLabelModal';
import { QRCodeOrderSearch } from '../QcQrInput/QRCodeOrderSearch';
import { QcSidebarSection } from './QcStationArea';
import { Qc3DModelViewer } from './QcStationDesignViewer.graphql';
import { QCStationErrorModal } from './QcStationError';
import { QcStationOrderDetails } from './QcStationOrderDetails';
import { useQcStationAction } from './state/QcStation.actions';
import { useQcStationPropSelector, useQcStationSelector } from './state/QcStation.selectors';
import { QcStationDialog } from './state/QcStation.state';
import { QcStationDataProvider, useQcStationRemoteData } from './useQcStationRemoteData';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import type { RequireChildrenProps } from '@orthly/ui';
import { SidebarContainer, LoadBlocker, ActionCard, NavigationLogoDandy, RootActionDialog } from '@orthly/ui';
import { Button, FlossPalette, Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

const ScanToStart: React.VFC = () => (
    <Grid item style={{ margin: `0 20%`, textAlign: `center` }}>
        <div style={{ height: 64 }} />
        <NavigationLogoDandy style={{ width: 128, opacity: 0.1 }} />
        <div style={{ height: 64 }} />
        <Text variant={`h4`}>Scan barcode code on slip</Text>
        <br />
        <Text variant={`body1`}>
            You can find the barcode on the top right of the order slip. Having issues? Type the order number
            (Dandy-123) on the top left.
        </Text>
    </Grid>
);

const QCShippingErrorDialog: React.FC<RequireChildrenProps> = ({ children }) => {
    const { qcDialog, activeOrderIdSearch } = useQcStationPropSelector(['qcDialog', 'activeOrderIdSearch']);
    const { labOrderFragment } = useQcStationRemoteData();
    const setOpen = useQcStationAction('TOGGLE_SHIPPING_OPEN');
    // fallback to the order id from the search if the order isn't loaded. this should get cleaned up in the future, that button could link to nowhere
    const orderId = labOrderFragment?.id ?? activeOrderIdSearch;
    return (
        <RootActionDialog
            loading={false}
            open={qcDialog === QcStationDialog.Shipping}
            setOpen={setOpen}
            hideButton={true}
            title={'Ship order'}
            content={
                <>
                    {children}
                    <Grid container justifyContent={'center'}>
                        <Button variant={'primary'} onClick={() => window.open(`/lab/${orderId}`)}>
                            Open order
                        </Button>
                    </Grid>
                </>
            }
        />
    );
};

const QCShippingDialog: React.VFC = () => {
    const isShippingDialogOpen = useQcStationSelector(s => s.qcDialog === QcStationDialog.Shipping);
    const setShippingDialogOpen = useQcStationAction('TOGGLE_SHIPPING_OPEN');
    const { labOrderFragment, mailingAddress, qcPassError: error } = useQcStationRemoteData();
    if (!labOrderFragment || !mailingAddress || error) {
        return (
            <QCShippingErrorDialog>
                <ActionCard
                    variant={'alert'}
                    title={'Failed to load shipment dialog'}
                    subtitle={'Please try shipping the order from the order page.'}
                />
            </QCShippingErrorDialog>
        );
    }

    if (labOrderFragment.status === LabsGqlLabOrderStatus.Shipped) {
        return (
            <QCShippingErrorDialog>
                <Text variant={'body1'} style={{ paddingBottom: 16 }}>
                    A shipping label has already been printed for this order. If you'd like to undo this and ship it
                    again, you can do so from the order page.
                </Text>
            </QCShippingErrorDialog>
        );
    }

    return (
        <CreateShippingLabelModal
            hideButton={true}
            isOpen={isShippingDialogOpen}
            mailingAddress={mailingAddress}
            salesOrderId={labOrderFragment.id}
            setOpen={setShippingDialogOpen}
            shippingSpeed={labOrderFragment.shipping_method}
        />
    );
};

const OrderNotFoundDisplay: React.VFC = () => {
    const orderId = useQcStationSelector(s => s.activeOrderIdSearch);
    const { labOrderLoading, labOrderFragment } = useQcStationRemoteData();
    if (!orderId || !!labOrderFragment || labOrderLoading) {
        return null;
    }
    return (
        <Grid
            container
            direction={'column'}
            style={{ padding: '8px 0 8px 16px', backgroundColor: FlossPalette.ATTENTION_BG }}
        >
            <Text variant={`body2`}>
                <i>{`No order found for search: "${orderId}"`}</i>
            </Text>
            <Text variant={`body2`}>Try searching by Order #, ID, or scannning the QR code</Text>
        </Grid>
    );
};

const QcStationRootContent: React.VFC = () => {
    const { labOrderLoading, labOrderFragment, qcPassSubmitting, qcFailSubmitting } = useQcStationRemoteData();
    const setActiveSearch = useQcStationAction('SUBMIT_ORDER_SEARCH');

    const loading = labOrderLoading || qcPassSubmitting || qcFailSubmitting;
    return (
        <Grid item container direction={`row`} style={{ height: `100vh` }}>
            <QCShippingDialog />
            <LoadBlocker blocking={loading} ContainerProps={{ wrap: 'nowrap' }}>
                <SidebarContainer
                    position={`left`}
                    style={{ padding: 0, height: `100vh`, overflow: `auto`, flexWrap: `nowrap` }}
                >
                    <Grid
                        item
                        style={{ height: 64, width: `100%`, borderBottom: `1px solid ${FlossPalette.DARK_TAN}` }}
                    >
                        <QRCodeOrderSearch setActiveSearch={setActiveSearch} />
                    </Grid>
                    <OrderNotFoundDisplay />
                    <QcStationOrderDetails />
                </SidebarContainer>
                <Grid item container style={{ flexBasis: 0, flexGrow: 1, height: `100vh`, overflow: 'hidden' }}>
                    {labOrderFragment ? <Qc3DModelViewer order={labOrderFragment} /> : <ScanToStart />}
                </Grid>
                <SidebarContainer
                    position={`right`}
                    style={{ padding: 0, height: `100vh`, flexWrap: 'nowrap', overflow: 'auto' }}
                >
                    <QcSidebarSection />
                </SidebarContainer>
                <QCStationErrorModal />
            </LoadBlocker>
        </Grid>
    );
};

export const QcStationRoot: React.VFC = () => {
    return (
        <QcStationDataProvider>
            <QcStationRootContent />
        </QcStationDataProvider>
    );
};
