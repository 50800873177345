import { LabPortalErrorPage } from '../../../LabPortalErrorPage';
import { LabPortalOrdersPage } from './LabPortalOrdersPage';
import type { TabOption } from './components/LabPortalOrderListTabs/LabPortalOrderListTabs';
import { LabPortalOnHoldListView } from './components/LabPortalOrderListViews/on-hold/LabPortalOnHoldListView';
import { convertFilterFromHumanReadableQueryParams } from './utils/QueryParamConversionUtils';
import type { LabsGqlLabPortalOnHoldOrderListCountsNullableFragment } from '@orthly/graphql-operations';
import { useLabPortalOnHoldOrderCountsQuery } from '@orthly/graphql-react';
import { LabsGqlLabPortalLabOrderSortKey } from '@orthly/graphql-schema';
import { useQueryParam } from '@orthly/ui';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

const TabOptions: TabOption[] = [{ label: 'On Hold', tabId: '', pathValue: '/on-hold', countsQueryKey: 'onHold' }];

export const LabPortalOnHoldPage: React.VFC = () => {
    const [filterString, _setFilterString] = useQueryParam('filters');

    const { data: countData } = useLabPortalOnHoldOrderCountsQuery({
        variables: {
            criteria: filterString ? convertFilterFromHumanReadableQueryParams(filterString) : [],
            sort: {},
            columns: ['onHold'],
        },
    });

    const tabOptionsWithCounts = React.useMemo(
        () =>
            TabOptions.map(option => ({
                ...option,
                orderCount:
                    countData?.orders?.[
                        option.countsQueryKey as keyof Omit<
                            LabsGqlLabPortalOnHoldOrderListCountsNullableFragment,
                            '__typename'
                        >
                    ],
            })),
        [countData],
    );
    return (
        <LabPortalOrdersPage
            title={'On Hold'}
            tabOptions={tabOptionsWithCounts}
            customSortOptions={[
                { label: 'Ship Date', value: LabsGqlLabPortalLabOrderSortKey.ShipByDate },
                { label: 'Time in List', value: LabsGqlLabPortalLabOrderSortKey.TimeInList },
            ]}
        >
            <Switch>
                <Route exact path={'/on-hold'}>
                    <LabPortalOnHoldListView />
                </Route>
                <Route>
                    <LabPortalErrorPage />
                </Route>
            </Switch>
        </LabPortalOrdersPage>
    );
};
