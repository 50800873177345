import { useManufacturerIdFromSession } from '../../../utils/authorization';
import { useCurrentManufacturerProfileQuery } from '@orthly/graphql-react';
import { LabsGqlInternalDesignAbility, LabsGqlIntegrationOrderIoCapability } from '@orthly/graphql-schema';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

type ManufacturerOptions = {
    manufacturer_id?: string;
    lab_does_design: boolean;
    has_write_integration: boolean;
    enablePartialsLabColumns: boolean;
};

export const useManufacturerOptions = (): ManufacturerOptions => {
    const manufacturer_id = useManufacturerIdFromSession();
    const { data: { profile } = {} } = useCurrentManufacturerProfileQuery({ fetchPolicy: `cache-first` });
    const lab_does_design =
        profile?.design_capability === LabsGqlInternalDesignAbility.ExternalOnly ||
        profile?.design_capability === LabsGqlInternalDesignAbility.Either;
    const has_write_integration =
        profile?.integration_configuration?.order_io_capability === LabsGqlIntegrationOrderIoCapability.ReadWrite;
    const { value: enablePartialsLabColumns = false } = useFeatureFlag(`enablePartialsLabColumns`);
    return React.useMemo(
        () => ({
            manufacturer_id,
            lab_does_design,
            has_write_integration,
            enablePartialsLabColumns,
        }),
        [manufacturer_id, lab_does_design, has_write_integration, enablePartialsLabColumns],
    );
};
