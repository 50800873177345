import { OrderFilterIdEnum } from '@orthly/shared-types';
import { SimpleMultiSelect } from '@orthly/ui';
import { Button, Grid, Popover } from '@orthly/ui-primitives';
import React from 'react';

export type FilterOption = { label: string; value: string };

export const LabPortalOrderListViewFilterButton: React.VFC<{
    // This controls what filters tags are displayed on the on hold page
    filters: string[];
    setFilters: (newFieldIds: string[]) => void;
    customFilterOptions?: FilterOption[];
}> = ({ filters, setFilters, customFilterOptions = [] }) => {
    const [filtersOpen, setFiltersOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement | null>(null);
    return (
        <Grid>
            <Button ref={anchorRef} variant={'primary'} onClick={() => setFiltersOpen(filtersOpen => !filtersOpen)}>
                Filters
            </Button>
            <Popover
                anchorEl={anchorRef.current}
                open={filtersOpen}
                PaperProps={{
                    style: { width: '400px' },
                }}
            >
                <SimpleMultiSelect
                    options={[
                        ...customFilterOptions,
                        { label: 'Product Line', value: OrderFilterIdEnum.order_product_line },
                    ]}
                    onChange={selected => setFilters(selected ?? [])}
                    value={filters ?? []}
                    label={'Filter by'}
                    SelectProps={{ open: filtersOpen, onClose: () => setFiltersOpen(false), variant: 'standard' }}
                />
            </Popover>
        </Grid>
    );
};
