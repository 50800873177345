import { DANDY_TEXAS_MANUFACTURER_ID, LEHI_MANUFACTURER_ID } from '@orthly/shared-types';
import { useFeatureFlag } from '@orthly/veneer';

export function isOvernightServiceLevel(serviceLevel: string): boolean {
    return serviceLevel.toLowerCase().includes('next_day') || serviceLevel.toLowerCase().includes('overnight');
}

/**
 * Get the label for a shelf based on the service level and shelf number
 * @param {string} serviceLevel - Shipping service level
 * @param {string | null} shelfNumber - Shelf assignment based on the manufacturer / practice
 * @returns {string}
 */
export function getShelfLabel(serviceLevel: string, shelfNumber: string | null): string {
    if (isOvernightServiceLevel(serviceLevel)) {
        return 'Next Day';
    }
    if (shelfNumber === null) {
        return 'Standard';
    }
    return `Shelf #${shelfNumber}`;
}

const shelfCountByLabId: { [labId: string]: number } = {
    // there are 29 slots in the Lehi shipping area
    [LEHI_MANUFACTURER_ID]: 29,
    // dallas has 50 slots
    [DANDY_TEXAS_MANUFACTURER_ID]: 50,
};

/**
 * Helper to account for the numbering system at Dandy Texas. They wanted letters too - A1-10, B11-20, etc (UGH)
 */
function getShelfDisplayLabel(shelfNumber: number, manufacturerId: string): string {
    if (manufacturerId === DANDY_TEXAS_MANUFACTURER_ID) {
        const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'];
        // anything up to 10 gets A, 11-20 gets B, etc. Subtract 1 to be zero-indexed
        const shelfLetterIdx = Math.ceil(shelfNumber / 10) - 1;
        const shelfLetter = letters[shelfLetterIdx] ?? '';
        return `${shelfLetter}${shelfNumber}`;
    }
    return `${shelfNumber}`;
}

/**
 * Does a deterministic assignment of addresses to shelves based on their numeric ID (via: ADDRESS_NUM % SHELF_COUNT).
 * This works because the distribution of batch shipments by address ID is (roughly) normal distributed. That's great
 * because it means we dont need to store shelf assignment state which could easily get out sync with the real world.
 * @see sample data here: https://docs.google.com/spreadsheets/d/1sLCzHsBVgRtJENrGmFsbYSLRZeVM8toekR-2u_p5r8E/edit#gid=544645286
 */
export function getShelfNumberForAddress(addressIdNumber: number | null, labId: string | null): string | null {
    // only enabled for Lehi at the moment
    if (typeof addressIdNumber !== 'number' || !labId) {
        return null;
    }
    const shelfCount = shelfCountByLabId[labId];
    if (typeof shelfCount !== 'number' || shelfCount === 0) {
        return null;
    }
    // this should be an Int but just to be safe. Assign to "Shelf 23.5" would be confusing for everyone
    const shelfBucket = Math.floor(addressIdNumber) % shelfCount;
    // add one to the bucket to make the shelf number 1-indexed
    return getShelfDisplayLabel(shelfBucket + 1, labId);
}

// List of practice IDs that are in the pilot for the new packaging format. This is a temporary solution for the test.
// Source: https://docs.google.com/spreadsheets/d/1suveEhg6eYo9YxdwZUVJCSvqxhMUgoaKCOD6SjY_4fE/edit?gid=370748399#gid=370748399
const NewPackagingPracticeIds: string[] = [
    '8fefe3b3-6d83-4052-b743-acd1f6ae9cb5',
    'aff84a19-0975-4427-8c84-b99c1d67c718',
    '45ce5b5d-fdc1-454a-b82f-adf67995b149',
    'b3cbddde-ddc0-487b-b0c0-f7d610d2166e',
    '84c515bc-40f6-4ebe-b547-849bb16aa76d',
    'f4850858-65c3-4adf-88c7-e4c478b212fc',
    '4e10974d-e83a-40a0-93ab-7a4c48341baa',
    'f85367d8-22ed-475b-9664-4a71b804ebe8',
    '9611fa49-0e7f-400c-a284-16e57fd54880',
    '8646de8a-8f79-4b0a-8b16-c269d1b5fdc0',
    '40bbc74c-e4c7-4076-91bb-fab78d6820d0',
    '004a0867-8841-4892-8f4f-37dd18916210',
    'f127f8ec-5ed2-432d-97eb-39433f0e5f10',
    'aac7ea2a-9081-4d9e-9fa3-efc2cdf92815',
    '5daeb489-3687-4556-a643-5417c61ed332',
    '5280b9ba-e93c-4952-aa0b-7cf550df2cfb',
    'c2cf46ec-a7fa-4b34-b092-c4ce35f93ed0',
    'a7a5f279-862d-47ce-b827-91ec5ca0b796',
    '50210658-9ef7-4c43-8a4d-b9f5ef754071',
    '5fec6261-1156-42d1-9eec-ddeb27920702',
    'a60de73e-808a-4961-95b5-823214e511ce',
    'fe0f0a46-5dda-4084-9735-f9c61b6ddf31',
    'c9da0551-fb39-446a-9530-928519b1fd08',
    '7267dbd8-2867-4a3f-b84c-2e8c973b7d16',
    '72534b0d-250a-4c85-b6a5-40f19e1ef3b1',
    'b188c7d7-9b8e-4f45-aa32-5a490a9380ff',
    'e442294c-66ff-4c9b-8eef-9773de618bae',
    'be746eed-71bf-4f1d-a430-edef1367b5c3',
    '7f373d17-8f4b-4aa6-a843-809eb9b9f40a',
    '83ced908-ca53-45ff-88b3-751a96f63156',
    '3f63e7cf-4313-46dc-b8cf-7982a2ad0475',
    '7395d0a0-61fd-463e-8859-ff088c1b7fe8',
    '7db230bc-7ad2-4e1b-8eab-010cad8f5f80',
    '576a5c9e-8047-4c52-97f8-d826a042a3a9',
    'dedea774-dab7-4125-8f05-48af9abff805',
    '7cc8320b-a37f-4ae8-a6ea-69afe20164b0',
    '842e2954-db02-4beb-ba96-a350c051018e',
    '332ae253-e7fc-4fde-83b5-71ff1d28d317',
    '93608374-ed84-4756-a8d6-210cd5ee687d',
    '1099b1a8-35fc-4ce4-8f8e-48d4738e2578',
    '4fc589da-7019-46fc-8cd9-3e9273af5e46',
    'af895ee7-a5dc-4c77-90df-e2bd4050478f',
    '93eb60d4-abed-46c8-b9f5-48f97b9b6a7c',
    '7212d3a0-21de-4bad-bada-9934ee1049a5',
    'b1be408d-16b3-467c-92d6-75f096d8ba99',
    'e96f4343-4862-44ac-a6b6-3e21cc32fd36',
    'b6e6b55e-c5dc-42e1-96f5-d472bdac8dcd',
    '2e2ab0f8-855e-4010-95c5-a28dc22e3d44',
    'c3681369-8a70-46a9-974b-027aa5a212de',
    'a0968bbb-ec04-4679-ae69-13f13427e47d',
];

/**
 * Hook to determine if the new packaging format is enabled for a given practice
 * @param {string} practiceId
 * @returns {boolean}
 */
export function useIsNewPackagingEnabled(practiceId: string): boolean {
    const { value: rolloutStatus = 'none' } = useFeatureFlag('newPackagingRolloutStatus');
    switch (rolloutStatus) {
        case 'all':
            return true;
        case 'none':
            return false;
        case 'testPracticesOnly':
            return NewPackagingPracticeIds.includes(practiceId);
    }
    return false;
}
