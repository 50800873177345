import { LabInboxBulkActionIconButton } from '../../../../components/bulk-actions/LabInboxBulkActionIconButton';
import type { LabPortalOrderActionProps } from './LabPortalOrderActions.types';
import type { DocumentNode } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { Format } from '@orthly/runtime-utils';
import { useChangeSubmissionFn, makeLazyDialog, RootActionDialog } from '@orthly/ui';
import { FlossPalette, Button, CheckIcon } from '@orthly/ui-primitives';
import { useSnackbar } from 'notistack';
import React from 'react';

const MarkOrdersIntakeCompletedByLabOrderIdsMutation = graphql(`
    mutation MarkOrdersIntakeCompletedByLabOrderIds($labOrderIds: [String!]!) {
        markOrdersIntakeCompletedByLabOrderIds(labOrderIds: $labOrderIds)
    }
`);

type BulkCompleteOrderIntakeResult = {
    errorCount: number;
    skippedCount: number;
    successCount: number;
};

function useBulkCompleteOrderIntakeAction(refetchOrders: () => Promise<any>) {
    const { enqueueSnackbar } = useSnackbar();

    const [submitMtn] = useMutation(MarkOrdersIntakeCompletedByLabOrderIdsMutation as DocumentNode);

    const runUpload = React.useCallback(
        async (orderIds: string[]): Promise<BulkCompleteOrderIntakeResult> => {
            const result: BulkCompleteOrderIntakeResult = { errorCount: 0, successCount: 0, skippedCount: 0 };
            const { data, errors } = await submitMtn({ variables: { labOrderIds: orderIds } });

            if (errors) {
                result.errorCount = orderIds.length;
            } else {
                const successCount = data?.markOrdersIntakeCompletedByLabOrderIds ?? 0;
                result.successCount = successCount;
                result.skippedCount = orderIds.length - successCount;
            }

            return result;
        },
        [submitMtn],
    );
    return useChangeSubmissionFn<BulkCompleteOrderIntakeResult, [string[]]>(runUpload, {
        onSuccess: async result => {
            const errorCount = result.errorCount;
            const successCount = result.successCount;
            const skippedCount = result.skippedCount;
            const totalCount = errorCount + successCount + skippedCount;
            if (errorCount > 0) {
                const countLabel = totalCount > 1 ? `${errorCount} of ${totalCount} orders` : `order`;
                enqueueSnackbar(`Failed to complete intake for ${countLabel}, please retry`, {
                    variant: 'error',
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                });
            } else {
                const skippedMessage =
                    skippedCount > 0 ? ` Skipped ${Format.pluralize('invalid order', skippedCount)}` : '';
                enqueueSnackbar(`Completed intake for ${Format.pluralize('order', successCount)}!${skippedMessage}`, {
                    variant: 'success',
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                });
            }
            await refetchOrders();
        },
    });
}

const useBulkCompleteOrderIntakeDialog = makeLazyDialog<{ submit: () => Promise<void>; orderLabel: string }>(props => {
    const { submit, open, setOpen, orderLabel } = props;

    return (
        <RootActionDialog
            title={`Please make sure you have printed all lab slips before completing intake for ${orderLabel}?`}
            open={open}
            setOpen={setOpen}
            loading={false}
            buttonText={``}
            content={
                <Button
                    variant={'primary'}
                    fullWidth={true}
                    onClick={async () => {
                        await submit();
                        setOpen(false);
                    }}
                >
                    Accept Orders
                </Button>
            }
        />
    );
});

export const LabPortalCompleteOrderIntakeAction: React.VFC<LabPortalOrderActionProps> = props => {
    const { selectedOrders, refetch, disabled } = props;
    const { submit, submitting } = useBulkCompleteOrderIntakeAction(refetch);

    const submitOrders = React.useCallback(async () => {
        await submit(selectedOrders.map(o => o.id));
    }, [selectedOrders, submit]);

    const itemsLabel = props.onSingleRow ? '1 order' : Format.pluralize('order', selectedOrders.length);

    const [setBulkAcceptDialogOpen, bulkCompleteIntakeDialog] = useBulkCompleteOrderIntakeDialog({
        submit: submitOrders,
        orderLabel: itemsLabel,
    });

    return (
        <>
            <LabInboxBulkActionIconButton
                onClick={() => setBulkAcceptDialogOpen(true)}
                loading={submitting}
                disabled={disabled ?? false}
                disableTooltip={disabled ?? false}
                selectionCount={selectedOrders.length}
                tooltipTitle={`Mark ${itemsLabel} intake complete`}
            >
                <CheckIcon
                    style={{ color: selectedOrders.length === 0 || submitting ? undefined : FlossPalette.STAR_GRASS }}
                />
            </LabInboxBulkActionIconButton>
            {bulkCompleteIntakeDialog}
        </>
    );
};
