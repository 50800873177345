/* eslint-disable max-lines */
import { ManufacturerBillingAdjustmentImportDialog } from '../components/ManufacturerBillingAdjustmentImport';
import { BillingColumn } from './BillingColumn';
import { CancelledLabOrders } from './CancelledLabOrders';
import { OrderDetailViewerDialog } from './OrderDetailViewerDialog.graphql';
import { LedgerEntryReviewToggle } from './ReviewLabCaseLedgerToggle';
import { HighlightChanged, OrderTotalPrice, TotalPrice } from './TotalPrice';
import type {
    BillingDetailPanelTableData,
    BillingDetailTableData,
    BillingMonthDetailRootProps,
    LedgerTabDefinition,
} from './types';
import { useStyles } from './types';
import { ledgerToCsvRows } from './utils';
import { differenceInBusinessDays } from '@orthly/date-fns-business-days';
import type {
    LabsGqlLabPriceLedgerSummaryResultFragment as LabPriceLedgerSummary,
    LabsGqlOverrideLabCaseUnitPriceMutationVariables,
} from '@orthly/graphql-operations';
import {
    useOverrideLabCaseUnitPriceMutation,
    useToggleLabCaseLedgerEntryReviewedMutation,
    useGetLabCaseLedgerDataQuery,
    useGetLabCaseLedgerSummaryQuery,
    useBulkOverrideLabUnitPricesMutation,
} from '@orthly/graphql-react';
import { LabsGqlLabPriceLedgerOverrideReason } from '@orthly/graphql-schema';
import { downloadAsCsv, MUITable } from '@orthly/mui-table';
import { Format } from '@orthly/runtime-utils';
import { LoadBlocker, QuickForm, RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import {
    FlossPalette,
    Text,
    Tab,
    Tabs,
    Grid,
    IconButton,
    InputAdornment,
    Tooltip,
    EditIcon,
    InfoIcon,
} from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import classNames from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { z } from 'zod';

function useLabOverrideOrderUnitPrice(onSuccess?: () => void) {
    const [submitMtn] = useOverrideLabCaseUnitPriceMutation();
    const mtnSubmitter = (variables: LabsGqlOverrideLabCaseUnitPriceMutationVariables) => submitMtn({ variables });
    return useChangeSubmissionFn<any, [LabsGqlOverrideLabCaseUnitPriceMutationVariables]>(mtnSubmitter, {
        onSuccess,
        closeOnComplete: true,
        successMessage: () => ['Unit price adjustment saved!', {}],
    });
}

function priceOverridePayload(
    data: BillingDetailPanelTableData,
    result: { unit_price_dollars: number | null; description: string },
) {
    if (result.unit_price_dollars === null) {
        return null;
    }
    const price_cents_override = Math.round(result.unit_price_dollars * 100);
    return {
        data: {
            price_cents_override,
            ledger_id: data.id,
            order_id: data.order_id,
            fulfillment_number: data.fulfillment_number,
            price_idx: data.price_idx,
            price_id: data.price_id,
            override_description: result.description,
        },
    };
}

interface EditOrderUnitPriceProps {
    data: BillingDetailPanelTableData;
    pricesAdjustable: boolean;
    refetch: () => Promise<void>;
}

const EditOrderUnitPrice: React.FC<EditOrderUnitPriceProps> = props => {
    const { data, pricesAdjustable, refetch } = props;
    const unitPriceDollars = data.unit_price_cents / 100;
    const unitPriceDollarsString = Format.currency(unitPriceDollars, 'dollars');
    const originalUnitPriceDollars = data.original_unit_price_cents / 100;
    const originalUnitPriceDollarsString = Format.currency(originalUnitPriceDollars, 'dollars');
    const { value: enableLabBillingPriceOverrides } = useFeatureFlag('enableLabBillingPriceOverrides');
    const { submit, submitting, open, setOpen } = useLabOverrideOrderUnitPrice();
    const CustomButton = React.useCallback(() => {
        return (
            <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <HighlightChanged
                    text={unitPriceDollarsString}
                    changed={data.unit_price_cents !== data.original_unit_price_cents}
                />
                <IconButton onClick={() => setOpen(true)} disabled={!pricesAdjustable}>
                    <EditIcon />
                </IconButton>
            </Grid>
        );
    }, [data.unit_price_cents, data.original_unit_price_cents, unitPriceDollarsString, pricesAdjustable, setOpen]);
    if (!enableLabBillingPriceOverrides) {
        return (
            <HighlightChanged
                text={unitPriceDollarsString}
                changed={data.unit_price_cents !== data.original_unit_price_cents}
            />
        );
    }
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={
                <div>
                    <p>Adjust Order Unit Price</p>
                    <Text variant={'body2'}>Original calculated price: {originalUnitPriceDollarsString}</Text>
                </div>
            }
            buttonText={''}
            CustomButton={CustomButton}
            content={
                <QuickForm<{ unit_price_dollars: number | null; description: string }>
                    fields={{
                        unit_price_dollars: {
                            label: 'Adjusted Unit Price',
                            type: 'number',
                            fieldProps: {
                                InputProps: {
                                    startAdornment: <InputAdornment position={'start'}>$</InputAdornment>,
                                },
                            },
                            validation: z.number().min(0, 'Unit price must not be negative'),
                        },
                        description: {
                            label: 'Adjustment reason',
                            type: 'text',
                            validation: z.string().min(30, 'Please enter at least 30 characters'),
                        },
                    }}
                    initialValues={{
                        unit_price_dollars: unitPriceDollars === originalUnitPriceDollars ? null : unitPriceDollars,
                        description: data.existing_override_description ?? '',
                    }}
                    onSubmit={async result => {
                        const payload = priceOverridePayload(data, result);
                        if (payload) {
                            await submit(payload);
                            await refetch();
                        }
                    }}
                    dirtySubmitOnly={true}
                />
            }
        />
    );
};

interface BillingOrderDetailPanelProps {
    data: BillingDetailTableData;
    pricesAdjustable: boolean;
    refetch: () => Promise<void>;
}

const BillingOrderDetailPanel: React.FC<BillingOrderDetailPanelProps> = props => {
    const { pricesAdjustable, refetch } = props;
    const { order_id, fulfillment_number, prices, id } = props.data;
    const data: BillingDetailPanelTableData[] = prices.map((price, index) => {
        const existing_override =
            price.price_override && price.price_override.reason === LabsGqlLabPriceLedgerOverrideReason.LabAdjustment
                ? price.price_override
                : null;
        return {
            id,
            order_id,
            fulfillment_number,
            price_idx: index,
            price_id: price.price_id,
            price_name: price.price_name,
            unit_price_cents: price.price_cents,
            count: price.lab_unit_count,
            order_price_cents: price.total_price_cents,
            original_unit_price_cents: existing_override?.original_price_cents ?? price.price_cents,
            existing_override_description: existing_override?.description ?? null,
        };
    });
    return (
        <MUITable<BillingDetailPanelTableData>
            title={'Prices'}
            displayOptions={{ elevation: 0 }}
            columns={[
                { name: 'id', hidden: true, render: 'id' },
                { name: 'order_id', hidden: true, render: 'order_id' },
                { name: 'fulfillment_number', hidden: true, render: 'fulfillment_number' },
                { name: 'price_idx', hidden: true, render: 'price_idx' },
                { name: 'price_id', hidden: true, render: 'price_id' },
                { name: 'original_unit_price_cents', hidden: true, render: 'original_unit_price_cents' },
                { name: 'existing_override_description', hidden: true, render: 'existing_override_description' },
                { name: 'Product', render: 'price_name' },
                { name: 'Count', render: 'count' },
                {
                    name: 'Unit Price',
                    render: r => <EditOrderUnitPrice data={r} pricesAdjustable={pricesAdjustable} refetch={refetch} />,
                },
                { name: 'Total Price', render: r => <TotalPrice data={r} /> },
            ]}
            data={data}
        />
    );
};

const ToggleLedgerEntryReview: React.FC<{
    priceLedger: {
        id: string;
        reviewed: boolean;
    };
}> = props => {
    const { priceLedger } = props;
    const [submitMtn] = useToggleLabCaseLedgerEntryReviewedMutation({
        variables: {
            data: {
                ledger_id: priceLedger.id,
                target_value: !priceLedger.reviewed,
            },
        },
    });
    const { submit, submitting } = useChangeSubmissionFn<any, []>(() => submitMtn(), {
        closeOnComplete: true,
        successMessage: () => ['Updated!', {}],
        onSuccess: () => {
            priceLedger.reviewed = !priceLedger.reviewed;
        },
    });
    return <LedgerEntryReviewToggle checked={priceLedger.reviewed} submit={submit} submitting={submitting} />;
};

function useBulkPriceAdjustmentV2() {
    const [submitMtn, { loading, data: mtnResult }] = useBulkOverrideLabUnitPricesMutation();
    return {
        submitMtn,
        loading,
        success: !!mtnResult?.bulkOverrideLabUnitPrices,
        errors: mtnResult?.bulkOverrideLabUnitPrices.errors ?? [],
    };
}

const BillingLedgerRoot: React.FC<BillingMonthDetailRootProps> = props => {
    const { summary, ledger, prices_adjustable, loading, refetch } = props;
    const styles = useStyles();
    const totalPriceCents = _.sumBy(ledger, e => _.sumBy(e.lab_prices, p => p.total_price_cents));
    const title = summary
        ? `Fulfilled (${Format.number(summary.orders_count)} orders, ${Format.currency(
              totalPriceCents,
              'cents',
          )} total)`
        : 'Fulfilled';
    const { value: enableLabBillingPriceOverrides = false } = useFeatureFlag('enableLabBillingPriceOverrides');
    const { value: enableLabBillingPriceBulkOverrides = false } = useFeatureFlag('enableLabBillingPriceBulkOverrides');
    const { value: enableReviewLabOrderPrice } = useFeatureFlag('enableReviewLabOrderPrice');
    const [uploadModalOpen, setUploadModalOpen] = React.useState<boolean>(false);

    return (
        <Grid container className={styles.mainContainer}>
            <LoadBlocker blocking={loading}>
                <ManufacturerBillingAdjustmentImportDialog
                    ledger={ledger}
                    loading={loading}
                    refetch={refetch}
                    open={uploadModalOpen}
                    setOpen={setUploadModalOpen}
                    bulkAdjustmentFn={useBulkPriceAdjustmentV2}
                />
                <MUITable<BillingDetailTableData>
                    title={title}
                    displayOptions={{ download: false, sort: true, search: true, filterValues: true, elevation: 0 }}
                    columns={[
                        { name: 'id', hidden: true, render: 'id' },
                        { name: 'order_id', hidden: true, render: 'order_id' },
                        {
                            name: 'Date Shipped',
                            render: row => dayjs(row.order_ship_date).format('MM/DD/YYYY'),
                            defaultSort: 'desc',
                        },
                        { name: 'Order #', render: 'order_number' },
                        { name: 'Patient Name', render: 'patient_name' },
                        { name: 'Total', render: row => <OrderTotalPrice data={row} /> },
                        {
                            name: 'View Order',
                            render: row => <OrderDetailViewerDialog order_id={row.order_id} />,
                        },
                        { name: 'fulfillment_number', hidden: true, render: 'fulfillment_number' },
                        { name: 'Prices', hidden: true, render: 'prices' },
                        { name: 'has_lab_adjustment', hidden: true, render: 'has_lab_adjustment' },
                        {
                            name: 'Reviewed',
                            title: (
                                <>
                                    <>Reviewed</>
                                    <Tooltip
                                        title={
                                            'This helps you track the items that you have previously marked as reviewed, ' +
                                            'but does not affect your actual invoices and payments.'
                                        }
                                    >
                                        <InfoIcon
                                            style={{ padding: 4, marginRight: 8, color: FlossPalette.STAR_GRASS }}
                                        />
                                    </Tooltip>
                                </>
                            ),
                            hidden: !enableReviewLabOrderPrice,
                            customSort: (a, b, direction) => {
                                if (direction === 'asc') {
                                    return Number(a.reviewed) - Number(b.reviewed);
                                }
                                return Number(b.reviewed) - Number(a.reviewed);
                            },
                            render: r => {
                                return <ToggleLedgerEntryReview priceLedger={r} />;
                            },
                        },
                    ]}
                    data={ledger.map(e => ({
                        id: e.id,
                        order_id: e.order_id,
                        order_number: e.order_number,
                        fulfillment_number: e.fulfillment_number,
                        order_ship_date: dayjs(e.ship_date).toDate(),
                        patient_name: `${e.patient_first_name} ${e.patient_last_name}`,
                        order_price_cents: _.sumBy(e.lab_prices, p => p.total_price_cents),
                        prices: e.lab_prices,
                        reviewed: e.reviewed,
                        has_lab_adjustment: e.lab_prices.some(
                            p =>
                                p.price_override &&
                                p.price_override.reason === LabsGqlLabPriceLedgerOverrideReason.LabAdjustment,
                        ),
                    }))}
                    DetailPanel={r => (
                        <BillingOrderDetailPanel
                            data={r.data}
                            pricesAdjustable={!!prices_adjustable}
                            refetch={refetch}
                        />
                    )}
                    actions={{
                        global: [
                            {
                                hidden: !(
                                    enableLabBillingPriceOverrides &&
                                    enableLabBillingPriceBulkOverrides &&
                                    prices_adjustable
                                ),
                                position: 'toolbar',
                                onClick: () => setUploadModalOpen(true),
                                icon: 'upload',
                                tooltip: 'Upload',
                            },
                            {
                                position: 'toolbar',
                                onClick: () => downloadAsCsv(ledgerToCsvRows(ledger), 'Invoice_Export'),
                                icon: 'download',
                                tooltip: 'Download CSV',
                            },
                            { position: 'toolbar', onClick: refetch, icon: 'refresh', tooltip: 'Refresh' },
                        ],
                    }}
                />
            </LoadBlocker>
        </Grid>
    );
};

export const ManufacturerBillingContent: React.VFC = () => {
    const { data, loading: ledgerSummaryLoading } = useGetLabCaseLedgerSummaryQuery();
    const ledgerSummary = React.useMemo<LabPriceLedgerSummary[]>(() => {
        return data?.getLabCaseLedgerSummary ?? [];
    }, [data]);
    const ledgerSummaryByMonth = React.useMemo<Record<string, LabPriceLedgerSummary>>(() => {
        return _.keyBy<LabPriceLedgerSummary>(ledgerSummary, e => dayjs(e.month).toISOString());
    }, [ledgerSummary]);

    // month selector
    const [month, setMonth] = React.useState<string | null>(null);
    const date_range = React.useMemo<{ begin: Date; end: Date } | null>(() => {
        return !!month
            ? {
                  begin: dayjs(month).utc().toDate(),
                  end: dayjs(month).utc().endOf('month').toDate(),
              }
            : null;
    }, [month]);

    const {
        data: ledgerDataForMonthRaw,
        loading: ledgerDataLoading,
        refetch: refetchLedgerForMonth,
    } = useGetLabCaseLedgerDataQuery({
        skip: month === null,
        variables: {
            date_range: date_range
                ? { begin: date_range.begin.toISOString(), end: date_range.end.toISOString() }
                : null,
        },
    });
    const ledgerDataForMonth = ledgerDataForMonthRaw?.getLabCaseLedgerData ?? [];
    const ledgerSummaryForMonth = month ? ledgerSummaryByMonth[month] ?? null : null;

    const { value: enableCancelledLabCases } = useFeatureFlag('enableCancelledLabCases');
    const { value: labBillingOverrideMaxDaysAfterEOM = 0 } = useFeatureFlag('labBillingOverrideMaxDaysAfterEOM');
    const dateEndAfterToday = date_range?.end && dayjs(date_range.end) > dayjs();
    const allowedToOverride =
        date_range?.end &&
        differenceInBusinessDays(dayjs(date_range.end).toDate(), new Date()) <= labBillingOverrideMaxDaysAfterEOM;
    const pricesAdjustable = dateEndAfterToday || allowedToOverride;
    const title = ledgerSummaryForMonth
        ? `Billing Overview - ${dayjs(ledgerSummaryForMonth.month).utc().format('MMMM YYYY')}`
        : 'Billing Overview';

    const ledgerTabs = {
        Fulfilled: {
            label: 'Fulfilled',
            hidden: false,
            onTabSwitch: () => refetchLedgerForMonth(), // refetch when switching tabs
        } as LedgerTabDefinition,
        Cancelled: {
            label: 'Canceled',
            hidden: !pricesAdjustable || !enableCancelledLabCases,
            onTabSwitch: () => {},
        } as LedgerTabDefinition,
    };

    type LedgerTab = keyof typeof ledgerTabs;

    const classes = useStyles();
    const [activeTab, setActiveTab] = React.useState<LedgerTab>('Fulfilled');

    return (
        <Grid container className={classes.root}>
            <BillingColumn ledgers={ledgerSummaryByMonth} loading={ledgerSummaryLoading} onClick={setMonth} />
            {!!month && (
                <Grid container direction={'column'} className={classes.mainContainer}>
                    <Text variant={'h5'} style={{ fontWeight: 500, paddingTop: 24, paddingLeft: 12 }}>
                        {title}
                    </Text>
                    <Grid item>
                        <Tabs
                            indicatorColor={'secondary'}
                            textColor={'inherit'}
                            onChange={(_, tab: LedgerTab) => {
                                setActiveTab(tab);
                                ledgerTabs[tab].onTabSwitch();
                            }}
                            value={activeTab}
                        >
                            {Object.entries(ledgerTabs).map(
                                ([value, { label, hidden }]) =>
                                    !hidden && (
                                        <Tab
                                            data-test={`ledger-tab-${value}`}
                                            key={value}
                                            label={label}
                                            value={value}
                                        />
                                    ),
                            )}
                        </Tabs>
                    </Grid>
                    <Grid item className={classNames(classes.contentContainer)}>
                        {activeTab === 'Fulfilled' && (
                            <BillingLedgerRoot
                                summary={ledgerSummaryForMonth}
                                ledger={ledgerDataForMonth}
                                prices_adjustable={!!pricesAdjustable}
                                period_begin={date_range?.begin ?? null}
                                period_end={date_range?.end ?? null}
                                loading={ledgerSummaryLoading || ledgerDataLoading}
                                refetch={refetchLedgerForMonth}
                            />
                        )}
                        {activeTab === 'Cancelled' && (
                            <CancelledLabOrders
                                summary={ledgerSummaryForMonth}
                                ledger={ledgerDataForMonth}
                                period_begin={date_range?.begin ?? null}
                                period_end={date_range?.end ?? null}
                                loading={ledgerSummaryLoading || ledgerDataLoading}
                                refetch={refetchLedgerForMonth}
                            />
                        )}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};
