import { useViewModel } from './DeleteShipment.vm';
import { type LabsGqlLabOrderStatus as OrderStatus } from '@orthly/graphql-schema';
import { LoadBlocker, TrashIcon } from '@orthly/ui';
import { IconButton, Tooltip } from '@orthly/ui-primitives';
import React from 'react';

type Props = {
    loading: boolean;
    onSubmit: () => void;
};

/** Button for deleting a shipment, with confirmation pop-up. */
const Button: React.VFC<Props> = ({ loading, onSubmit }) => {
    return (
        <LoadBlocker blocking={loading} ContainerProps={{ style: { width: 'auto', marginLeft: 8 } }}>
            <Tooltip title={'Remove shipment'}>
                <IconButton
                    size={'small'}
                    disabled={loading}
                    onClick={() => window.confirm('Remove shipment?') && onSubmit()}
                >
                    <TrashIcon style={{ width: 16, height: 16 }} />
                </IconButton>
            </Tooltip>
        </LoadBlocker>
    );
};

type WithVmProps = {
    manufacturerId: string;
    orderStatus: OrderStatus;
    salesOrderId: string;
    shipDate: Date | null;
    shippedByLab: boolean | null;
};

/** Button with view model for deleting a shipment, with confirmation pop-up. */
export const DeleteShipmentButton: React.VFC<WithVmProps> = ({
    manufacturerId,
    orderStatus,
    salesOrderId,
    shipDate,
    shippedByLab,
}) => {
    const { enabled, submit, submitting } = useViewModel({
        manufacturerId,
        orderStatus,
        salesOrderId,
        shipDate,
        shippedByLab,
    });
    return enabled ? <Button loading={submitting} onSubmit={submit} /> : null;
};

export const forStorybook = { Button };
