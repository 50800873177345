import { LOGIN_ROUTE } from './routes';
import {
    BATCH_SHIPMENTS_ROUTE,
    BILLING_ROUTE,
    METAL_PRICING_ROUTE,
} from './screens/manufacturer/ManufacturerAppRoutes';
import {
    INTAKE_WITH_DESIGN_ROUTE,
    INTAKE_WITHOUT_DESIGN_ROUTE,
    NEW_WITH_DESIGN_ROUTE,
    NEW_WITHOUT_DESIGN_ROUTE,
} from './screens/manufacturer/screens/LabPortalOrderListPages/LabPortalRoutes';
import { reduxHistory, store } from './store';
import { LabPortalGqlProvider } from './utils/LabPortalGqlProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { RetainerSessionProvider } from '@orthly/session-client';
import { OrthlyEnvironment } from '@orthly/shared-types';
import type { OrthlyBrowserConfig } from '@orthly/ui';
import {
    ReloadStalePages,
    OrthlyErrorBoundary,
    LoadBlocker,
    StyledSnackbarProvider,
    OrthlyConfigProvider,
    OrthlyFrontendApps,
} from '@orthly/ui';
import { DandyThemeProvider, Grid } from '@orthly/ui-primitives';
import { ConnectedFirebaseProvider, DandyStatsigProvider } from '@orthly/veneer';
import { ConnectedRouter } from 'connected-react-router';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

const ImpersonateLoadingFallback: React.FC = () => {
    return (
        <LoadBlocker blocking={true} loader={'dandy'}>
            <Grid container style={{ height: '100vh', width: '100vw' }} />
        </LoadBlocker>
    );
};

const LabPortalSession: React.FC = props => {
    const { enqueueSnackbar } = useSnackbar();
    return (
        <RetainerSessionProvider
            allowedOrgTypes={['practice', 'lab']}
            allowImpersonate
            showNotification={(msg, isError) => {
                enqueueSnackbar(msg, { variant: isError ? 'error' : undefined });
            }}
            TokenExchangeLoadingFallback={ImpersonateLoadingFallback}
        >
            <DandyStatsigProvider>
                <ConnectedFirebaseProvider
                    appName={OrthlyFrontendApps.labPortal}
                    envOverride={process.env.REACT_APP_LOCAL_PROD ? OrthlyEnvironment.production : undefined}
                >
                    <QueryParamProvider ReactRouterRoute={Route}>{props.children}</QueryParamProvider>
                </ConnectedFirebaseProvider>
            </DandyStatsigProvider>
        </RetainerSessionProvider>
    );
};

export const LabPortalRootProvider: React.FC<{ config: OrthlyBrowserConfig }> = props => {
    return (
        <DandyThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <OrthlyErrorBoundary componentName={'PracticeAppRoot'} FallbackComponent={() => null}>
                    <OrthlyConfigProvider config={props.config}>
                        <StyledSnackbarProvider
                            maxSnack={1}
                            autoHideDuration={3500}
                            // Parker: this is lazy but i cant figure out what has an obscene z-index so gotta beat it
                            style={{ zIndex: 100000 }}
                        >
                            <Provider store={store as any}>
                                <ConnectedRouter history={reduxHistory}>
                                    <ReloadStalePages
                                        safeToReloadRoutes={[
                                            LOGIN_ROUTE,
                                            '/',
                                            BATCH_SHIPMENTS_ROUTE,
                                            BILLING_ROUTE,
                                            METAL_PRICING_ROUTE,
                                            INTAKE_WITH_DESIGN_ROUTE,
                                            INTAKE_WITHOUT_DESIGN_ROUTE,
                                            NEW_WITH_DESIGN_ROUTE,
                                            NEW_WITHOUT_DESIGN_ROUTE,
                                        ]}
                                        maxAgeInDays={1}
                                    >
                                        <LabPortalGqlProvider>
                                            <LabPortalSession>{props.children}</LabPortalSession>
                                        </LabPortalGqlProvider>
                                    </ReloadStalePages>
                                </ConnectedRouter>
                            </Provider>
                        </StyledSnackbarProvider>
                    </OrthlyConfigProvider>
                </OrthlyErrorBoundary>
            </LocalizationProvider>
        </DandyThemeProvider>
    );
};
