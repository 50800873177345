import { AnalyticsClient } from '../../../../analytics/analyticsClient';
import { filterPartials, mapClasp, mapCoverageType } from './EditPartialDenture.util';
import { useUpdatePartialDentureMetadataMutation } from '@orthly/graphql-react';
import { type LabsGqlUpdatePartialDentureMetadataCommand as UpdatePartialDentureCommand } from '@orthly/graphql-schema';
import {
    type IOrderItemPartialDentureClasp as PartialDentureClasp,
    type IOrderItemV2DTO,
    type IPartialDentureItem,
    type OrderItemPartialDentureCoverageType as CoverageType,
} from '@orthly/items';
import { useChangeSubmissionFn } from '@orthly/ui';

/** Whether partial denture editing is enabled. */
export function isEnabled(items: IPartialDentureItem[]): boolean {
    return items.length > 0;
}

type SubmitArgs = {
    clasps: PartialDentureClasp[] | null;
    coverageType: CoverageType | null;
    itemId: string;
    salesOrderId: string;
};

/** Edit a partial denture item. */
export function submit(args: SubmitArgs, submitChange: (data: UpdatePartialDentureCommand) => Promise<unknown>) {
    AnalyticsClient.track('Lab - Item Partial Denture Details Edited', {
        itemId: args.itemId,
        $groups: { order: args.salesOrderId },
    });

    const clasps = args.clasps ? args.clasps.map(mapClasp) : undefined;
    const coverage_type = args.coverageType ? mapCoverageType(args.coverageType) : undefined;

    void submitChange({
        clasps,
        coverage_type,
        itemId: args.itemId,
        orderId: args.salesOrderId,
    });
}

export function useViewModel(items: IOrderItemV2DTO[]) {
    // TODO: [DECP-331] Use an API endpoint that calls into
    //     LabSalesOrderItemUpdateService.updateSalesOrderItems.
    //     It doesn't exist yet.
    const [mutation] = useUpdatePartialDentureMetadataMutation();
    const submitter = (data: UpdatePartialDentureCommand) => mutation({ variables: { data } });
    const {
        open,
        setOpen,
        submit: submitChange,
        submitting,
    } = useChangeSubmissionFn(submitter, {
        closeOnComplete: true,
        successMessage: () => ['Partial denture updated!', {}],
    });

    const filteredItems = filterPartials(items);
    const enabled = isEnabled(filteredItems);

    const submit_ = (args: SubmitArgs) => {
        submit(args, submitChange);
    };

    return { enabled, filteredItems, open, setOpen, submit: submit_, submitting };
}
