import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useSetCbctScanUrlMutation } from '@orthly/graphql-react';
import { getFullStoragePath, OrderingStorageConfigs } from '@orthly/shared-types';
import { LoadBlocker, OrthlyBrowserConfig, useChangeSubmissionFn, SimpleAutocomplete } from '@orthly/ui';
import {
    Text,
    FlossPalette,
    Dialog,
    Grid,
    IconButton,
    InputAdornment,
    Button,
    CloseIcon,
    SearchIcon,
} from '@orthly/ui-primitives';
import type { FileUploaderFieldResult } from '@orthly/veneer';
import { FileUploaderSingle, useFirebasePreview, OrderDetailBlock, useFirebaseFileDownload } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

// Guides retrieved from https://drive.google.com/drive/folders/169-wOqYknsIFSXgfVTV5ivayiKlm76cT
// Names are deprived from the file naming conventions with spot-checks to match PDF names.
// Each key in this object is a machine's name as it appears in the Autocomplete,
// and its value is the path in Google Cloud Storage to it.
const MachineNameToGuidePathMap: Record<string, string> = {
    'AxiUm MiPacs': 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from AxiUm MiPacs.pdf',
    // This isn't a typo -- kept verbatim to avoid future renaming when coming from the google drive
    Acteon: 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting From Acteon .pdf',
    'Acteon Viewer': 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from Acteon Viewer.pdf',
    Carestream: 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from Carestream.pdf',
    'DatCard Systems': 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from DatCard Systems.pdf',
    ICAT: 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from ICAT.pdf',
    'ICAT Classic': 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from ICAT Classic.pdf',
    Iluma: 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from Iluma.pdf',
    Instrumetarium: 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from Instrumetarium.pdf',
    'Kavo Invivo': 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from Kavo Invivo.pdf',
    'LED Imaging': 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from LED Imaging.pdf',
    Morita: 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from Morita.pdf',
    NewTom: 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from NewTom.pdf',
    'NewTom Viewer': 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from NewTom Viewer.pdf',
    'Pancorp Encompass': 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from Pancorp Encompass.pdf',
    Planmeca: 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from Planmeca.pdf',
    PreXion: 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from PreXion.pdf',
    Rayscan: 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from Rayscan.pdf',
    'Sidexis 4': 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from Sidexis 4.pdf',
    'Sidexis XG': 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from Sidexis XG.pdf',
    Triana: 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from Triana.pdf',
    'TxSTUDIO / Invivo5': 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from TxSTUDIO_Invivo5.pdf',
    'VaTech (3DPlus)': 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from VaTech_3DPlus.pdf',
    'VaTech (Ez3D-i)': 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from VaTech_Ez3D-i.pdf',
    'VaTech (EZDenti-i)': 'surgical-guides-cbct-guides/CBCT Exporting Instructions_Exporting from VaTech EZDenti-i.pdf',
};

const GuideViewer: React.VFC<{ path: string; open: boolean; setOpen: (open: boolean) => void }> = ({
    path,
    open,
    setOpen,
}) => {
    const { execute } = useFirebasePreview();
    const [iframeUrl, setIframeUrl] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        void execute(path).then(result => {
            setIframeUrl(result);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path]);

    if (!open || !iframeUrl) {
        return null;
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            maxWidth={'xl'}
            PaperProps={{ style: { height: '100%', width: '100%' } }}
        >
            <iframe key={iframeUrl} style={{ height: '100%', width: '100%' }} title={'CBCT Guide'} src={iframeUrl} />
        </Dialog>
    );
};

const PopupDialog: React.VFC<{ open: boolean; setOpen: (open: boolean) => void }> = ({ open, setOpen }) => {
    const [guideOpen, setGuideOpen] = React.useState<boolean>(false);
    const [machineName, setMachineName] = React.useState<string | undefined>(undefined);

    const path = machineName ? MachineNameToGuidePathMap[machineName] : undefined;

    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                PaperProps={{ style: { width: 686, maxWidth: 686, padding: 8 } }}
            >
                <Grid container direction={'column'}>
                    <Grid item style={{ alignSelf: 'flex-end' }}>
                        <IconButton
                            style={{ height: 32, width: 32, borderRadius: 0, backgroundColor: FlossPalette.TAN }}
                            onClick={() => setOpen(false)}
                        >
                            <CloseIcon style={{ color: FlossPalette.BLACK }} />
                        </IconButton>
                    </Grid>
                    <Grid item style={{ padding: '0px 32px' }}>
                        <Text variant={'h4'} color={'BLACK'}>
                            Need help exporting the files from the CBCT machine?{' '}
                            <span style={{ color: FlossPalette.STAR_GRASS }}>We got you!</span>
                        </Text>
                    </Grid>
                    <Grid
                        item
                        container
                        direction={'row'}
                        spacing={2}
                        style={{ marginTop: 24, padding: '0px 32px 32px' }}
                    >
                        <Grid item style={{ marginTop: 16 }}>
                            <Text variant={'body2'}>I use</Text>
                        </Grid>
                        <Grid item xs>
                            <SimpleAutocomplete
                                options={Object.keys(MachineNameToGuidePathMap)}
                                label={``}
                                onChange={val => setMachineName(val ?? undefined)}
                                TextFieldProps={{
                                    InputProps: {
                                        endAdornment: (
                                            <InputAdornment position={'end'} style={{ height: 30, paddingBottom: 4 }}>
                                                <SearchIcon color={'action'} />
                                            </InputAdornment>
                                        ),
                                        disableUnderline: true,
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item style={{ width: 270 }}>
                            <Button
                                variant={'primary'}
                                onClick={() => {
                                    setGuideOpen(true);
                                }}
                                fullWidth
                                disabled={!path}
                                endIcon={'CallMadeIcon'}
                                style={{ height: 56 }}
                            >
                                View guide
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
            {path && <GuideViewer path={path} open={guideOpen} setOpen={setGuideOpen} />}
        </>
    );
};

const OrderDetailsCbctInstructions: React.VFC = () => {
    const [open, setOpen] = React.useState<boolean>(false);
    return (
        <>
            <PopupDialog open={open} setOpen={setOpen} />
            <Button variant={'ghost'} onClick={() => setOpen(true)} endIcon={'HelpIcon'}>
                Instructions
            </Button>
        </>
    );
};

export const OrderDetailsCbctScanManagement: React.VFC<{ order: LabsGqlOrder; refetch: () => void }> = (props: {
    order: LabsGqlOrder;
    refetch: () => void;
}) => {
    const { order, refetch } = props;
    const [rawSetCbctUrl] = useSetCbctScanUrlMutation();

    const { submitting, submit } = useChangeSubmissionFn(rawSetCbctUrl, {
        successMessage: () => [!order.cbct_url ? 'Uploaded CBCT Scan' : 'Removed CBCT Scan', {}],
    });
    const onUploadComplete = async (files: FileUploaderFieldResult<string>[]) => {
        await submit({ variables: { order_id: order.id, cbct_url: files[0]?.uploadedPath ?? null } });
        refetch();
    };
    const removeCbctUrl = async () => {
        await submit({ variables: { order_id: order.id, cbct_url: null } });
        refetch();
    };

    const filename =
        _.last(order?.cbct_url?.split('/')) ||
        `${order?.scan_export?.patient_first_name} ${order?.scan_export?.patient_last_name}.zip`;

    const downloader = useFirebaseFileDownload(order?.cbct_url || ``, filename || ``);

    const actions = !!order.cbct_url ? (
        <div style={{ paddingBottom: '6px' }}>
            <Button variant={'alert-secondary'} onClick={removeCbctUrl}>
                Delete
            </Button>
            <Button variant={'ghost'} onClick={() => downloader.execute()}>
                Download
            </Button>
        </div>
    ) : (
        <OrderDetailsCbctInstructions />
    );

    const storagePathConfig = getFullStoragePath(
        OrthlyBrowserConfig.env,
        OrderingStorageConfigs.surgicalGuideCbct,
        order.scan_export_id,
    );

    return (
        <Grid container>
            <OrderDetailBlock variant={'full'} title={'CBCT Scan'} actions={actions}>
                <Grid container direction={'column'} style={{ padding: 5 }}>
                    <LoadBlocker blocking={submitting}>
                        {!order.cbct_url ? (
                            <FileUploaderSingle
                                paperStyle={{ backgroundColor: 'transparent', boxShadow: 'none' }}
                                wrapperStyle={{ backgroundColor: 'transparent' }}
                                storagePathConfig={storagePathConfig}
                                fileFields={[{ fieldKey: 'cbct' }]}
                                onComplete={onUploadComplete}
                                dropzoneContent={<>Drop your ZIP file here, or click to browse files.</>}
                                prependTimestampToFilename={true}
                            />
                        ) : (
                            <>
                                <Grid
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '16px 24px',
                                        borderRadius: '16px',
                                        width: '100%',
                                    }}
                                >
                                    <Grid container direction={'row'} alignItems={'center'} justifyContent={'center'}>
                                        <img
                                            src={'/checkout/zipFile.svg'}
                                            alt={'Zip File Uploaded'}
                                            style={{ filter: 'drop-shadow(0px 2.66667px 10px rgba(0, 0, 0, 0.15))' }}
                                        />
                                        <Text variant={'body2'} style={{ paddingLeft: '24px' }}>
                                            {_.last(order.cbct_url.split('/'))}
                                        </Text>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </LoadBlocker>
                </Grid>
            </OrderDetailBlock>
        </Grid>
    );
};
