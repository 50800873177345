import { stylesFactory, Grid } from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';

export const useRootStyles = stylesFactory(theme => ({
    noMobileDisplay: {
        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },
    },
    noSmallScreenDisplay: {
        [theme.breakpoints.down('md')]: {
            display: 'none !important',
        },
    },
}));

const useStyles = stylesFactory(theme => ({
    root: {
        maxWidth: '100vw',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        '@media print': {
            paddingLeft: '0 !important',
            overflow: 'visible',
            maxHeight: 'unset',
            height: 'auto',
        },
    },
    manufacturer: {
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: { paddingTop: 0 },
    },
    practice: {},
}));

export const LoggedInContainerManufacturer: React.FC = props => {
    const classes = useStyles();

    return (
        <Grid container className={cx(classes.root, classes.manufacturer)}>
            {props.children}
        </Grid>
    );
};
