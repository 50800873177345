import type { OrthlyBrowserConfig } from './browser-config';
import { CloudPropagator } from '@google-cloud/opentelemetry-cloud-trace-propagator';
import type { ExportResult } from '@opentelemetry/core';
import { CompositePropagator, ExportResultCode, W3CTraceContextPropagator } from '@opentelemetry/core';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { Resource } from '@opentelemetry/resources';
import type { ReadableSpan } from '@opentelemetry/sdk-trace-base';
import { BatchSpanProcessor, ConsoleSpanExporter, SimpleSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';

class CustomConsoleSpanExporter extends ConsoleSpanExporter {
    export(spans: ReadableSpan[], resultCallback: (result: ExportResult) => void): void {
        if ((window as any).enableConsoleSpanExporter) {
            return super.export(spans, resultCallback);
        }
        resultCallback({ code: ExportResultCode.SUCCESS });
    }
}

export function setupOpenTelemetry(config: OrthlyBrowserConfig) {
    const provider = new WebTracerProvider({
        resource: new Resource({
            [SemanticResourceAttributes.SERVICE_NAME]: config.appName,
            [SemanticResourceAttributes.SERVICE_VERSION]: config.version,
            [SemanticResourceAttributes.DEPLOYMENT_ENVIRONMENT]: config.env,
        }),
    });

    if (process.env['OPEN_TELEMETRY_ENDPOINT']) {
        const traceExporter = new OTLPTraceExporter({ url: process.env['OPEN_TELEMETRY_ENDPOINT'] });
        const spanProcessor = new BatchSpanProcessor(traceExporter);
        provider.addSpanProcessor(spanProcessor);
    } else {
        const traceExporter = new CustomConsoleSpanExporter();
        const spanProcessor = new SimpleSpanProcessor(traceExporter);
        provider.addSpanProcessor(spanProcessor);
    }

    const propagator = new CompositePropagator({
        propagators: [new CloudPropagator(), new W3CTraceContextPropagator()],
    });

    provider.register({ propagator });

    const ignoreUrls = [
        /segment\.io/,
        /fullstory\.com/,
        /version\.json$/,
        /launchdarkly\.com/,
        /maps\.googleapis\.com/,
    ];
    const propagateTraceHeaderCorsUrls = [/dandyqa\.dev/, /dandyserv\.net/, /meetdandy\.com/, /orthly\.com/];

    registerInstrumentations({
        instrumentations: [
            new XMLHttpRequestInstrumentation({ ignoreUrls, propagateTraceHeaderCorsUrls }),
            new FetchInstrumentation({ ignoreUrls, propagateTraceHeaderCorsUrls }),
        ],
    });
}
