import type { ManufacturerScreen } from './manufacturer.reducer';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { createSyncAction, generateUseActionHook } from '@orthly/redux-async-actions';
import { useRootActionCommand } from '@orthly/ui/dist';
import { useAsyncCallback } from 'react-async-hook';

const PREFIX = 'manufacturer';

const BulkRecordLabSlipsPrintedMutation = graphql(`
    mutation BulkRecordLabSlipsPrinted_Mutation($labOrderIds: [String!]!) {
        recordLabSlipsViewedByLabOrderIds(labOrderIds: $labOrderIds)
    }
`);

export function useRecordLabSlipPrinted(order_ids: string[]) {
    const mutation = useMutation(BulkRecordLabSlipsPrintedMutation);
    const { submit } = useRootActionCommand(mutation, {});
    return useAsyncCallback(() => submit({ labOrderIds: order_ids }));
}

interface ManufacturerSetScreenArgs {
    screen: ManufacturerScreen;
}

export const ManufacturerActions = {
    SET_SCREEN: createSyncAction<ManufacturerSetScreenArgs, [ManufacturerSetScreenArgs]>(`${PREFIX}/SET_SCREEN`),
    DISMISS_OUTDATED_LAB_SLIP_WARNING: createSyncAction<string, [string]>(
        `${PREFIX}/DISMISS_OUTDATED_LAB_SLIP_WARNING`,
    ),
};

export const useManufacturerAction = generateUseActionHook<typeof ManufacturerActions>(ManufacturerActions);
