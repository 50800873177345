import React from 'react';

export const LabPortalErrorPage: React.VFC = () => {
    return (
        <div>
            <h2>Page not found</h2>
            <p>
                We are unable to locate the page you are looking for. If you believe this is an error, please contact
                support.
            </p>
        </div>
    );
};
