import type { ChatMessageVisibilityOrganizationIds } from '@orthly/chat';
import type { IOrganizationType } from '@orthly/retainer-common';

export function getLegacyIdObject(
    orgType: IOrganizationType,
    orgId: string | undefined,
): ChatMessageVisibilityOrganizationIds {
    if (orgId === undefined) {
        return {};
    }
    switch (orgType) {
        case 'lab':
            return { manufacturer_id: orgId };
        case 'practice':
            return { partner_id: orgId };
        default:
            return {};
    }
}
