import type { OrthlyBrowserConfig } from './browser-config';
import { setupOpenTelemetry } from './telemetry';
import * as Sentry from '@sentry/react';
import type { AxiosError } from 'axios';
import axios from 'axios';

// this is just for sentry getting network errors
function axiosErrHandler(error: any | AxiosError) {
    // log it out for debugging
    if (typeof window !== 'undefined' && window.location.host.includes('localhost')) {
        console.error(error);
    }
    // this has to be set for react dev tools to get a correct stack trace
    error.stack = error.stack ?? new Error().stack;
    // handle axios errors (dont know why they would ever not be but this is how axios typings are)
    if (error.isAxiosError) {
        const axiosError: AxiosError = error;
        const responseMessage =
            axiosError.response && axiosError.response.data && (axiosError.response.data as any).message;
        if (typeof responseMessage === 'string') {
            // set the error message to our servers custom error message
            axiosError.message = responseMessage;
        }
        Sentry.withScope(scope => {
            scope.setContext('request', {
                method: axiosError.config.method,
                url: axiosError.config.url,
                body: axiosError.config.data,
            });
            scope.addBreadcrumb({ category: 'stack', level: 'error', data: { stack: error.stack } });
            scope.addBreadcrumb({ category: 'stack', level: 'info', data: axiosError.response });
            if (axiosError.response?.data) {
                scope.setContext('response_error', axiosError.response.data as any);
            }
            Sentry.captureException(axiosError, {
                fingerprint: [axiosError.config.url ?? 'unknown', axiosError.message],
            });
        });
        throw axiosError;
    }
    Sentry.withScope(scope => {
        scope.addBreadcrumb({ category: 'stack', level: 'error', data: { stack: error.stack } });
        scope.setExtra('error', error);
        scope.setContext('stack', { stack: error.stack });
        Sentry.captureException(error);
    });
    throw error;
}

/**
 * Setup utility - initializes Sentry error tracking and axios error handlers
 */
export function setupOrthlyBrowser(config: OrthlyBrowserConfig) {
    const window = (global as any).window;
    if (typeof window === 'object' && window) {
        window.PACKAGE_VERSION = window.PACKAGE_VERSION || config.logging.RELEASE;
    }
    Sentry.init(config.sentryConfig());
    axios.interceptors.response.use(response => response, axiosErrHandler);
    setupOpenTelemetry(config);
}
