import type { IconName, SvgIconProps } from '@orthly/ui-primitives';
import { Icon, createStyles, makeStyles, Paper, Grid, Text, Typography } from '@orthly/ui-primitives';
import React from 'react';

const useErrorPageBaseStyles = makeStyles(() =>
    createStyles({
        errorPage: {
            position: 'relative',
            width: '100%',
            height: '100%',
        },
        errorPageTopBar: {
            position: 'relative',
            width: '100%',
            height: 50,
            padding: 10,
        },
        errorContainer: {
            width: '100%',
            height: '100%',
        },
    }),
);

export type ErrorPageProps = {
    message: string;
    text: string;
    icon?: React.ComponentType<SvgIconProps>;
    iconName?: IconName;
};

export const ErrorPage: React.FC<ErrorPageProps> = props => {
    const classes = useErrorPageBaseStyles();
    return (
        <Paper className={classes.errorPage}>
            <Grid className={classes.errorContainer} container justifyContent={'center'} alignContent={'center'}>
                <Grid item style={{ textAlign: 'center' }}>
                    {!!props.icon && (
                        <Text variant={'h1'}>
                            <props.icon fontSize={'inherit'} />
                        </Text>
                    )}
                    {!!props.iconName && (
                        <Text variant={'h1'}>
                            <Icon icon={props.iconName} fontSize={'inherit'} />
                        </Text>
                    )}
                    <Text variant={'h3'}>{props.message}</Text>
                    <Typography variant={'subtitle1'}>{props.text}</Typography>
                    <Grid container>{props.children}</Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};
