import { useListOrderFilterOptionsQuery } from '@orthly/graphql-react';
import type { LabsGqlFilterCriteriaSubmissionInput } from '@orthly/graphql-schema';
import { LabsGqlFilterComparator } from '@orthly/graphql-schema';
import { OrderFilterIdEnum } from '@orthly/shared-types';
import { SimpleMultiSelect, FilterIcon, LoadBlocker } from '@orthly/ui';
import { Button, Grid, Popover } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export const LabPortalOrderListViewProductLineFilterTag: React.VFC<{
    currentFilterValue: any;
    addFilter: (filter: LabsGqlFilterCriteriaSubmissionInput) => void;
}> = ({ currentFilterValue, addFilter }) => {
    const { loading, data: optionsData } = useListOrderFilterOptionsQuery({
        variables: { filterId: OrderFilterIdEnum.order_product_line, partnerId: null },
    });

    const [filterTagOpen, setFilterTagOpen] = React.useState(false);
    const [filterValue, setFilterValue] = React.useState<string[] | undefined>(currentFilterValue);

    const selectedFilterLabel = React.useMemo(
        () =>
            _.compact(
                filterValue?.map(value => optionsData?.loadOrderFilterOptions?.find(opt => opt.value === value)?.label),
            ),
        [optionsData, filterValue],
    );

    const anchorRef = React.useRef<HTMLButtonElement | null>(null);
    return (
        <Grid>
            <Button
                ref={anchorRef}
                variant={'secondary'}
                onClick={() => setFilterTagOpen(filterTagOpen => !filterTagOpen)}
            >
                <FilterIcon /> Product Line
                {!_.isEmpty(filterValue) ? `: ${selectedFilterLabel}` : ''}
            </Button>
            <Popover
                anchorEl={anchorRef.current}
                open={filterTagOpen}
                PaperProps={{
                    style: { width: '400px' },
                }}
            >
                <LoadBlocker blocking={loading}>
                    <SimpleMultiSelect
                        options={optionsData?.loadOrderFilterOptions ?? []}
                        onChange={selected => setFilterValue(selected)}
                        value={filterValue ?? []}
                        label={'Filter by'}
                        SelectProps={{
                            open: filterTagOpen,
                            onClose: () => {
                                setFilterTagOpen(false);
                                addFilter({
                                    filter_id: OrderFilterIdEnum.order_product_line,
                                    comparator: LabsGqlFilterComparator.OneOf,
                                    comparison_value: filterValue,
                                });
                            },
                            variant: 'standard',
                        }}
                    />
                </LoadBlocker>
            </Popover>
        </Grid>
    );
};
