import { IndicatorFlag } from '@orthly/ui';
import { FlossPalette } from '@orthly/ui-primitives';

export type UpdateTagKey = keyof typeof UpdateTags;

export const UpdateTags = {
    OrderCancelled: (
        <IndicatorFlag
            color={FlossPalette.ATTENTION}
            title={'Order Canceled'}
            iconName={'XIcon'}
            style={{ paddingRight: 4 }}
        />
    ),
    OrderTransferred: (
        <IndicatorFlag
            iconName={'ArrowForwardIcon'}
            title={'Order Transferred'}
            color={FlossPalette.ATTENTION}
            style={{ paddingRight: 4 }}
        />
    ),
    OrderHoldCreated: (
        <IndicatorFlag
            color={FlossPalette.BURGUNDY}
            title={'Order Hold Created'}
            iconName={'WarningIcon'}
            style={{ paddingRight: 4 }}
        />
    ),
    OrderItemUpdated: (
        <IndicatorFlag
            color={FlossPalette.BURGUNDY}
            title={'Item Updated'}
            iconName={'RefreshIcon'}
            style={{ paddingRight: 4 }}
        />
    ),
    OrderItemAdded: (
        <IndicatorFlag
            color={FlossPalette.GRAY}
            title={'Item Added'}
            iconName={'PlusIcon'}
            style={{ paddingRight: 4 }}
        />
    ),
    OrderItemDeleted: (
        <IndicatorFlag
            color={FlossPalette.GRAY}
            title={'Item Deleted'}
            iconName={'DeleteIcon'}
            style={{ paddingRight: 4 }}
        />
    ),
    NewDesignFile: (
        <IndicatorFlag
            color={FlossPalette.GRAY}
            title={'New Design File'}
            iconName={'AttachFileIcon'}
            style={{ paddingRight: 4 }}
        />
    ),
    NewScanFile: (
        <IndicatorFlag
            color={FlossPalette.GRAY}
            title={'New Scan File'}
            iconName={'AttachFileIcon'}
            style={{ paddingRight: 4 }}
        />
    ),
    PhotoAdded: (
        <IndicatorFlag
            color={FlossPalette.GRAY}
            title={'Photo Added'}
            iconName={'CameraIcon'}
            style={{ paddingRight: 4 }}
        />
    ),
    OrderHoldRemoved: (
        <IndicatorFlag
            color={FlossPalette.STAR_GRASS}
            title={'Hold Removed'}
            iconName={'ThumbsUpIcon'}
            style={{ paddingRight: 4 }}
        />
    ),
    OrderWaxupReviewed: (
        <IndicatorFlag
            color={FlossPalette.ATTENTION}
            title={'Waxup Reviewed'}
            iconName={'CastConnected'}
            style={{ paddingRight: 4 }}
        />
    ),
    ShippingDateChanged: (
        <IndicatorFlag
            color={FlossPalette.GRAY}
            title={'Shipping Date Changed'}
            iconName={'LocalShipping'}
            style={{ paddingRight: 4 }}
        />
    ),
    ShippingAddressChanged: (
        <IndicatorFlag color={FlossPalette.GRAY} title={'Shipping Address Changed'} iconName={'LocalShipping'} />
    ),
    ShippingSpeedChanged: <IndicatorFlag color={FlossPalette.GRAY} title={'Rushed'} iconName={'LocalShipping'} />,
    NoteAdded: <IndicatorFlag color={FlossPalette.GRAY} title={'Note Added'} iconName={'AddNoteIcon'} />,
};
