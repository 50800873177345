import { DeleteShipmentButton } from '../../actions/manufacturers/DeleteShipmentButton';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { ShippingUtils } from '@orthly/shared-types';
import { Text, useScreenIsMobile, createStyles, makeStyles, Grid, Button } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        trackingLink: {
            height: 'unset',
            padding: '0 0 0 6px',
        },
    }),
);

const OrderDetailTrackingActions: React.FC<{ order: LabsGqlOrder }> = props => {
    const { order } = props;
    const classes = useStyles();
    const isMobile = useScreenIsMobile();

    if (!order.tracking_number || isMobile) {
        return null;
    }

    const trackingLink = ShippingUtils.getTrackingLinkForCarrier(order.carrier ?? 'ups', order.tracking_number);

    return (
        <Grid container alignItems={'center'}>
            <Text variant={'body2'} color={'GRAY'}>
                Track on {ShippingUtils.getCarrierDisplayLink(order.carrier ?? 'ups')}:
            </Text>
            <Button
                variant={'ghost'}
                data-test={'tracking-link'}
                onClick={() => window.open(trackingLink, '_blank')?.focus()}
                className={classes.trackingLink}
                endIcon={'CallMadeIcon'}
                analytics={{
                    AssetLocation: 'Practice - Order Details',
                    AssetName: 'Tracking Link Clicked',
                }}
            >
                {order.tracking_number}
            </Button>
            <DeleteShipmentButton
                manufacturerId={order.manufacturer_id}
                orderStatus={order.status}
                salesOrderId={order.id}
                shipDate={order.ship_date ? new Date(order.ship_date) : null}
                shippedByLab={order.shipped_by_lab}
            />
        </Grid>
    );
};

export const OrderDetailTrackingUnitButton: React.FC<{
    order: LabsGqlOrder;
}> = ({ order }) => {
    return <OrderDetailTrackingActions order={order} />;
};
