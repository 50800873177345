import { SelectStaffActions } from '../screens/select-staff/state/select-staff.actions';
import { useApolloClient } from '@apollo/client';
import { clearApolloCache } from '@orthly/graphql-react';
import { RetainerTokenStorage } from '@orthly/retainer-common';
import React from 'react';
import { useDispatch } from 'react-redux';

export function usePracticeLogout() {
    const client = useApolloClient();
    const dispatch = useDispatch();
    return React.useCallback(async () => {
        await clearApolloCache(client);
        dispatch(SelectStaffActions.LOGOUT_STAFF());
        RetainerTokenStorage.clearAll();
    }, [client, dispatch]);
}
