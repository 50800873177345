import type { FilterOption } from './components/LabPortalOrderListFilters/LabPortalOrderListViewFilterButton';
import { LabPortalOrderListViewFilterButton } from './components/LabPortalOrderListFilters/LabPortalOrderListViewFilterButton';
import { LabPortalOrderListViewFilterTag } from './components/LabPortalOrderListFilters/LabPortalOrderListViewFilterTag';
import type { SortOption } from './components/LabPortalOrderListSort/LabPortalOrderListSortButton';
import { LabPortalOrderListViewSortButton } from './components/LabPortalOrderListSort/LabPortalOrderListSortButton';
import { LabPortalOrderListViewSortTag } from './components/LabPortalOrderListSort/LabPortalOrderListSortTag';
import type { TabOption } from './components/LabPortalOrderListTabs/LabPortalOrderListTabs';
import { LabPortalOrderListTabs } from './components/LabPortalOrderListTabs/LabPortalOrderListTabs';
import { LabPortalOrderListViewCtxProvider } from './components/providers/LabPortalOrderListViewProvider';
import {
    convertFilterFromHumanReadableQueryParams,
    convertFiltersToHumanReadableQueryParams,
    convertSortToHumanReadableQueryParams,
    convertSortFromHumanReadableQueryParams,
} from './utils/QueryParamConversionUtils';
import type { LabsGqlFilterCriteriaSubmissionInput, LabsGqlLabPortalListOrdersSort } from '@orthly/graphql-schema';
import { LabsGqlLabPortalLabOrderSortKey } from '@orthly/graphql-schema';
import { OrderFilterIdEnum } from '@orthly/shared-types';
import { useQueryParam } from '@orthly/ui';
import { Grid, Text, makeStyles } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

const useStyles = makeStyles({
    root: {
        padding: '64px',
        width: '100%',
    },
    pageHeader: {
        marginBottom: '24px',
    },
    toolbar: {},
    toolbarTabs: {
        padding: '8px',
    },
    toolbarTags: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    toolbarTag: {
        padding: '8px',
    },
    toolbarActions: {
        display: 'flex',
        flexDirection: 'row',
        float: 'right',
    },
    toolbarAction: {
        padding: '8px',
    },
    tableContainer: {
        width: '100%',
        justifySelf: 'center',
    },
});

export const LabPortalOrdersPage: React.VFC<{
    title: string;
    tabOptions: TabOption[];
    customSortOptions?: SortOption[];
    customFilterOptions?: FilterOption[];
    disableSort?: boolean;
    disableFilter?: boolean;
    children: React.ReactNode;
}> = ({ title, tabOptions, children, customSortOptions, customFilterOptions = [], disableSort, disableFilter }) => {
    const classes = useStyles();
    const params = useParams<{ tabId: string }>();

    const [filterFields, _setFilterFields] = React.useState<string[]>([
        OrderFilterIdEnum.order_product_line,
        ...(customFilterOptions?.map(opt => opt.value) ?? []),
    ]);
    const [filterString, setFilterString] = useQueryParam('filters', true);
    const filters: LabsGqlFilterCriteriaSubmissionInput[] = React.useMemo(() => {
        if (!filterString) {
            // set default filters
            return [];
        }
        const convertedFilters = convertFilterFromHumanReadableQueryParams(filterString);
        _setFilterFields(convertedFilters.map(filter => filter.filter_id));
        return convertedFilters;
    }, [filterString, _setFilterFields]);
    const setFilters = React.useCallback(
        (filterInput: LabsGqlFilterCriteriaSubmissionInput[]) => {
            setFilterString(convertFiltersToHumanReadableQueryParams(filterInput));
        },
        [setFilterString],
    );

    const setFilterFields = React.useCallback(
        (newFieldIds: string[]) => {
            _setFilterFields(newFieldIds);
            setFilters(filters.filter(f => newFieldIds.includes(f.filter_id)));
        },
        [setFilters, _setFilterFields, filters],
    );

    const addFilter = React.useCallback(
        (filter: LabsGqlFilterCriteriaSubmissionInput) => {
            setFilters([...filters.filter(f => f.filter_id !== filter.filter_id), filter]);
        },
        [setFilters, filters],
    );

    const [sortString, setSortString] = useQueryParam('sort', true);
    const sort: LabsGqlLabPortalListOrdersSort = React.useMemo(() => {
        if (!sortString) {
            // set default sort
            return {
                key: customSortOptions?.[0] ? customSortOptions[0].value : LabsGqlLabPortalLabOrderSortKey.UpdatedAt,
                asc: true,
            };
        }
        return convertSortFromHumanReadableQueryParams(sortString);
    }, [sortString, customSortOptions]);
    const setSort = React.useCallback(
        (sortInput: LabsGqlLabPortalListOrdersSort) => {
            setSortString(convertSortToHumanReadableQueryParams(sortInput));
        },
        [setSortString],
    );

    const setSortKey = React.useCallback(
        (newSortKey: string | undefined) => {
            setSort({
                key: newSortKey as LabsGqlLabPortalLabOrderSortKey,
                asc: true,
            } as LabsGqlLabPortalListOrdersSort);
        },
        [setSort],
    );

    const setSortAscending = React.useCallback(
        (sortAscending: boolean) => {
            setSort({ key: sort.key, asc: sortAscending });
        },
        [setSort, sort],
    );

    const { pathname: baseUrl, ...locationRest } = useLocation();
    const history = useHistory();
    const tabOptionsWithRouter = React.useMemo(() => {
        return tabOptions.map(tabOption => ({
            ...tabOption,
            onClick: () => {
                history.push({
                    pathname: tabOption.pathValue,
                    ...locationRest,
                });
            },
        }));
    }, [tabOptions, locationRest, history]);

    return (
        <Grid className={classes.root}>
            <Grid className={classes.pageHeader}>
                <Text variant={'h3'}>{title}</Text>
            </Grid>
            <Grid className={classes.toolbar}>
                <Grid className={classes.toolbarActions}>
                    {!disableSort && (
                        <Grid className={classes.toolbarAction}>
                            <LabPortalOrderListViewSortButton
                                sortKey={sort.key ?? undefined}
                                setSortKey={setSortKey}
                                customSortOptions={customSortOptions}
                            />
                        </Grid>
                    )}
                    {!disableFilter && (
                        <Grid className={classes.toolbarAction}>
                            <LabPortalOrderListViewFilterButton
                                filters={filterFields}
                                setFilters={setFilterFields}
                                customFilterOptions={customFilterOptions}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid className={classes.toolbarTabs}>
                    <LabPortalOrderListTabs selectedTab={params?.tabId ?? ''} tabOptions={tabOptionsWithRouter} />
                </Grid>
                <Grid className={classes.toolbarTags}>
                    {!disableSort && sort.key && (
                        <Grid className={classes.toolbarTag}>
                            <LabPortalOrderListViewSortTag
                                sort={sort}
                                setSortAscending={setSortAscending}
                                customSortOptions={customSortOptions}
                            />
                        </Grid>
                    )}
                    {!disableFilter &&
                        filterFields?.map(filter => (
                            <Grid key={filter} className={classes.toolbarTag}>
                                <LabPortalOrderListViewFilterTag
                                    filterField={filter}
                                    currentFilterValue={filters.find(f => f.filter_id === filter)?.comparison_value}
                                    addFilter={addFilter}
                                />
                            </Grid>
                        ))}
                </Grid>
            </Grid>
            <LabPortalOrderListViewCtxProvider filters={filters} sort={sort}>
                <Grid className={classes.tableContainer}>{children}</Grid>
            </LabPortalOrderListViewCtxProvider>
        </Grid>
    );
};
