import _ from 'lodash';
import React from 'react';

/** Memoizes an object, only updating the result when the new version fails a deep equality check with the previous one. */
export function useMemoizedValue<T>(object: T): T {
    const memoizedObject = React.useRef<T>(object);
    if (!_.isEqual(object, memoizedObject.current)) {
        memoizedObject.current = object;
    }
    return memoizedObject.current;
}
