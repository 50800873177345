import { LabInboxBulkActionIconButton } from '../../../../components/bulk-actions/LabInboxBulkActionIconButton';
import { useRecordLabSlipPrinted } from '../../../../state/manufacturer.actions.graphql';
import { usePrintDialog } from '../../utils/usePrintDialog';
import type { LabPortalOrderActionProps } from './LabPortalOrderActions.types';
import { Format } from '@orthly/runtime-utils';
import { PrintIcon } from '@orthly/ui-primitives';
import { usePrintableState, LabSlipV2 } from '@orthly/veneer';
import React from 'react';

export const LabPortalPrintLabSlipAction: React.FC<LabPortalOrderActionProps> = props => {
    const { selectedOrders, disabled } = props;
    const { printableIsOpen, openPrintable, closePrintable } = usePrintableState();

    const itemsLabel = props.onSingleRow ? 'lab slip' : Format.pluralize('lab slip', selectedOrders.length);
    const title = selectedOrders.length > 0 ? `Print ${itemsLabel}` : '';

    const { loading: recordPrintLoading, execute: recordLabSlipsViewedBulk } = useRecordLabSlipPrinted(
        selectedOrders.map(o => o.id),
    );
    const renderLabSlips = React.useCallback(() => {
        if (selectedOrders.length > 0) {
            openPrintable();
            void recordLabSlipsViewedBulk();
        }
    }, [selectedOrders, openPrintable, recordLabSlipsViewedBulk]);

    const [setPrintDialogOpen, printDialog] = usePrintDialog({
        title,
        onClick: renderLabSlips,
        autoClose: true,
    });
    return (
        <>
            <LabInboxBulkActionIconButton
                onClick={() => setPrintDialogOpen(true)}
                loading={recordPrintLoading}
                disabled={disabled ?? false}
                disableTooltip={disabled ?? false}
                selectionCount={selectedOrders.length}
                tooltipTitle={title}
            >
                <PrintIcon />
            </LabInboxBulkActionIconButton>
            {printDialog}
            {printableIsOpen && (
                <LabSlipV2
                    orderIds={selectedOrders.map(o => o.id)}
                    autoPrintEnabled={true}
                    onPrintComplete={() => {
                        closePrintable();
                    }}
                />
            )}
        </>
    );
};
