import * as Sentry from '@sentry/react';
import { replayIntegration, replayCanvasIntegration } from '@sentry/react';
import React from 'react';

let initializedSentrySessionReplay = false;

export function useEnableSentrySessionReplay(shouldRecord: boolean = true): void {
    React.useEffect(() => {
        const sentryEnabled = !!process.env.ENABLE_SENTRY_SESSION_REPLAY;
        if (shouldRecord && sentryEnabled && !initializedSentrySessionReplay) {
            initializedSentrySessionReplay = true;
            Sentry.addIntegration(
                replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            );
            Sentry.addIntegration(replayCanvasIntegration());
        }
    }, [shouldRecord]);
}
