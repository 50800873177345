import moment from 'moment';

export function calculateShipDateDisplay(shipDate: string): string {
    const shipDayDiff = moment(shipDate).endOf('day').diff(moment(new Date()).endOf('day'), 'days');
    if (shipDayDiff === 0) {
        return 'Today';
    } else if (shipDayDiff < 0) {
        return `${shipDayDiff * -1} Days Ago`;
    } else {
        return `${shipDayDiff} Days`;
    }
}

export function calculateTimeInListDisplay(inListDate: Date): string {
    const diffMinutes = moment().diff(moment(inListDate), 'minutes');
    if (diffMinutes < 60) {
        return `~${diffMinutes} minutes`;
    }
    const diffHours = Math.trunc(diffMinutes / 60);
    if (diffHours < 24) {
        return `~${diffHours} hours`;
    }
    const diffDays = Math.trunc(diffHours / 24);
    return `~${diffDays} days`;
}
