import {
    LabsGqlOrderItemPartialDentureClaspType as GqlClaspType,
    LabsGqlOrderItemPartialDentureCoverageType as GqlCoverageType,
    type LabsGqlOrderItemPartialDentureClasp as GqlPartialDentureClasp,
} from '@orthly/graphql-schema';
import {
    type IOrderItemPartialDentureClasp as PartialDentureClasp,
    type IOrderItemV2DTO,
    type IPartialDentureItem,
    LabOrderItemSKUType as SKUType,
    OrderItemPartialDentureClaspType as ClaspType,
    OrderItemPartialDentureCoverageType as CoverageType,
    OrderItemV2Utils,
} from '@orthly/items';
import { belongsToEnum } from '@orthly/runtime-utils';

// TODO: Move these functions to a shared partial denture util.

/** Helper function for displaying the text of a clasp type. */
export function displayClaspType(val: ClaspType): string {
    switch (val) {
        case ClaspType.MetalRest:
            return 'Metal (rest)';
        case ClaspType.ValplastTCS:
            return 'Valplast/TCS';
        default:
            return val;
    }
}

/** Filter partial dentures. */
export function filterPartials(items: IOrderItemV2DTO[]): IPartialDentureItem[] {
    return items.filter(OrderItemV2Utils.itemIsType(SKUType.Partial));
}

/** Get the coverage type from a partial denture. */
export function getCoverageType(item: IPartialDentureItem): CoverageType | null {
    if (item.coverage_type) {
        return item.coverage_type;
    }
    const value = item.preference_fields.find(
        field => field.field_id === 'partial-coverage-type-order-item-meta',
    )?.value;
    return typeof value === 'string' && belongsToEnum(value, CoverageType) ? value : null;
}

/** Map a partial denture clasp to its GQL counterpart. */
export function mapClasp(clasp: PartialDentureClasp): GqlPartialDentureClasp {
    return { count: clasp.count, type: mapClaspType(clasp.type) };
}

/** Map a partial denture clasp type to its GQL counterpart. */
export function mapClaspType(claspType: ClaspType): GqlClaspType {
    if (claspType === ClaspType.ValplastTCS) {
        return GqlClaspType.ValplastTcs;
    }
    return GqlClaspType[claspType];
}

/** Map a partial denture coverage type to its GQL counterpart. */
export function mapCoverageType(coverageType: CoverageType): GqlCoverageType {
    return GqlCoverageType[coverageType];
}
