import { App } from './App';
import { LabPortalRootProvider } from './LabPortalRootProvider';
import './analytics/analyticsClient';
import './index.css';
import { ConfigService } from './utils';
import './utils/dayjs-utc';
import { setupOrthlyBrowser } from '@orthly/ui';
import '@orthly/ui/assets/fonts/BagossStandard.css';
import '@orthly/ui/assets/fonts/Inter.css';
import 'core-js/features/string/replace-all';
import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';

(window as any).PACKAGE_VERSION = ConfigService.logging.RELEASE;
setupOrthlyBrowser(ConfigService);

// Strict mode only runs in development, see docs here: https://reactjs.org/docs/strict-mode.html
ReactDOM.render(
    <React.StrictMode>
        <LabPortalRootProvider config={ConfigService}>
            <App />
        </LabPortalRootProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// eslint-disable-next-line sonarjs/no-collapsible-if
if (!process.env.BUNDLED_WITHOUT_WEBPACK) {
    if ((module as any).hot) {
        (module as any).hot.accept();
    }
}

if (process.env.SERVE_ESBUILD) {
    new EventSource('/esbuild').addEventListener('change', () => location.reload());
}
