import { ErrorCrashPage } from './ErrorCrashPage';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import * as Sentry from '@sentry/react';
import React from 'react';
import type { FallbackProps, ErrorBoundaryPropsWithComponent } from 'react-error-boundary';
import { ErrorBoundary } from 'react-error-boundary';

export interface OrthlyErrorFallbackProps extends FallbackProps {
    sentryEventId?: string;
}

interface OrthlyErrorBoundaryProps extends Omit<ErrorBoundaryPropsWithComponent, 'FallbackComponent'> {
    componentName?: string;
    FallbackComponent?: React.ComponentType<OrthlyErrorFallbackProps>;
}

// extra safety in case segment isn't loaded on the page
function trackErrorToAnalytics({ componentName }: { componentName?: string }) {
    try {
        BrowserAnalyticsClientFactory.Instance?.track('Component Crashed', { componentName });
    } catch (e: any) {
        console.log(e);
    }
}

// Source: https://git.io/JEbvC
const DefaultFallbackComponent: React.FC<FallbackProps> = ({ error }) => {
    const isLocal = window.location.hostname === 'localhost';
    return (
        <ErrorCrashPage
            title={'Something went wrong!'}
            subtitle={'Refresh the page or return to the home page.'}
            secondaryButtonAction={() => window.location.reload()}
            secondaryButtonText={'Refresh'}
            error={isLocal ? error : undefined}
            fullPage
        />
    );
};

export const OrthlyErrorBoundary: React.FC<OrthlyErrorBoundaryProps> = ({
    componentName,
    children,
    FallbackComponent,
    ...errorBoundaryProps
}) => {
    const [sentryEventId, setSentryEventId] = React.useState<string | undefined>();
    const errorHandler = (error: Error, { componentStack }: { componentStack: string }) => {
        console.error(error);
        const eventId = Sentry.captureException(error, { extra: { componentStack, componentName }, level: 'fatal' });
        setSentryEventId(eventId);
        trackErrorToAnalytics({ componentName });
        if (errorBoundaryProps.onError) {
            errorBoundaryProps.onError(error, { componentStack });
        }
    };
    const OrthlyFallbackComponent = FallbackComponent
        ? (fallbackProps: FallbackProps) => <FallbackComponent sentryEventId={sentryEventId} {...fallbackProps} />
        : DefaultFallbackComponent;
    return (
        <ErrorBoundary {...errorBoundaryProps} FallbackComponent={OrthlyFallbackComponent} onError={errorHandler}>
            {children}
        </ErrorBoundary>
    );
};

export const withErrorBoundary = <OwnProps extends any = any>(
    Wrapped: React.FC<OwnProps>,
    errorProps?: OrthlyErrorBoundaryProps,
): React.FC<OwnProps> => {
    return props => (
        <OrthlyErrorBoundary {...errorProps}>
            <Wrapped {...props} />
        </OrthlyErrorBoundary>
    );
};
