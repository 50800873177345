import { Tooltip } from '@orthly/ui-primitives';
import React from 'react';

/**
 * Wrapper providing a tooltip, if tooltip text was specified. The MUI documentation says that passing a falsey value as the title prop of its Tooltip
 * will disable the tooltip, but I found this to not be the case. This component has the additional benefit of displaying the tooltip even when the
 * children are disabled.
 */
export const OptionalTooltip: React.FC<{ title?: React.ReactNode; children: React.ReactElement }> = ({
    title,
    children,
}) => {
    return title ? (
        <Tooltip title={title}>
            {/* Wrapping the children in another element, i.e. the `span`, allows the tooltip to be shown even when the children are disabled. */}
            <span>{children}</span>
        </Tooltip>
    ) : (
        <>{children}</>
    );
};
