import { styled } from '@orthly/ui-primitives';
import React from 'react';

const WithTopAndBottomBorders = styled('div')({
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    fontFamily: 'Inter Semibold, Verdana, sans-serif',
    alignSelf: 'center',
    textWrap: 'nowrap',
    '&.pan-label-name-section': {
        gridArea: 'name',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    '&.pan-label-tags-section': {
        gridArea: 'tags',
        textAlign: 'end',
    },
});

interface PanLabelCenterSectionProps {
    children: React.ReactNode;
    variant: 'name' | 'tags';
}

export const PanLabelCenterSection: React.VFC<PanLabelCenterSectionProps> = ({ children, variant }) => {
    return <WithTopAndBottomBorders className={`pan-label-${variant}-section`}>{children}</WithTopAndBottomBorders>;
};
