import { useKeyboardCapture } from './useKeyboardCapture';
import { FlossPalette, InputAdornment, TextField, SearchIcon } from '@orthly/ui-primitives';
import React from 'react';

interface QROrderSearchBaseProps {
    setActiveSearch: (search: string) => void;
    disabled?: boolean;
}

/**
 * Generic order search component, allows both text and QR code scanning.
 *
 * @remarks QR code scanning is enabled via keyboard capture, this may create issues if there are other inputs on the page.
 *
 * @param {(search: string) => void} setActiveSearch - Callback to set the active search when user clicks enter
 * @returns {JSX.Element}
 */
export const QRCodeOrderSearch: React.VFC<QROrderSearchBaseProps> = ({ setActiveSearch, disabled }) => {
    const [searchInput, setSearchInput] = React.useState<string>('');
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    useKeyboardCapture(inputRef);

    const onChange = React.useCallback(
        ({ target }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setSearchInput(target.value ?? '');
        },
        [setSearchInput],
    );

    const onKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            if (event.key === 'Enter') {
                setActiveSearch(searchInput);
                setSearchInput('');
            }
        },
        [setActiveSearch, searchInput],
    );

    return (
        <TextField
            variant={'standard'}
            disabled={disabled}
            placeholder={'Press Enter to submit search'}
            label={`Search by Order #, ID, or QR code`}
            style={{ margin: `2px 0`, width: `100%` }}
            value={searchInput}
            InputProps={{
                inputRef,
                onKeyDown,
                onChange,
                autoFocus: true,
                style: { borderRadius: '0px' },
                endAdornment: (
                    <InputAdornment position={'end'} style={{ paddingRight: 12 }}>
                        <SearchIcon style={{ color: FlossPalette.GRAY }} />
                    </InputAdornment>
                ),
                disableUnderline: true,
            }}
        />
    );
};
