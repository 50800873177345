import type { LabPortalDownloadableOrder } from '../screens/manufacturer/screens/LabPortalOrderListPages/components/LabPortalOrderActions/LabPortalOrderActions.types';
import type { OrderFileDownloadConfig, DownloadRecord } from '@orthly/veneer';
import { useFeatureFlag } from '@orthly/veneer';

export const useOrderDownloadConfig = (): OrderFileDownloadConfig => {
    const { value: autoRotateManufacturingFiles } = useFeatureFlag('autoRotateManufacturingFiles');
    const autoRotate = autoRotateManufacturingFiles ?? false;
    return { autoRotate } as OrderFileDownloadConfig;
};

export const getScanFilesNew = (order: LabPortalDownloadableOrder): DownloadRecord[] => {
    const patient_name = order.patientName.trim();
    const orderNumber = order.partnerOrderNumber ? `#${order.partnerOrderNumber}` : '';

    if (order.scanExportSanitizedUrl) {
        return [
            {
                recordPath: order.scanExportSanitizedUrl,
                fileName: `${patient_name} ${orderNumber}`,
                orderId: order.id,
            },
        ];
    }

    return [];
};
