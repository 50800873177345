import type { FlossInputConfig } from '../primitives/Input/FlossInput';
import { FlossInputThemeProvider } from '../primitives/Input/FlossInput';
import { TextField, useScreenIsMobile } from '@orthly/ui-primitives';
import type { ChangeEvent } from 'react';
import React from 'react';

export interface SimpleCodeInputProps extends FlossInputConfig {
    length: number;
    value: string;
    error?: boolean;
    fullWidth?: boolean;
    onChange: (value: string) => void;
    onCompletion: (code: string) => void;
}

export const SimpleCodeInput: React.FC<SimpleCodeInputProps> = props => {
    const { length, value, error, fullWidth, onChange, flossInputConfig, onCompletion } = props;
    const isMobile = useScreenIsMobile();

    const handleChange = (evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
        const target = evt.target;
        const targetValue = target.value.trim();
        const inputIsValid = !isNaN(parseInt(targetValue));

        if (!inputIsValid && targetValue !== '') {
            return;
        }

        const newValue = inputIsValid ? targetValue : ' ';

        if (newValue.length === 1) {
            const updatedCode = value.substring(0, index) + newValue + value.substring(index + 1);
            onChange(updatedCode);

            if (!inputIsValid) {
                return;
            }

            const nextSibling = target.parentElement?.parentElement?.nextElementSibling?.children[0]
                ?.children[0] as HTMLTextAreaElement | null;
            if (nextSibling) {
                nextSibling.focus();
            }

            if (updatedCode.length === length) {
                onCompletion(updatedCode);
                target.blur();
            }
        } else if (newValue.length === length) {
            onChange(newValue);
            onCompletion(newValue);
            target.blur();
        }
    };

    const handleKeyDown = (evt: React.KeyboardEvent<HTMLDivElement>) => {
        const target = evt.target as HTMLInputElement;

        target.setSelectionRange(0, target.value.length);

        if (evt.key !== 'Backspace' || target.value !== '') {
            return;
        }

        const previousSibling = target.parentElement?.parentElement?.previousElementSibling?.children[0]
            ?.children[0] as HTMLTextAreaElement | null;
        if (previousSibling) {
            previousSibling.focus();
        }
    };

    const handleFocus = (evt: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { target } = evt;

        target.setSelectionRange(0, target.value.length);
    };

    const codeItems = React.useMemo(() => {
        const codeArray = value.split('');
        const items = [];

        for (let i = 0; i < length; i++) {
            const char = codeArray[i] ?? '';
            items.push(!isNaN(parseInt(char)) ? char : '');
        }

        return items;
    }, [value, length]);

    return (
        <FlossInputThemeProvider flossInputConfig={flossInputConfig}>
            {codeItems.map((data, index) => {
                let marginRight: string | number = 16;
                if (isMobile || fullWidth) {
                    marginRight = index !== length - 1 ? `calc(10% / ${length - 1})` : 0;
                }
                return (
                    <TextField
                        variant={'standard'}
                        type={'numeric'}
                        key={index}
                        placeholder={'0'}
                        inputProps={{ maxLength: length, style: { textAlign: 'center' } }}
                        value={data}
                        error={error}
                        onChange={e => handleChange(e, index)}
                        onFocus={e => handleFocus(e)}
                        onKeyDown={e => handleKeyDown(e)}
                        autoFocus={index === 0}
                        inputRef={input => input && index === 0 && value.length === 0 && input.focus()}
                        style={{
                            width: isMobile || fullWidth ? `calc(90% / ${length})` : 56,
                            marginRight,
                        }}
                    />
                );
            })}
        </FlossInputThemeProvider>
    );
};
