import type { LabOrderActionProps } from '../index';
import type {
    LabsGqlCreateOrderHoldMutationVariables,
    LabsGqlRemoveOrderHoldMutationVariables,
} from '@orthly/graphql-operations';
import { useCreateOrderHoldMutation, useRemoveOrderHoldMutation, useOrderRefetch } from '@orthly/graphql-react';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { HoldUtils } from '@orthly/shared-types';
import { QuickForm, RootActionDialog, useChangeSubmissionFn, SimpleSelect } from '@orthly/ui';
import { Button } from '@orthly/ui-primitives';
import { EditHoldForm } from '@orthly/veneer';
import React from 'react';

// TODO: Create a storybook for this

const AddHoldToOrder: React.FC<LabOrderActionProps> = props => {
    const { order } = props;
    const refetchOrder = useOrderRefetch();

    const [submitMtn] = useCreateOrderHoldMutation();
    type Vars = LabsGqlCreateOrderHoldMutationVariables['data'];
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const { submit, submitting, open, setOpen } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Order marked on hold!', {}],
        onSuccess: () => refetchOrder(order.id),
    });
    const [labOrDdc, setLabOrDdc] = React.useState<`lab` | `ddc`>(`lab`);

    return (
        <RootActionDialog
            title={'Place Order On Hold'}
            loading={submitting}
            open={open}
            setOpen={setOpen}
            style={{ padding: 0 }}
            content={
                <>
                    <SimpleSelect
                        options={[
                            { value: `lab`, label: `Fabrication issue` },
                            { value: `ddc`, label: `Design issue` },
                        ]}
                        value={labOrDdc}
                        onChange={labOrDdc => setLabOrDdc(labOrDdc as `lab` | `ddc`)}
                        label={`Issue type`}
                        style={{ marginBottom: 8 }}
                    />
                    <EditHoldForm
                        show_expected_delay_days={order.manufacturer_sla.version === 3}
                        can_show_practice={false}
                        show_only_lab_visible_categories={labOrDdc === `lab`}
                        onSubmit={async hold => {
                            await submit({
                                orderId: order.id,
                                hold_details: HoldUtils.deconstructHold(hold),
                                holdReason: await HoldUtils.describeHold(hold),
                                show_hold_status_to_practice: hold.show_to_practice,
                                expected_delay_days: hold.expected_delay_days,
                            });
                        }}
                        labOrder={order}
                    />
                </>
            }
            CustomButton={buttonProps => (
                <Button {...buttonProps} fullWidth={false} variant={'text'} startIcon={'StopIcon'}>
                    Mark On Hold
                </Button>
            )}
        />
    );
};

const RemoveHoldFromOrder: React.FC<LabOrderActionProps> = props => {
    type Vars = LabsGqlRemoveOrderHoldMutationVariables['data'];
    const { order } = props;
    const [submitMtn] = useRemoveOrderHoldMutation();
    const refetchOrder = useOrderRefetch();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const { submit, submitting, open, setOpen } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Order hold removed!', {}],
        onSuccess: () => refetchOrder(order.id),
    });
    return (
        <RootActionDialog
            title={'Remove Order From On Hold'}
            loading={submitting}
            open={open}
            setOpen={setOpen}
            style={{ padding: 0 }}
            content={
                <QuickForm<Omit<Vars, 'orderId'>>
                    fields={{
                        reason: {
                            type: 'text',
                            label: 'Why is this order being removed from hold?',
                            fieldProps: { multiline: true, minRows: 3, maxRows: 100 },
                        },
                    }}
                    initialValues={{}}
                    onSubmit={async result => {
                        await submit({
                            reason: result.reason,
                            orderId: order.id,
                        });
                    }}
                />
            }
            CustomButton={buttonProps => (
                <Button {...buttonProps} fullWidth={false} variant={'text'} startIcon={'TrashCanIcon'}>
                    Remove Order Hold
                </Button>
            )}
        />
    );
};

export const AddOrRemoveHoldButton: React.FC<LabOrderActionProps> = props => {
    const { order } = props;
    if (order.shipped_to_dandy === true) {
        return null;
    }

    if (order.status === LabsGqlLabOrderStatus.OnHold) {
        return <RemoveHoldFromOrder {...props} />;
    }
    if (order.status === LabsGqlLabOrderStatus.New || order.status === LabsGqlLabOrderStatus.Fabrication) {
        return <AddHoldToOrder {...props} />;
    }
    return null;
};
