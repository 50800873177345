import { LabPortalOrderListPanCategoryFilterTag } from './LabPortalOrderListPanCategoryFilterTag';
import { LabPortalOrderListViewProductLineFilterTag } from './LabPortalOrderListProductLineFilterTag';
import { LabPortalOrderListViewShipDateFilterTag } from './LabPortalOrderListShipDateFilterTag';
import type { LabsGqlFilterCriteriaSubmissionInput } from '@orthly/graphql-schema';
import { LabsGqlFilterComparator } from '@orthly/graphql-schema';
import { OrderFilterIdEnum } from '@orthly/shared-types';
import { FilterIcon, SimpleInput } from '@orthly/ui';
import { Button, Grid, Popover } from '@orthly/ui-primitives';
import { startCase } from 'lodash';
import React from 'react';

export const LabPortalOrderListViewFilterTag: React.VFC<{
    filterField: string;
    currentFilterValue: any;
    addFilter: (filter: LabsGqlFilterCriteriaSubmissionInput) => void;
}> = ({ filterField, currentFilterValue, addFilter }) => {
    const [filterTagOpen, setFilterTagOpen] = React.useState(false);
    const [filterValue, setFilterValue] = React.useState<string | undefined>(currentFilterValue);
    const anchorRef = React.useRef<HTMLButtonElement | null>(null);

    if (filterField === OrderFilterIdEnum.order_product_line) {
        return (
            <LabPortalOrderListViewProductLineFilterTag currentFilterValue={currentFilterValue} addFilter={addFilter} />
        );
    }

    if (filterField === 'ship_by_date') {
        return (
            <LabPortalOrderListViewShipDateFilterTag currentFilterValue={currentFilterValue} addFilter={addFilter} />
        );
    }

    if (filterField === OrderFilterIdEnum.lab_pan_category) {
        return <LabPortalOrderListPanCategoryFilterTag currentFilterValue={currentFilterValue} addFilter={addFilter} />;
    }

    return (
        <Grid>
            <Button
                ref={anchorRef}
                variant={'secondary'}
                onClick={() => setFilterTagOpen(filterTagOpen => !filterTagOpen)}
            >
                <FilterIcon /> {startCase(filterField)}
                {filterValue ? `: ${filterValue}` : ''}
            </Button>
            <Popover
                anchorEl={anchorRef.current}
                open={filterTagOpen}
                onClose={() => {
                    setFilterTagOpen(false);
                    addFilter({
                        filter_id: filterField,
                        comparator: LabsGqlFilterComparator.Equals,
                        comparison_value: filterValue,
                    });
                }}
            >
                <SimpleInput
                    fullWidth
                    label={'Type a value...'}
                    value={filterValue}
                    onChange={value => setFilterValue(value)}
                />
            </Popover>
        </Grid>
    );
};
