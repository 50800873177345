import { useMergedMuiClasses, SidebarWidth } from '../../util';
import type { RequireChildrenProps, WithClassesProp } from '../../util/floss.types';
import { stylesFactory } from '../../util/stylesFactory';
import type { Theme } from '@orthly/ui-primitives';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

export type MainContainerSidebarCount = 0 | 1 | 2;

export type MainContainerClassKey = 'root' | 'content';

const useStyles = stylesFactory<{ sidebars: MainContainerSidebarCount }, MainContainerClassKey>((theme: Theme) => ({
    root: props => ({
        position: 'relative',
        maxWidth: `calc(100% - ${SidebarWidth * (props.sidebars || 1)}px)`,
        maxHeight: '100vh',
        overflowY: 'scroll',
        overflowX: 'hidden',
        flexWrap: 'nowrap',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        [theme.breakpoints.down('lg')]: {
            maxWidth: `unset`,
        },
    }),
    content: {
        padding: 40,
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            padding: 24,
        },
    },
}));

interface MainContainerProps extends RequireChildrenProps, WithClassesProp<MainContainerClassKey> {
    sidebars?: MainContainerSidebarCount;
    toolbar?: React.ReactNode;
}

/**
 * Main section container, excluding navigation and sidebars
 */
export const MainContainer: React.FC<MainContainerProps> = props => {
    const classes = useMergedMuiClasses<MainContainerClassKey>(
        useStyles({ sidebars: props.sidebars ?? 0 }),
        props.classes,
    );
    return (
        <Grid container direction={'column'} className={classes.root}>
            {props.toolbar ?? null}
            <Grid container className={classes.content}>
                {props.children}
            </Grid>
        </Grid>
    );
};
