import type { BatchShippingDestinationAddress } from '../hooks/useBatchShipDestinationAddress.graphql';
import { BatchShipSummaryBox } from './BatchShipSummaryBox';
import { FlossPalette, Text } from '@orthly/ui-primitives';
import React from 'react';

interface BatchShipDestinationSummaryProps {
    remainingToShipToday: number;
    loading: boolean;
    mailingAddress: BatchShippingDestinationAddress | null;
}

/**
 * Renders the Practice's mailing address and the number of unshipped orders that are due to ship today.
 */
export const BatchShipDestinationSummary: React.VFC<BatchShipDestinationSummaryProps> = props => {
    const { remainingToShipToday, mailingAddress, loading } = props;
    return (
        <BatchShipSummaryBox title={'Destination'}>
            {mailingAddress ? (
                <Text variant={'body1'} style={{ marginTop: 8 }}>
                    {mailingAddress.street_one}
                    {mailingAddress?.street_two && `, ${mailingAddress?.street_two}`}
                    <br />
                    {mailingAddress.city}, {mailingAddress.state} {mailingAddress.postal_code}
                </Text>
            ) : (
                <Text variant={'body1'}>{loading ? 'Loading address...' : ' Error loading address'}</Text>
            )}
            <Text variant={'h6'} style={{ width: '100%', marginTop: 8 }}>
                Remaining expected to ship today:{' '}
                <span
                    style={{
                        color: remainingToShipToday === 0 ? FlossPalette.ATTENTION_FOREGROUND : FlossPalette.GREEN,
                    }}
                >
                    {remainingToShipToday}
                </span>
            </Text>
        </BatchShipSummaryBox>
    );
};
