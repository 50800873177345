import type { RequireChildrenProps } from '../util';
import { stylesFactory } from '../util';
import { LoadBlocker } from './Loaders';
import type { DialogProps, DialogContentProps, DialogTitleProps } from '@orthly/ui-primitives';
import { Dialog, DialogActions, DialogContent, DialogTitle, FlossPalette, Icon, Text } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    paper: {
        minWidth: 350,
        padding: 40,
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        position: 'sticky',
        top: 0,
        zIndex: 3,
        backgroundColor: '#fff',
        alignItems: 'center',
        padding: 0,
    },
    contentRoot: { padding: 0 },
    closeIconWrapper: { padding: 8, position: 'relative' },
    closeIcon: {
        width: 32,
        height: 32,
        margin: 8,
        padding: 4,
        backgroundColor: FlossPalette.TAN,
        cursor: 'pointer',
        color: FlossPalette.BLACK,
        position: 'absolute',
        top: 0,
        right: 0,
    },
}));

interface SimpleDialogProps extends RequireChildrenProps {
    open: boolean;
    onClose: (reason: 'backdropClick' | 'escapeKeyDown' | 'closeButton') => void;
    title: React.ReactNode | string;
    loading?: boolean;
    actions?: React.ReactNode;
    hideCloseButton?: boolean;
    DialogProps?: Partial<DialogProps>;
    DialogTitleProps?: Partial<DialogTitleProps>;
    DialogContentProps?: DialogContentProps;
}

export const SimpleDialog: React.FC<SimpleDialogProps> = props => {
    const { open, onClose, hideCloseButton } = props;
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            onClose={(_e, reason) => onClose(reason)}
            maxWidth={'sm'}
            fullWidth
            PaperProps={{ className: classes.paper }}
            {...props.DialogProps}
        >
            {!hideCloseButton && (
                <Icon icon={'CloseIcon'} className={classes.closeIcon} onClick={() => onClose('closeButton')} />
            )}
            <DialogTitle {...props.DialogTitleProps} className={clsx(classes.title, props.DialogTitleProps?.className)}>
                {typeof props.title === 'string' ? (
                    <Text variant={'h4'} component={'span'}>
                        {props.title}
                    </Text>
                ) : (
                    props.title
                )}
            </DialogTitle>
            <LoadBlocker blocking={!!props.loading}>
                <DialogContent
                    {...props.DialogContentProps}
                    className={clsx(classes.contentRoot, props.DialogContentProps?.className)}
                >
                    {props.children}
                </DialogContent>
                {props.actions && (
                    <DialogActions style={{ padding: '12px 0 0', width: '100%' }}>{props.actions}</DialogActions>
                )}
            </LoadBlocker>
        </Dialog>
    );
};
