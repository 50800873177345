import type {
    TextVariant as TextVariantUiPrimitives,
    TextColor as TextColorUiPrimitives,
    TextProps as TextPropsUiPrimitives,
} from '@orthly/ui-primitives';
import {
    Text as TextUiPrimitives,
    Green as GreenUiPrimitives,
    Blue as BlueUiPrimitives,
    DarkGray as DarkGrayUiPrimitives,
    Medium as MediumUiPrimitives,
    Red as RedUiPrimitives,
} from '@orthly/ui-primitives';

/**
 * @deprecated Import directly from ui-primitives instead as TextVariant. The API may change slightly.
 */
export type TypographyVariant = TextVariantUiPrimitives;
/**
 * @deprecated Import directly from ui-primitives TextColor instead. The API may change slightly.
 */
export type TypographyColor = TextColorUiPrimitives;
/**
 * @deprecated Import directly from ui-primitives TextProps instead. The API may change slightly.
 */
export type TypographyProps = TextPropsUiPrimitives;
/**
 * @deprecated Import directly from ui-primitives Text instead. The API may change slightly.
 */
export const Typography = TextUiPrimitives;
/**
 * @deprecated Import directly from ui-primitives Text primitive instead. The API may change slightly.
 */
export const Green = GreenUiPrimitives;
/**
 * @deprecated Import directly from ui-primitives Text primitive instead. The API may change slightly.
 */
export const Blue = BlueUiPrimitives;
/**
 * @deprecated Import directly from ui-primitives Text primitive instead. The API may change slightly.
 */
export const Medium = MediumUiPrimitives;
/**
 * @deprecated Import directly from ui-primitives Text primitive instead. The API may change slightly.
 */
export const DarkGray = DarkGrayUiPrimitives;
/**
 * @deprecated Import directly from ui-primitives Text primitive instead. The API may change slightly.
 */
export const Red = RedUiPrimitives;
