import { usePracticeAppSelector } from '../redux';
import { usePartnerUiAction } from '../redux/ui.actions';
import { useOpenOrderDetailPage } from '../screens/labs/LabsUtils';
import { useQuery } from '@apollo/client';
import { useSearchAutocomplete } from '@orthly/dentin';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import type { ItemSearchResults, ItemSearchControls } from '@orthly/graphql-react';
import { useOrdersByIds, useStableWhileLoading } from '@orthly/graphql-react';
import type { LabsGqlStackCodeOrderDetails } from '@orthly/graphql-schema';
import type { OrdersSpotlightSearchItemProps } from '@orthly/veneer';
import { HotkeysManager, SpotlightSearch, OrdersSpotlightSearchItem } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

const OrderIDByStackCode_Query = graphql(`
    query getOrderIdByStackCode($stackCode: String!) {
        getOrderIdByStackCode(stackCode: $stackCode) {
            order_id
            stack_code
        }
    }
`);

interface StackCodeSearchProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    openOrder: (orderId: string) => void;
}

function sanitizeStackCodeInput(input: string) {
    // '-' is the delimiter used when printing pan code labels
    const splitCode = input.split('-');

    if (splitCode.length === 1) {
        // no prefix or suffix
        return splitCode[0]?.toUpperCase();
    }
    if (splitCode.length === 3) {
        // both prefix and suffix
        return splitCode[1]?.toUpperCase();
    }
    if (splitCode.length === 2) {
        // either prefix or suffix
        if (splitCode[1]?.startsWith('*') || splitCode[1]?.startsWith('#')) {
            // Assume all suffixes start with '*' or '#'
            return splitCode[0]?.toUpperCase();
        }
        // assume prefix
        return splitCode[1]?.toUpperCase();
    }
    // fall through case, just use the input
    return input?.toUpperCase();
}

function useGetOrderByStackCode(
    controls?: ItemSearchControls,
    initialValue?: string,
): ItemSearchResults<LabsGqlLabOrderFragment> {
    const [_search, _setSearch] = React.useState<string | undefined>(initialValue);
    const search = controls?.search ?? _search;
    const setSearchInternal = controls?.setSearch ?? _setSearch;
    const setSearch = React.useCallback(
        (search?: string) => {
            setSearchInternal(search);
        },
        [setSearchInternal],
    );

    const skip = !search || search.length < 3;

    const { data, loading: loadingStackCode } = useQuery<{ getOrderIdByStackCode: LabsGqlStackCodeOrderDetails }>(
        OrderIDByStackCode_Query,
        { variables: { stackCode: search }, skip },
    );

    const resultSearchIDs = React.useMemo(
        () =>
            data?.getOrderIdByStackCode && !loadingStackCode ? ([data.getOrderIdByStackCode.order_id] as string[]) : [],
        [data, loadingStackCode],
    );
    const orderIds = useStableWhileLoading(resultSearchIDs, loadingStackCode);

    const { orders: rawOrders, loading: loadingOrder } = useOrdersByIds(orderIds);
    const orders = rawOrders.filter((o): o is LabsGqlLabOrderFragment => !!o);
    return {
        search,
        setSearch,
        items: orders,
        skipQuery: skip,
        loading: loadingStackCode || loadingOrder,
    };
}

const StackCodeSearch = ({ open, setOpen, openOrder }: StackCodeSearchProps) => {
    const { search, loading, items: orders, onInputChange } = useSearchAutocomplete(openOrder, useGetOrderByStackCode);
    const options = React.useMemo(() => {
        return _.sortBy(orders, o => -new Date(o.created_at).valueOf()).map<OrdersSpotlightSearchItemProps>(order => ({
            value: order.id,
            label: `${order.patient.first_name} ${order.patient.last_name}`,
            status: order.status,
            created_at: order.created_at,
            doctor_name: order.doctor_name,
        }));
    }, [orders]);

    return (
        <SpotlightSearch<OrdersSpotlightSearchItemProps>
            onSelectOption={value => {
                onInputChange(null);
                setOpen(false);
                openOrder(value);
            }}
            setSearch={text => onInputChange(text ? sanitizeStackCodeInput(text) ?? null : null)}
            search={search}
            options={options}
            open={open}
            noOptionsText={'Type to search...'}
            hotkey={'searchStackCode'}
            onClose={() => setOpen(false)}
            loading={loading}
            placeholder={'Search by stack code'}
            renderOption={OrdersSpotlightSearchItem}
        />
    );
};

export const StackCodeSpotlightSearch: React.FC = () => {
    const open = usePracticeAppSelector(s => s.ui.activeSpotlight === 'searchStackCode');
    const setOpen = usePartnerUiAction('SET_STACKCODE_SEARCH');

    const toggleShowingSearch = React.useCallback(() => {
        setOpen(!open);
    }, [open, setOpen]);
    useHotkeys(HotkeysManager.keyCombo('searchStackCode'), toggleShowingSearch, [toggleShowingSearch]);

    const openOrder = useOpenOrderDetailPage();
    return <StackCodeSearch open={open} setOpen={setOpen} openOrder={openOrder} />;
};
