import { useViewModel } from './MarkInFabrication.vm';
import { LoadBlocker } from '@orthly/ui';
import { Button } from '@orthly/ui-primitives';
import React from 'react';

type Props = {
    blocking: boolean;
    onClick: () => void;
};

/** Button for marking an order in fabrication, with confirmation pop-up. */
const ConfirmButton: React.VFC<Props> = ({ blocking, onClick }) => {
    return (
        <LoadBlocker blocking={blocking}>
            <Button
                fullWidth
                style={{ height: '100%' }}
                variant={'contained'}
                onClick={() => {
                    if (!blocking && window.confirm('Change status to in fabrication?')) {
                        onClick();
                    }
                }}
            >
                Mark In Fabrication
            </Button>
        </LoadBlocker>
    );
};

type WithVmProps = {
    salesOrderId: string;
};

/** Button with view model for marking an order in fabrication, with confirmation pop-up. */
export const MarkInFabricationButton: React.VFC<WithVmProps> = ({ salesOrderId }) => {
    const { submit, submitting } = useViewModel(salesOrderId);
    return <ConfirmButton blocking={submitting} onClick={submit} />;
};

export const forStorybook = { ConfirmButton };
