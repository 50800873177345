import type { LabOrderForLabPortal } from '../../LabsUtils';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { SimpleCopyToClipboard } from '@orthly/dentin';
import type { LabsGqlOrder, LabsGqlDoctorPreferencesFragment } from '@orthly/graphql-operations';
import type { LabsGqlNotificationGroupDto } from '@orthly/graphql-schema';
import {
    LabsGqlPortalShipShippingSpeed,
    LabsGqlDoctorNotificationChannel,
    LabsGqlWhoReceivesDoctorNotifications,
} from '@orthly/graphql-schema';
import { OrderFormatUtils } from '@orthly/shared-types';
import { stylesFactory, FlossPalette, Collapse, Grid, Text, Typography, Skeleton } from '@orthly/ui-primitives';
import {
    OrderDetailFactRow,
    OrderDetailBlock,
    useFeatureFlag,
    OrderDetailCancelAndResubmitBlock,
    ScanExportSourceToScannerNameMap,
} from '@orthly/veneer';
import moment from 'moment-timezone';
import React from 'react';

interface OrderDetailSummaryTitleProps {
    order?: LabsGqlOrder;
}

const ShippingSpeedToText: { [key in LabsGqlPortalShipShippingSpeed]: string } = {
    [LabsGqlPortalShipShippingSpeed.Overnight]: 'Overnight',
    [LabsGqlPortalShipShippingSpeed.TwoDay]: 'Two-day',
    [LabsGqlPortalShipShippingSpeed.ThreeDay]: 'Three-day',
    [LabsGqlPortalShipShippingSpeed.Ground]: 'Ground',
};

const NameForWhoReceivesNotification: { [key in LabsGqlWhoReceivesDoctorNotifications]: string } = {
    [LabsGqlWhoReceivesDoctorNotifications.Dr]: 'Doctor',
    [LabsGqlWhoReceivesDoctorNotifications.Assistant]: 'My assistant',
    [LabsGqlWhoReceivesDoctorNotifications.Admin]: 'Office administrator',
    [LabsGqlWhoReceivesDoctorNotifications.Other]: 'Other',
};

const NameForNotificationChannel: { [key in LabsGqlDoctorNotificationChannel]: string } = {
    [LabsGqlDoctorNotificationChannel.Sms]: 'SMS',
    [LabsGqlDoctorNotificationChannel.Email]: 'Email',
};

const notifyPrefForPotentialIssues = (grouped_notification_preferences: LabsGqlNotificationGroupDto): string => {
    const notification_methods = [];
    if (
        grouped_notification_preferences.review_required_email ||
        grouped_notification_preferences.status_change_email
    ) {
        notification_methods.push(NameForNotificationChannel.email);
    }
    if (grouped_notification_preferences.review_required_sms || grouped_notification_preferences.status_change_sms) {
        notification_methods.push(NameForNotificationChannel.sms);
    }
    return notification_methods.join(' and ') || 'Not set';
};

const GENDER_INITIALS: Record<string, string> = { Male: `M`, Female: `F` };
const useStyles = stylesFactory(() => ({
    patientOrderIndicator: {
        backgroundColor: FlossPalette.DARK_TAN,
        height: 16,
        width: 16,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 2,
        marginTop: 2,
    },
    separator: {
        width: '100%',
        height: '1px',
        background: FlossPalette.DARK_TAN,
        margin: '10px 0',
    },
}));

const OrderDetailSummaryTitle: React.FC<OrderDetailSummaryTitleProps> = props => {
    const { order } = props;
    const { patient } = order ?? {};
    const classes = useStyles();
    return (
        <Grid container item alignItems={`center`} spacing={2} wrap={`nowrap`}>
            <Grid item>
                <Text variant={'h5'}>
                    {!order && <Skeleton animation={'wave'} variant={'text'} style={{ width: 250 }} />}
                    {patient?.first_name} {patient?.last_name}
                </Text>
            </Grid>
            {order?.patient_order_count && (
                <Grid item style={{ paddingLeft: 0 }}>
                    <Grid container className={classes.patientOrderIndicator}>
                        <Text variant={'caption'} style={{ color: FlossPalette.GRAY, fontWeight: 500, lineHeight: 0 }}>
                            {order?.patient_order_count}
                        </Text>
                    </Grid>
                </Grid>
            )}
            <Grid item>
                <Typography color={`textSecondary`} style={{ whiteSpace: 'nowrap' }}>
                    {patient?.birthday && moment().diff(moment(patient.birthday), 'years')}{' '}
                    {patient?.gender && GENDER_INITIALS[patient.gender]}
                </Typography>
            </Grid>
        </Grid>
    );
};

interface OrderDetailSummaryProps {
    order: LabOrderForLabPortal;
    manufacturerName?: string;
    partnerName?: string;
    addressLabel?: string;
    treatmentPlanner?: string;
    displayDoctorPrefs?: boolean;
    doctorPrefs?: LabsGqlDoctorPreferencesFragment;
    doctorAction?: React.ReactNode;
    addressAction?: React.ReactNode;
    manufacturerAction?: React.ReactNode;
    waxupAction?: React.ReactNode;
    designerTypeAction?: React.ReactNode;
    designPrepAction?: React.ReactNode;
    designSeparateModelAction?: React.ReactNode;
    designReviewAction?: React.ReactNode;
    designReview2Action?: React.ReactNode;
    designCapabilityRequirements?: string;
    bottomContent?: React.ReactNode;
    impersonateAction?: React.ReactNode;
    orderEditValue?: string;

    variant: 'left' | 'right' | 'full';
    openOrder: (orderId: string, e?: React.MouseEvent) => void;
    canSeeCancelAndResubmitBlock?: boolean;
}

// eslint-disable-next-line max-lines-per-function
const OrderDetailSummaryContent: React.FC<OrderDetailSummaryProps> = props => {
    const {
        order,
        manufacturerName,
        partnerName,
        impersonateAction,
        manufacturerAction,
        displayDoctorPrefs,
        doctorPrefs,
        doctorAction,
        waxupAction,
        designerTypeAction,
        designPrepAction,
        designSeparateModelAction,
        designReviewAction,
        designCapabilityRequirements,
        addressAction,
        addressLabel,
        treatmentPlanner,
        variant,
        designReview2Action,
        openOrder,
        canSeeCancelAndResubmitBlock,
        orderEditValue,
    } = props;
    const { aligner_case, replaced_by_ids, cancelled_order_id } = order;
    const shipping_method_override = order.fulfillment.current.shipping_method_override;
    const hasCancelAndResubmitBlock =
        canSeeCancelAndResubmitBlock && ((replaced_by_ids ?? []).length > 0 || !!cancelled_order_id);
    const classes = useStyles();
    const enableNuancedCr = !!useFeatureFlag('enableNuancedCr').value;
    const contactInfo = OrderFormatUtils.bestContactInfo({
        doctor_name: order.doctor_name,
        contact_email: doctorPrefs?.contact_email,
        contact_phone_call_number: doctorPrefs?.contact_phone_call_number,
        contact_phone: doctorPrefs?.contact_phone,
        best_call_hour: doctorPrefs?.best_call_hour,
        timezone: doctorPrefs?.timezone,
    });
    const { value: hidePracticeDetails = false } = useFeatureFlag('hidePracticeDetailsWherePossible');

    return (
        <OrderDetailBlock
            title={
                <div>
                    <SimpleCopyToClipboard
                        onClick={() =>
                            BrowserAnalyticsClientFactory.Instance?.track('Order Number Copied', {
                                $groups: { order: order.id },
                            })
                        }
                        tooltip={'Copy order ID'}
                        value={order.order_number}
                    >{`Order ID: #${order.order_number}`}</SimpleCopyToClipboard>
                    {order.stack_code && <div style={{ fontSize: '14px' }}>{`Stack Code: ${order.stack_code}`}</div>}
                </div>
            }
            variant={variant}
            actions={impersonateAction}
        >
            <OrderDetailSummaryTitle order={order} />
            {props.children}
            {hasCancelAndResubmitBlock && (
                <>
                    <div className={classes.separator} />
                    <OrderDetailCancelAndResubmitBlock
                        cancelled_order_id={cancelled_order_id}
                        replaced_by_ids={replaced_by_ids}
                        openOrder={openOrder}
                    />
                </>
            )}
            <div className={classes.separator} />
            <OrderDetailFactRow title={'Doctor'} value={contactInfo.doctor} action={doctorAction} />
            <Collapse in={!!displayDoctorPrefs && !!doctorPrefs}>
                <Grid container style={{ padding: '8px 0px 8px 8px' }}>
                    <OrderDetailFactRow flexBasisLeft={100} title={'Email'} value={contactInfo.email} />
                    <OrderDetailFactRow flexBasisLeft={100} title={'Call #'} value={contactInfo.call_number} />
                    <OrderDetailFactRow flexBasisLeft={100} title={'Sms #'} value={contactInfo.sms_number} />
                    <OrderDetailFactRow
                        flexBasisLeft={100}
                        title={'Best Call Hour'}
                        value={contactInfo.best_call_hour}
                    />
                    <OrderDetailFactRow
                        flexBasisLeft={100}
                        title={'Best Contact'}
                        value={
                            doctorPrefs?.who_receives_dr_notifications
                                ? NameForWhoReceivesNotification[doctorPrefs.who_receives_dr_notifications]
                                : 'Not set'
                        }
                    />
                    <OrderDetailFactRow
                        flexBasisLeft={100}
                        title={'Notify for Issues'}
                        value={
                            doctorPrefs?.grouped_notification_preferences
                                ? notifyPrefForPotentialIssues(doctorPrefs.grouped_notification_preferences)
                                : 'Not set'
                        }
                    />
                </Grid>
            </Collapse>

            {partnerName && <OrderDetailFactRow title={`Practice`} value={partnerName} />}
            {!hidePracticeDetails && (
                <OrderDetailFactRow
                    title={`Address`}
                    value={addressLabel ?? 'Address deleted'}
                    action={addressAction}
                />
            )}
            {manufacturerName && (
                <OrderDetailFactRow title={`Lab`} value={manufacturerName} action={manufacturerAction} />
            )}
            {aligner_case && treatmentPlanner && (
                <OrderDetailFactRow
                    title={`Treatment planner`}
                    value={treatmentPlanner}
                    valueStyle={{ width: '100%' }}
                />
            )}
            {!aligner_case && waxupAction && (
                <OrderDetailFactRow title={`Design Preview`} value={waxupAction} valueStyle={{ width: '100%' }} />
            )}
            {!aligner_case && designerTypeAction && (
                <OrderDetailFactRow title={`Designer`} value={designerTypeAction} valueStyle={{ width: '100%' }} />
            )}
            {!aligner_case && designPrepAction && (
                <OrderDetailFactRow title={`Prep`} value={designPrepAction} valueStyle={{ width: '100%' }} />
            )}
            {!aligner_case && designSeparateModelAction && (
                <OrderDetailFactRow
                    title={`Sep. Model`}
                    value={designSeparateModelAction}
                    valueStyle={{ width: '100%' }}
                />
            )}
            {!aligner_case && designReviewAction && (
                <OrderDetailFactRow title={`Design QC`} value={designReviewAction} valueStyle={{ width: '100%' }} />
            )}
            {!aligner_case && designReview2Action && (
                <OrderDetailFactRow title={`Double QC?`} value={designReview2Action} valueStyle={{ width: '100%' }} />
            )}
            {designCapabilityRequirements && (
                <OrderDetailFactRow
                    title={`Design Levels`}
                    value={designCapabilityRequirements}
                    valueStyle={{ width: '100%' }}
                />
            )}
            <OrderDetailFactRow
                title={'Shipping'}
                value={ShippingSpeedToText[shipping_method_override ?? order.shipping_method]}
            />
            <OrderDetailFactRow
                title={'Source'}
                value={`${ScanExportSourceToScannerNameMap[order.scan_export.scan_source]} ${order.scan_export.scan_source_version ?? ''}`}
            />
            {orderEditValue && !enableNuancedCr && <OrderDetailFactRow title={'Item edit'} value={orderEditValue} />}
            {props.bottomContent}
        </OrderDetailBlock>
    );
};

export const LabPortalOrderDetailSummary: React.FC<
    Omit<OrderDetailSummaryProps, 'order'> & { order?: LabOrderForLabPortal }
> = props => {
    const { order, variant } = props;
    if (!order) {
        // empty block
        return (
            <OrderDetailBlock title={`Order #`} variant={variant}>
                <OrderDetailSummaryTitle order={order} />
                <OrderDetailFactRow
                    title={'Doctor'}
                    value={<Skeleton animation={'wave'} variant={'text'} style={{ width: 150 }} />}
                />
                <OrderDetailFactRow
                    title={'Address'}
                    value={<Skeleton animation={'wave'} variant={'text'} style={{ width: 150 }} />}
                />
            </OrderDetailBlock>
        );
    }
    return <OrderDetailSummaryContent {...props} order={order} />;
};
