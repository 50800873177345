import { QCWarningMessage, getOrderErrorDescription } from './QcStationError';
import { useQcStationAction } from './state/QcStation.actions';
import { useQcStationPropSelector } from './state/QcStation.selectors';
import { QcStationDialog } from './state/QcStation.state';
import type { LabsGqlLabOrderFragment, LabsGqlQcSubIssueFragment } from '@orthly/graphql-operations';
import { SimpleSelect, RootActionDialog } from '@orthly/ui';
import { FlossPalette, stylesFactory, Grid, createStyles, Button } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() =>
    createStyles({
        passFail: {
            padding: 8,
            borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
            '& .MuiButton-root': {
                flexGrow: 1,
                margin: 8,
            },
            '&&': {
                width: `100%`,
                justifyContent: `space-between`,
            },
        },
        fail: {
            backgroundColor: `${FlossPalette.ATTENTION} !important`,
            color: FlossPalette.WHITE,
        },
        errorMessage: {
            color: FlossPalette.ATTENTION,
            marginTop: 8,
        },
    }),
);

interface QcPassFailProps {
    canSubmit: boolean;
    order: LabsGqlLabOrderFragment;
    qcFailReasons: LabsGqlQcSubIssueFragment[];
    pass: () => Promise<unknown>;
    fail: () => Promise<unknown>;
}

export const QcPassFail: React.VFC<QcPassFailProps> = ({ qcFailReasons, pass, fail, canSubmit, order }) => {
    const classes = useStyles();
    const setFailOpen = useQcStationAction('TOGGLE_FAIL_OPEN');
    const setPassOpen = useQcStationAction('TOGGLE_PASS_OPEN');
    const setFailReason = useQcStationAction('SET_FAIL_REASON');
    const { qcDialog, failReason } = useQcStationPropSelector(['qcDialog', 'failReason']);

    const [errorMessage, setErrorMessage] = React.useState('');

    const handleFailClick = async () => {
        if (!failReason) {
            setErrorMessage('An issue must be selected to progress.');
        } else {
            setErrorMessage('');
            await fail();
        }
    };

    const qcWarning = order?.qc_error ? (
        <QCWarningMessage
            title={`You are submitting QC for an order that is ${getOrderErrorDescription(order.qc_error)}`}
            details={[]}
            style={{ marginBottom: 16 }}
        />
    ) : null;

    return (
        <Grid item container direction={`row`} className={classes.passFail}>
            <RootActionDialog
                open={qcDialog === QcStationDialog.QcFail}
                setOpen={setFailOpen}
                title={`Mark QC failure`}
                loading={false}
                buttonText={`Fail`}
                buttonProps={{
                    fullWidth: false,
                    className: classes.fail,
                    endIcon: 'ThumbsDownIcon',
                }}
                content={
                    <>
                        {qcWarning}
                        <SimpleSelect
                            options={qcFailReasons.map(({ name }) => ({ value: name }))}
                            label={`Fail reason`}
                            value={failReason}
                            onChange={value => {
                                setFailReason(value);
                                setErrorMessage('');
                            }}
                        />
                        {errorMessage && <div className={classes.errorMessage}>{errorMessage}</div>}

                        <Grid item container style={{ paddingTop: 16, justifyContent: `flex-end` }}>
                            <Button
                                variant={`primary`}
                                className={classes.fail}
                                onClick={handleFailClick}
                                disabled={!failReason}
                            >
                                Submit failure
                            </Button>
                        </Grid>
                    </>
                }
            />

            <RootActionDialog
                open={!!order?.qc_error && qcDialog === QcStationDialog.QcPass}
                setOpen={order?.qc_error ? setPassOpen : pass}
                title={`Mark QC passing`}
                loading={false}
                buttonText={`Pass`}
                buttonProps={{
                    fullWidth: false,
                    disabled: !canSubmit,
                    endIcon: 'ThumbsUpIcon',
                }}
                content={
                    <>
                        {qcWarning}
                        <Grid item container style={{ justifyContent: `flex-end` }}>
                            <Button variant={`primary`} onClick={pass}>
                                Submit pass
                            </Button>
                        </Grid>
                    </>
                }
            />
        </Grid>
    );
};
