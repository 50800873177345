import React from 'react';

/**
 * referentially stabilizes a callback function so it's the same every render
 *
 * this is useful so the callback can be passed to a memoized child component without causing it to re-render
 * or so the callback can be used in a hook's dependency list without causing it to re-run on every render
 *
 * uses a ref on the inside to store the unstable callback so the latest version of it is available everywhere
 *
 * @param unstable_callback a callback that changes every render
 * @returns an equivalent callback with will not change on render
 */
export const useStableCallback = <Args extends unknown[], Return extends unknown>(
    unstable_callback: (...args: Args) => Return,
): ((...args: Args) => Return) => {
    const unstable_callback_ref = React.useRef<(...args: Args) => Return>(
        // this type assertion is clearly untrue
        // that's okay though because it's a temporary value and is overwritten immediately
        // see the `.current = ...` line below
        undefined as unknown as (...args: Args) => Return,
    );

    unstable_callback_ref.current = unstable_callback;

    // giving this variable a name makes it easier to understand
    const stable_callback = React.useCallback((...args: Args): Return => {
        return unstable_callback_ref.current(...args);
        // because refs are mutable they stay up to date without needing to be included in the dependency array
    }, []);

    return stable_callback;
};
