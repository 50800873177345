import type {
    LabsGqlLabPriceLedgerSummaryResultFragment as LabPriceLedgerSummary,
    LabsGqlLabPriceLedgerResultFragment as LabPriceLedger,
    LabsGqlLabPriceLedgerPriceDtoFragment as LabPrice,
} from '@orthly/graphql-operations';
import { stylesFactory, FlossPalette } from '@orthly/ui-primitives';

const ManufacturerListItemClassName = 'manufacturer-list-item';

export const useStyles = stylesFactory(theme => ({
    root: {
        position: 'relative',
        flexWrap: 'nowrap',
        height: '100vh',
        maxWidth: '100%',
        [theme.breakpoints.down('lg')]: {
            maxWidth: 'unset',
        },
    },
    toolbarContainer: {
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        paddingLeft: 16,
        paddingRight: 16,
    },
    virtualColumnRoot: {
        position: 'relative',
        borderRight: `1px solid ${FlossPalette.DARK_TAN}`,
        width: '15%',
        maxWidth: '20%',
        height: '100%',
        [`&:hover .${ManufacturerListItemClassName}`]: {
            opacity: '1 !important' as any,
        },
    },
    mainContainer: {
        maxHeight: '100vh',
        overflow: 'scroll',
        flexWrap: 'nowrap',
    },
    tabs: {
        borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
        padding: '0 12px',
    },
    tab: {
        whiteSpace: 'nowrap',
        maxWidth: 'unset',
    },
    contentContainer: {
        minHeight: 0,
        overflow: 'auto',
        paddingTop: '24px',
        paddingBottom: '24px',
    },
}));

export interface BillingColumnProps {
    ledgers: Record<string, LabPriceLedgerSummary>;
    loading: boolean;
    onClick: (month: string) => void;
}

export interface BillingMonthDetailRootProps {
    summary: LabPriceLedgerSummary | null;
    ledger: LabPriceLedger[];
    prices_adjustable?: boolean;
    period_begin: Date | null;
    period_end: Date | null;
    loading: boolean;
    refetch: () => Promise<any>;
}

export type BillingDetailTableData = {
    id: string;
    order_id: string;
    order_number: string;
    fulfillment_number: number;
    order_ship_date: Date;
    patient_name: string;
    order_price_cents: number;
    prices: LabPrice[];
    has_lab_adjustment: boolean;
    reviewed: boolean;
};

export type BillingDetailPanelTableData = {
    id: string;
    order_id: string;
    fulfillment_number: number;
    price_idx: number;
    price_id: string;
    price_name: string;
    unit_price_cents: number;
    count: number;
    order_price_cents: number;
    original_unit_price_cents: number;
    existing_override_description: string | null;
};

export type CanceledOrdersDetailTableData = {
    id: string;
    order_id: string;
    order_number: string;
    fulfillment_number: number | null;
    canceled_at: Date;
    patient_name: string;
    percentage_completed: number | null;
};

export interface ManufacturerBillingCsvRow {
    order_id: string;
    fulfillment_number: string;
    price_idx: string;
    item_id: string;
    price_id: string;
    'Order Number': string;
    'Ship Date': string;
    Patient: string;
    Product: string;
    'Adjusted Unit Price': string;
    'Original Unit Price': string;
    Quantity: string;
    'Adjusted Total': string;
    'Original Total': string;
    'Adjustment Reason': string;
}

export type LedgerTabDefinition = {
    label: string;
    hidden: boolean;
    onTabSwitch: Function;
};
