import { stylesFactory } from '../../util/stylesFactory';
import { FlossPalette, Grid, Typography } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    root: {
        paddingTop: 16,
        paddingBottom: 16,
        alignItems: 'center',
        '&:not(:first-of-type)': {
            borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
        },
    },
    headline: {
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.up('xs')]: {
            flexWrap: 'nowrap',
        },
    },
}));

interface SidebarContentItemProps {
    title: React.ReactNode;
    value?: React.ReactNode;
}

export const SidebarContentItem: React.FC<SidebarContentItemProps> = props => {
    const classes = useStyles();
    return (
        <Grid container className={classes.root}>
            <Grid container className={classes.headline}>
                <Grid container item xs={12} sm={'auto'}>
                    <Typography variant={'body1'} color={'textSecondary'} style={{ fontWeight: 500 }}>
                        {props.title}
                    </Typography>
                </Grid>
                {props.value !== undefined && (
                    <Grid container item xs={12} sm={'auto'} justifyContent={'flex-end'}>
                        <Typography variant={'h6'} color={'textPrimary'}>
                            {props.value}
                        </Typography>
                    </Grid>
                )}
            </Grid>
            {props.children}
        </Grid>
    );
};
