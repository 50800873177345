import { calculateShipDateDisplay } from '../../../utils/DisplayUtils';
import { useLabPortalOrderListViewContext } from '../../providers/LabPortalOrderListViewProvider';
import type { IndicatorFlagKey } from '../shared/IndicatorFlags';
import { IndicatorFlags } from '../shared/IndicatorFlags';
import type { ColumnDef } from '../shared/LabPortalOrderListView';
import { LabPortalOrderListView, LabPortalListViewNameColumn } from '../shared/LabPortalOrderListView';
import { useLabPortalOnHoldOrdersByListViewQuery } from '@orthly/graphql-react';
import { LoadBlocker } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

export const LabPortalOnHoldListView: React.VFC = () => {
    const { filters, sort } = useLabPortalOrderListViewContext();
    const columns: ColumnDef[] = React.useMemo(
        () => [
            {
                label: 'Name',
                cell: order => (
                    <LabPortalListViewNameColumn
                        orderId={order.id}
                        orderNumber={order.orderNumber}
                        patientName={order.patientName}
                    />
                ),
            },
            { label: 'Order Product Line', cell: order => order.orderProductLine },
            {
                label: 'Flags',
                cell: order =>
                    order.listFlags?.map((flag: IndicatorFlagKey) => <Grid key={flag}>{IndicatorFlags[flag]}</Grid>),
            },
            {
                label: 'Ship Date',
                cell: order => calculateShipDateDisplay(order.shipDate),
            },
        ],
        [],
    );

    const { data, loading } = useLabPortalOnHoldOrdersByListViewQuery({
        fetchPolicy: 'no-cache',
        variables: { criteria: filters, labPortalSort: sort },
    });

    return (
        <LoadBlocker blocking={loading}>
            <LabPortalOrderListView orders={data?.orders ?? []} columns={columns} />
        </LoadBlocker>
    );
};
