import FlossToothCracked from '../../../assets/images/FlossToothCracked.svg';
import { stylesFactory } from '../../util';
import { FlossPalette, Button, Text } from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    layout: {
        width: '100%',

        maxHeight: '100%',
        maxWidth: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr',
        placeItems: 'center',
        gap: 24,
    },
    partialPage: {
        height: '100%',
        marginTop: '10vh',
    },
    fullPage: {
        height: '100vh',
        marginTop: '0',
        placeContent: 'center',
    },
    buttonContainer: {
        marginTop: 24,
        minWidth: 200,
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: 24,
    },
    twoActionButtons: {
        gridTemplateColumns: '1fr 1fr',
        minWidth: 400,
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr',
            minWidth: 200,
        },
    },
    image: {
        // The height of the image, to prevent layout shift
        minHeight: 121,
    },
    errorText: {
        background: FlossPalette.ATTENTION_BACKGROUND,
        color: FlossPalette.ATTENTION_FOREGROUND,
        borderRadius: 4,
        padding: 12,
    },
}));

export const ErrorCrashPage: React.VFC<{
    title: string;
    subtitle: string;
    secondaryButtonAction: () => void;
    secondaryButtonText: string;
    error?: Error;
    fullPage?: boolean;
}> = ({ title, subtitle, secondaryButtonAction, secondaryButtonText, error, fullPage }) => {
    const classes = useStyles();

    return (
        <div className={cx(classes.layout, fullPage ? classes.fullPage : classes.partialPage)}>
            <img className={classes.image} src={FlossToothCracked} alt={'An unhappy, cracked tooth'} />

            <Text variant={'h5'} medium>
                {title}
            </Text>

            {error && (
                <pre className={classes.errorText}>
                    <code>{error?.toString()}</code>
                </pre>
            )}

            <Text variant={'body2'}>{subtitle}</Text>

            <div className={cx(classes.buttonContainer, secondaryButtonText && classes.twoActionButtons)}>
                {secondaryButtonText && (
                    <Button
                        variant={'secondary'}
                        startIcon={'RefreshIcon'}
                        onClick={() => secondaryButtonAction()}
                        size={'large'}
                    >
                        {secondaryButtonText}
                    </Button>
                )}
                <Button
                    variant={'primary'}
                    startIcon={'HomeIcon'}
                    onClick={() => {
                        // We can't use `useHistory` because we might be outside the react-router context
                        window.location.pathname = '/';
                    }}
                    size={'large'}
                >
                    Home
                </Button>
            </div>
        </div>
    );
};
