import type { EnhancedTableProps } from './Table.types';
import { SORT_ORDER } from './Table.types';
import { TableCell, TableHead, TableRow, TableSortLabel, Icon } from '@orthly/ui-primitives';
import React from 'react';

export function SortableHeaders({ columns, sortOrder, orderBy, handleSort }: EnhancedTableProps) {
    const handleSortEvent = (property: string) => (event: React.MouseEvent<unknown>) => {
        handleSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {columns.map(column =>
                    Boolean(column.sortable ?? true) ? (
                        <TableCell
                            key={column.id}
                            align={column.numeric || column.alignRight ? 'right' : 'left'}
                            sortDirection={orderBy === column.id ? sortOrder : false}
                        >
                            <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? sortOrder : SORT_ORDER.asc}
                                onClick={handleSortEvent(column.id)}
                                IconComponent={props => <Icon icon={'ArrowDownIcon'} {...props} />}
                            >
                                {column.label}
                            </TableSortLabel>
                        </TableCell>
                    ) : (
                        <TableCell key={column.id} align={column.numeric || column.alignRight ? 'right' : 'left'}>
                            {column.label}
                        </TableCell>
                    ),
                )}
            </TableRow>
        </TableHead>
    );
}
