import { graphql } from '@orthly/graphql-inline-react';

export const BatchShipCandidateFragment_Doc = graphql(`
    fragment BatchShipCandidateOrder on BatchShipCandidateOrderDTO {
        lab_order_id
        practice_id
        order_number
        mailing_address_id
        manufacturer_id
        patient_name
        label_url
        service_level
        tracking_number
        stack_code
        scanned_in_shipping_at
        packing_completed_at
    }
`);
