import type { LabsGqlFilterCriteriaSubmissionInput, LabsGqlLabPortalListOrdersSort } from '@orthly/graphql-schema';
import React from 'react';

interface LabPortalOrderListViewContextVal {
    filters: LabsGqlFilterCriteriaSubmissionInput[];
    sort: LabsGqlLabPortalListOrdersSort | null;
}

const LabPortalOrderListViewProvider = React.createContext<LabPortalOrderListViewContextVal>({
    filters: [],
    sort: null,
});

export const LabPortalOrderListViewCtxProvider: React.FC<{
    filters?: LabsGqlFilterCriteriaSubmissionInput[];
    sort?: LabsGqlLabPortalListOrdersSort;
}> = props => {
    return (
        <LabPortalOrderListViewProvider.Provider
            value={{
                filters: props.filters ?? [],
                sort: props.sort ?? null,
            }}
        >
            {props.children}
        </LabPortalOrderListViewProvider.Provider>
    );
};

export function useLabPortalOrderListViewContext() {
    return React.useContext<LabPortalOrderListViewContextVal>(LabPortalOrderListViewProvider);
}
