/* eslint-disable max-lines */
import { AnalyticsClient } from '../../../analytics/analyticsClient';
import { useRootStyles } from '../../../components/Containers';
import { usePanLabels, useShowUnboxingSlipOnOrderDetailsPage } from '../../../utils/EnabledFeaturesForLab';
import { useOrderDownloadConfig } from '../../../utils/OrderFileDownloadUtils';
import { useRecordLabSlipPrinted } from '../../manufacturer/state/manufacturer.actions.graphql';
import type { LabOrderActionProps } from '../actions';
import { ShipOrderModal } from '../actions/practitioners/ShipmentDialog/ShipOrderModal';
import { useDownloadOrderFiles } from '../components/useDownloadOrderFiles';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { LabsGqlOrder, LabsGqlOrderLabSlipPreviewFragment } from '@orthly/graphql-operations';
import {
    useOrderRefetch,
    useMarkPartialsFulfillmentReturnReceivedMutation,
    useConfirmReturnReceivedMutation,
    usePendingReturnForOrderQuery,
} from '@orthly/graphql-react';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { OrderItemV2Utils } from '@orthly/items';
import { UuidUtils } from '@orthly/runtime-utils';
import { LoadBlocker, ToolbarContainer, useChangeSubmissionFn } from '@orthly/ui';
import type { Theme } from '@orthly/ui-primitives';
import {
    stylesFactory,
    FlossPalette,
    useScreenIsMobile,
    Grid,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Popover,
    useMediaQuery,
    MoreVertIcon,
} from '@orthly/ui-primitives';
import {
    LabSlipV2,
    UnboxingSlip,
    UploadDesignFileAction,
    useFeatureFlag,
    usePrintableState,
    DesignOrderRevisionManufacturerFilesDownload,
    PanLabelSeries,
} from '@orthly/veneer';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = stylesFactory(theme => ({
    root: {
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        [theme.breakpoints.down('lg')]: {
            height: 'auto',
            padding: '0 8px',
            position: 'sticky',
            top: 0,
            zIndex: theme.zIndex.appBar - 1,
            background: FlossPalette.TAN,
        },
    },
}));

type TopBarPrintButtonProps = Omit<LabOrderActionProps, 'order'> & {
    order: LabsGqlOrderLabSlipPreviewFragment;
};
export const TopBarPrintButton: React.FC<TopBarPrintButtonProps> = props => {
    const rootClasses = useRootStyles();
    const { execute: recordLabSlipsViewedBulk, loading: recordLoading } = useRecordLabSlipPrinted([props.order.id]);
    const { printableIsOpen, openPrintable, closePrintable } = usePrintableState();

    const onClickPrint = React.useCallback(async () => {
        await recordLabSlipsViewedBulk();
        openPrintable();
    }, [recordLabSlipsViewedBulk, openPrintable]);

    return (
        <LoadBlocker blocking={recordLoading} ContainerProps={{ style: { width: 'auto', marginRight: '8px' } }}>
            <Button
                onClick={() => void onClickPrint()}
                startIcon={'PrintIcon'}
                variant={'ghost'}
                className={rootClasses.noSmallScreenDisplay}
            >
                RX
            </Button>
            {printableIsOpen && (
                <LabSlipV2 orderIds={[props.order.id]} autoPrintEnabled={true} onPrintComplete={closePrintable} />
            )}
        </LoadBlocker>
    );
};

const PanLabelPrintButton: React.VFC<{ orderId: string }> = ({ orderId }) => {
    const { printableIsOpen, openPrintable, closePrintable } = usePrintableState();

    const labOrderIds = React.useMemo(() => [orderId], [orderId]);

    return (
        <>
            {printableIsOpen && (
                <PanLabelSeries autoPrintEnabled={true} labOrderIds={labOrderIds} onPrintComplete={closePrintable} />
            )}
            <Button variant={'ghost'} onClick={openPrintable} startIcon={'PrintIcon'}>
                Pan Labels
            </Button>
        </>
    );
};

const DownloadButton: React.FC<{ order: LabsGqlOrder }> = props => {
    const { order } = props;
    const {
        trigger3oxzDownload,
        loading3oxz,
        triggerStlDownload,
        loadingStl,
        designAvailable,
        loadingDesign,
        triggerDesignDownload,
    } = useDownloadOrderFiles(order);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleDownload = (type: 'stl' | '3oxz' | 'design') => () => {
        setAnchorEl(null);
        switch (type) {
            case 'stl':
                triggerStlDownload?.().catch(console.error);
                return;
            case '3oxz':
                trigger3oxzDownload().catch(console.error);
                return;
            case 'design':
                triggerDesignDownload?.().catch(console.error);
                return;
        }
    };

    const { value: allowDownloadLabManufacturingDesignFiles } = useFeatureFlag('downloadLabManufacturingDesignFiles');
    const config = useOrderDownloadConfig();

    return (
        <Grid container style={{ width: 'auto', marginRight: '8px' }}>
            <LoadBlocker
                blocking={loading3oxz || loadingStl || loadingDesign}
                CircularProgressProps={{ style: { color: FlossPalette.STAR_GRASS }, color: 'inherit' }}
            >
                <Button variant={'ghost'} startIcon={'DownloadIcon'} onClick={handleClick}>
                    Download files
                </Button>
            </LoadBlocker>

            <Menu
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={handleDownload('3oxz')}>Download scan (.3oxz)</MenuItem>
                <MenuItem disabled={!triggerStlDownload} onClick={handleDownload('stl')}>
                    Download scan (.stl)
                </MenuItem>
                {designAvailable && (
                    <MenuItem disabled={!triggerDesignDownload} onClick={handleDownload('design')}>
                        Download design (.zip)
                    </MenuItem>
                )}
                {allowDownloadLabManufacturingDesignFiles && (
                    <DesignOrderRevisionManufacturerFilesDownload
                        orderIds={[order.id]}
                        rotated={config.autoRotate}
                        Button={({ onClick }) => (
                            <MenuItem
                                disabled={!order.design_file_path}
                                onClick={() => {
                                    setAnchorEl(null);
                                    onClick();
                                }}
                            >
                                Download manufacturer STL (.stl)
                            </MenuItem>
                        )}
                        onDownloadStarted={() => {
                            AnalyticsClient.track('Experiment Assignment Made', {
                                subjectType: 'order',
                                subjectId: order.id,
                                experimentId: 'stl-rotation',
                                variation: config.autoRotate ? 'variant' : 'control',
                                timestamp: new Date(),
                                subjectAttributes: {
                                    autoRotate: config.autoRotate,
                                    manufacturerId: order.manufacturer_id,
                                },
                            });
                        }}
                    />
                )}
            </Menu>
        </Grid>
    );
};

const MobileActionsMenu: React.FC<{ children: React.ReactNode[] }> = props => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'order-detail-menu-popover' : undefined;
    return (
        <>
            <IconButton onClick={event => setAnchorEl(event.currentTarget)} aria-describedby={id}>
                <MoreVertIcon style={{ color: FlossPalette.STAR_GRASS }} />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                PaperProps={{ style: { padding: 12 } }}
            >
                {React.Children.map(props.children, (child, idx) => (
                    <Grid key={idx} container>
                        {child}
                    </Grid>
                ))}
            </Popover>
        </>
    );
};

const UploadDesignFilesAction: React.FC<LabOrderActionProps> = props => {
    const { order } = props;
    // Don't allow labs to upload designs if this is an internally designed order
    if (order.fulfillment_workflow.configuration.internal_design_required) {
        return null;
    }
    return (
        <UploadDesignFileAction
            onClose={() => {}}
            refetchOrder={async () => {
                props.refetch();
            }}
            CustomButton={btnProps => (
                <Button startIcon={'CloudUploadIcon'} onClick={btnProps.onClick} variant={'ghost'}>
                    Upload Design
                </Button>
            )}
            order={props.order}
        />
    );
};

const UnboxingSlipDownload: React.VFC<{ orderId: string }> = ({ orderId }) => {
    const { printableIsOpen, openPrintable, closePrintable } = usePrintableState();

    return (
        <>
            {printableIsOpen && <UnboxingSlip orderId={orderId} onPrintComplete={closePrintable} />}
            <Button
                variant={'ghost'}
                onClick={() => {
                    openPrintable();
                    BrowserAnalyticsClientFactory.Instance?.track('Lab - Portal - Unboxing Slip Opened', {
                        $groups: { order: orderId },
                        displayLocation: 'order_page',
                    });
                }}
                startIcon={'PrintIcon'}
            >
                Unboxing
            </Button>
        </>
    );
};

const MarkPartialsFulfillmentReturnReceivedButton: React.VFC<{ lab_order_id: string; refetch: () => any }> = ({
    lab_order_id,
    refetch,
}) => {
    const [markReturnReceivedMtn] = useMarkPartialsFulfillmentReturnReceivedMutation({
        variables: { data: { lab_order_id } },
    });
    const { submit, submitting } = useChangeSubmissionFn(() => markReturnReceivedMtn(), {
        successMessage: () => [`Successfully marked return as received`, {}],
        errorMessage: () => [`Failed to mark return as received, please try again`, {}],
        onSuccess: () => {
            refetch();
        },
    });

    return (
        <Button
            variant={'ghost'}
            onClick={async () => {
                if (!submitting) {
                    await submit();
                }
            }}
            startIcon={'BoxIcon'}
            disabled={submitting}
        >
            Mark Partial Return Received
        </Button>
    );
};

const MarkRefabReturnReceivedButton: React.VFC<{ returnId: string; refetch: () => any }> = ({ returnId, refetch }) => {
    const [submitMtn] = useConfirmReturnReceivedMutation({ variables: { returnId } });
    const { submit, submitting } = useChangeSubmissionFn<any, []>(() => submitMtn(), {
        successMessage: () => [`Successfully marked return as received`, {}],
        errorMessage: () => [`Failed to mark return as received, please try again`, {}],
        onSuccess: () => {
            refetch();
        },
    });

    return (
        <Button
            variant={'ghost'}
            onClick={async () => {
                if (!submitting) {
                    await submit();
                }
            }}
            startIcon={'BoxIcon'}
            disabled={submitting}
        >
            Mark Return Received
        </Button>
    );
};

type OrderDetailTopBarActionsProps = {
    order: LabsGqlOrder;
    salesOrder: {
        createdAt: Date;
        id: string;
    };
};

const OrderDetailTopBarActions: React.FC<OrderDetailTopBarActionsProps> = props => {
    const { order, salesOrder } = props;
    const refetchOrder = useOrderRefetch();
    const actionProps = React.useMemo<LabOrderActionProps>(() => {
        return {
            items: OrderItemV2Utils.parseItems(order.items_v2),
            onError: () => {},
            onSuccess: () => refetchOrder(order.id),
            order,
            refetch: () => refetchOrder(order.id),
            salesOrderId: order.id,
        };
    }, [order, refetchOrder]);
    const isTablet = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));
    const isMobile = useScreenIsMobile();
    const Container = isTablet ? MobileActionsMenu : React.Fragment;
    const isOrderBeingFabricated =
        order && order.status === LabsGqlLabOrderStatus.Fabrication && order.fabrication_start_date;
    const hasPartialReturn = order.partials_fulfillment?.upcoming !== undefined;
    const {
        data,
        loading,
        refetch: refetchReturn,
    } = usePendingReturnForOrderQuery({
        skip: !UuidUtils.isUUID(order.id),
        variables: {
            orderId: order.id,
        },
    });
    const linkedReturnId = !loading && data?.pendingReturnForOrder?.id;
    const showPanLabels = usePanLabels(order.manufacturer_id);
    const showUnboxingSlip = useShowUnboxingSlipOnOrderDetailsPage(order.manufacturer_id);

    return (
        <Container>
            {linkedReturnId && (
                <MarkRefabReturnReceivedButton
                    returnId={linkedReturnId}
                    refetch={() => {
                        refetchOrder(order.id);
                        void refetchReturn();
                    }}
                />
            )}
            {hasPartialReturn && (
                <MarkPartialsFulfillmentReturnReceivedButton
                    lab_order_id={order.id}
                    refetch={() => {
                        refetchOrder(order.id);
                    }}
                />
            )}
            {!isMobile && <DownloadButton order={order} />}
            <TopBarPrintButton {...actionProps} />
            {showPanLabels && <PanLabelPrintButton orderId={order.id} />}
            {showUnboxingSlip && <UnboxingSlipDownload orderId={order.id} />}
            <UploadDesignFilesAction {...actionProps} />
            {isOrderBeingFabricated && order.shipped_to_dandy !== true && (
                <div style={{ marginLeft: 8 }}>
                    <ShipOrderModal
                        mailingAddressId={order.mailing_address_id}
                        salesOrder={salesOrder}
                        shippingSpeed={order.shipping_method}
                    />
                </div>
            )}
        </Container>
    );
};

type OrderDetailTopBarProps = {
    disableBackButton?: boolean;
    order?: LabsGqlOrder;
    salesOrder: {
        createdAt: Date;
        id: string;
    };
};

export const OrderDetailTopBar: React.FC<OrderDetailTopBarProps> = props => {
    const { order, salesOrder, disableBackButton } = props;
    const classes = useStyles();
    const history = useHistory();
    const onBack = React.useCallback(() => {
        history.goBack();
    }, [history]);
    return (
        <ToolbarContainer className={classes.root}>
            <Grid container style={{ width: 150 }}>
                {!!disableBackButton ? null : (
                    <Button
                        variant={'ghost'}
                        startIcon={'ChevronLeft'}
                        onClick={() => onBack()}
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        Back
                    </Button>
                )}
            </Grid>
            <Grid container justifyContent={'flex-end'} wrap={'nowrap'} alignItems={'center'}>
                {props.children}
                {order && salesOrder && <OrderDetailTopBarActions order={order} salesOrder={salesOrder} />}
            </Grid>
        </ToolbarContainer>
    );
};
