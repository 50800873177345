import { PanLabelLowerSection } from './PanLabelLowerSection';
import React from 'react';

export interface PanLabelNightGuardSpecificationsProps {
    __typename: 'NightGuardPanLabelInfo';
    arch?: string;
    canineGuidance?: boolean;
    anteriorRamp?: boolean;
    occlusalSurfaceType?: string;
    idealHeight?: string;
}

export const PanLabelNightGuardSpecifications: React.VFC<PanLabelNightGuardSpecificationsProps> = ({
    arch,
    canineGuidance,
    anteriorRamp,
    occlusalSurfaceType,
    idealHeight,
}) => {
    return (
        <PanLabelLowerSection variant={'specifications'}>
            {arch && <div>Arch: {arch}</div>}
            {canineGuidance !== undefined && <div>Canine Guidance: {canineGuidance ? 'Yes' : 'No'}</div>}
            {anteriorRamp !== undefined && <div>Anterior Ramp: {anteriorRamp ? 'Yes' : 'No'}</div>}
            {occlusalSurfaceType !== undefined && <div>Occlusal Surface Type: {occlusalSurfaceType}</div>}
            {idealHeight !== undefined && <div>Ideal Height: {idealHeight}</div>}
        </PanLabelLowerSection>
    );
};
