import { AddItemToSalesOrderModal } from '../../actions/manufacturers/AddItemToSalesOrderModal';
import { EditPartialDentureModal } from '../../actions/manufacturers/EditPartialDentureModal';
import { EditPennyweightModal } from '../../actions/manufacturers/EditPennyweightModal';
import { MarkInFabricationButton } from '../../actions/manufacturers/MarkInFabricationButton';
import { useViewModel } from './OrderActions.vm';
import type { LabsGqlLabOrderStatus as OrderStatus } from '@orthly/graphql-schema';
import type { IOrderItemV2DTO } from '@orthly/items';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

type ButtonGridProps = {
    enabledActions: {
        addItemToSalesOrder: boolean;
        editPartialDenture: boolean;
        editPennyweight: boolean;
        markInFabrication: boolean;
    };
    items: IOrderItemV2DTO[];
    salesOrderId: string;
};

/** Grid for action buttons on an order. */
export const ButtonGrid: React.FC<ButtonGridProps> = ({ enabledActions, items, salesOrderId }) => {
    return (
        <Grid container spacing={2} style={{ paddingTop: 8 }}>
            {enabledActions.markInFabrication && <MarkInFabricationButton salesOrderId={salesOrderId} />}
            {enabledActions.addItemToSalesOrder && <AddItemToSalesOrderModal salesOrderId={salesOrderId} />}
            {enabledActions.editPennyweight && <EditPennyweightModal items={items} salesOrderId={salesOrderId} />}
            {enabledActions.editPartialDenture && <EditPartialDentureModal items={items} salesOrderId={salesOrderId} />}
        </Grid>
    );
};

type OrderActionsGridProps = {
    items: IOrderItemV2DTO[];
    orderStatus: OrderStatus;
    salesOrderId: string;
};

/** Grid with view model for action buttons on an order. */
export const OrderActionsGrid: React.FC<OrderActionsGridProps> = ({ items, orderStatus, salesOrderId }) => {
    const { enabledActions } = useViewModel(items, orderStatus);
    return <ButtonGrid enabledActions={enabledActions} items={items} salesOrderId={salesOrderId} />;
};

export const forStorybook = { ButtonGrid };
