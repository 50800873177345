import { SortableHeaders } from './SortableHeaders';
import {
    TablePrimitive,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    TablePagination,
    styled,
} from '@orthly/ui-primitives';

const StyledPagination = styled(TablePagination)({
    display: 'block',
}) as typeof TablePagination;

const StyledTableRow = styled(TableRow)(() => ({
    '&:last-child td, &:last-child th': { border: 0 },
})) as typeof TableRow;

type TableCompoundProperties = typeof TablePrimitive & {
    Body: typeof TableBody;
    Header: typeof TableHead;
    Row: typeof StyledTableRow;
    Cell: typeof TableCell;
    SortLabel: typeof TableSortLabel;
    Pagination: typeof StyledPagination;
    SortableHeaders: typeof SortableHeaders;
};

export const Table = TablePrimitive as TableCompoundProperties;

Table.Body = TableBody;
Table.Header = TableHead;
Table.Row = StyledTableRow;
Table.Cell = TableCell;
Table.SortLabel = TableSortLabel;
Table.Pagination = StyledPagination;
Table.SortableHeaders = SortableHeaders;
