import type { LabsGqlOrderSlaFragment } from '@orthly/graphql-operations';
import { DateUtils } from '@orthly/shared-types';
import moment from 'moment';

export class SlaUtil {
    private static calculateDelayDays(committed: string | Date, actual: string | Date) {
        return moment(committed).endOf('day').diff(moment(actual).endOf('day'), 'days');
    }

    static calculateDaysPastDate(date: string | Date): number {
        return moment().endOf('day').diff(moment(date).endOf('day'), 'days');
    }

    static isManufacturerDueDateDelay(sla: LabsGqlOrderSlaFragment) {
        const delayDaysDiff = this.calculateDelayDays(sla.manufacturer_committed_due_date, sla.due_date);
        return delayDaysDiff < 0;
    }

    /**
     * Returns the ship by date visible in the order tracker and other similar spots in the portal.
     * This is meant to the be the latest estimate, and may change on various re-renders.
     * This should never be utilized in the lab slip or LabTrac.
     */
    static getOrderTrackerShipByDate(
        sla: Pick<LabsGqlOrderSlaFragment, 'manufacturer_committed_ship_by_date' | 'ship_by_date'>,
    ) {
        return DateUtils.getMaxDate([sla.manufacturer_committed_ship_by_date, sla.ship_by_date]);
    }
}
