import { useAddToBatchMutation } from '../hooks/useAddToBatchMutation.graphql';
import { Format } from '@orthly/runtime-utils';
import { DateUtils } from '@orthly/shared-types';
import { LoadBlocker } from '@orthly/ui';
import { FlossPalette, Text, Button } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

interface AddOrderToBatchButtonProps {
    searchedOrderId: string;
    containsSearchedOrder: boolean;
    packingCompletedDate: Date | null;
    ordersInBatch: { lab_order_id: string; tracking_number?: string | null }[];
}

/**
 * Button to switch the searched order to use the same tracking number as the other orders in the batch
 */
export const AddOrderToBatchButton: React.VFC<AddOrderToBatchButtonProps> = props => {
    const { searchedOrderId, ordersInBatch, containsSearchedOrder, packingCompletedDate } = props;
    const ordersInBatchIds = ordersInBatch.map(o => o.lab_order_id);
    const orderIds = _.uniq([searchedOrderId, ...ordersInBatchIds]);
    const { createBatch, loading, currentlyLoadingIds } = useAddToBatchMutation();

    // check if any of the orders in this batch are currently loading. we only use the order Ids in the batch because
    // the searched order will always be part of the mutation
    const thisBatchIsLoading = _.intersection(currentlyLoadingIds, ordersInBatchIds).length > 0;
    return (
        <LoadBlocker
            // we only want to indicate the button is loading if this is the batch currently loading
            // if the mutation is loading at all (e.g for another batch), the button is disabled (see below)
            blocking={thisBatchIsLoading}
            ContainerProps={{
                style: { width: 'auto', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-end' },
            }}
        >
            {packingCompletedDate && (
                <Text
                    variant={'body1'}
                    color={containsSearchedOrder ? 'GRAY' : 'ATTENTION'}
                    style={{ paddingRight: 8 }}
                >
                    Packing Completed:&nbsp;
                    <b>{DateUtils.relativeFormat(packingCompletedDate).replace('Today, ', '')}</b>
                </Text>
            )}
            {containsSearchedOrder ? (
                <Text variant={'body1'} style={{ color: FlossPalette.STAR_GRASS, fontWeight: 'bold' }}>
                    Order in batch!
                </Text>
            ) : (
                <Button
                    style={{ maxHeight: 32 }}
                    variant={'primary'}
                    disabled={loading}
                    onClick={() => {
                        const message = packingCompletedDate
                            ? `WARNING: This shipment completed packing ${Format.fromNow(packingCompletedDate, {
                                  max: 1,
                              })} ago. Are you sure you want to add this order to the batch?`
                            : '';
                        // if the shipment has been marked as "packing complete", we warn the user before adding the order to the batch
                        // TODO: In the future, we will want to hide these batches once we've confirmed the new workflow is behaving as expected
                        if (!packingCompletedDate || window.confirm(message)) {
                            void createBatch(orderIds);
                        }
                    }}
                >
                    Add to batch
                </Button>
            )}
        </LoadBlocker>
    );
};
