import { QueryString } from '@orthly/ui';
import _ from 'lodash';

export class PracticeUtils {
    // pass in location if you want to a different one, such as the result of `useLocation` from react-router.
    static getParsedQueryProperty(propertyName: string, search: string = window.location.search): string | undefined {
        const parsed = QueryString.parse(search);
        const value = parsed[propertyName];
        return typeof value === 'string' ? value : undefined;
    }
}

export function orderIdFromQRCodeUrl(qrCodeLink: string): string | undefined {
    try {
        // Order link format: <base-url>/<some-path>/:orderId?queryString
        // this gets all the path portions that are 36 chars long (UUID length)
        const uuidLengthPathPortions = new URL(qrCodeLink).pathname.split('/').filter(t => t.length === 36);
        // We'll assume the last 36 char portion of the url is the order id
        return _.last(uuidLengthPathPortions);
    } catch (_e: any) {
        return undefined;
    }
}
