let routerUrl = 'http://localhost:3005/graphql';
const remoteQaSlug = process.env.REMOTE_QA_SLUG || process.env.FALLBACK_REMOTE_QA_SLUG;

if (remoteQaSlug) {
    routerUrl = `https://router-${remoteQaSlug}.dandyqa.dev/graphql`;
}

export const QA_ENV_VARS = {
    REACT_APP_USE_QA: true,
    REACT_APP_GATEWAY_URL: routerUrl,
};
